import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { RowContainer } from '../CampaignExperienceBasics';
import Input from '../Input';
import styles from './CampaignExperienceConnections.module.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import ErrorContainer from '../ErrorContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOpenAiSuggestionsRequest,
  loginExclusiveAdvertiser,
  registerExclusiveAdvertiser,
} from 'containers/NewExperienceCampaignBuilderPage/actions';
import toasterVariants from 'constants/toasterVariants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import classNames from 'classnames';
import {
  makeSelectBusinessRulesVariables,
  makeSelectIsAuthenticated,
  makeSelectIsImpersonated,
  makeSelectTimezones,
} from 'containers/App/selectors';
import {
  makePlatformActionsSelector,
  makeSelectGetSuggestionsPending,
  makeSelectObjectiveSuggestions,
  makeSelectSuggestions,
  makeTasksSelector,
} from 'containers/NewExperienceCampaignBuilderPage/selectors';
import CampaignBuilderHeader from '../CampaignBuilderHeader';
import LoadingSpinner from 'components/Layout/LoadingSpinner';
import Select from '../Select';
import Slider from '../Slider';
import DatePicker from 'react-datepicker';
import { formatDate, today, addDays, now } from 'utils/date';
import { ReactComponent as DeleteBlackIcon } from 'assets/images/black_close_icon.svg';
import { ReactComponent as SyncIcon } from 'assets/images/sync.svg';

const CampaignExperienceConnections = forwardRef(
  (
    {
      setStep,
      setFormData,
      formData,
      selectedObjectives,
      setLoadingBtn,
      brandStory,
      importedCampaign,
    },
    ref
  ) => {
    const isAuth = useSelector(makeSelectIsAuthenticated());
    const tasks = useSelector(makeTasksSelector());
    const getSuggestionsPending = useSelector(
      makeSelectGetSuggestionsPending()
    );
    const businessRulesVariables = useSelector(
      makeSelectBusinessRulesVariables()
    );
    const isImpersonated = useSelector(makeSelectIsImpersonated());
    const platformActions = useSelector(makePlatformActionsSelector());
    const suggestions = useSelector(makeSelectSuggestions());
    const campaignSuggestions = useSelector(makeSelectObjectiveSuggestions());
    const [isRegen, setIsRegen] = useState(false);

    const dispatch = useDispatch();

    const campaignConnectionsSchema = yup
      .object({
        onsiteLocation: yup.boolean(),
        taskLocation: yup.boolean(),
        login: yup.boolean(),
        locationName: yup.string().when('onsiteLocation', {
          is: true,
          then: yup.string().trim().required('Location name field is required'),
        }),
        locationAddress: yup.string().when('onsiteLocation', {
          is: true,
          then: yup
            .string()
            .trim()
            .required('Location address field is required'),
        }),
        companyName: yup.string().when('login', {
          is: false,
          then: yup.string().trim().required('Company name field is required'),
        }),
        fullName: yup.string().when('login', {
          is: false,
          then: yup.string().trim().required('Full name field is required'),
        }),
        email: yup
          .string()
          .trim()
          .required('Email field is required')
          .email('Invalid email'),
        password: yup
          .string()
          .trim()
          .required('Password field is required')
          .matches(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
            'Password is not acceptable (min 8 chars including digits and uppercase chars)'
          ),
        title: yup.string().trim().required('Title is required'),
        description: yup.string().trim().required('Description is required'),
        startDate: yup
          .date()
          .typeError('Start Date is required')
          .required('Start Date is required')
          .min(
            new Date(Date.now() - 86400000),
            'Start Date cannot be in the past'
          ),
        startTime: yup
          .date()
          .typeError('Start Time is required')
          .required('Start Time is required'),
        timezone: yup.mixed().required('Timezone is required'),
      })
      .required();

    const campaignBasicsSchema = yup
      .object({
        title: yup.string().trim().required('Title is required'),
        description: yup.string().trim().required('Description is required'),
        startDate: yup
          .date()
          .typeError('Start Date is required')
          .required('Start Date is required')
          .min(
            new Date(Date.now() - 86400000),
            'Start Date cannot be in the past'
          ),
        startTime: yup
          .date()
          .typeError('Start Time is required')
          .required('Start Time is required'),
        timezone: yup.mixed().required('Timezone is required'),
      })
      .required();

    const {
      register,
      handleSubmit,
      setValue,
      control,
      getValues,
      formState: { errors },
      watch,
    } = useForm({
      defaultValues: {
        title: '',
        description: '',
        onsiteLocation: false,
        taskLocation: false,
        locationName: '',
        locationAddress: '',
        companyName: '',
        fullName: '',
        email: '',
        password: '',
        logo: null,
        campaignLogo: null,
        login: true,
        file: null,
        startDate: null,
        startTime: new Date(new Date().setHours(8, 0, 0, 0)),
        timezone: null,
        experienceWindow: 2,
      },
      delayError: 300,
      resolver: !isAuth
        ? yupResolver(campaignConnectionsSchema)
        : yupResolver(campaignBasicsSchema),
    });

    const startDateWatch = watch('startDate');
    const experienceWindowWatch = watch('experienceWindow');
    const title = watch('title');
    const description = watch('description');

    useEffect(() => {
      if (Object.keys(suggestions).length !== 0) {
        if (isRegen || !(formData.title || formData.description)) {
          setFormData((prev) => ({
            ...prev,
            title: suggestions.name,
            description: suggestions.description,
          }));
          setValue('description', suggestions.description);
          setValue('title', suggestions.name);
          setIsRegen(false);
        }

        const parts = suggestions.date.split('-');
        const mydate = new Date(parts[0], parts[1] - 1, parts[2]);
        setValue('startDate', mydate);
      }
    }, [suggestions, setValue]);

    const loginWatch = watch('login');

    const timezones = useSelector(makeSelectTimezones());

    useEffect(() => {
      if (importedCampaign) {
        setValue('description', importedCampaign.campaignDescription);
        setValue('title', importedCampaign.campaignName);
        setValue('experienceWindow', importedCampaign.experienceWindowInDays);
        setValue('startDate', new Date(importedCampaign.startDate));

        const timezone = timezones.find(
          (t) => t.name === importedCampaign.timezone
        );
        if (timezone) {
          setValue('timezone', {
            value: timezone.id,
            label: `${timezone.name} (${timezone.utc})`,
          });
        }
      }
    }, [importedCampaign, setValue, timezones]);

    useEffect(() => {
      if (Object.keys(formData).length) {
        if (formData.data?.onsiteLocation) {
          setValue('onsiteLocation', formData.data?.onsiteLocation);
        }
        if (formData.data?.taskLocation) {
          setValue('taskLocation', formData.data?.taskLocation);
        }
        if (formData.data?.locationName) {
          setValue('locationName', formData.data?.locationName);
        }
        if (formData.data?.locationAddress) {
          setValue('locationAddress', formData.data?.locationAddress);
        }
        if (formData.data?.companyName) {
          setValue('companyName', formData.data?.companyName);
        }
        if (formData.data?.fullName) {
          setValue('fullName', formData.data?.fullName);
        }
        if (formData.data?.email) {
          setValue('email', formData.data?.email);
        }
        if (formData.data?.password) {
          setValue('password', formData.data?.password);
        }
        if (formData?.file) {
          setValue('file', formData?.file);
        }
        if (formData.title) {
          setValue('title', formData.title);
        }
        if (formData.description) {
          setValue('description', formData.description);
        }
        if (formData.startDate) {
          setValue('startDate', formData.startDate);
        }
        if (formData.startTime) {
          setValue('startTime', formData.startTime);
        }
        if (formData.experienceWindow) {
          setValue('experienceWindow', formData.experienceWindow);
        }
        if (formData.logo) {
          setValue('campaignLogo', formData.logo);
        }
        if (formData.timezone) {
          setValue('timezone', formData.timezone);
        } else {
          if (timezones) {
            let foundTz = null;
            const userTzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
            timezones.forEach((timezone) => {
              if (timezone.name === userTzName) {
                foundTz = timezone;
              }
            });
            if (foundTz) {
              setValue('timezone', {
                value: foundTz.id,
                label: `${foundTz.name} (${foundTz.utc})`,
              });
            }
          }
        }
      }
    }, [setValue, formData]);

    const timezoneOptions = useMemo(() => {
      return timezones.map((timezone) => ({
        value: timezone.id,
        label: `${timezone.name} (${timezone.utc})`,
      }));
    }, [timezones]);

    useEffect(() => {
      if (!importedCampaign) {
        if (
          Object.keys(suggestions).length === 0 &&
          (campaignSuggestions.length === 0 ||
            !(formData.title || formData.description))
        ) {
          const newObjectives = selectedObjectives.map((objective) => {
            return {
              name: objective.objective,
              headline: objective.headline,
              tagline: objective.tagline,
              description: objective.description,
              platform: platformActions.find(
                (pa) => pa.objectiveId === objective.id
              )?.platform,
              tasks: tasks
                .filter((task) => task.objectiveId === objective.id)
                .map((task) => {
                  return { name: task.name };
                }),
            };
          });
          dispatch(
            fetchOpenAiSuggestionsRequest({
              objectives: newObjectives,
              brandStory,
            })
          );
        }
      }
    }, [
      dispatch,
      platformActions,
      selectedObjectives,
      tasks,
      brandStory,
      suggestions,
      importedCampaign,
    ]);

    const handleRegenerate = () => {
      if (!getSuggestionsPending) {
        const newObjectives = selectedObjectives.map((objective) => {
          return {
            name: objective.objective,
            headline: objective.headline,
            tagline: objective.tagline,
            description: objective.description,
            platform: platformActions.find(
              (pa) => pa.objectiveId === objective.id
            )?.platform,
            tasks: tasks
              .filter((task) => task.objectiveId === objective.id)
              .map((task) => {
                return { name: task.name };
              }),
          };
        });
        setIsRegen(true);
        dispatch(
          fetchOpenAiSuggestionsRequest({
            objectives: newObjectives,
            brandStory,
          })
        );
      }
    };

    const onSubmit = (data) => {
      const {
        title,
        description,
        campaignLogo,
        startTime,
        startDate,
        timezone,
        experienceWindow,
        ...otherData
      } = data;
      setFormData((prev) => ({
        ...prev,
        data: otherData,
        title,
        description,
        logo: campaignLogo,
        startTime,
        startDate,
        timezone,
        experienceWindow,
      }));

      if (!isAuth) {
        setLoadingBtn(true);

        if (data.login) {
          dispatch(
            loginExclusiveAdvertiser(
              {
                email: data.email,
                password: data.password,
              },
              () => {
                setLoadingBtn(false);
                setStep((step) => step + 1);
              },
              () => {
                setLoadingBtn(false);
              }
            )
          );
        } else {
          dispatch(
            registerExclusiveAdvertiser(
              {
                email: data.email,
                password: data.password,
                passwordConfirmation: data.password,
                contactPerson: data.fullName,
                companyName: data.companyName,
                logo: data.logo,
              },
              () => {
                setLoadingBtn(false);
                setStep((step) => step + 1);
              },
              () => {
                setLoadingBtn(false);
              }
            )
          );
        }
      } else {
        setStep((step) => step + 1);
      }
    };

    const onBackClick = () => {
      const data = getValues();

      const {
        title,
        description,
        campaignLogo,
        startTime,
        startDate,
        timezone,
        experienceWindow,
        ...otherData
      } = data;
      setFormData((prev) => ({
        ...prev,
        data: otherData,
        title,
        description,
        logo: campaignLogo,
        startTime,
        startDate,
        timezone,
        experienceWindow,
      }));
    };

    useImperativeHandle(ref, () => ({
      handleFormSubmit() {
        handleSubmit(onSubmit)();
      },
      onBack() {
        onBackClick();
      },
    }));
    const validateFile = (file) => {
      if (file.size < 10685760) {
        const fileType = file.type.split('/')[1];
        if (['png', 'jpeg', 'jpg'].includes(fileType)) {
          return file;
        } else {
          dispatch(
            enqueueSnackbar({
              message: `unsuported file type`,
              variant: toasterVariants.error,
            })
          );
        }
      } else {
        dispatch(
          enqueueSnackbar({
            message: `file size error`,
            variant: toasterVariants.error,
          })
        );
      }
    };

    const minStartDate =
      isImpersonated ||
      businessRulesVariables?.campaignStartDateMinDaysValue === 0
        ? now()
        : addDays(
            today(),
            businessRulesVariables?.campaignStartDateMinDaysValue
          );

    const maxStartDate = addDays(
      today(),
      businessRulesVariables?.campaignStartDateMaxDaysValue
    );

    return (
      <div className={styles.contentContainer}>
        <div className={styles.container}>
          <div className={styles.summaryContainer}>
            <div className={styles.descriptionContainer}>
              <CampaignBuilderHeader title="CAMPAIGN EXPERIENCE DETAILS" />
              <div
                className={styles.regenerateContainer}
                onClick={handleRegenerate}
              >
                <SyncIcon color="white" width={18} height={18} />
                <p className={styles.pbutton}>regenerate</p>
              </div>
            </div>
            <div className={styles.separator} />
            <RowContainer
              title="Campaign Experience Title: *"
              infoButton="Write the headline that your EyVocates will see for your campaign experience."
            >
              <div className={styles.spinnerDiv}>
                <div className={styles.relativeInputContainer}>
                  <Input
                    placeholder="Campaign Experience Title"
                    {...register('title')}
                    error={errors.title?.message}
                    // required
                    rightIcon={<DeleteBlackIcon width={20} height={20} />}
                    handleClearInput={() => {
                      setValue('title', '');
                    }}
                    value={title}
                  />
                </div>
                {getSuggestionsPending ? (
                  <LoadingSpinner className={styles.spinner} />
                ) : (
                  <div className={styles.spinnerDiv} />
                )}
              </div>
            </RowContainer>
            <RowContainer
              title="Description: *"
              infoButton="Write the description that your EyVocates will see for your campaign experience."
            >
              <div className={styles.spinnerDiv}>
                <div className={styles.relativeInputContainer}>
                  <Input
                    placeholder="Campaign Experience Description"
                    type="textarea"
                    {...register('description')}
                    error={errors.description?.message}
                    // required
                    rightIcon={<DeleteBlackIcon width={20} height={20} />}
                    handleClearInput={() => {
                      setValue('description', '');
                    }}
                    value={description}
                  />
                </div>
                {getSuggestionsPending ? (
                  <LoadingSpinner className={styles.spinner} />
                ) : (
                  <div className={styles.spinnerDiv} />
                )}
              </div>
            </RowContainer>
            <RowContainer
              title="Experience Window Start:"
              infoButton="Select the start date and time of your campaign experience window."
            >
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => {
                  return (
                    <div>
                      <div className={styles.endDateContainer}>
                        <div className={styles.dateFieldContainer}>
                          <DatePicker
                            className={styles.datePicker}
                            placeholderText="date (mm/dd/yyyy)"
                            dateFormat="MM/dd/yyyy"
                            onChange={field.onChange}
                            minDate={minStartDate}
                            maxDate={maxStartDate}
                            selected={field.value}
                          />
                          <p className={styles.required}>*</p>
                          {getSuggestionsPending ? (
                            <LoadingSpinner className={styles.spinner} />
                          ) : (
                            <div className={styles.spinnerEmptyDiv} />
                          )}
                        </div>
                        <p className={styles.endTimeText}>8:00 a.m.</p>
                      </div>

                      <ErrorContainer errorText={errors.startDate?.message} />
                    </div>
                  );
                }}
              />
            </RowContainer>
            <RowContainer
              title=" Experience Window: *"
              withoutError
              infoButton={
                'The experience window is the timeframe in which attendees need to finish the activities of your brand experience on the EyCrowd app.'
              }
            >
              <Controller
                name="experienceWindow"
                control={control}
                render={({ field }) => {
                  return (
                    <Slider
                      value={field.value}
                      onChange={(event) => {
                        if (event.target) {
                          field.onChange(event.target.value);
                        } else {
                          field.onChange(event);
                        }
                      }}
                      valueSufix="days"
                      min={
                        businessRulesVariables?.campaignWindowDurationMinDaysValue
                      }
                      max={
                        businessRulesVariables?.campaignWindowDurationMaxDaysValue
                      }
                      step={1}
                    />
                  );
                }}
              />
            </RowContainer>
            <RowContainer
              title="Experience Window End:"
              withoutError
              infoButton="End date and time of your campaign experience window."
            >
              <div
                className={styles.endDateContainer}
                style={{ marginLeft: '21px' }}
              >
                {startDateWatch ? (
                  <p className={styles.endTimeText}>
                    {formatDate(
                      addDays(startDateWatch, experienceWindowWatch),
                      'MM/dd/yyyy'
                    )}
                  </p>
                ) : (
                  <p />
                )}
                <p className={styles.endTimeText}>8:00 p.m.</p>
              </div>
            </RowContainer>
            <RowContainer
              title="Experience Timezone:"
              infoButton={
                'The timezone in which your experience will be published.'
              }
            >
              <div className={styles.windowStartContainer}>
                <div>
                  <Controller
                    name="timezone"
                    control={control}
                    render={({ field }) => {
                      return (
                        <>
                          <Select
                            options={timezoneOptions}
                            isSearchable
                            handleChange={field.onChange}
                            selectedOption={field.value}
                          />
                          <ErrorContainer
                            errorText={errors.timezone?.message}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </RowContainer>

            <RowContainer
              title="Campaign Logo: *"
              infoButton={
                "The campaign logo is the brand image associated with your brand experience when it's published on the EyCrowd app."
              }
            >
              <Controller
                name="campaignLogo"
                control={control}
                render={({ field }) => {
                  return (
                    <>
                      <div
                        className={styles.fileInputCard}
                        style={{ height: '30px' }}
                      >
                        <input
                          type="file"
                          onChange={(e) => {
                            const { files: newFiles } = e.target;
                            if (newFiles.length) {
                              const file = validateFile(newFiles[0]);
                              field.onChange(file);
                            }
                          }}
                          // value={field.value?.name ? field.value.name : ''}
                          accept=".jpeg, .png, .jpeg"
                        />
                        {field.value ? (
                          <p>
                            Filename:{' '}
                            {field.value.name.length > 30
                              ? field.value.name.slice(0, 30) + '...'
                              : field.value.name}
                          </p>
                        ) : (
                          <p>upload</p>
                        )}
                      </div>
                      <ErrorContainer errorText={errors.file?.message} />
                    </>
                  );
                }}
              />
            </RowContainer>
            {/* <RowContainer title="Task Duration" withoutError>
                    <p>{`${timeConvert(taskDuration)}`}</p>
                  </RowContainer> */}
          </div>
          <div className={styles.summaryContainer}>
            {/* {selectedTasks.some((task) => {
              const pv = task.originalId
                ? getTaskProjectedValue(task.originalId)
                : getTaskProjectedValue(task.id);
              return !!pv.url_required;
            }) && (
              <>
                <CampaignBuilderHeader title="LINKS & CONNECTIONS" />
                <div className={styles.separator} />
                {!selectedTasks.every((task) => task.category !== 'SOCIAL') && (
                  <div className={styles.urlInputContainer}>
                    <p className={styles.companyLabel}>
                      {'Smart AI Assist 💡'}
                    </p>

                    <Input
                      className={styles.companyInput}
                      placeholder={`website, profile url, post url`}
                      hideErrorMessage
                      onBlur={(e) => {
                        const value = e.target.value;
                        let newValue;

                        if (value) {
                          if (!value.startsWith('https://')) {
                            newValue = `https://${value}`;
                          } else {
                            newValue = value;
                          }
                          setCompanyUrl(newValue);
                          if (
                            !getSocialAccountSuggestionsPending &&
                            window.innerWidth <= 630
                          ) {
                            onInputBlur(newValue);
                          }
                        }
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        let newValue;

                        if (value.length > previousValue.length) {
                          if (value && !value.startsWith('https://')) {
                            newValue = `https://${value}`;
                          } else {
                            newValue = value;
                          }
                        } else {
                          newValue = value;
                        }
                        setPreviousValue(newValue);
                        setCompanyUrl(newValue);
                      }}
                      value={companyUrl}
                    />
                    <div
                      className={styles.guessContainer}
                      onClick={() => {
                        if (
                          companyUrl !== '' &&
                          !getSocialAccountSuggestionsPending
                        ) {
                          onInputBlur(companyUrl);
                        }
                      }}
                    >
                      <AssistIcon
                        color="white"
                        width={18}
                        height={18}
                        className={styles.assistButton}
                      />
                      <p className={styles.pbutton}>guess</p>
                    </div>
                    <div className={styles.spinnerDiv} />
                  </div>
                )}
              </>
            )} */}

            {/* {selectedTasks.map((task) => {
              let pv;
              if (task.originalId) {
                pv = getTaskProjectedValue(task.originalId);
              } else {
                pv = getTaskProjectedValue(task.id);
              }
              if (!!pv.url_required) {
                return (
                  <RowContainer
                    key={`${task.objectiveId}-${task.id}`}
                    title={`${getTask(task.id).objectiveHeadline} URL`}
                    infoButton={
                      tasksInfo[`${task.objectiveId}-${task.id}`]?.description
                        ? `Define the URL for the task: ${
                            tasksInfo[`${task.objectiveId}-${task.id}`]
                              .description
                          }`
                        : `Define the URL for the task: ${
                            getTask(task.id)?.descriptions[0].label
                          }`
                    }
                  >
                    <div className={styles.inputContainer}>
                      <Input
                        placeholder={`${
                          PLATFOMR_MAPPER[pv.platform]
                        } URL https://`}
                        onChange={(event) =>
                          onTextChange(
                            `${task.objectiveId}-${task.id}`,
                            event.target.value,
                            'url',
                            pv.platform
                          )
                        }
                        required
                        onBlur={(e) => {
                          const value = e.target.value;
                          let newValue;

                          if (value) {
                            if (!value.startsWith('https://')) {
                              newValue = `https://${value}`;
                            } else {
                              newValue = value;
                            }
                            onTextChange(
                              `${task.objectiveId}-${task.id}`,
                              newValue.trim(),
                              'url',
                              pv.platform
                            );
                          }
                        }}
                        value={
                          tasksInfo[`${task.objectiveId}-${task.id}`]?.url
                            ? tasksInfo[`${task.objectiveId}-${task.id}`].url
                            : ''
                        }
                      />
                      {tasksInfo[`${task.objectiveId}-${task.id}`]?.url &&
                        !getSocialAccountSuggestionsPending && (
                          <div
                            className={styles.visitUrlContainer}
                            onClick={() => {
                              const url = tasksInfo[
                                `${task.objectiveId}-${task.id}`
                              ]?.url
                                ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                    .url
                                : '';
                              if (url !== '') {
                                window.open(url, '_blank');
                              }
                            }}
                          >
                            <VisitIcon color="white" width={18} height={18} />
                            <p>visit</p>
                          </div>
                        )}
                      {getSocialAccountSuggestionsPending ? (
                        <LoadingSpinner className={styles.spinner} />
                      ) : (
                        <div className={styles.spinnerDiv} />
                      )}
                    </div>
                  </RowContainer>
                );
              } else {
                return null;
              }
            })} */}
            <form onSubmit={handleSubmit(onSubmit)}>
              {!isAuth && (
                <>
                  <CampaignBuilderHeader title="LOGIN/REGISTER" />
                  <div className={styles.separator} />
                  <RowContainer withoutError>
                    <Controller
                      name="login"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div className={styles.authorizeActions}>
                            <div
                              onClick={() => field.onChange(true)}
                              className={classNames(
                                styles.btn,
                                field.value && styles.selectedBtn
                              )}
                            >
                              <p>LOGIN</p>
                            </div>
                            <div
                              onClick={() => field.onChange(false)}
                              className={classNames(
                                styles.btn,
                                !field.value && styles.selectedBtn
                              )}
                            >
                              <p>REGISTER</p>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </RowContainer>
                  {!loginWatch && (
                    <>
                      <RowContainer title="Company">
                        <Input
                          placeholder="company name"
                          {...register('companyName')}
                          error={errors.companyName?.message}
                          required
                        />
                      </RowContainer>

                      <RowContainer title="Logo (optional)">
                        <Controller
                          name="logo"
                          control={control}
                          render={({ field }) => {
                            return (
                              <>
                                <input
                                  type="file"
                                  onChange={(e) => {
                                    const { files: newFiles } = e.target;
                                    if (newFiles.length) {
                                      const file = validateFile(newFiles[0]);
                                      field.onChange(file);
                                    }
                                  }}
                                  accept=".jpeg, .png, .jpeg"
                                />
                                <ErrorContainer
                                  errorText={errors.file?.message}
                                />
                              </>
                            );
                          }}
                        />
                      </RowContainer>

                      <RowContainer title="Name">
                        <Input
                          placeholder="full name"
                          {...register('fullName')}
                          required
                          error={errors.fullName?.message}
                        />
                      </RowContainer>
                    </>
                  )}
                  <RowContainer title="Email">
                    <Input
                      placeholder="email"
                      {...register('email')}
                      required
                      error={errors.email?.message}
                    />
                  </RowContainer>
                  <RowContainer title="Password">
                    <Input
                      placeholder="password"
                      {...register('password')}
                      required
                      type="password"
                      error={errors.password?.message}
                    />
                  </RowContainer>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
);

export default CampaignExperienceConnections;
