import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './ProjectedValues.module.scss';
import { ReactComponent as EycLogo } from 'assets/images/eyc-logo-new.svg';

const PROJECTED_VALUES = {
  review: 'authentic reviews',
  survey: 'authentic surveys',
  anyWay: 'personal interactions',
  p2pDiscovery: 'personal discoveries',
  p2pExperience: 'personal experiences',
  productInteraction: 'product interaction',
  brandInteraction: 'hours of brand attention',
  photo: 'authentic photos',
  video: 'authentic videos',
  post: 'authentic posts',
  share: 'authentic shares',
  like: 'real likes',
  comment: 'authentic comments',
  follow: 'authentic follows',
  mention: 'real mentions',
  tag: 'real hashtags',
  webVisit: 'real website visits',
  realFollowersWatching: 'real followers watching',
};

const ProjectedValues = ({ numOfEyvocates = 30, values, inPerson }) => {
  const [brandInpact, ugc, socialMedia] = useMemo(() => {
    let brandInpact = {
      review: 0,
      survey: 0,
      anyWay: 0,
      p2pDiscovery: 0,
      p2pExperience: 0,
      productInteraction: 0,
      brandInteraction: 0,
      realFollowersWatching: 0,
    };
    let ugc = {
      photo: 0,
      video: 0,
    };
    let socialMedia = {
      post: 0,
      share: 0,
      like: 0,
      comment: 0,
      follow: 0,
      mention: 0,
      tag: 0,
      webVisit: 0,
    };

    values.forEach((element) => {
      brandInpact = {
        review: brandInpact.review + element.pv_review,
        survey: brandInpact.survey + element.pv_survey,
        anyWay: brandInpact.anyWay + element.pv_any_view,
        p2pDiscovery: brandInpact.p2pDiscovery + element.pv_p2p_discovery,
        p2pExperience: brandInpact.p2pExperience + element.pv_p2p_experience,
        realFollowersWatching:
          brandInpact.realFollowersWatching + +element.average_followers,
        productInteraction:
          brandInpact.productInteraction + element.pv_product_interaction,
        brandInteraction:
          brandInpact.brandInteraction + element.pv_brand_interaction,
      };
      ugc = {
        photo: ugc.photo + element.pv_ugc_photo,
        video: ugc.video + element.pv_ugc_video,
      };

      socialMedia = {
        post: socialMedia.post + element.pv_post,
        share: socialMedia.share + element.pv_share,
        like: socialMedia.like + element.pv_like,
        comment: socialMedia.comment + element.pv_comment,
        follow: socialMedia.follow + element.pv_follow,
        mention: socialMedia.mention + element.pv_mention,
        tag: socialMedia.tag + element.pv_tag,
        webVisit: socialMedia.webVisit + element.pv_web_visit,
      };
    });

    return [brandInpact, ugc, socialMedia];
  }, [values]);

  const containerRef = useRef();

  const [valuesRender, setValuesRender] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setValuesRender((prev) => prev + 1);
    }, 50);
  }, [values]);

  const isContainerOverflow =
    containerRef.current?.offsetHeight < containerRef.current?.scrollHeight + 2;

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.welcomeToEycLogoContainer}>
        <EycLogo />
        <p className={styles.welcomeToEycContainerTrademark}>™</p>
      </div>
      <p className={styles.title}>PROJECTED VALUE</p>
      <p className={styles.text}>
        with {numOfEyvocates ? numOfEyvocates : 'thirty'} EyVocates
      </p>

      <div className={styles.sectionsContainer}>
        <div className={styles.section}>
          <p className={styles.sectionTitle}>Brand impact</p>
          <div className={styles.valueContainer}>
            <p className={styles.valueNumber}>{numOfEyvocates}</p>
            <p className={styles.valueText}>
              {inPerson ? 'foot traffic' : 'online visits'}
            </p>
          </div>
          {Object.entries(brandInpact).map(([key, val]) => {
            if (val) {
              return (
                <div key={key} className={styles.valueContainer}>
                  <p className={styles.valueNumber}>{val * numOfEyvocates}</p>
                  <p className={styles.valueText}>{PROJECTED_VALUES[key]}</p>
                </div>
              );
            }
            return null;
          })}

          <div className={styles.valueContainer}>
            <p className={styles.valueNumber}>zero</p>
            <p className={styles.valueText}>bot impressions</p>
          </div>

          <div className={styles.valueContainer}>
            <p className={styles.valueNumber}>priceless</p>
            <p className={styles.valueText}>word-of-mouth buzz</p>
          </div>
        </div>
        <div className={styles.section}>
          <p className={styles.sectionTitle}>User generated content</p>
          {Object.entries(ugc).map(([key, val]) => {
            if (val) {
              return (
                <div key={key} className={styles.valueContainer}>
                  <p className={styles.valueNumber}>{val * numOfEyvocates}</p>
                  <p className={styles.valueText}>{PROJECTED_VALUES[key]}</p>
                </div>
              );
            }
            return null;
          })}
          <div className={styles.valueContainer}>
            <p className={styles.valueNumber}>zero</p>
            <p className={styles.valueText}>artificial ingredients</p>
          </div>
        </div>
        <div className={styles.section}>
          <p className={styles.sectionTitle}>social media engagement</p>
          {Object.entries(socialMedia).map(([key, val]) => {
            if (val) {
              return (
                <div key={key} className={styles.valueContainer}>
                  <p className={styles.valueNumber}>{val * numOfEyvocates}</p>
                  <p className={styles.valueText}>{PROJECTED_VALUES[key]}</p>
                </div>
              );
            }
            return null;
          })}
          <div className={styles.valueContainer}>
            <p className={styles.valueNumber}>zero</p>
            <p className={styles.valueText}>bots / fake traffic</p>
          </div>
        </div>
      </div>

      <div
        className={styles.gradient}
        style={{ display: isContainerOverflow ? 'flex' : 'none' }}
      >
        <p className={styles.moreText}>more</p>
      </div>
    </div>
  );
};

export default ProjectedValues;
