import classNames from 'classnames';
import React from 'react';
import styles from './Button.module.scss';
import Spinner from 'assets/images/spinner.gif';

const Button = ({
  title,
  onClick,
  alt,
  type,
  disabled,
  loading,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
      className={classNames(
        styles.styledButton,
        alt && styles.alt,
        (disabled || loading) && styles.disabledBtn,
        className
      )}
    >
      {loading ? (
        <img src={Spinner} alt="Spinner" className={styles.spinner} />
      ) : (
        title
      )}
    </button>
  );
};

export default Button;
