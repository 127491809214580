import {
  UPDATE_CAMPAIGN_ERROR,
  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
} from 'containers/SingleCampaignPage/constants';
import produce from 'immer';
import { EYCROWD_ACCESS_TOKEN } from 'utils/constants';
import { getItem } from 'utils/localStorage';
import {
  ADD_PAYMENT_METHOD_ERROR,
  ADD_PAYMENT_METHOD_REQUEST,
  ADD_PAYMENT_METHOD_SUCCESS,
  CREATE_CAMPAIGN_ERROR,
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  DELETE_PAYMENT_METHOD_SUCCESS,
  FETCH_AUTHENTICATED_ADVERTISER_SUCCESS,
  FETCH_COMMON_DATA_SUCCESS,
  FETCH_CAMPAIGN_COMMON_DATA_SUCCESS,
  FETCH_EYVOCATE_LOCATIONS_ATTEMPT,
  FETCH_EYVOCATE_LOCATIONS_SUCCESS,
  FETCH_EYVOCATE_LOCATIONS_ERROR,
  FETCH_PAYMENT_METHODS_SUCCESS,
  LOGOUT_SUCCESS,
  MOBILE_FALSE,
  MOBILE_TRUE,
  SESSION_EXPIRED,
  SET_DEFAULT_PAYMENT_METHOD_ERROR,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  SET_TOKEN,
  SET_VERIFICATION_TOKEN,
  VERIFY_ACCOUNT_SUCCESS,
  SET_DISABLE_PUBLISH,
  UPDATE_COMPANY_INFO_SUCCESS,
  UPDATE_COMPANY_INFO_ERROR,
  UPDATE_COMPANY_INFO_RESET,
  DELETE_TASK_TEMPLATE_SUCCESS,
  ADD_SOCIAL_ACCOUNT_SUCCESS,
  ADD_SOCIAL_ACCOUNT_RESET,
  ADD_SOCIAL_ACCOUNT_REQUEST,
  VERIFY_SOCIAL_ACCOUNT_REQUEST,
  VERIFY_SOCIAL_ACCOUNT_SUCCESS,
  VERIFY_SOCIAL_ACCOUNT_RESET,
  FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ATTEMPT,
  FETCH_ADVERTISER_SOCIAL_ACCOUNTS_SUCCESS,
  FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ERROR,
  DELETE_SOCIAL_ACCOUNT_SUCCESS,
  DELETE_SOCIAL_ACCOUNT_REQUEST,
  DELETE_SOCIAL_ACCOUNT_RESET,
  FETCH_CAMPAIGN_TEMPLATES_SUCCESS,
  FETCH_CAMPAIGN_TEMPLATES_REQUEST,
  FETCH_PAYMENT_METHODS_REQUEST,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_ERROR,
  UPDATE_SOCIAL_ACCOUNT_REQUEST,
  UPDATE_SOCIAL_ACCOUNT_SUCCESS,
  UPDATE_SOCIAL_ACCOUNT_RESET,
  APPLY_TO_PARTNER_PROGRAM_REQUEST,
  APPLY_TO_PARTNER_PROGRAM_SUCCESS,
  APPLY_TO_PARTNER_PROGRAM_ERROR,
  APPLY_TO_PARTNER_PROGRAM_RESET,
} from './constants';

export const initialState = {
  token: getItem(EYCROWD_ACCESS_TOKEN) || null,
  advertiser: null,
  paymentMethods: [],
  paymentMethodsRequestAttempt: false,
  disabilities: [],
  interests: [],
  company: {},
  ageRanges: [],
  timezones: [],
  taskTemplates: [],
  campaignTemplates: {
    loading: false,
    data: null,
    perPage: null,
    page: null,
    total: null,
  },
  defaultSupervisorTasks: [],
  socialPlatforms: [],
  businessRulesVariables: {},
  isPending: false,
  verificationToken: null,
  isMobile: false,
  disabledSubmitPublish: false,
  updatePartialCompanyInfoSuccess: false,
  verifySocialAccountSuccess: false,
  addSocialAccount: {
    attempt: false,
    success: false,
  },
  updateSocialAccount: {
    attempt: false,
    success: false,
  },
  fetchEyVocateLocations: {
    attempt: false,
    success: false,
    error: null,
  },
  fetchAdvertisersSocialAccounts: {
    attempt: false,
    success: false,
    error: null,
  },
  deleteSocialAccountSuccess: false,
  verifyAccountPending: false,
  applyToPartnerProgram: {
    attempt: false,
    success: false,
  },
};

/* eslint-disable default-case */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_AUTHENTICATED_ADVERTISER_SUCCESS:
        draft.advertiser = action.advertiser;
        break;
      case LOGOUT_SUCCESS:
        draft.isMobile = action.mobile;
        draft.advertiser = null;
        draft.token = null;
        break;
      case SESSION_EXPIRED:
        draft.advertiser = null;
        draft.token = null;
        break;
      case SET_TOKEN:
        draft.token = action.token;
        break;
      case FETCH_COMMON_DATA_SUCCESS:
        draft.interests = action.commonData.interests;
        draft.disabilities = action.commonData.disabilities;
        draft.socialPlatforms = action.commonData.socialPlatforms;
        draft.defaultSupervisorTasks = action.commonData.defaultSupervisorTasks;
        draft.businessRulesVariables = action.commonData.businessRulesVariables;
        break;
      case FETCH_CAMPAIGN_COMMON_DATA_SUCCESS:
        draft.interests = action.campaignCommonData.interests;
        draft.ageRanges = action.campaignCommonData.ageRanges;
        draft.timezones = action.campaignCommonData.timezones;
        draft.taskTemplates = action.campaignCommonData.taskTemplates;
        draft.company = action.campaignCommonData.company;
        draft.socialPlatforms = action.campaignCommonData.socialPlatforms;
        draft.businessRulesVariables =
          action.campaignCommonData.businessRulesVariables;
        break;
      case FETCH_EYVOCATE_LOCATIONS_ATTEMPT:
        draft.fetchEyVocateLocations = {
          attempt: true,
          success: false,
          error: null,
        };
        break;
      case FETCH_EYVOCATE_LOCATIONS_SUCCESS:
        draft.eyVocateLocations = action.eyVocateLocations;
        draft.fetchEyVocateLocations = {
          attempt: false,
          success: true,
          error: null,
        };
        break;
      case FETCH_EYVOCATE_LOCATIONS_ERROR:
        draft.fetchEyVocateLocations = {
          attempt: false,
          success: false,
          error: action.payload,
        };
        break;
      case UPDATE_COMPANY_INFO_SUCCESS:
        if (draft.advertiser) {
          draft.advertiser = {
            ...action.advertiser,
            isImpersonated: state.advertiser.isImpersonated,
          };
          draft.updatePartialCompanyInfoSuccess = true;
        }
        break;
      case UPDATE_COMPANY_INFO_ERROR:
        draft.updatePartialCompanyInfoSuccess = false;
        break;
      case UPDATE_COMPANY_INFO_RESET:
        draft.updatePartialCompanyInfoSuccess = false;
        break;
      case ADD_SOCIAL_ACCOUNT_REQUEST:
        draft.addSocialAccount = {
          attempt: true,
          success: false,
        };
        break;
      case ADD_SOCIAL_ACCOUNT_SUCCESS:
        if (draft.advertiser) {
          draft.advertiser.socialAccounts = [
            ...state.advertiser.socialAccounts,
            action.socialAccount,
          ];
          draft.addSocialAccount = {
            attempt: false,
            success: true,
          };
        }
        break;
      case ADD_SOCIAL_ACCOUNT_RESET:
        draft.addSocialAccount = {
          attempt: false,
          success: false,
        };
        break;
      case UPDATE_SOCIAL_ACCOUNT_REQUEST:
        draft.updateSocialAccount = {
          attempt: true,
          success: false,
        };
        break;
      case UPDATE_SOCIAL_ACCOUNT_SUCCESS:
        if (draft.advertiser) {
          const newSocialAccountsList = state.advertiser.socialAccounts.map(
            (acc) => (acc.id !== action.id ? acc : action.socialAccountInfo)
          );
          draft.advertiser.socialAccounts = [...newSocialAccountsList];
          draft.updateSocialAccount = {
            attempt: false,
            success: true,
          };
        }
        break;
      case UPDATE_SOCIAL_ACCOUNT_RESET:
        draft.updateSocialAccount = {
          attempt: false,
          success: false,
        };
        break;
      case VERIFY_SOCIAL_ACCOUNT_REQUEST:
        draft.verifySocialAccountSuccess = false;
        break;
      case VERIFY_SOCIAL_ACCOUNT_SUCCESS:
        draft.advertiser.socialAccounts = [
          ...state.advertiser.socialAccounts,
          action.socialAccount,
        ];
        draft.verifySocialAccountSuccess = true;
        break;
      case VERIFY_SOCIAL_ACCOUNT_RESET:
        draft.verifySocialAccountSuccess = false;
        break;
      case FETCH_PAYMENT_METHODS_REQUEST:
        draft.paymentMethodsRequestAttempt = true;
        break;
      case FETCH_PAYMENT_METHODS_SUCCESS:
        draft.paymentMethods = action.paymentMethods;
        draft.paymentMethodsRequestAttempt = false;
        break;
      case ADD_PAYMENT_METHOD_SUCCESS:
        draft.paymentMethods.push(action.paymentMethod);
        draft.isPending = false;
        break;
      case SET_DEFAULT_PAYMENT_METHOD_SUCCESS:
        draft.paymentMethods = draft.paymentMethods.map((paymentMethod) => ({
          ...paymentMethod,
          isDefault: paymentMethod.id === action.paymentMethod.id,
        }));
        draft.isPending = false;
        break;
      case DELETE_PAYMENT_METHOD_SUCCESS:
        draft.paymentMethods = draft.paymentMethods.filter(
          ({ id }) => id !== action.paymentMethod.id
        );
        break;
      case DELETE_SOCIAL_ACCOUNT_REQUEST:
        draft.deleteSocialAccountSuccess = false;
        break;
      case DELETE_SOCIAL_ACCOUNT_SUCCESS:
        if (draft.advertiser) {
          draft.deleteSocialAccountSuccess = true;
          draft.advertiser.socialAccounts =
            draft.advertiser.socialAccounts.filter(
              ({ id }) => id !== action.id
            );
        }
        break;
      case DELETE_SOCIAL_ACCOUNT_RESET:
        draft.deleteSocialAccountSuccess = false;
        break;
      case CREATE_CAMPAIGN_REQUEST:
        break;
      case UPDATE_CAMPAIGN_REQUEST:
        break;
      case ADD_PAYMENT_METHOD_REQUEST:
        draft.isPending = true;
        break;
      case VERIFY_ACCOUNT_REQUEST:
        draft.verifyAccountPending = true;
        break;
      case VERIFY_ACCOUNT_SUCCESS:
        draft.verifyAccountPending = false;
        draft.verificationToken = null;
        if (draft.advertiser) {
          draft.advertiser.verifiedAt = new Date();
        }
        break;
      case VERIFY_ACCOUNT_ERROR:
        draft.verifyAccountPending = false;
        break;
      case CREATE_CAMPAIGN_SUCCESS:
        draft.disabledSubmitPublish = false;
        break;
      case UPDATE_CAMPAIGN_SUCCESS:
        draft.disabledSubmitPublish = false;
        break;
      case CREATE_CAMPAIGN_ERROR:
        draft.disabledSubmitPublish = false;
        break;
      case UPDATE_CAMPAIGN_ERROR:
        draft.disabledSubmitPublish = false;
        break;
      case ADD_PAYMENT_METHOD_ERROR:
        break;
      case SET_DEFAULT_PAYMENT_METHOD_ERROR:
        draft.isPending = false;
        break;
      case SET_VERIFICATION_TOKEN:
        draft.verificationToken = action.token;
        break;
      case MOBILE_TRUE:
        draft.isMobile = true;
        break;
      case MOBILE_FALSE:
        draft.isMobile = false;
        break;
      case DELETE_TASK_TEMPLATE_SUCCESS:
        draft.taskTemplates = draft.taskTemplates.filter((task) => {
          return task.id !== action.id;
        });
        break;
      case FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ATTEMPT:
        draft.fetchAdvertisersSocialAccounts = {
          attempt: true,
          success: false,
          error: null,
        };
        break;
      case FETCH_ADVERTISER_SOCIAL_ACCOUNTS_SUCCESS:
        draft.advertiser.socialAccounts = [...action.socialAccounts];
        draft.fetchAdvertisersSocialAccounts = {
          attempt: false,
          success: true,
          error: null,
        };
        break;
      case FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ERROR:
        draft.fetchAdvertisersSocialAccounts = {
          attempt: false,
          success: false,
          error: action.payload,
        };
        break;
      case SET_DISABLE_PUBLISH:
        draft.disabledSubmitPublish = true;
        break;
      case FETCH_CAMPAIGN_TEMPLATES_REQUEST:
        draft.campaignTemplates.loading = true;
        break;
      case FETCH_CAMPAIGN_TEMPLATES_SUCCESS:
        draft.campaignTemplates = {
          ...action.campaignTemplates,
          loading: false,
          data:
            action.campaignTemplates.page === 1
              ? action.campaignTemplates.data
              : [
                  ...draft.campaignTemplates.data,
                  ...action.campaignTemplates.data,
                ],
        };
        break;
      case APPLY_TO_PARTNER_PROGRAM_REQUEST:
        draft.applyToPartnerProgram = {
          attempt: true,
          success: false,
        };
        break;
      case APPLY_TO_PARTNER_PROGRAM_SUCCESS:
        draft.applyToPartnerProgram = {
          attempt: false,
          success: true,
        };
        draft.advertiser = {
          ...draft.advertiser,
          isPartner: true,
          partnerProgram: action.advertiser.partnerProgram,
        };
        break;
      case APPLY_TO_PARTNER_PROGRAM_ERROR:
        draft.applyToPartnerProgram = {
          attempt: false,
          success: false,
        };
        break;
      case APPLY_TO_PARTNER_PROGRAM_RESET:
        draft.applyToPartnerProgram = {
          attempt: false,
          success: false,
        };
        break;
    }
  });

export default appReducer;
