// const getSocialPlatformIdFromName = (name) => {
//   switch (name) {
//     case 'instagram':
//       return 1;
//     case 'facebook':
//       return 2;
//     case 'linkedin':
//       return 3;
//     default:
//       return 1;
//   }
// };

export const removeOffset = (date) => {
  const tzoffset = date.getTimezoneOffset() * 60000;
  return new Date(new Date(date - tzoffset).setSeconds(0, 0))
    .toISOString()
    .slice(0, -1);
};

export const makeCampaignRequestBody = (
  values,
  objectiveId,
  campaignType,
  polygon,
  location,
  allAgeRanges,
  campaignId,
  status,
  appliedCoupon
) => {
  // Tasks
  const tasks = [];

  values.preCampaignTasks.forEach((task) => {
    if (task.category === 'arrival')
      tasks.push({
        description: task.description,
        type: 'pre-campaign',
        category: 'arrival',
      });
    else if (task.category === 'survey') {
      tasks.push({
        description: null,
        type: 'pre-campaign',
        category: 'survey',
        taskSurveyQuestion: task.taskSurveyQuestion,
      });
    } else {
      if (task.type) {
        if (task.type === 'social-account') {
          tasks.push({
            description: task.description,
            type: 'pre-campaign',
            category: 'social',
            suggestedHashtags: task.suggestedHashtags,
            socialAccountId: task.taskSocialAccount.value,
          });
        } else if (task.type === 'social-task') {
          if (task?.socialAction?.value === 'visit') {
            tasks.push({
              description: task.description,
              type: 'pre-campaign',
              category: 'social',
              url: task.taskSocialMedia.link,
            });
          } else {
            tasks.push({
              description: task.description,
              type: 'pre-campaign',
              category: task.socialAction.value,
              taskSocialPlatform: {
                socialPlatformId: task.taskSocialPlatform.socialPlatform.value,
                type: null,
              },
              url: task.taskSocialMedia.link,
            });
          }
        } else {
          if (task.taskSocialPlatform.socialPlatform.value) {
            tasks.push({
              description: task.description,
              type: 'pre-campaign',
              category: 'social',
              suggestedHashtags: task.suggestedHashtags,
              taskSocialPlatform: {
                socialPlatformId: task.taskSocialPlatform.socialPlatform.value,
                type: task.taskSocialPlatform.type,
              },
            });
          } else {
            tasks.push({
              description: task.description,
              type: 'pre-campaign',
              category: task.taskSocialPlatform.type,
              suggestedHashtags: task.suggestedHashtags,
            });
          }
        }
      } else {
        tasks.push({
          description: task.description,
          type: 'pre-campaign',
        });
      }
    }
  });

  values.activeCampaignTasks.forEach((task) => {
    if (task.type) {
      if (task.type === 'social-account') {
        tasks.push({
          description: task.description,
          type: 'live-campaign',
          category: 'social',
          suggestedHashtags: task.suggestedHashtags,
          socialAccountId: task.taskSocialAccount.value,
        });
      } else if (task.type === 'social-task') {
        if (task?.socialAction?.value === 'visit') {
          tasks.push({
            description: task.description,
            type: 'live-campaign',
            category: 'social',
            url: task.taskSocialMedia.link,
          });
        } else {
          tasks.push({
            description: task.description,
            type: 'live-campaign',
            category: task.socialAction.value,
            taskSocialPlatform: {
              socialPlatformId: task.taskSocialPlatform.socialPlatform.value,
              type: null,
            },
            url: task.taskSocialMedia.link,
          });
        }
      } else {
        if (task.taskSocialPlatform.socialPlatform.value) {
          tasks.push({
            description: task.description,
            type: 'live-campaign',
            category: 'social',
            suggestedHashtags: task.suggestedHashtags,
            taskSocialPlatform: {
              socialPlatformId: task.taskSocialPlatform.socialPlatform.value,
              type: task.taskSocialPlatform.type,
            },
          });
        } else {
          tasks.push({
            description: task.description,
            type: 'live-campaign',
            category: task.taskSocialPlatform.type,
            suggestedHashtags: task.suggestedHashtags,
          });
        }
      }
    } else if (task.category === 'survey') {
      tasks.push({
        description: null,
        type: 'live-campaign',
        category: 'survey',
        taskSurveyQuestion: task.taskSurveyQuestion,
      });
    } else {
      tasks.push({
        description: task.description,
        type: 'live-campaign',
      });
    }
  });

  values.postCampaignTasks.forEach((task) => {
    if (task.type) {
      if (task.type === 'social-account') {
        tasks.push({
          description: task.description,
          type: 'follow-up',
          category: 'social',
          suggestedHashtags: task.suggestedHashtags,
          socialAccountId: task.taskSocialAccount.value,
        });
      } else if (task.type === 'social-task') {
        if (task?.socialAction?.value === 'visit') {
          tasks.push({
            description: task.description,
            type: 'follow-up',
            category: 'social',
            url: task.taskSocialMedia.link,
          });
        } else {
          tasks.push({
            description: task.description,
            type: 'follow-up',
            category: task.socialAction.value,
            taskSocialPlatform: {
              socialPlatformId: task.taskSocialPlatform.socialPlatform.value,
              type: null,
            },
            url: task.taskSocialMedia.link,
          });
        }
      } else {
        if (task.taskSocialPlatform.socialPlatform.value) {
          tasks.push({
            description: task.description,
            type: 'follow-up',
            category: 'social',
            suggestedHashtags: task.suggestedHashtags,
            taskSocialPlatform: {
              socialPlatformId: task.taskSocialPlatform.socialPlatform.value,
              type: task.taskSocialPlatform.type,
            },
          });
        } else {
          tasks.push({
            description: task.description,
            type: 'follow-up',
            category: task.taskSocialPlatform.type,
            suggestedHashtags: task.suggestedHashtags,
          });
        }
      }
    } else if (task.category === 'survey') {
      tasks.push({
        description: null,
        type: 'follow-up',
        category: 'survey',
        taskSurveyQuestion: task.taskSurveyQuestion,
      });
    } else {
      tasks.push({
        description: task.description,
        type: 'follow-up',
      });
    }
    // tasks.push({
    //   type: 'follow-up',
    //   description: task.description,
    // });
  });

  // // media
  // let logo = {
  //   url: values.companyInformation.logoUrl
  //     ? values.companyInformation.logoUrl
  //     : values.companyInformation.logo,
  //   isExternal: values.companyInformation.logoExternal
  //     ? values.companyInformation.logoExternal
  //     : false,
  // };
  // let video = {
  //   url: values.companyInformation.videoUrl
  //     ? values.companyInformation.videoUrl
  //     : values.companyInformation.video,
  //   isExternal: values.companyInformation.videoExternal
  //     ? values.companyInformation.videoExternal
  //     : false,
  // };

  const campaignData = {
    name: values.campaignBasics.name,
    type: campaignType,
    description: values.campaignBasics.description,
    isPrivate: values.campaignBasics.isPrivate,
    startDate: removeOffset(values.campaignBasics.startDate),
    endDate: removeOffset(values.campaignBasics.endDate),
    timeZone: {
      id: values.campaignBasics.timezone?.value,
    },
    tasksTotalDurationInMinutes: values.campaignBasics.tasksDuration,
    logo: values.companyInformation.logo,
    video: values.companyInformation.video,
    thumbnail: values.companyInformation.thumbnail,
    isExternalLogo: !!values.companyInformation.isExternalLogo,
    isExternalVideo: !!values.companyInformation.isExternalVideo,
    campaignId,
    genders: ['male', 'female', 'custom'],
    genderSelection: values.eyvocateDemographics.genderSelection,
    maxEyvocates: +values.campaignBasics.numOfEyVocates,
    pricePerHour: +values.campaignBasics.pricePerHour,
    interests: values.eyvocateDemographics.interests.map((interest) => ({
      id: interest.id,
    })),
    couponId: appliedCoupon ? appliedCoupon.id : null,
    status: status,
    ageRanges: values.eyvocateDemographics.ageSelection.includes('0')
      ? allAgeRanges.map((rangeId) => ({
          id: rangeId.id,
        }))
      : values.eyvocateDemographics.ageSelection.map((rangeId) => ({
          id: rangeId,
        })),
    tasks,
    location: {
      path: polygon.map((point) => ({
        latitude: point.lat,
        longitude: point.lng,
      })),
      centerPoint: {
        longitude: location?.lng,
        latitude: location?.lat,
      },
    },
    objectiveId: objectiveId,
    eyvocatePlaces: values.eyvocateDemographics.locations.map((place) => ({
      externalPlaceId: place.id,
      label: place.label,
    })),
  };

  return campaignData;
};
