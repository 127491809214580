import { LOGIN, NOT_FOUND } from '../routes';
import { store } from 'index';
import { logout } from 'containers/App/actions';

export const handleBadRequestAndUnauthorised = (statusCode) => {
  switch (statusCode) {
    case 400:
      window.location = NOT_FOUND;
      break;
    case 401:
      // store.dispatch(logout());
      // window.location = LOGIN;
      break;
    default:
      return null;
  }
};
