import {
  makePlatformActionsSelector,
  makeTasksSelector,
} from 'containers/NewExperienceCampaignBuilderPage/selectors';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RowContainer } from '../CampaignExperienceBasics';
import { timeConvert } from '../TaskDetails';
import styles from './CampaignExperienceSummary.module.scss';
import { useIntl } from 'react-intl';
import globalMessages from 'messages';
import { addHours, format } from 'date-fns';
import {
  makeSelectAgeRanges,
  makeSelectIsAuthenticated,
  makeSelectSocialPlatforms,
  makeSelectAdvertiser,
  makeSelectPaymentMethods,
  makeSelectInterests,
} from 'containers/App/selectors';
import { removeOffset } from 'containers/ECWCreateCampaignPage/util';
import { addDays } from 'utils/date';
import { publishExclusiveCampaignRequest } from 'containers/NewExperienceCampaignBuilderPage/actions';
import {
  fetchAuthenticatedAdvertiser,
  fetchPaymentMethods,
  setToken,
} from '../../../containers/App/actions';
import { EYCROWD_ACCESS_TOKEN } from 'utils/constants';
import { getItem } from 'utils/localStorage';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from 'routes';
import Select from '../Select';
import { setDefaultPaymentMethod } from '../../../containers/App/actions';

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import Input from '../Input';
import Button from '../Button';
import { verifyPromoCode } from 'containers/ECWCreateCampaignPage/actions';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import toasterVariants from 'constants/toasterVariants';
import { usePaymentMethodModal } from 'components/PaymentMethodModal';
import AddPaymentMethodForm from '../AddPaymentMethodForm';
import { addPaymentMethod } from '../../../containers/App/actions';
import CampaignBuilderHeader from '../CampaignBuilderHeader';
import config from '../../../config';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';

export const BASIC_EXPERIENCE_STEP = {
  FOOT_TRAFFIC: 'Real Foot Traffic',
  ONLINE_INTERACTIONS: 'Real Online Interactions',
  PRODUCT_DISCOVERY: 'Real Product Discovery',
};

const CampaignExperienceSummary = forwardRef(
  (
    {
      setInputInvalid,
      formData,
      selectedObjectives,
      tasksInfo,
      polygon,
      location,
      setLoadingBtn,
      circle,
      inPerson,
      basicExperience,
      coupon,
      setCoupon,
      storyBrandFormData,
      brandStory,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const allAgeRanges = useSelector(makeSelectAgeRanges());
    const interests = useSelector(makeSelectInterests());
    const platformAction = useSelector(makePlatformActionsSelector());
    const socialPlatforms = useSelector(makeSelectSocialPlatforms());
    const isAuth = useSelector(makeSelectIsAuthenticated());
    const advertiser = useSelector(makeSelectAdvertiser());
    const paymentMethods = useSelector(makeSelectPaymentMethods());
    const [promoCode, setPromoCode] = useState('');
    const [openPaymentMethodModal] = usePaymentMethodModal();
    const history = useHistory();

    useEffect(() => {
      dispatch(fetchPaymentMethods());
    }, [dispatch]);

    useEffect(() => {
      if (!!paymentMethods.length) {
        setInputInvalid(false);
      }
    }, [paymentMethods, setInputInvalid]);

    const mobileLinkPrefix =
      config.api.baseUrl === 'https://eycrowd-server.production.eycrowd.com/api'
        ? 'https://eycrowd.app.link/'
        : 'https://eycrowd.test-app.link/';

    const handleCopyClick = () => {
      navigator.clipboard.writeText(
        `${mobileLinkPrefix}${formData.mobileLink}`
      );
      dispatch(
        enqueueSnackbar({
          message: 'Link successfully copied to your clipboard',
          variant: toasterVariants.success,
        })
      );
    };

    const getTaskProjectedValue = useCallback(
      (taskId) => {
        return platformAction.find((p) => p.taskId === taskId);
      },
      [platformAction]
    );

    const tasks = useSelector(makeTasksSelector());

    const paymentMethodOptions = useMemo(
      () =>
        paymentMethods.map((pm) => ({
          label: `**** ${pm.lastDigits} (${pm.brand})`,
          value: pm,
        })),
      [paymentMethods]
    );

    const changeDefaulPayment = (paymentMethod) => {
      dispatch(setDefaultPaymentMethod(paymentMethod.value));
    };

    const selectedTasks = useMemo(() => {
      const retTasks = [];

      for (const objective of selectedObjectives) {
        if ((objective.id + '').includes('/')) {
          let objectiveId = +objective.id.split('/')[0];
          const objectiveTasks = tasks.filter(
            (task) => task.objectiveId === objectiveId
          );
          retTasks.push(
            ...objectiveTasks.map((task) => ({
              ...task,
              objectiveId: objective.id,
            }))
          );
        } else {
          const objectiveTasks = tasks.filter(
            (task) => task.objectiveId === objective.id
          );
          retTasks.push(...objectiveTasks);
        }
      }

      return retTasks;
    }, [selectedObjectives, tasks]);

    const taskDuration = selectedTasks.length
      ? selectedTasks
          .map((item) => item.time)
          .reduce((prev, next) => +prev + +next)
      : 0;

    const STAGE_MAPPER = {
      'PRE, LIVE': 'pre-campaign',
      LIVE: 'live-campaign',
      'LIVE, POST': 'follow-up',
    };

    const getCategoryTask = (pv) => {
      if (pv.url_required) {
        switch (pv.platform_action) {
          case 'COMMENT':
            return 'comment';
          case 'REPLY':
            return 'comment';
          case 'LIKE':
            return 'like';
          case 'FOLLOW':
            return 'follow';
          case 'SHARE':
            return 'share';
          default:
            return 'social-account';
        }
      }

      if (pv.platform_action === 'POST') {
        return 'social-platform';
      }
      if (pv.platform_action === 'SURVEY') {
        return 'survey';
      }

      if (pv.platform_action === 'CREATE') {
        if (pv.platform_item === 'IMAGE') {
          return 'photo';
        }

        if (pv.platform_item === 'VIDEO') {
          return 'video';
        }
      }

      switch (pv.basic_verification_method) {
        case 'GALLERY-PHOTO':
          return 'photo';
        case 'GALLERY-VIDEO':
          return 'video';
        case 'SURVEY-LONG':
          return 'survey';
        case 'SURVEY-SHORT':
          return 'survey';
        case 'SURVEY-MULTIPLE':
          return 'survey';
        case 'SURVEY-SINGLE':
          return 'survey';
        default:
          return 'default';
      }
    };

    const getSuggestedHashtags = (taskId) => {
      const selectedTask = tasksInfo[taskId];

      let suggestedHashtag = '';

      if (selectedTask) {
        if (selectedTask.hashtags) {
          suggestedHashtag = suggestedHashtag.concat(selectedTask.hashtags);
        }

        if (selectedTask.mentions) {
          suggestedHashtag = suggestedHashtag.concat(
            ` ${selectedTask.mentions}`
          );
        }

        return !suggestedHashtag.trim() ? null : suggestedHashtag;
      } else {
        return null;
      }
    };

    const getSocialAccountUrl = (taskId) => {
      const selectedTask = tasksInfo[taskId];

      let url = null;
      if (selectedTask) {
        if (selectedTask.url) {
          url = {
            link: selectedTask.url,
            platform: PLATFOMR_MAPPER[selectedTask.platform],
          };
        }
      }

      return url;
    };

    const getTasksSocialMedia = (taskId) => {
      const selectedTask = tasksInfo[taskId];
      let url = null;
      if (selectedTask) {
        url = { link: selectedTask.url };
      }
      return url;
    };

    const PLATFOMR_MAPPER = {
      FB: socialPlatforms.find((sp) => sp.slug === 'facebook')?.id,
      IG: socialPlatforms.find((sp) => sp.slug === 'instagram')?.id,
      TW: socialPlatforms.find((sp) => sp.slug === 'twitter')?.id,
      X: socialPlatforms.find((sp) => sp.slug === 'twitter')?.id,
      LI: socialPlatforms.find((sp) => sp.slug === 'linkedin')?.id,
      TT: socialPlatforms.find((sp) => sp.slug === 'tiktok')?.id,
      YELP: socialPlatforms.find((sp) => sp.slug === 'yelp')?.id,
      AMAZON: socialPlatforms.find((sp) => sp.slug === 'other')?.id,
      HTTP3: socialPlatforms.find((sp) => sp.slug === 'other')?.id,
      EYCROWD: socialPlatforms.find((sp) => sp.slug === 'other')?.id,
    };

    const getTaskSocialPlatform = (pv) => {
      if (pv.platform_action === 'POST') {
        if (pv.platform_item === 'IMAGE') {
          return {
            type: 'photo',
            socialPlatformId: PLATFOMR_MAPPER[pv.platform],
          };
        }

        if (pv.platform_item === 'VIDEO') {
          return {
            type: 'video',
            socialPlatformId: PLATFOMR_MAPPER[pv.platform],
          };
        }
      } else if (pv.platform_action === 'COMMENT') {
        return {
          type: null,
          socialPlatformId: PLATFOMR_MAPPER[pv.platform],
        };
      } else if (pv.platform_action === 'LIKE') {
        return {
          type: null,
          socialPlatformId: PLATFOMR_MAPPER[pv.platform],
        };
      } else if (pv.platform_action === 'FOLLOW') {
        return {
          type: null,
          socialPlatformId: PLATFOMR_MAPPER[pv.platform],
        };
      } else if (pv.platform_action === 'REPLY') {
        return {
          type: null,
          socialPlatformId: PLATFOMR_MAPPER[pv.platform],
        };
      } else if (pv.platform_action === 'SHARE') {
        return {
          type: null,
          socialPlatformId: PLATFOMR_MAPPER[pv.platform],
        };
      }

      return null;
    };

    const QUESTION_TYPE_MAPPER = {
      SINGLE_CHOICE: 'single',
      SHORT_TEXT: 'short',
      LONG_TEXT: 'long',
      MULTIPLE_CHOICE: 'multiple',
      INPUT: 'input',
    };

    const getSurveyTask = (task, pv) => {
      const selectedTask = tasksInfo[`${task.objectiveId}-${task.id}`];

      let questionType = QUESTION_TYPE_MAPPER[pv.platform_item];

      if (!questionType) {
        switch (pv.basic_verification_method) {
          case 'SURVEY-LONG':
            questionType = QUESTION_TYPE_MAPPER.LONG_TEXT;
            break;
          case 'SURVEY-SHORT':
            questionType = QUESTION_TYPE_MAPPER.SHORT_TEXT;
            break;
          case 'SURVEY-MULTIPLE':
            questionType = QUESTION_TYPE_MAPPER.MULTIPLE_CHOICE;
            break;
          case 'SURVEY-SINGLE':
            questionType = QUESTION_TYPE_MAPPER.SINGLE_CHOICE;
            break;
          default:
            break;
        }
      }

      if (selectedTask && questionType) {
        return {
          questionType,
          text: selectedTask.description
            ? selectedTask.description
            : task.descriptions[0].label,
          options: selectedTask.options,
        };
      }
      return null;
    };

    const stripe = useStripe();

    const stripeElements = useElements();

    const exportCampaignData = () => {
      const tasks = [];

      selectedTasks.forEach((task) => {
        const description = tasksInfo[`${task.objectiveId}-${task.id}`]
          ? tasksInfo[`${task.objectiveId}-${task.id}`].description
            ? tasksInfo[`${task.objectiveId}-${task.id}`].description
            : task.descriptions[0].label
          : task.descriptions[0].label;

        const mentions = tasksInfo[`${task.objectiveId}-${task.id}`]
          ? tasksInfo[`${task.objectiveId}-${task.id}`].mentions
            ? tasksInfo[`${task.objectiveId}-${task.id}`].mentions
            : task.mentions
          : task.mentions;

        const hashtags = tasksInfo[`${task.objectiveId}-${task.id}`]
          ? tasksInfo[`${task.objectiveId}-${task.id}`].hashtags
            ? tasksInfo[`${task.objectiveId}-${task.id}`].hashtags
            : task.hashtags
          : task.hashtags;

        const url = tasksInfo[`${task.objectiveId}-${task.id}`]
          ? tasksInfo[`${task.objectiveId}-${task.id}`].url
            ? tasksInfo[`${task.objectiveId}-${task.id}`].url
            : task.url
          : task.url;

        const options = tasksInfo[`${task.objectiveId}-${task.id}`]
          ? tasksInfo[`${task.objectiveId}-${task.id}`].options
            ? tasksInfo[`${task.objectiveId}-${task.id}`].options
            : null
          : null;

        tasks.push({
          id: task.originalId || task.id,
          objectiveId: task.originalId
            ? tasks.find((t) => t.id === task.originalId).objectiveId
            : task.objectiveId,
          description,
          ...(mentions && { mentions }),
          ...(hashtags && {
            hashtags: Array.isArray(hashtags) ? hashtags : hashtags.split(' '),
          }),
          ...(url && { url }),
          ...(options && { options }),
        });
      });

      const exportData = {
        brandName: storyBrandFormData.businessName,
        brandStory,
        website: storyBrandFormData.businessWebsite,
        campaignName: formData.title,
        campaignDescription: formData.description,
        startDate: new Date(formData.startDate).toISOString().split('T')[0],
        experienceWindowInDays: formData.experienceWindow,
        timezone: formData.timezone.label.split(' ')[0],
        tasks,
        numOfEyvocates: formData.numOfEyvocates,
        pricePerHour: +formData.pricePerHour,
        overAllocationPercentage: formData.overAllocationPercentage,
        interests: formData.interests,
        ageRanges: formData.ageRanges,
        genders: formData.genders,
        locations: formData.locations,
      };

      const jsonString = JSON.stringify(exportData, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.download = 'experience.json';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    };

    const onAddPaymentSuccess = () => {
      try {
        setLoadingBtn(true);
        let tasks = [];

        selectedTasks.forEach((task) => {
          let pv;
          if (task.originalId) {
            pv = getTaskProjectedValue(task.originalId);
          } else {
            pv = getTaskProjectedValue(task.id);
          }

          const taskCategory = getCategoryTask(pv);
          const finalTaskCategory = [
            'social-account',
            'social-platform',
          ].includes(taskCategory)
            ? 'social'
            : taskCategory;

          const newTask = {
            taskActivityLocations: [
              tasksInfo[`${task.objectiveId}-${task.id}`].inPersonTask
                ? { createdAt: new Date() }
                : {},
            ],
            description: tasksInfo[`${task.objectiveId}-${task.id}`]
              ? tasksInfo[`${task.objectiveId}-${task.id}`].description
                ? tasksInfo[`${task.objectiveId}-${task.id}`].description
                : task.descriptions[0].label
              : task.descriptions[0].label,
            type: tasksInfo[`${task.objectiveId}-${task.id}`]
              ? tasksInfo[`${task.objectiveId}-${task.id}`].stage
                ? STAGE_MAPPER[
                    tasksInfo[`${task.objectiveId}-${task.id}`].stage.label
                  ]
                : STAGE_MAPPER.LIVE
              : STAGE_MAPPER.LIVE,
            category: finalTaskCategory,
            ...(['social-account', 'social-platform', 'comment'].includes(
              taskCategory
            ) && {
              suggestedHashtags: getSuggestedHashtags(
                `${task.objectiveId}-${task.id}`
              ),
            }),
            ...(['social-account'].includes(taskCategory) && {
              socialAccount: getSocialAccountUrl(
                `${task.objectiveId}-${task.id}`
              ),
            }),
            ...(['share', 'comment', 'like', 'follow'].includes(
              taskCategory
            ) && {
              taskSocialMedia: getTasksSocialMedia(
                `${task.objectiveId}-${task.id}`
              ),
            }),
            ...([
              'share',
              'comment',
              'like',
              'follow',
              'social-platform',
            ].includes(taskCategory) && {
              taskSocialPlatform: getTaskSocialPlatform(pv),
            }),
            ...(taskCategory === 'survey' &&
              getSurveyTask(task, pv) && {
                taskSurveyQuestion: getSurveyTask(task, pv),
              }),
          };

          tasks.push(newTask);
        });

        if (formData.data.taskLocation) {
          tasks.push({
            description: 'Arrive inside the geofence',
            type: 'pre-campaign',
            category: 'arrival',
          });
        }

        const startDate = addHours(
          formData.startDate,
          formData.startTime.getHours()
        );

        const endDate = addHours(
          addDays(startDate, formData.experienceWindow),
          12
        );

        const campaigData = {
          name: formData.title,
          description: formData.description,
          type: inPerson ? 'in-person' : 'digital',
          startDate: removeOffset(startDate),
          endDate: removeOffset(endDate),
          timeZone: {
            id: formData.timezone.value,
          },
          tasksTotalDurationInMinutes: taskDuration,
          logo: formData.logo
            ? formData.logo
            : 'https://eycrowdmedia.s3.us-east-2.amazonaws.com/campaigns/resize-1681484314891726648yearlyaccess02.jpg',
          isExternalLogo: !formData.logo,
          genders: ['male', 'female', 'custom'],
          maxEyvocates: formData.totalEyvocate,
          isPrivate: formData.isPrivate,
          pricePerHour: +formData.pricePerHour,
          mobileLink: formData.mobileLink,
          interests: !!formData.interests.find((a) => a.id === 999)
            ? interests.map((interest) => ({
                id: interest.id,
              }))
            : formData.interests.map((interest) => ({
                id: interest.id,
              })),
          ageRanges: !!formData.ageRanges.find((a) => a.id === 0)
            ? allAgeRanges.map((range) => ({
                id: range.id,
              }))
            : formData.ageRanges.flatMap((range) => {
                if (range.id === 998) {
                  return [{ id: 2 }, { id: 3 }, { id: 4 }];
                }
                if (range.id === 999) {
                  return [{ id: 5 }, { id: 6 }];
                }

                return { id: range.id };
              }),
          eyvocatePlaces: formData.locations.map((place) => ({
            externalPlaceId: place.id,
            label: place.label,
          })),
          couponId: coupon ? coupon.id : null,
          polygonLocation: {
            path: polygon.map((point) => ({
              latitude: point.lat,
              longitude: point.lng,
            })),
            centerPoint: {
              longitude: location?.lng,
              latitude: location?.lat,
            },
          },
          ...(circle && {
            circle: {
              path: [
                {
                  radius: circle.radius,
                  latitude: circle.lat,
                  longitude: circle.lng,
                },
              ],
              centerPoint: {
                longitude: location?.lng,
                latitude: location?.lat,
              },
            },
          }),
          tasks,
          genderSelection: formData.genders[0].id,
          customerInvitationFile: formData.file,
        };

        dispatch(
          publishExclusiveCampaignRequest(
            campaigData,
            () => {
              if (isAuth) {
                dispatch(fetchAuthenticatedAdvertiser());
                history.push(DASHBOARD);
              } else {
                dispatch(fetchAuthenticatedAdvertiser());
                const token = getItem(EYCROWD_ACCESS_TOKEN);
                dispatch(setToken(token));
              }

              setLoadingBtn(false);
            },
            () => {
              setLoadingBtn(false);
            }
          )
        );
      } catch (error) {
        console.log({ error });
        setLoadingBtn(false);
      }
    };

    const onClick = async () => {
      const cardNumberElement = stripeElements.getElement(CardNumberElement);
      setLoadingBtn(true);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          // name: cardHolder,
        },
      });

      if (error) {
        dispatch(
          enqueueSnackbar({
            message: globalMessages.paymentProviderUnavailable,
          })
        );
        return;
      }

      const onError = () => {
        setLoadingBtn(false);
      };

      dispatch(
        addPaymentMethod(paymentMethod.id, onAddPaymentSuccess, onError)
      );
    };

    useImperativeHandle(ref, () => ({
      handleFormSubmit() {
        if (!!paymentMethods.length) {
          onAddPaymentSuccess();
        } else {
          if (coupon?.type === 'vip') {
            onAddPaymentSuccess();
          } else {
            onClick();
          }
        }
      },
    }));

    const renderAccountInfo = () => {
      if (isAuth) {
        const {
          email,
          company: { name },
        } = advertiser;
        return (
          <p>
            {email},{name}
          </p>
        );
      } else {
        if (formData.data.fullName) {
          return (
            <p>
              {formData.data.email},{formData.data.fullName},
              {formData.data.companyName}
            </p>
          );
        } else {
          return <p>{formData.data.email}</p>;
        }
      }
    };

    const applyCoupon = () => {
      setCoupon(null);
      dispatch(
        verifyPromoCode(promoCode, (coupon) => {
          dispatch(
            enqueueSnackbar({
              message: 'Successfully applied coupon',
              variant: toasterVariants.success,
            })
          );

          setCoupon(coupon);
        })
      );
    };

    const getTotalPrice = () => {
      if (coupon) {
        if (coupon.type === 'vip') {
          return 0;
        }
        if (formData.totalPrice - coupon.value < 0) {
          return 0;
        } else {
          return formData.totalPrice - coupon.value;
        }
      } else {
        return formData.totalPrice;
      }
    };

    return (
      <div className={styles.contentContainer}>
        <div className={styles.container}>
          <div className={styles.summaryContainer}>
            <CampaignBuilderHeader title="Campaign Experience Summary" />
            <div className={styles.separator} />
            <RowContainer title="Export Campaign:" withoutError>
              <Button
                title="Export"
                onClick={exportCampaignData}
                className={styles.button}
              />
            </RowContainer>
            {formData.mobileLink && (
              <RowContainer
                title="Private Experience Link (Mobile App Link):"
                withoutError
                // infoButton="The total number of EyVocates for this campaign experience including over-booking."
              >
                <div className={styles.mobileLinkContainer}>
                  <p>
                    {mobileLinkPrefix}
                    {formData.mobileLink}
                  </p>

                  <div
                    className={styles.copyContainer}
                    onClick={handleCopyClick}
                  >
                    <CopyIcon color="white" width={18} height={18} />
                    <p>copy</p>
                  </div>
                </div>
              </RowContainer>
            )}
            {formData.logo && (
              <RowContainer title="Campaign Logo:" withoutError>
                <p>{formData.logo.name}</p>
              </RowContainer>
            )}
            {basicExperience && (
              <RowContainer title="Experience Type: " withoutError>
                <p>{BASIC_EXPERIENCE_STEP[basicExperience]}</p>
              </RowContainer>
            )}
            <RowContainer title="Title:" withoutError>
              <p>{formData.title}</p>
            </RowContainer>
            <RowContainer title="Description:" withoutError>
              <p>{formData.description}</p>
            </RowContainer>
            <RowContainer title="EyVocate™ Mix:" withoutError>
              <p>
                {formData.isPrivate
                  ? 'Private: My Customers Only'
                  : 'Public: My Customers and Public App Users'}
              </p>
            </RowContainer>
            <RowContainer title="Experience Window Start:" withoutError>
              <p>
                {format(formData.startDate, 'MM/dd/yyyy')}{' '}
                {format(formData.startTime, 'h:mm a')}
              </p>
            </RowContainer>
            <RowContainer title="Experience Window End:" withoutError>
              <p>
                {format(
                  addDays(formData.startDate, formData.experienceWindow),
                  'MM/dd/yyyy'
                )}{' '}
                {format(addHours(new Date(formData.startTime), 12), 'h:mm a')}{' '}
              </p>
            </RowContainer>
            <RowContainer
              title="EyVocates™, Activities, Duration:"
              withoutError
            >
              <div>
                <p>{`${formData.numOfEyvocates} EyVocates™,${
                  selectedTasks.length
                } Tasks, ${timeConvert(taskDuration)}`}</p>
              </div>
            </RowContainer>
            {/* <RowContainer
              title="Private Customers & Contacts List:"
              withoutError
            >
              <p>
                {formData.file
                  ? 'Yes, Private contact list uploaded'
                  : 'No, Private contact list was not uploaded (private experience link remains active and functional).'}
              </p>
            </RowContainer> */}

            <RowContainer title="EyVocate™ Interests:" withoutError>
              <p>
                {formData.interests
                  .map((i) => formatMessage(globalMessages.interests[i.slug]))
                  .join(', ')}
              </p>
            </RowContainer>
            <RowContainer title="EyVocate™ Ages:" withoutError>
              <p>{formData.ageRanges.map((i) => i.label).join(', ')}</p>
            </RowContainer>
            <RowContainer title="EyVocate™ Genders:" withoutError>
              <p>{formData.genders.map((i) => i.label).join(', ')}</p>
            </RowContainer>
            <RowContainer title="EyVocate™ Home Locations:" withoutError>
              <p>{formData.locations.map((i) => i.label).join(', ')}</p>
            </RowContainer>
            {/* <RowContainer title="Objectives, Tasks, and Time" withoutError>
              <p>{`${selectedObjectives.length} objectives with ${
                selectedTasks.length
              }  tasks, ${timeConvert(
                selectedTasks
                  .map((item) => item.time)
                  .reduce((prev, next) => +prev + +next)
              )}`}</p>
            </RowContainer> */}

            <RowContainer
              title={coupon ? 'Amount:' : 'Total Amount:'}
              withoutError
            >
              <p>
                {`$${formData.totalPrice?.toFixed(2)}   (${
                  formData.totalEyvocate
                } EyVocates x $${formData.pricePerHour} x ${timeConvert(
                  taskDuration
                )})`}
              </p>
            </RowContainer>

            <RowContainer title="Coupon Code:" withoutError>
              {!coupon && (
                <div>
                  <Input
                    placeholder="Enter coupon"
                    value={promoCode}
                    onChange={(event) => setPromoCode(event.target.value)}
                  />
                  <Button
                    title="Apply coupon"
                    onClick={applyCoupon}
                    disabled={!promoCode.trim()}
                  />
                </div>
              )}
              {coupon && (
                <p className={styles.couponAmount}>
                  {coupon.type === 'vip' ? `VIP Coupon` : `- $${coupon.value}`}
                </p>
              )}
            </RowContainer>
            {coupon && (
              <RowContainer title="Total Amount:" withoutError>
                <p className={styles.finalAmount}>
                  ${getTotalPrice()}
                  {coupon?.type === 'vip' &&
                    ` (${formData.totalEyvocate} EyVocates x $0 x ${timeConvert(
                      taskDuration
                    )})`}
                </p>
              </RowContainer>
            )}

            <RowContainer title="Account:" withoutError>
              {renderAccountInfo()}
            </RowContainer>

            {coupon?.type !== 'vip' && (
              <>
                {!!paymentMethods.length ? (
                  <div>
                    <div className={styles.titleContainer}>
                      <p className={styles.titleText}>Select Payment Method</p>

                      <div className={styles.selectContainer}>
                        <div className={styles.select}>
                          <Select
                            options={paymentMethodOptions}
                            handleChange={changeDefaulPayment}
                            selectedOption={paymentMethodOptions.find(
                              (t) => t.value.isDefault
                            )}
                            isSearchable={false}
                          />
                        </div>

                        <p>or</p>
                        <Button
                          title="Add new payment"
                          onClick={() => openPaymentMethodModal(() => {})}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <AddPaymentMethodForm
                    totalAmount={getTotalPrice()}
                    setInputInvalid={setInputInvalid}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CampaignExperienceSummary;
