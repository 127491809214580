import React from 'react';
import styles from './CodaQALibrary.module.scss';

function CodaQALibrary() {
  return (
    <iframe
      className={styles.embed}
      src="https://coda.io/embed/3kkHCysMyF/_suMoP?viewMode=embedplay"
      allow="fullscreen"
    ></iframe>
  );
}

export default CodaQALibrary;
