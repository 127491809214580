import React, { forwardRef } from 'react';
import Button from '../Button';
import styles from './ActionButtonsContainer.module.scss';

const ActionButtonsContainer = forwardRef(
  (
    {
      onBack,
      onContinue,
      continueBtnDisabled,
      continueBtnTitle = 'CONTINUE',
      children,
      continueLoading,
      continueHide,
    },
    ref
  ) => {
    return (
      <div className={styles.buttonsContainer} ref={ref}>
        {children}
        <div className={styles.buttonsWrapper}>
          <Button title="BACK" alt onClick={onBack} />
          {!continueHide && (
            <Button
              title={continueBtnTitle}
              onClick={onContinue}
              disabled={continueBtnDisabled}
              loading={continueLoading}
            />
          )}
        </div>
      </div>
    );
  }
);

export default ActionButtonsContainer;
