import React, { useEffect, useRef } from 'react';
import styles from './AICampaignSuggestion.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import {
  makeObjectivesSelector,
  makeSelectObjectiveSuggestions,
  makeSelectGetObjectiveSuggestionsPending,
  makeSelectGetObjectiveSuggestionsMessage,
  makeSelectGetObjectiveSuggestionsError,
} from 'containers/NewExperienceCampaignBuilderPage/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOpenAiObjectiveSuggestionsRequest } from 'containers/NewExperienceCampaignBuilderPage/actions';
import { ReactComponent as CheckIcon } from '../../../assets/images/checkMarkWhite.svg';
import LoadingSpinner from 'components/Layout/LoadingSpinner';
import { ReactComponent as DeleteBlackIcon } from 'assets/images/black_close_icon.svg';
import CheckBox from '../CheckBox';
import Input from '../Input';
import Tooltip from 'components/Tooltip';
import { ReactComponent as CatalogIcon } from 'assets/images/view_module.svg';

const CampaignSkeleton = ({ objectiveSuggestionsPending }) => {
  const campaignSkeletonRef = useRef(null);

  useEffect(() => {
    if (objectiveSuggestionsPending && campaignSkeletonRef.current) {
      campaignSkeletonRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [objectiveSuggestionsPending]);

  return (
    <div className={styles.aiDreamContainer}>
      <div className={styles.aiDreamHeadlineContainer}>
        <p
          className={styles.aiTitle}
          style={{ color: '#338891' }}
        >{`EyLa AI >  `}</p>

        <div
          className={`${styles.skeletonShape} ${styles.aiDescription}`}
          style={{ height: '20px', width: '550px' }}
        ></div>
      </div>
      <div
        className={`${styles.aiDreamContentContainer} ${styles.skeletonShape}`}
        style={{
          opacity: '0.5',
          cursor: 'default',
        }}
      >
        <div className={styles.aiDreamCampaignContainer}>
          <div
            className={`${styles.skeletonShape}`}
            style={{ height: '20px', width: '100px' }}
          ></div>
        </div>
        <div className={styles.aiDreamCampaignContainer}>
          <div
            className={`${styles.skeletonShape}`}
            style={{ height: '20px', width: '200px' }}
          ></div>
        </div>
        <div className={styles.aiDreamCampaignContainer}>
          <div
            className={`${styles.skeletonShape}`}
            style={{ height: '20px', width: '350px' }}
          ></div>
        </div>
        <div>
          <ul className={styles.objectiveList}>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '200px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '240px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '200px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '220px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '200px' }}
            ></li>
          </ul>
        </div>
        <div
          className={styles.buttonContainer}
          style={{ backgroundColor: '#e0e0e0' }}
        >
          <p>select</p>
        </div>
      </div>
      <div
        className={`${styles.aiDreamContentContainer} ${styles.skeletonShape}`}
        style={{
          opacity: '0.5',
          cursor: 'default',
        }}
      >
        <div className={styles.aiDreamCampaignContainer}>
          <div
            className={`${styles.skeletonShape}`}
            style={{ height: '20px', width: '100px' }}
          ></div>
        </div>
        <div className={styles.aiDreamCampaignContainer}>
          <div
            className={`${styles.skeletonShape}`}
            style={{ height: '20px', width: '200px' }}
          ></div>
        </div>
        <div className={styles.aiDreamCampaignContainer}>
          <div
            className={`${styles.skeletonShape}`}
            style={{ height: '20px', width: '350px' }}
          ></div>
        </div>
        <div>
          <ul className={styles.objectiveList}>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '200px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '240px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '200px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '220px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '200px' }}
            ></li>
          </ul>
        </div>
        <div
          className={styles.buttonContainer}
          style={{ backgroundColor: '#e0e0e0' }}
        >
          <p>select</p>
        </div>
      </div>
      <div
        className={`${styles.aiDreamContentContainer} ${styles.skeletonShape}`}
        style={{
          opacity: '0.5',
          cursor: 'default',
        }}
      >
        <div className={styles.aiDreamCampaignContainer}>
          <div
            className={`${styles.skeletonShape}`}
            style={{ height: '20px', width: '100px' }}
          ></div>
        </div>
        <div className={styles.aiDreamCampaignContainer}>
          <div
            className={`${styles.skeletonShape}`}
            style={{ height: '20px', width: '200px' }}
          ></div>
        </div>
        <div className={styles.aiDreamCampaignContainer}>
          <div
            className={`${styles.skeletonShape}`}
            style={{ height: '20px', width: '350px' }}
          ></div>
        </div>
        <div>
          <ul className={styles.objectiveList}>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '200px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '240px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '200px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '220px' }}
            ></li>
            <li
              className={`${styles.skeletonShape}`}
              style={{ height: '20px', width: '200px' }}
            ></li>
          </ul>
        </div>
        <div
          className={styles.buttonContainer}
          style={{ backgroundColor: '#e0e0e0' }}
        >
          <p>select</p>
        </div>
      </div>
    </div>
  );
};

const WANT_ACHIEVE = {
  REAL_IMPRESSION: 'real-impression',
  VIRAL_EFFECTS: 'viral-effects',
  USER_GENERATED_CONTENT: 'user-generated-content',
  REAL_AWARENESS: 'real-awareness',
  ONLINE_ENGAGEMENT: 'online-engagement',
  ELSE: 'else',
};

const AICampaignSuggestion = ({
  setSelectedObjectives,
  selectedObjectives,
  setFormData,
  selectedCampaignIndex,
  setSelectedCampaignIndex,
  onFieldsFilledChange,
  setAreObjectivesFromDream,
  setSelectedMode,
  basicExperience,
}) => {
  const campaignBasicInfoSchema = yup
    .object({
      wantAchieveElseText: yup
        .string()
        .when('wantAchieve', (wantAchieve, schema) => {
          // if it includes 'spouse'
          return wantAchieve.includes(WANT_ACHIEVE.ELSE)
            ? yup.string().trim().required('Field is required')
            : schema;
        }),
      wantAchieve: yup.array().min(1, 'At least one checkbox must be selected'),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      wantAchieve: [],
      wantAchieveElseText: '',
      additionalText: '',
    },
    delayError: 300,
    resolver: yupResolver(campaignBasicInfoSchema),
  });

  const wantAchieveWatch = watch('wantAchieve');
  const wantAchieveElseTextWatch = watch('wantAchieveElseText');
  const additionalTextWatch = watch('additionalText');

  useEffect(() => {
    const allFieldsFilled = wantAchieveWatch.length !== 0;

    onFieldsFilledChange(allFieldsFilled);
  }, [wantAchieveWatch, onFieldsFilledChange]);

  const allObjectives = useSelector(makeObjectivesSelector());
  const campaignSuggestions = useSelector(makeSelectObjectiveSuggestions());
  const objectiveSuggestionsPending = useSelector(
    makeSelectGetObjectiveSuggestionsPending()
  );
  const suggestionsMessage = useSelector(
    makeSelectGetObjectiveSuggestionsMessage()
  );
  const suggestionsError = useSelector(
    makeSelectGetObjectiveSuggestionsError()
  );

  const dispatch = useDispatch();

  const handleClearInputForField = (fieldName) => {
    setValue(fieldName, '');
  };

  const industryOptions = [
    { value: 'marketing', label: 'Marketing' },
    { value: 'retail', label: 'Retail' },
    { value: 'finance', label: 'Finance' },
    { value: 'technology', label: 'Technology' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'education', label: 'Education' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'realestate', label: 'Real Estate' },
    { value: 'entertainment', label: 'Entertainment' },
    { value: 'hospitality', label: 'Hospitality' },
    { value: 'transportation', label: 'Transportation' },
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'construction', label: 'Construction' },
    { value: 'energy', label: 'Energy' },
    { value: 'foodservice', label: 'Food Service' },
  ];

  const onSubmit = async (data) => {
    const filteredObjectives = allObjectives
      .filter((o) => !o.metatags || !o.metatags.includes('<COMING_SOON>'))
      .map((o) => ({ id: o.id, name: o.objective }));

    const industryPhrase = data.industry
      ? `${industryOptions[0].value} companies chain`
      : 'companies chain';

    const locationPhrase = data.numOfLocations
      ? `with at least 5 physical location${5 > 1 ? 's' : ''}`
      : '';

    const regionPhrase = data.regions ? `in ${data.regions}` : '';
    const engagementPhrase = data.engagement
      ? `That results in a ${data.engagement} impact`
      : '';
    const additionalPhrase = data.additionalText
      ? `and also consider ${data.additionalText} as requirements.`
      : '';
    const userInput =
      `for a ${industryPhrase} ${locationPhrase} and 1 online store ${regionPhrase}. ${engagementPhrase} ${additionalPhrase}`
        .trim()
        .replace(/\s+/g, ' ');

    const payload = {
      userInput,
      objectives: filteredObjectives,
    };
    setSelectedObjectives([]);
    dispatch(fetchOpenAiObjectiveSuggestionsRequest(payload));
  };

  useEffect(() => {
    if (basicExperience) {
      setValue('wantAchieve', [basicExperience]);
      onSubmit({});
    }
  }, [basicExperience]);

  const onCampaignClick = (campaign, index) => {
    const campaignObjectiveIds = campaign.objectives.map((obj) =>
      obj.id.toString()
    );

    const foundObjectives = allObjectives.filter((objective) =>
      campaignObjectiveIds.includes(objective.id.toString())
    );
    if (selectedCampaignIndex === index) {
      setSelectedCampaignIndex(null);
      setFormData((prev) => ({
        ...prev,
        title: null,
        description: null,
      }));
      setSelectedObjectives([]);
      setAreObjectivesFromDream(false);
    } else {
      if (selectedObjectives.length > 0) {
        setSelectedObjectives([]);
      }
      setFormData((prev) => ({
        ...prev,
        title: campaign.campaignTitle,
        description: campaign.campaignDescription,
      }));
      setSelectedCampaignIndex(index);
      setSelectedObjectives(foundObjectives);
      setAreObjectivesFromDream(true);
    }
  };

  const campaignSkeletonRef = useRef(null);

  useEffect(() => {
    if (objectiveSuggestionsPending && campaignSkeletonRef.current) {
      campaignSkeletonRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [objectiveSuggestionsPending]);

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        EyLa AI Dream, Let’s dream up brand experience ideas...
      </p>
      {!basicExperience ? (
        <>
          <p className={styles.title}>
            Choose the desired results for your brand experience (select all
            that apply):
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.checkboxWrapper}>
              <Controller
                name="wantAchieve"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.checkboxWrapper}>
                      <CheckBox
                        hideErrorMessage
                        onChange={() => {
                          const ind = field.value.indexOf(
                            WANT_ACHIEVE.REAL_IMPRESSION
                          );

                          const newValues = [...field.value];
                          if (ind !== -1) {
                            newValues.splice(ind, 1);
                            field.onChange(newValues);
                          } else {
                            field.onChange([
                              ...field.value,
                              WANT_ACHIEVE.REAL_IMPRESSION,
                            ]);
                          }
                        }}
                        checked={field.value.includes(
                          WANT_ACHIEVE.REAL_IMPRESSION
                        )}
                      >
                        Real brand immersion (hours and days)
                      </CheckBox>
                      <Tooltip
                        className={styles.tooltip}
                        label={`brandImmersion`}
                        customContent={
                          <p>
                            Your devoted customers and fans will enjoy hours of
                            engagement, interaction, entertainment, and
                            immersion in your brand via the EyCrowd app.
                          </p>
                        }
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div className={styles.checkboxWrapper}>
              <Controller
                name="wantAchieve"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.checkboxWrapper}>
                      <CheckBox
                        hideErrorMessage
                        onChange={() => {
                          const ind = field.value.indexOf(
                            WANT_ACHIEVE.VIRAL_EFFECTS
                          );

                          const newValues = [...field.value];
                          if (ind !== -1) {
                            newValues.splice(ind, 1);
                            field.onChange(newValues);
                          } else {
                            field.onChange([
                              ...field.value,
                              WANT_ACHIEVE.VIRAL_EFFECTS,
                            ]);
                          }
                        }}
                        checked={field.value.includes(
                          WANT_ACHIEVE.VIRAL_EFFECTS
                        )}
                      >
                        Authentic word-of-mouth viral effects
                      </CheckBox>
                      <Tooltip
                        className={styles.tooltip}
                        label={`wordOfMouth`}
                        customContent={
                          <p>
                            Your loyal customers and fans will tell friends and
                            family about the great time they had immersed in
                            your brand experience on the EyCrowd app.
                          </p>
                        }
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div className={styles.checkboxWrapper}>
              <Controller
                name="wantAchieve"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.checkboxWrapper}>
                      <CheckBox
                        hideErrorMessage
                        onChange={() => {
                          const ind = field.value.indexOf(
                            WANT_ACHIEVE.USER_GENERATED_CONTENT
                          );

                          const newValues = [...field.value];
                          if (ind !== -1) {
                            newValues.splice(ind, 1);
                            field.onChange(newValues);
                          } else {
                            field.onChange([
                              ...field.value,
                              WANT_ACHIEVE.USER_GENERATED_CONTENT,
                            ]);
                          }
                        }}
                        checked={field.value.includes(
                          WANT_ACHIEVE.USER_GENERATED_CONTENT
                        )}
                      >
                        Genuine user generated content with your brand, products
                        / services (photos & videos)
                      </CheckBox>
                      <Tooltip
                        className={styles.tooltip}
                        label={`UGC`}
                        customContent={
                          <p>
                            Your loyal customers and fans will tell friends and
                            family about the great time they had immersed in
                            your brand experience on the EyCrowd app.
                          </p>
                        }
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div className={styles.checkboxWrapper}>
              <Controller
                name="wantAchieve"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.checkboxWrapper}>
                      <CheckBox
                        hideErrorMessage
                        onChange={() => {
                          const ind = field.value.indexOf(
                            WANT_ACHIEVE.REAL_AWARENESS
                          );

                          const newValues = [...field.value];
                          if (ind !== -1) {
                            newValues.splice(ind, 1);
                            field.onChange(newValues);
                          } else {
                            field.onChange([
                              ...field.value,
                              WANT_ACHIEVE.REAL_AWARENESS,
                            ]);
                          }
                        }}
                        checked={field.value.includes(
                          WANT_ACHIEVE.REAL_AWARENESS
                        )}
                      >
                        Build real awareness of my products / services
                      </CheckBox>
                      <Tooltip
                        className={styles.tooltip}
                        label={`realAwerness`}
                        customContent={
                          <p>
                            Your loyal customers and fans will gain a renewed
                            and profound understanding of your brand, products,
                            and services from their enjoyable experience with
                            your brand on the EyCrowd app.
                          </p>
                        }
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div className={styles.checkboxWrapper}>
              <Controller
                name="wantAchieve"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.checkboxWrapper}>
                      <CheckBox
                        hideErrorMessage
                        onChange={() => {
                          const ind = field.value.indexOf(
                            WANT_ACHIEVE.ONLINE_ENGAGEMENT
                          );

                          const newValues = [...field.value];
                          if (ind !== -1) {
                            newValues.splice(ind, 1);
                            field.onChange(newValues);
                          } else {
                            field.onChange([
                              ...field.value,
                              WANT_ACHIEVE.ONLINE_ENGAGEMENT,
                            ]);
                          }
                        }}
                        checked={field.value.includes(
                          WANT_ACHIEVE.ONLINE_ENGAGEMENT
                        )}
                      >
                        Authentic online engagement and web traffic
                      </CheckBox>
                      <Tooltip
                        className={styles.tooltip}
                        label={`authenticOnlineEngagement`}
                        customContent={
                          <p>
                            Your devoted customers and fans will discover and
                            delight in your brand, products, and services like
                            never before through the brand experience on the
                            EyCrowd app. (100% real engaged traffic, zero bots)
                          </p>
                        }
                      />
                    </div>
                  );
                }}
              />
            </div>
            {/* <div className={styles.checkboxWrapper}>
          <Controller
            name="wantAchieve"
            control={control}
            render={({ field }) => {
              return (
                <div className={styles.checkboxWrapper}>
                  <CheckBox
                    error={errors.wantAchieve?.message}
                    onChange={() => {
                      const ind = field.value.indexOf(WANT_ACHIEVE.ELSE);

                      const newValues = [...field.value];
                      if (ind !== -1) {
                        newValues.splice(ind, 1);
                        field.onChange(newValues);
                      } else {
                        field.onChange([...field.value, WANT_ACHIEVE.ELSE]);
                      }
                    }}
                    checked={field.value.includes(WANT_ACHIEVE.ELSE)}
                  >
                    Enter anything else (it’s ok, enter any authentic goal, EyLa
                    AI will understand)
                  </CheckBox>
                </div>
              );
            }}
          />
        </div> */}

            {wantAchieveWatch.includes(WANT_ACHIEVE.ELSE) && (
              <div className={styles.inputWrapper}>
                <Input
                  type="textarea"
                  placeholder="enter any authentic result you want to achieve for your brand, in a few words or sentences"
                  {...register('wantAchieveElseText')}
                  error={errors.wantAchieveElseText?.message}
                  rightIcon={<DeleteBlackIcon />}
                  handleClearInput={() =>
                    handleClearInputForField('wantAchieveElseText')
                  }
                  value={wantAchieveElseTextWatch}
                />
              </div>
            )}
            <div className={styles.titleContainer}>
              <p className={styles.title}>
                Add additional considerations for your campaign experience
                (optional):
              </p>
              <Tooltip
                className={styles.tooltip}
                label={`additionalConsiderations`}
                customContent={
                  <p>
                    List all requirements, preferences, background information,
                    or other details needed to ensure an amazing brand
                    experience for your customers and fans on the EyCrowd app.
                  </p>
                }
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                type="textarea"
                placeholder="Enter any additional requirements and preferences you want EyLa AI to consider for your brand experience. Communicate as you would with a team member, using full sentences to detail your points as much as you like."
                {...register('additionalText')}
                error={errors.additionalText?.message}
                rightIcon={<DeleteBlackIcon />}
                handleClearInput={() =>
                  handleClearInputForField('additionalText')
                }
                value={additionalTextWatch}
                className={styles.additionalText}
              />
            </div>
            <div className={styles.dreamRow} ref={campaignSkeletonRef}>
              {/* <p className={styles.textLeft} /> */}
              <div className={styles.btnContainer}>
                <button
                  disabled={objectiveSuggestionsPending}
                  className={styles.btnUpdated}
                >
                  {!!campaignSuggestions.length ? 'DREAM AGAIN' : 'DREAM'}
                </button>
                {objectiveSuggestionsPending ? (
                  <LoadingSpinner className={styles.spinner} />
                ) : (
                  <div className={styles.spinnerDiv} />
                )}
              </div>
              <div
                className={styles.catalogBtnContainer}
                onClick={() => setSelectedMode('CATALOG')}
              >
                <CatalogIcon height={26} color={'#5ca0a7'} />
                <p className={styles.btnText}>CATALOG</p>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          {objectiveSuggestionsPending ? (
            <LoadingSpinner className={styles.spinner} />
          ) : (
            <div className={styles.spinnerDiv} />
          )}
        </>
      )}
      {suggestionsError && !objectiveSuggestionsPending && (
        <div className={styles.aiDreamErrorContainer}>
          <p
            className={styles.aiTitle}
            style={{ color: '#db4f4fcc' }}
          >{`EyLa AI >  `}</p>
          <p className={styles.errorMessage}>{suggestionsMessage}</p>
        </div>
      )}
      {objectiveSuggestionsPending && (
        <CampaignSkeleton
          objectiveSuggestionsPending={objectiveSuggestionsPending}
        />
      )}
      {!!campaignSuggestions.length && !objectiveSuggestionsPending && (
        <div className={styles.aiDreamContainer}>
          <div className={styles.aiDreamHeadlineContainer}>
            <p
              className={styles.aiTitle}
              style={{ color: '#338891' }}
            >{`EyLa AI >  `}</p>
            <p className={styles.aiDescription}>{suggestionsMessage}</p>
          </div>
          {campaignSuggestions.map((campaign, index) => {
            return (
              <div
                key={index}
                className={styles.aiDreamContentContainer}
                onClick={() => onCampaignClick(campaign, index)}
                style={
                  index === selectedCampaignIndex
                    ? {
                        border: '3px solid #338891',
                        background: 'rgba(92, 160, 167, 0.20)',
                      }
                    : {}
                }
              >
                <div className={styles.aiDreamCampaignContainer}>
                  <p
                    className={styles.aiDreamHeadlines}
                  >{`Brand Experience Idea ${index + 1}:`}</p>
                </div>
                <div className={styles.aiDreamCampaignContainer}>
                  <p className={styles.aiDreamHeadlines}>
                    Title: {campaign.campaignTitle}
                  </p>
                </div>
                <div className={styles.aiDreamCampaignContainer}>
                  <p>
                    <span className={styles.aiDreamHeadlines}>
                      Description:
                    </span>{' '}
                    <span className={styles.aiDreamText}>
                      {campaign.campaignDescription}
                    </span>
                  </p>
                </div>
                <div>
                  <ul className={styles.objectiveList}>
                    {campaign.objectives.map((objective, i) => {
                      return (
                        <li
                          key={objective.id}
                          className={styles.aiDreamText}
                        >{`Objective ${i + 1}: ${objective.headline}`}</li>
                      );
                    })}
                  </ul>
                </div>
                <div className={styles.buttonContainer}>
                  <p
                    style={{
                      marginLeft:
                        selectedCampaignIndex === index ? '20px' : '0',
                    }}
                  >
                    {selectedCampaignIndex === index ? 'selected' : 'select'}
                  </p>
                  {selectedCampaignIndex === index && (
                    <CheckIcon color="#ffffff" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {!!campaignSuggestions.length && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.dreamRow}>
            {/* <p className={styles.textLeft} /> */}
            <div className={styles.btnContainer}>
              <button
                disabled={objectiveSuggestionsPending}
                className={styles.btnUpdated}
              >
                {!!campaignSuggestions.length ? 'DREAM AGAIN' : 'DREAM'}
              </button>
              {objectiveSuggestionsPending ? (
                <LoadingSpinner className={styles.spinner} />
              ) : (
                <div className={styles.spinnerDiv} />
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AICampaignSuggestion;
