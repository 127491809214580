import classNames from 'classnames';
import React from 'react';
import styles from './CardValueChip.module.scss';

const CardValueChip = ({ text, background }) => {
  return (
    <div style={{ background }} className={classNames(styles.chipContainer)}>
      <p>{text}</p>
    </div>
  );
};

export default CardValueChip;
