import React from 'react';
import styles from './SearchInput.module.scss';
import { ReactComponent as CloseIcon } from 'assets/images/close1.svg';

const SearchInput = ({
  name,
  defaultValue,
  value,
  placeholder,
  onChange,
  autoFocus,
  disabled,
  type,
  onCancelClick,
}) => {
  return (
    <div className={styles.inputContainer}>
      <input
        type={type}
        name={name}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autoFocus}
        disabled={disabled}
        className={styles.styledInput}
      />

      {onCancelClick && value && (
        <CloseIcon onClick={onCancelClick} className={styles.icon} />
      )}
    </div>
  );
};

export default SearchInput;
