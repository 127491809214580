import React from 'react';
import styles from './Spinner.module.scss';
import Spinner from 'assets/images/spinner.gif';
import classNames from 'classnames';

const LoadingSpinner = ({ className, margin }) => {
  return (
    <div
      style={{ marginBottom: margin ? 24 : 0 }}
      className={classNames(styles.spinnerWrapper, className)}
    >
      <img src={Spinner} alt="Spinner" />
    </div>
  );
};

export default LoadingSpinner;
