import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  parseJSON,
  startOfDay,
  subDays,
} from 'date-fns';

const now = () => new Date();

const today = () => startOfDay(now());

const addDays = (date, daysToAdd) => add(date, { days: daysToAdd });

const addHours = (date, hoursToAdd) => add(date, { hours: hoursToAdd });

const addMinutes = (date, minutesToAdd) => add(date, { minutes: minutesToAdd });

const subtractDays = (date, daysToSubtract) => subDays(date, daysToSubtract);

const formatJsonDate = (jsonDate) => parseJSON(jsonDate);

const diffMinutes = (endDate, startDate) =>
  differenceInMinutes(endDate, startDate);

const diffHours = (endDate, startDate) => differenceInHours(endDate, startDate);

const diffDays = (endDate, startDate) => differenceInDays(endDate, startDate);

const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes()
  );
};

const formatDate = (date, dateFormat) =>
  format(parseJSON(date || new Date()), dateFormat);

const isAfterNow = (date) => formatJsonDate(date) > now();

const isSameDay = (firstDate, secondDate) => {
  return (
    firstDate.getUTCFullYear() === secondDate.getUTCFullYear() &&
    firstDate.getUTCMonth() === secondDate.getUTCMonth() &&
    firstDate.getUTCDate() === secondDate.getUTCDate()
  );
};

export {
  now,
  today,
  addDays,
  addHours,
  addMinutes,
  subtractDays,
  formatJsonDate,
  formatDate,
  getUTCDate,
  diffMinutes,
  diffHours,
  diffDays,
  isAfterNow,
  isSameDay,
};
