import {
  LOGIN_ADVERTISER_REQUEST,
  PUBLISH_EXCLUSIVE_CAMPAING_REQUEST,
  REGISTER_ADVERTISER_REQUEST,
  REORGANIZE_JSON_DATA,
  FETCH_OPENAI_SUGGESTIONS_SUCCESS,
  FETCH_OPENAI_SUGGESTIONS_REQUEST,
  FETCH_OPENAI_SUGGESTIONS_ERROR,
  FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_REQUEST,
  FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_ERROR,
  FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_SUCCESS,
  ADD_DUPLICATE_TASK,
  DELETE_TASK,
  MOVE_TASK,
  FETCH_OBJECTIVE_SUGGESTIONS_REQUEST,
  FETCH_OBJECTIVE_SUGGESTIONS_SUCCESS,
  FETCH_OBJECTIVE_SUGGESTIONS_ERROR,
  FETCH_OPENAI_SUGGESTIONS_RESET,
  FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_RESET,
  GET_BRAND_STORY_REQUEST,
  GET_BRAND_STORY_ERROR,
  GET_BRAND_STORY_SUCCESS,
  GET_TASK_DESCRIPTIONS_REQUEST,
  GET_TASK_DESCRIPTIONS_SUCCESS,
  GET_TASK_DESCRIPTIONS_ERROR,
  GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_REQUEST,
  GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_SUCCESS,
  GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_ERROR,
  SET_LAST_TIME_ACCESSED_BUILDER_TIME_REQUEST,
} from './constants';

export function reorganizeJsonData(jsonData) {
  return {
    type: REORGANIZE_JSON_DATA,
    payload: { jsonData },
  };
}

export function registerExclusiveAdvertiser(
  advertiserDetails,
  onSuccess,
  onError
) {
  return {
    type: REGISTER_ADVERTISER_REQUEST,
    advertiserDetails,
    meta: { onSuccess, onError },
  };
}

export function publishExclusiveCampaignRequest(
  campaignData,
  onSuccess,
  onError
) {
  return {
    type: PUBLISH_EXCLUSIVE_CAMPAING_REQUEST,
    campaignData,
    meta: { onSuccess, onError },
  };
}

export function loginExclusiveAdvertiser(
  advertiserDetails,
  onSuccess,
  onError
) {
  return {
    type: LOGIN_ADVERTISER_REQUEST,
    advertiserDetails,
    meta: { onSuccess, onError },
  };
}

export function fetchOpenAiSuggestionsRequest(data) {
  return {
    type: FETCH_OPENAI_SUGGESTIONS_REQUEST,
    data,
  };
}

export function fetchOpenAiSuggestionsSuccess(suggestions) {
  return {
    type: FETCH_OPENAI_SUGGESTIONS_SUCCESS,
    payload: suggestions,
  };
}

export function fetchOpenAiSuggestionsError() {
  return {
    type: FETCH_OPENAI_SUGGESTIONS_ERROR,
  };
}

export function fetchOpenAiSocialAccountSuggestionsRequest(data) {
  return {
    type: FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_REQUEST,
    payload: data,
  };
}

export function fetchOpenAiSocialAccountSuggestionsSuccess(
  suggestions,
  message
) {
  return {
    type: FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_SUCCESS,
    payload: { suggestions, message },
  };
}

export function fetchOpenAiSocialAccountSuggestionsError(error) {
  return {
    type: FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_ERROR,
    payload: error,
  };
}

export function addDuplicateTask(task, newId) {
  return {
    type: ADD_DUPLICATE_TASK,
    payload: { task, newId },
  };
}

export function deleteTask(id, objectiveId) {
  return {
    type: DELETE_TASK,
    payload: { id, objectiveId },
  };
}

export function moveTask(currentTask, direction) {
  return {
    type: MOVE_TASK,
    payload: { currentTask, direction },
  };
}

export function fetchOpenAiObjectiveSuggestionsRequest(data) {
  return {
    type: FETCH_OBJECTIVE_SUGGESTIONS_REQUEST,
    payload: data,
  };
}

export function fetchOpenAiObjectiveSuggestionsSuccess(objectives, message) {
  return {
    type: FETCH_OBJECTIVE_SUGGESTIONS_SUCCESS,
    payload: { objectives, message },
  };
}

export function fetchOpenAiObjectiveSuggestionsError(error) {
  return {
    type: FETCH_OBJECTIVE_SUGGESTIONS_ERROR,
    payload: error,
  };
}

export function resetOpenAiSuggestions() {
  return {
    type: FETCH_OPENAI_SUGGESTIONS_RESET,
  };
}

export function resetSocialAccountSuggestions() {
  return {
    type: FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_RESET,
  };
}

export function getBrandStoryRequest(data, onSuccess, onError) {
  return {
    type: GET_BRAND_STORY_REQUEST,
    data,
    meta: { onSuccess, onError },
  };
}

export function getBrandStorySuccess() {
  return {
    type: GET_BRAND_STORY_SUCCESS,
  };
}

export function getBrandStoryError() {
  return {
    type: GET_BRAND_STORY_ERROR,
  };
}

export function getTaskDescriptionsRequest(data, onSuccess, onError) {
  return {
    type: GET_TASK_DESCRIPTIONS_REQUEST,
    data,
    meta: { onSuccess, onError },
  };
}

export function getTaskDescriptionsSuccess() {
  return {
    type: GET_TASK_DESCRIPTIONS_SUCCESS,
  };
}

export function getTaskDescriptionsError() {
  return {
    type: GET_TASK_DESCRIPTIONS_ERROR,
  };
}

export function aiRegenerateTaskDescriptionPlusSocialMediaUrlsRequest(
  data,
  onSuccess,
  onError
) {
  return {
    type: GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_REQUEST,
    data,
    meta: { onSuccess, onError },
  };
}

export function aiRegenerateTaskDescriptionPlusSocialMediaUrlsSuccess() {
  return {
    type: GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_SUCCESS,
  };
}

export function aiRegenerateTaskDescriptionPlusSocialMediaUrlsError() {
  return {
    type: GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_ERROR,
  };
}

export function setLastTimeAccessedBuilderTimeRequest() {
  return {
    type: SET_LAST_TIME_ACCESSED_BUILDER_TIME_REQUEST,
  };
}
