export const stateAbbreviations = {
  AL: { id: 'ChIJdf5LHzR_hogR6czIUzU0VV4', label: 'Alabama, USA' },
  AK: { id: 'ChIJG8CuwJzfAFQRNduKqSde27w', label: 'Alaska, USA' },
  AZ: { id: 'ChIJaxhMy-sIK4cRcc3Bf7EnOUI', label: 'Arizona, USA' },
  AR: { id: 'ChIJYSc_dD-e0ocR0NLf_z5pBaQ', label: 'Arkansas, USA' },
  CA: { id: 'ChIJPV4oX_65j4ARVW8IJ6IJUYs', label: 'California, USA' },
  CO: { id: 'ChIJt1YYm3QUQIcR_6eQSTGDVMc', label: 'Colorado, USA' },
  CT: { id: 'ChIJpVER8hFT5okR5XBhBVttmq4', label: 'Connecticut, USA' },
  DE: { id: 'ChIJO9YMTXYFx4kReOgEjBItHZQ', label: 'Delaware, USA' },
  FL: { id: 'ChIJvypWkWV2wYgR0E7HW9MTLvc', label: 'Florida, USA' },
  GA: { id: 'ChIJV4FfHcU28YgR5xBP7BC8hGY', label: 'Georgia, USA' },
  HI: { id: 'ChIJBeB5Twbb_3sRKIbMdNKCd0s', label: 'Hawaii, USA' },
  ID: { id: 'ChIJ6Znkhaj_WFMRWIf3FQUwa9A', label: 'Idaho, USA' },
  IL: { id: 'ChIJGSZubzgtC4gRVlkRZFCCFX8', label: 'Illinois, USA' },
  IN: { id: 'ChIJHRv42bxQa4gRcuwyy84vEH4', label: 'Indiana, USA' },
  IA: { id: 'ChIJGWD48W9e7ocR2VnHV0pj78Y', label: 'Iowa, USA' },
  KS: { id: 'ChIJawF8cXEXo4cRXwk-S6m0wmg', label: 'Kansas, USA' },
  KY: { id: 'ChIJyVMZi0xzQogR_N_MxU5vH3c', label: 'Kentucky, USA' },
  LA: { id: 'ChIJZYIRslSkIIYRA0flgTL3Vck', label: 'Louisiana, USA' },
  ME: { id: 'ChIJ1YpTHd4dsEwR0KggZ2_MedY', label: 'Maine, USA' },
  MD: { id: 'ChIJ35Dx6etNtokRsfZVdmU3r_I', label: 'Maryland, USA' },
  MA: { id: 'ChIJ_b9z6W1l44kRHA2DVTbQxkU', label: 'Massachusetts, USA' },
  MI: { id: 'ChIJEQTKxz2qTE0Rs8liellI3Zc', label: 'Michigan, USA' },
  MN: { id: 'ChIJmwt4YJpbWE0RD6L-EJvJogI', label: 'Minnesota, USA' },
  MS: { id: 'ChIJGdRK5OQyKIYR2qbc6X8XDWI', label: 'Mississippi, USA' },
  MO: { id: 'ChIJfeMiSNXmwIcRcr1mBFnEW7U', label: 'Missouri, USA' },
  MT: { id: 'ChIJ04p7LZwrQVMRGGwqz1jWcfU', label: 'Montana, USA' },
  NE: { id: 'ChIJ7fwMtciNk4cRxArzDwyQJ6E', label: 'Nebraska, USA' },
  NV: { id: 'ChIJcbTe-KEKmYARs5X8qooDR88', label: 'Nevada, USA' },
  NH: { id: 'ChIJ66bAnUtEs0wR64CmJa8CyNc', label: 'New Hampshire, USA' },
  NJ: { id: 'ChIJn0AAnpX7wIkRjW0_-Ad70iw', label: 'New Jersey, USA' },
  NM: { id: 'ChIJqVKY50NQGIcRup41Yxpuv0Y', label: 'New Mexico, USA' },
  NY: { id: 'ChIJqaUj8fBLzEwRZ5UY3sHGz90', label: 'New York State, USA' },
  NC: { id: 'ChIJgRo4_MQfVIgRGa4i6fUwP60', label: 'North Carolina, USA' },
  ND: { id: 'ChIJY-nYVxKD11IRyc9egzmahA0', label: 'North Dakota, USA' },
  OH: { id: 'ChIJwY5NtXrpNogRFtmfnDlkzeU', label: 'Ohio, USA' },
  OK: { id: 'ChIJnU-ssRE5rIcRSOoKQDPPHF0', label: 'Oklahoma, USA' },
  OR: { id: 'ChIJVWqfm3xuk1QRdrgLettlTH0', label: 'Oregon, USA' },
  PA: { id: 'ChIJieUyHiaALYgRPbQiUEchRsI', label: 'Pennsylvania, USA' },
  RI: { id: 'ChIJD9cOYhQ15IkR5wbB57wYTh4', label: 'Rhode Island, USA' },
  SC: { id: 'ChIJ49ExeWml-IgRnhcF9TKh_7k', label: 'South Carolina, USA' },
  SD: { id: 'ChIJpTjphS1DfYcRt6SGMSnW8Ac', label: 'South Dakota, USA' },
  TN: { id: 'ChIJA8-XniNLYYgRVpGBpcEgPgM', label: 'Tennessee, USA' },
  TX: { id: 'ChIJSTKCCzZwQIYRPN4IGI8c6xY', label: 'Texas, USA' },
  UT: { id: 'ChIJzfkTj8drTIcRP0bXbKVK370', label: 'Utah, USA' },
  VT: { id: 'ChIJ_87aSGzctEwRtGtUNnSJTSY', label: 'Vermont, USA' },
  VA: { id: 'ChIJzbK8vXDWTIgRlaZGt0lBTsA', label: 'Virginia, USA' },
  WA: { id: 'ChIJ-bDD5__lhVQRuvNfbGh4QpQ', label: 'Washington, USA' },
  WV: { id: 'ChIJRQnL1KVUSogRQzrN3mjHALs', label: 'West Virginia, USA' },
  WI: { id: 'ChIJr-OEkw_0qFIR1kmG-LjV1fI', label: 'Wisconsin, USA' },
  WY: { id: 'ChIJaS7hSDTiXocRLzh90nkisCY', label: 'Wyoming, USA' },
};
