import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styles from './DashboardLayout.module.scss';
import Icon from '../../Icon';
import { ReactComponent as HomeIcon } from 'assets/images/newHomeIcon.svg';
import { ReactComponent as PlusSign } from 'assets/images/add-black.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';
import { ReactComponent as HelpIcon } from 'assets/images/help.svg';
import { ReactComponent as NotificationIcon } from 'assets/images/bell.svg';
import { ReactComponent as StarIcon } from 'assets/images/newStar.svg';
import { ReactComponent as UpdateIcon } from 'assets/images/updateIcon.svg';
import { ReactComponent as TrainingIcon } from 'assets/images/training.svg';
import { ReactComponent as AppLibraryIcon } from 'assets/images/App_library.svg';
import { ReactComponent as BuilderLibraryIcon } from 'assets/images/plusBuilder.svg';
import { ReactComponent as QALibraryIcon } from 'assets/images/Q&AIcon.svg';
import { ReactComponent as PartnerProgramOverviewIcon } from 'assets/images/partnerProgramOverview.svg';
import { ReactComponent as PartnerProgramIcon } from 'assets/images/partnerProgramIcon.svg';
import { ReactComponent as partnerUpdatesIcon } from 'assets/images/partnerUpdatesIcon.svg';
import { ReactComponent as WhyEyCrowdIcon } from 'assets/images/new-checkmark.svg';
import { ReactComponent as NewHomeIcon } from 'assets/images/new-home.svg';
import { ReactComponent as NewSettingsIcon } from 'assets/images/new-settings.svg';
import { ReactComponent as NewPlusIcon } from 'assets/images/new-plus.svg';
import { ReactComponent as NewPartnerIcon } from 'assets/images/new-partner.svg';
import { ReactComponent as NewBecomePartnerIcon } from 'assets/images/new-become-partner.svg';
import { ReactComponent as NewAppLibraryIcon } from 'assets/images/new-applibrary.svg';
import { ReactComponent as NewBuliderLibraryIcon } from 'assets/images/new-builderlibrary.svg';
import { ReactComponent as NewQALibraryIcon } from 'assets/images/new-qalibrary.svg';
import { ReactComponent as NewUpdateIcon } from 'assets/images/new-update.svg';
import { ReactComponent as NewHelpIcon } from 'assets/images/new-help.svg';
import { ReactComponent as NewPartnerUpdateIcon } from 'assets/images/new-partner-update.svg';

import partnerBadgeLogo from 'assets/images/partnerBadgeLogo.svg';
import forBusinessLogo from 'assets/images/forBusinessLogo.svg';
// import dashboardLogo from 'assets/images/logo-black-2.png';
// import dashboardLogo from 'assets/images/logo-black-3.png';
import dashboardLogo from 'assets/images/logo-black-1.svg';
import {
  DASHBOARD_CREATE_CAMPAIGN,
  DASHBOARD,
  ADVERTISER_SETTINGS,
  NEED_HELP,
  PARTNER_PROGRAM,
  PARTNER_PROGRAM_INFO,
  APP_VIDEO_LIBRARY,
  BUILDER_VIDEO_LIBRARY,
  QA_VIDEO_LIBRARY,
  PARTNER_PROGRAM_UPDATE,
  EYCROWD_UPDATES,
  WHY_EYCROWD,
} from 'routes';
import { useConfirmationModal } from 'components/ConfirmationModal';
import { useIntl } from 'react-intl';
import messages from './messages';
import { NEW_CREATE_CAMPAIGN } from 'routes';
import { makeSelectAdvertiser } from 'containers/App/selectors';
import { useSelector } from 'react-redux';

const SIDE_BAR_ITEMS = [
  {
    label: 'Create Experience',
    route: NEW_CREATE_CAMPAIGN,
    icon: () => <NewPlusIcon className={styles.newIcons} />,
  },
  {
    label: 'Dashboard',
    route: DASHBOARD,
    icon: () => <NewHomeIcon className={styles.newIcons} />,
  },
  // {
  //   label: 'Notifications',
  //   icon: NotificationIcon,
  //   route: ADVERTISER_SETTINGS,
  // },
  {
    label: 'Settings',
    route: ADVERTISER_SETTINGS,
    icon: () => <NewSettingsIcon className={styles.newIcons} />,
  },
];

const SIDE_BAR_NEED_HELP_ITEM = [
  {
    label: 'Need help?',
    route: NEED_HELP,
    icon: () => <NewHelpIcon className={styles.newIcons} />,
  },
];

const SIDE_BAR_ADDITIONAL_ITEMS = [
  {
    label: 'Become a Partner',
    sublabel: 'Signup',
    route: PARTNER_PROGRAM,
    icon: () => <NewBecomePartnerIcon className={styles.newIcons} />,
    isPartner: false,
  },
  {
    label: 'Partner Program',
    sublabel: 'Information Page',
    icon: () => <NewPartnerIcon className={styles.newIcons} />,
    isPartner: false,
    route: PARTNER_PROGRAM_INFO,
  },
  {
    label: 'Partner Updates',
    sublabel: 'Information Page',
    icon: () => <NewPartnerUpdateIcon className={styles.newIcons} />,
    isPartner: true,
    route: PARTNER_PROGRAM_UPDATE,
  },
  {
    label: 'Partner Program',
    sublabel: 'Information Page',
    route: PARTNER_PROGRAM_INFO,
    isPartner: true,
    icon: () => <NewPartnerIcon className={styles.newIcons} />,
  },

  // {
  //   label: 'Training',
  //   route: NEW_CREATE_CAMPAIGN,
  //   icon: TrainingIcon,
  //   isPartner: true,
  // },
];

const SIDE_BAR_LEARNING_ITEMS = [
  {
    label: 'Why EyCrowd',
    sublabel: 'Information Page',
    route: WHY_EYCROWD,
    icon: () => <WhyEyCrowdIcon className={styles.newIcons} />,
    isPartner: false,
  },
  {
    label: 'EyCrowd App',
    sublabel: 'Video Library',
    route: APP_VIDEO_LIBRARY,
    icon: () => <NewAppLibraryIcon className={styles.newIcons} />,
    isPartner: false,
  },
  {
    label: 'Experience Builder',
    sublabel: 'Video Library',
    route: BUILDER_VIDEO_LIBRARY,
    icon: () => <NewBuliderLibraryIcon className={styles.newIcons} />,
    isPartner: false,
  },
  {
    label: 'Q&A with the CEO',
    sublabel: 'Video Library',
    route: QA_VIDEO_LIBRARY,
    icon: () => <NewQALibraryIcon className={styles.newIcons} />,
    isPartner: false,
  },
  {
    label: 'EyCrowd Updates',
    sublabel: 'Information Page',
    route: EYCROWD_UPDATES,
    icon: () => <NewUpdateIcon className={styles.newIcons} />,
    isPartner: false,
  },
];

const DashboardSideBar = () => {
  const { formatMessage } = useIntl();

  const location = useLocation();
  const history = useHistory();
  const [openConfirmationModal] = useConfirmationModal();
  const advertiser = useSelector(makeSelectAdvertiser());

  const filteredItems = SIDE_BAR_ADDITIONAL_ITEMS.filter(
    (item) => item.isPartner === !!advertiser?.isPartner
  );

  const handleOnClick = (e, toLink) => {
    if (!advertiser?.verifiedAt && toLink === NEW_CREATE_CAMPAIGN) {
      e.preventDefault();
      return;
    }
    if (
      location.pathname === DASHBOARD_CREATE_CAMPAIGN &&
      toLink !== DASHBOARD_CREATE_CAMPAIGN
    ) {
      e.preventDefault();
      openConfirmationModal(formatMessage(messages.areYouSureLeavePage), () => {
        history.push(toLink);
      });
    }
  };

  return (
    <div
      className={styles.sideBar}
      style={{
        backgroundColor: advertiser?.isPartner ? '#d9eef3' : '#ffef9a',
      }}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.logoWrapper}>
          {advertiser?.isPartner ? (
            <img
              src={partnerBadgeLogo}
              alt="EyCrowd logo"
              style={{ height: '100%' }}
            />
          ) : (
            <img
              src={forBusinessLogo}
              alt="EyCrowd logo"
              style={{ height: '100%' }}
            />
          )}
        </div>
        <p className={styles.portalText}>
          {advertiser?.isPartner ? 'PARTNER PORTAL' : 'CLIENT PORTAL'}
        </p>
      </div>

      <div className={styles.welcomeWrapper}>
        <p className={styles.welcomeText}>Welcome back! 👋🏼</p>
      </div>

      <div className={styles.resourcesWrapper}>
        <div
          className={styles.partnerSeparator}
          style={{
            backgroundColor: advertiser?.isPartner
              ? 'rgba(42, 117, 137, 0.50)'
              : 'rgba(127, 112, 27, 0.50)',
          }}
        />
        <>
          <div className={styles.craftWrapper}>
            <p className={styles.craftText}>CRAFT</p>
          </div>
          <div
            className={styles.partnerSeparator}
            style={{
              backgroundColor: advertiser?.isPartner
                ? 'rgba(42, 117, 137, 0.50)'
                : 'rgba(127, 112, 27, 0.50)',
            }}
          />
        </>
      </div>

      <ul className={styles.spaceBetween}>
        <div>
          {SIDE_BAR_ITEMS.map((item) => (
            <li className={styles.listItem} key={item.label}>
              <NavLink
                to={item.route}
                activeClassName={styles.linkActive}
                className={`${styles.link} ${
                  !advertiser?.verifiedAt && item.route === NEW_CREATE_CAMPAIGN
                    ? styles.disabledLink
                    : ''
                }`}
                onClick={(e) => handleOnClick(e, item.route)}
                exact
              >
                <span className={styles.iconWrapper}>
                  <Icon
                    icon={item.icon}
                    className={
                      !advertiser?.verifiedAt &&
                      item.route === NEW_CREATE_CAMPAIGN
                        ? styles.disabledLink
                        : ''
                    }
                  />
                </span>
                <span
                  className={
                    !advertiser?.verifiedAt &&
                    item.route === NEW_CREATE_CAMPAIGN
                      ? styles.disabledLink
                      : ''
                  }
                >
                  {item.label}
                </span>
              </NavLink>
            </li>
          ))}
        </div>
        <div className={styles.resourcesWrapper}>
          <div
            className={styles.partnerSeparator}
            style={{
              backgroundColor: advertiser?.isPartner
                ? 'rgba(42, 117, 137, 0.50)'
                : 'rgba(127, 112, 27, 0.50)',
            }}
          />
          <>
            <div className={styles.partnerResourcesWrapper}>
              <p className={styles.partnerText}>PARTNER</p>
            </div>
            <div
              className={styles.partnerSeparator}
              style={{
                backgroundColor: advertiser?.isPartner
                  ? 'rgba(42, 117, 137, 0.50)'
                  : 'rgba(127, 112, 27, 0.50)',
              }}
            />
          </>
          <div style={{ marginTop: '20px' }}>
            {filteredItems.map((item) =>
              !!item?.externalUrl ? (
                <li className={styles.listItem} key={item.label}>
                  <a
                    href={item.externalUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    <span className={styles.iconWrapper}>
                      <Icon icon={item.icon} />
                    </span>
                    <div>
                      <div>{item.label}</div>
                      <div className={styles.subLabel}>
                        {item.sublabel}
                      </div>{' '}
                    </div>
                  </a>
                </li>
              ) : (
                <li className={styles.listItem} key={item.label}>
                  <NavLink
                    to={item.route}
                    activeClassName={styles.linkActive}
                    className={styles.link}
                    onClick={(e) => handleOnClick(e, item.route)}
                    exact
                  >
                    <span className={styles.iconWrapper}>
                      <Icon icon={item.icon} />
                    </span>
                    <div>
                      <div>{item.label}</div>
                      <div className={styles.subLabel}>
                        {item.sublabel}
                      </div>{' '}
                    </div>
                  </NavLink>
                </li>
              )
            )}
          </div>
        </div>
        <div className={styles.resourcesWrapper}>
          <div
            className={styles.partnerSeparator}
            style={{
              backgroundColor: advertiser?.isPartner
                ? 'rgba(42, 117, 137, 0.50)'
                : 'rgba(127, 112, 27, 0.50)',
            }}
          />
          <>
            <div className={styles.craftWrapper}>
              <p className={styles.craftText}>LEARN</p>
            </div>
            <div
              className={styles.partnerSeparator}
              style={{
                backgroundColor: advertiser?.isPartner
                  ? 'rgba(42, 117, 137, 0.50)'
                  : 'rgba(127, 112, 27, 0.50)',
              }}
            />
          </>
        </div>
        <div style={{ marginTop: '20px' }}>
          {SIDE_BAR_LEARNING_ITEMS.map((item) =>
            !!item?.externalUrl ? (
              <li className={styles.listItem} key={item.label}>
                <a
                  href={item.externalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  <span className={styles.iconWrapper}>
                    <Icon icon={item.icon} />
                  </span>
                  <div>
                    <div className={styles.mainLabel}>{item.label}</div>
                    <div className={styles.subLabel}>{item.sublabel}</div>
                  </div>
                </a>
              </li>
            ) : (
              <li className={styles.listItem} key={item.label}>
                <NavLink
                  to={item.route}
                  activeClassName={styles.linkActive}
                  className={styles.link}
                  onClick={(e) => handleOnClick(e, item.route)}
                  exact
                >
                  <span className={styles.iconWrapper}>
                    <Icon icon={item.icon} />
                  </span>
                  <div>
                    <div className={styles.mainLabel}>{item.label}</div>
                    <div className={styles.subLabel}>{item.sublabel}</div>
                  </div>
                </NavLink>
              </li>
            )
          )}
        </div>
        <div>
          {SIDE_BAR_NEED_HELP_ITEM.map((item) => (
            <li className={styles.listItem} key={item.label}>
              <NavLink
                to={item.route}
                activeClassName={styles.linkActive}
                className={styles.link}
                onClick={(e) => handleOnClick(e, item.route)}
                exact
              >
                <span className={styles.iconWrapper}>
                  <Icon icon={item.icon} />
                </span>
                {item.label}
              </NavLink>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
};

export default DashboardSideBar;
