import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSingleCampaign = (state) => state.singleCampaign || initialState;

const makeSelectSingleCampaign = () =>
  createSelector(selectSingleCampaign, (substate) => substate.singleCampaign);

const makeSelectIsSingleCampaignLoading = () =>
  createSelector(
    selectSingleCampaign,
    (substate) => substate.isSingleCampaignLoading
  );

const makeSelectSingleCampaignGallery = () =>
  createSelector(
    selectSingleCampaign,
    (substate) => substate.singleCampaignGallery
  );

const makeSelectSingleCampaignEyvisorGallery = () =>
  createSelector(
    selectSingleCampaign,
    (substate) => substate.singleCampaignEyvisorGallery
  );

const makeSelectCustomNotifications = () =>
  createSelector(
    selectSingleCampaign,
    (substate) => substate.customNotifications
  );

const makeSelectIsLoadingGallery = () =>
  createSelector(selectSingleCampaign, (substate) => substate.isLoadingGallery);

const makeSelectIsLoadingEyvisorGallery = () =>
  createSelector(
    selectSingleCampaign,
    (substate) => substate.isLoadingEyvisorGallery
  );

const makeSelectIsEditMode = () =>
  createSelector(selectSingleCampaign, (substate) => substate.isEditMode);

const makeSelectInviteLink = () =>
  createSelector(selectSingleCampaign, (substate) => substate.inviteLink);

const makeSelectCamapignContentData = () =>
  createSelector(selectSingleCampaign, (substate) => substate.contentData);

const makeSelectCamapignContentLoading = () =>
  createSelector(selectSingleCampaign, (substate) => substate.isLoadingContent);

export {
  makeSelectSingleCampaign,
  makeSelectIsSingleCampaignLoading,
  makeSelectSingleCampaignGallery,
  makeSelectSingleCampaignEyvisorGallery,
  makeSelectIsLoadingGallery,
  makeSelectIsLoadingEyvisorGallery,
  makeSelectIsEditMode,
  makeSelectCustomNotifications,
  makeSelectInviteLink,
  makeSelectCamapignContentData,
  makeSelectCamapignContentLoading,
};
