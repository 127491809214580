import React, { useMemo } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute';
import PublicRoute from 'containers/PublicRoute';
import AdvertisersPage from 'containers/AdvertisersPage/Loadable';
import CreateCampaignPage from 'containers/CreateCampaignPage/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import SingleCampaign from 'containers/SingleCampaignPage/Loadable';
import ReportsPage from 'containers/ReportsPage/Loadable';
import AdvertiserSettings from '../../containers/AdvertiserSettingsPage/Loadable';
import AdvertiserSettingsPage from 'containers/AdvertiserSettingsPage/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import RegisterPage from 'containers/RegisterPage/Loadable';
import ForgotPasswordPage from 'containers/ForgotPasswordPage/Loadable';
import ResetPasswordPage from 'containers/ResetPasswordPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import PartnerProgramPage from 'containers/PartnerProgramPage/Loadable';
import PartnerIntroPage from 'containers/PartnerPage/Loadable';
import CodaPartnerPage from 'components/CodaEmbed/CodaPartner';
import CodaAppLibraryPage from 'components/CodaEmbed/CodaAppLibrary';
import CodaBuilderLibraryPage from 'components/CodaEmbed/CodaBuilderLibrary';
import CodaQALibraryPage from 'components/CodaEmbed/CodaQALibrary';
import CodaPartnerUpdatePage from 'components/CodaEmbed/CodaPartnerUpdate';
import CodaEyCrowdUpdates from 'components/CodaEmbed/CodaEyCrowdUpdates';
import CodaWhyEyCrowdPage from 'components/CodaEmbed/CodaWhyEyCrowd/index.js';
import {
  WELCOME,
  ADVERTISERS,
  CREATE_CAMPAIGN,
  DASHBOARD,
  DASHBOARD_CREATE_CAMPAIGN,
  ADVERTISER_SETTINGS,
  SINGLE_CAMPAIGN,
  LOGIN,
  REGISTER,
  REPORTS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ABOUT_US,
  TERMS,
  PRIVACY,
  JOBS,
  CONTACT,
  WE_LOVE_HOUSTON,
  DASHBOARD_CREATE_CAMPAIGN_OBJECTIVES,
  CAMPAIGN_SUMMARY,
  NEED_HELP,
  CREATE_SIMPLE_CAMPAIGN,
  VERIFY_EMAIL,
  NEW_CREATE_CAMPAIGN,
  DELETE_ACCOUNT,
  PARTNER_PROGRAM,
  PARTNER,
  PARTNER_PROGRAM_INFO,
  APP_VIDEO_LIBRARY,
  BUILDER_VIDEO_LIBRARY,
  QA_VIDEO_LIBRARY,
  PARTNER_PROGRAM_UPDATE,
  EYCROWD_UPDATES,
  WHY_EYCROWD,
} from 'routes';
import DashboardLayout from 'components/Layout/DashboardLayout';
// import JobsPage from 'containers/JobsPage/Loadable';
// import PrivacyPolicyPage from 'containers/PrivacyPolicyPage/Loadable';
// import TermsAndConditions from 'containers/TermsAndConditions/Loadable';
// import ContactPage from 'containers/ContactPage/Loadable';
// import WeLovePage from '../WeLovePage/Loadable';
// import AboutUsPage from 'containers/AboutUsPage/Loadable';
import CampaignSummaryPage from 'containers/CampaignSummaryPage/Loadable';
import ECWCreateCampaignPage from 'containers/ECWCreateCampaignPage/Loadable';
import ECWCampaignObjectives from 'components/ECWCreateCampaign/ECWCampaignObjectives';
import NeedHelpPage from 'containers/NeedHelpPage/Loadable';
import CreateSimpleCampaignPage from 'containers/CreateSimpleCampaignPage/Loadable';
import VerifyEmailPage from 'containers/VerifyEmailPage/Loadable';
import DeleteAccountPage from 'containers/DeleteAccountPage/Loadable';
import NewExperienceCampaignBuilderPage from 'containers/NewExperienceCampaignBuilderPage';

const DASHBOARD_EXCLUDED_ROUTES = [
  WELCOME,
  ADVERTISERS,
  ABOUT_US,
  JOBS,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CREATE_CAMPAIGN,
  TERMS,
  PRIVACY,
  CONTACT,
  WE_LOVE_HOUSTON,
  CAMPAIGN_SUMMARY,
  CREATE_SIMPLE_CAMPAIGN,
  VERIFY_EMAIL,
  NEW_CREATE_CAMPAIGN,
  DELETE_ACCOUNT,
  PARTNER_PROGRAM,
  PARTNER,
];
export default function Routes() {
  const location = useLocation();

  const getExactPathName = (path) => path.split('/')[1];

  const shouldRenderDashboard = useMemo(() => {
    return !DASHBOARD_EXCLUDED_ROUTES.some(
      (route) => getExactPathName(route) === getExactPathName(location.pathname)
    );
  }, [location.pathname]);

  return (
    <>
      {shouldRenderDashboard && <DashboardLayout hasNavigationMenu />}
      <Switch>
        <PrivateRoute exact path={DASHBOARD} component={Dashboard} />
        <PrivateRoute exact path={REPORTS} component={ReportsPage} />
        <PrivateRoute
          exact
          path={DASHBOARD_CREATE_CAMPAIGN}
          component={ECWCreateCampaignPage}
        />
        <PrivateRoute
          exact
          path={DASHBOARD_CREATE_CAMPAIGN_OBJECTIVES}
          component={ECWCampaignObjectives}
        />
        <PrivateRoute
          exact
          path={NEW_CREATE_CAMPAIGN}
          component={NewExperienceCampaignBuilderPage}
        />
        {/* <PrivateRoute
          exact
          path={ECW_CREATE_CAMPAIGN_OBJECTIVES}
          component={ECWCampaignObjectives}
        /> */}
        {/* <PrivateRoute
          exact
          path={ECW_CREATE_CAMPAIGN}
          component={ECWCreateCampaignPage}
        /> */}
        <PrivateRoute exact path={SINGLE_CAMPAIGN} component={SingleCampaign} />
        <PrivateRoute
          exact
          path={ADVERTISER_SETTINGS}
          component={AdvertiserSettings}
        />
        <PrivateRoute
          exact
          path={ADVERTISER_SETTINGS}
          component={AdvertiserSettingsPage}
        />
        <PrivateRoute
          exact
          path={PARTNER_PROGRAM_INFO}
          component={CodaPartnerPage}
        />
        <PrivateRoute
          exact
          path={APP_VIDEO_LIBRARY}
          component={CodaAppLibraryPage}
        />
        <PrivateRoute
          exact
          path={BUILDER_VIDEO_LIBRARY}
          component={CodaBuilderLibraryPage}
        />
        <PrivateRoute
          exact
          path={QA_VIDEO_LIBRARY}
          component={CodaQALibraryPage}
        />
        <PrivateRoute
          exact
          path={PARTNER_PROGRAM}
          component={PartnerProgramPage}
        />
        <PrivateRoute
          exact
          path={PARTNER_PROGRAM_UPDATE}
          component={CodaPartnerUpdatePage}
        />
        <PrivateRoute
          exact
          path={EYCROWD_UPDATES}
          component={CodaEyCrowdUpdates}
        />
        <PrivateRoute exact path={WHY_EYCROWD} component={CodaWhyEyCrowdPage} />

        <Route exact path={NEED_HELP} component={NeedHelpPage} />
        <PublicRoute exact path={ADVERTISERS} component={AdvertisersPage} />
        <Route exact path={WELCOME}>
          <Redirect to={LOGIN} />
        </Route>
        <PublicRoute
          exact
          path={CREATE_CAMPAIGN}
          component={NewExperienceCampaignBuilderPage}
        />
        <PublicRoute exact path={LOGIN} component={LoginPage} />
        <PublicRoute exact path={REGISTER} component={RegisterPage} />
        <PublicRoute
          exact
          path={FORGOT_PASSWORD}
          component={ForgotPasswordPage}
        />
        <PublicRoute
          exact
          path={RESET_PASSWORD}
          component={ResetPasswordPage}
        />
        <PublicRoute
          exact
          path={CREATE_SIMPLE_CAMPAIGN}
          component={CreateSimpleCampaignPage}
        />
        <PublicRoute
          exact
          path={DELETE_ACCOUNT}
          component={DeleteAccountPage}
        />
        <PublicRoute exact path={PARTNER} component={PartnerIntroPage} />
        {/* <PublicRoute exact path={JOBS} component={JobsPage} />
        <PublicRoute exact path={PRIVACY} component={PrivacyPolicyPage} />
        <PublicRoute exact path={TERMS} component={TermsAndConditions} />
        <PublicRoute exact path={CONTACT} component={ContactPage} /> */}
        <Route path={VERIFY_EMAIL} component={VerifyEmailPage} />

        <Route path={CAMPAIGN_SUMMARY} component={CampaignSummaryPage} />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
}
