import { takeLatest, call, put } from 'redux-saga/effects';
import request from 'utils/request';
import { setItem } from 'utils/localStorage';
import {
  LOGIN_ADVERTISER_REQUEST,
  PUBLISH_EXCLUSIVE_CAMPAING_REQUEST,
  REGISTER_ADVERTISER_REQUEST,
  FETCH_OPENAI_SUGGESTIONS_REQUEST,
  FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_REQUEST,
  FETCH_OBJECTIVE_SUGGESTIONS_REQUEST,
  GET_BRAND_STORY_REQUEST,
  GET_TASK_DESCRIPTIONS_REQUEST,
  GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_REQUEST,
  SET_LAST_TIME_ACCESSED_BUILDER_TIME_REQUEST,
} from './constants';
import { CAMPAIGN_TYPES, ENDPOINTS, GOOGLE_MAPS } from '../../constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { format } from 'util';
import config from 'config';

import { EYCROWD_ACCESS_TOKEN, EYCROWD_REFRESH_TOKEN } from 'utils/constants';
import { getLocationData } from 'utils/googleLocations';
import toasterVariants from 'constants/toasterVariants';
import {
  fetchOpenAiSocialAccountSuggestionsError,
  fetchOpenAiSocialAccountSuggestionsSuccess,
  fetchOpenAiSuggestionsError,
  fetchOpenAiSuggestionsSuccess,
  fetchOpenAiObjectiveSuggestionsSuccess,
  fetchOpenAiObjectiveSuggestionsError,
  resetOpenAiSuggestions,
  resetSocialAccountSuggestions,
  getBrandStoryError,
  getBrandStorySuccess,
  getTaskDescriptionsSuccess,
  getTaskDescriptionsError,
  aiRegenerateTaskDescriptionPlusSocialMediaUrlsSuccess,
  aiRegenerateTaskDescriptionPlusSocialMediaUrlsError,
} from './actions';

export function* registerExclusiveAdvertiser({
  advertiserDetails,
  meta: { onSuccess, onError },
}) {
  try {
    if (advertiserDetails.logo) {
      const formData = new FormData();
      formData.append('logo', advertiserDetails.logo);
      const { key } = yield call(request, {
        url: ENDPOINTS.ADVERTISER_COMPANY_LOGO,
        method: 'post',
        data: formData,
      });
      advertiserDetails.logo = key;
    }
    const { accessToken, refreshToken } = yield call(request, {
      url: ENDPOINTS.AUTH_ADVERTISER_REGISTER_EXCLUSIVE,
      method: 'post',
      data: advertiserDetails,
    });

    yield call(setItem, EYCROWD_ACCESS_TOKEN, accessToken);
    yield call(setItem, EYCROWD_REFRESH_TOKEN, refreshToken);
    onSuccess();
  } catch (error) {
    onError();
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export function* publishExclusiveCampaign({
  campaignData,
  meta: { onSuccess, onError },
}) {
  try {
    if (campaignData.type !== CAMPAIGN_TYPES.DIGITAL) {
      const locationLat = campaignData.circle
        ? campaignData.circle.centerPoint.latitude
        : campaignData.polygonLocation.centerPoint.latitude;
      const locationLng = campaignData.circle
        ? campaignData.circle.centerPoint.longitude
        : campaignData.polygonLocation.centerPoint.longitude;

      console.log('locationLat', locationLat);
      console.log('locationLng', locationLng);
      const centerPointLocationData = yield call(request, {
        url: format(
          GOOGLE_MAPS.GEOCODE_API_URL,
          locationLat,
          locationLng,
          config.googleMaps.apiKey
        ),
        method: 'get',
        withoutToken: true,
        allowHeaders: false,
      });
      const data = getLocationData(centerPointLocationData);
      campaignData.location = {};
      campaignData.location.city = data.city;
      campaignData.location.address = data.address;
      campaignData.location.type = campaignData.circle ? 'circle' : 'polygon';
      campaignData.location.path = campaignData.circle
        ? campaignData.circle.path
        : campaignData.polygonLocation.path;

      campaignData.location.centerPoint = campaignData.circle
        ? campaignData.circle.centerPoint
        : campaignData.polygonLocation.centerPoint;
    } else {
      delete campaignData.location;
    }
    if (!campaignData.isExternalLogo) {
      const formData = new FormData();
      formData.append('logo', campaignData.logo);
      const { key } = yield call(request, {
        url: ENDPOINTS.CAMPAIGN_LOGO,
        method: 'post',
        data: formData,
      });
      campaignData.logo = key;
    }

    if (campaignData.customerInvitationFile) {
      const formData = new FormData();
      formData.append('file', campaignData.customerInvitationFile);
      const { key } = yield call(request, {
        url: ENDPOINTS.CUSTOMER_INVITAION_FILE,
        method: 'post',
        data: formData,
      });
      campaignData.customerInvitationFile = key;
    }

    yield call(request, {
      url: ENDPOINTS.PUBLISH_EXCLUSIVE_CAMPAIGN,
      method: 'post',
      data: campaignData,
    });

    yield put(
      enqueueSnackbar({
        message: 'Campaign successfully published',
        variant: toasterVariants.success,
      })
    );
    yield put(resetOpenAiSuggestions());
    yield put(resetSocialAccountSuggestions());
    onSuccess();
  } catch (err) {
    console.log(err);
    onError();
    yield put(
      enqueueSnackbar({
        message: err.data.message,
      })
    );
  }
}

export function* loginExclusiveAdvertiser({
  advertiserDetails,
  meta: { onSuccess, onError },
}) {
  try {
    const { accessToken, refreshToken } = yield call(request, {
      url: ENDPOINTS.AUTH_ADVERTISER_LOGIN,
      method: 'post',
      data: advertiserDetails,
    });

    yield call(setItem, EYCROWD_ACCESS_TOKEN, accessToken);
    yield call(setItem, EYCROWD_REFRESH_TOKEN, refreshToken);
    onSuccess();
  } catch (error) {
    onError();
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export function* fetchOpenAiSuggestions(data) {
  try {
    const suggestions = yield call(request, {
      url: ENDPOINTS.OPENAI_SUGGESTIONS,
      method: 'post',
      data: data.data,
    });
    yield put(fetchOpenAiSuggestionsSuccess(suggestions));
  } catch (error) {
    yield put(fetchOpenAiSuggestionsError());
    // yield put(
    //   enqueueSnackbar({
    //     message: error.data?.message,
    //   })
    // );
  }
}

export function* fetchOpenAiSocialAccountSuggestions({ payload }) {
  try {
    const suggestions = yield call(request, {
      url: ENDPOINTS.SOCIAL_ACCOUNT_OPENAI_SUGGESTIONS,
      method: 'post',
      data: payload,
    });
    yield put(
      fetchOpenAiSocialAccountSuggestionsSuccess(
        suggestions,
        'smart ai text is complete (manual entry preserved)'
      )
    );
  } catch (error) {
    yield put(
      fetchOpenAiSocialAccountSuggestionsError(
        'smart ai text failed - contact support (manual entry)'
      )
    );
    // yield put(
    //   enqueueSnackbar({
    //     message: error.data?.message,
    //   })
    // );
  }
}

export function* fetchOpenAiObjectiveSuggestions({ payload }) {
  try {
    const { objectives } = yield call(request, {
      url: ENDPOINTS.OPENAI_OBJECTIVE_SUGGESTIONS,
      method: 'post',
      data: payload,
    });
    yield put(
      fetchOpenAiObjectiveSuggestionsSuccess(
        objectives,
        `Of course! I understand your request. Here are ${objectives.campaigns.length} EyCrowd™ campaign experience ideas that I dreamed up for you. Feel free to click the "DREAM AGAIN" button for more ideas!`
      )
    );
  } catch (error) {
    yield put(
      fetchOpenAiObjectiveSuggestionsError(
        'Smart ai dream failed - dream again.'
      )
    );
    // yield put(
    //   enqueueSnackbar({
    //     message: error.data?.message,
    //   })
    // );
  }
}

export function* getBrandStoryRequest({ data, meta: { onSuccess, onError } }) {
  try {
    const { brandStory } = yield call(request, {
      url: ENDPOINTS.GET_BRAND_STORY,
      method: 'post',
      data: data,
    });
    yield put(getBrandStorySuccess());
    onSuccess(brandStory);
  } catch (error) {
    onError();
    yield put(getBrandStoryError());
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export function* getTaskDescriptionsRequest({
  data,
  meta: { onSuccess, onError },
}) {
  try {
    const { taskDescriptions } = yield call(request, {
      url: ENDPOINTS.GET_TASK_DESCRIPTIONS,
      method: 'post',
      data: data,
    });
    yield put(getTaskDescriptionsSuccess());
    onSuccess(taskDescriptions);
  } catch (error) {
    onError();
    // yield put(getTaskDescriptionsError());
    // yield put(
    //   enqueueSnackbar({
    //     message: error.data?.message,
    //   })
    // );
  }
}

export function* getAiTaskDescriptionAndSocialMediaUrlsRequest({
  data,
  meta: { onSuccess, onError },
}) {
  try {
    const result = yield call(request, {
      url: ENDPOINTS.GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS,
      method: 'post',
      data: data,
    });
    yield put(aiRegenerateTaskDescriptionPlusSocialMediaUrlsSuccess());
    onSuccess(result);
  } catch (error) {
    onError();
    yield put(aiRegenerateTaskDescriptionPlusSocialMediaUrlsError());
    // yield put(
    //   enqueueSnackbar({
    //     message: error.data?.message,
    //   })
    // );
  }
}

export function* setLastTimeAccessedBuilderTime() {
  try {
    yield call(request, {
      url: ENDPOINTS.SET_ACCESSED_BUILDER_TIME,
      method: 'post',
    });
  } catch (error) {}
}

export default function* registerSaga() {
  yield takeLatest(REGISTER_ADVERTISER_REQUEST, registerExclusiveAdvertiser);
  yield takeLatest(
    PUBLISH_EXCLUSIVE_CAMPAING_REQUEST,
    publishExclusiveCampaign
  );
  yield takeLatest(LOGIN_ADVERTISER_REQUEST, loginExclusiveAdvertiser);
  yield takeLatest(FETCH_OPENAI_SUGGESTIONS_REQUEST, fetchOpenAiSuggestions);
  yield takeLatest(
    FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_REQUEST,
    fetchOpenAiSocialAccountSuggestions
  );
  yield takeLatest(
    FETCH_OBJECTIVE_SUGGESTIONS_REQUEST,
    fetchOpenAiObjectiveSuggestions
  );
  yield takeLatest(GET_BRAND_STORY_REQUEST, getBrandStoryRequest);
  yield takeLatest(GET_TASK_DESCRIPTIONS_REQUEST, getTaskDescriptionsRequest);
  yield takeLatest(
    GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_REQUEST,
    getAiTaskDescriptionAndSocialMediaUrlsRequest
  );
  yield takeLatest(
    SET_LAST_TIME_ACCESSED_BUILDER_TIME_REQUEST,
    setLastTimeAccessedBuilderTime
  );
}
