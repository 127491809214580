import React from 'react';
import styles from './Sidebar.module.scss';
import { ReactComponent as PlayIcon } from 'assets/images/play_arrow.svg';
import { ReactComponent as CatalogIcon } from 'assets/images/view_module.svg';
import { ReactComponent as LightbulbIcon } from 'assets/images/lightbulb.svg';
import { ReactComponent as FavoriteIcon } from 'assets/images/favorite.svg';
import { ReactComponent as StarIcon } from 'assets/images/star.svg';
import { ReactComponent as TrendingUpIcon } from 'assets/images/trending_up.svg';
import { ReactComponent as SandWatchIcon } from 'assets/images/sand_watch.svg';
import { ReactComponent as AllIcon } from 'assets/images/copy_sidebar.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/social-platforms/instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/social-platforms/facebook2.svg';
import { ReactComponent as TikTokIcon } from 'assets/images/social-platforms/tiktokBox.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/social-platforms/twitter_x.svg';
import { ReactComponent as LinkedinIcon } from 'assets/images/social-platforms/linkedin.svg';
import { ReactComponent as EycLogoIcon } from 'assets/images/logoY.svg';
import { ReactComponent as EycLogoBlackIcon } from 'assets/images/black_logo.svg';
import { ReactComponent as WorldIcon } from 'assets/images/social-platforms/world.svg';
import { ReactComponent as InPersonIcon } from 'assets/images/in_person_logo.svg';
import Icon from 'components/Icon';
import classNames from 'classnames';

const Sidebar = ({
  activeStep,
  setActiveStep,
  selectedCategory,
  setSelectedCategory,
  selectedMetatag,
  setSelectedMetatag,
  top,
  selectedMode,
  setSelectedMode,
  selectedSocialPlatform,
  setSelectedSocialPlatform,
  selectedObjectiveType,
  setSelectedObjectiveType,
  basicExperience,
}) => {
  const renderSteps = () => {
    const activeSteps = [];

    if (activeStep === 1 || activeStep === 0 || activeStep === -1) {
      activeSteps.push(
        <SidebarStep
          key={1}
          number={activeStep === 1 ? 1 : 'W'}
          text={activeStep === 1 ? 'OBJECTIVES' : 'WELCOME'}
          activeStep={[0, 1, -1].includes(activeStep)}
        />
      );
      return activeSteps;
    }

    activeSteps.push(
      <SidebarStep
        key={1}
        number={1}
        text="OBJECTIVES"
        activeStep={activeStep === 1}
        onClick={() => setActiveStep(1)}
      />
    );

    activeSteps.push(
      <SidebarStep
        key={2}
        number={2}
        text="DETAILS"
        activeStep={activeStep === 2}
        onClick={() => setActiveStep(2)}
      />
    );

    activeSteps.push(
      <SidebarStep
        key={3}
        number={3}
        text="ACTIVITIES"
        activeStep={activeStep === 3}
        onClick={() => setActiveStep(3)}
      />
    );

    activeSteps.push(
      <SidebarStep
        key={4}
        number={4}
        text="EYVOCATES"
        activeStep={activeStep === 4}
        onClick={() => setActiveStep(4)}
      />
    );

    return activeSteps;
  };

  return (
    <div
      className={classNames(styles.sidebar)}
      style={{ top: `${top + 30}px` }}
    >
      <div className={styles.stepsContainer}>{renderSteps()}</div>
      {activeStep === 1 && (
        <>
          {!basicExperience && (
            <div className={styles.contentCard}>
              <div
                onClick={() => setSelectedMode('DREAM')}
                className={classNames(
                  styles.sidebarBtn,
                  selectedMode === 'DREAM' && styles.selectedSidebarBtn
                )}
              >
                <div className={styles.iconContainer}>
                  <LightbulbIcon
                    height={20}
                    color={selectedMode === 'DREAM' ? '#ffffff' : '#5ca0a7'}
                  />
                  <p
                    className={classNames(
                      styles.aiText,
                      selectedMode === 'DREAM' && styles.selectedAiText
                    )}
                  >
                    Ai
                  </p>
                </div>
                <p
                  className={classNames(
                    styles.sidebarBtnText,
                    selectedMode === 'DREAM' && styles.sidebarBtnTextSelected
                  )}
                >
                  DREAM
                </p>
              </div>
              <div
                className={classNames(
                  styles.sidebarBtn,
                  selectedMode === 'CATALOG' && styles.selectedSidebarBtn
                )}
                onClick={() => setSelectedMode('CATALOG')}
              >
                <CatalogIcon
                  height={26}
                  color={selectedMode === 'CATALOG' ? '#ffffff' : '#5ca0a7'}
                />
                <p
                  className={classNames(
                    styles.sidebarBtnText,
                    selectedMode === 'CATALOG' && styles.sidebarBtnTextSelected
                  )}
                >
                  CATALOG
                </p>
              </div>
            </div>
          )}
          <div style={{ height: '20px' }} />
          {selectedMode === 'CATALOG' && (
            <>
              <div className={styles.contentCard}>
                <div
                  className={classNames(
                    styles.options,
                    selectedMetatag === '<FEATURED>' && styles.selectedOptions
                  )}
                  onClick={() => {
                    if (selectedCategory) {
                      setSelectedCategory(null);
                    }
                    if (selectedSocialPlatform) {
                      setSelectedSocialPlatform([]);
                      setSelectedObjectiveType(null);
                    }
                    setSelectedMetatag((prev) =>
                      prev === '<FEATURED>' ? null : '<FEATURED>'
                    );
                  }}
                >
                  <StarIcon
                    width={30}
                    color={
                      selectedMetatag === '<FEATURED>' ? '#5CA0A7' : '#999999'
                    }
                  />
                  <p
                    className={classNames(
                      styles.text,
                      selectedMetatag === '<FEATURED>' && styles.selectedText
                    )}
                  >
                    Featured
                  </p>
                </div>
                <div
                  className={classNames(
                    styles.options,
                    selectedMetatag === '<POPULAR>' && styles.selectedOptions
                  )}
                  onClick={() => {
                    if (selectedCategory) {
                      setSelectedCategory(null);
                    }
                    if (selectedSocialPlatform) {
                      setSelectedSocialPlatform([]);
                      setSelectedObjectiveType(null);
                    }
                    setSelectedMetatag((prev) =>
                      prev === '<POPULAR>' ? null : '<POPULAR>'
                    );
                  }}
                >
                  <TrendingUpIcon
                    width={30}
                    color={
                      selectedMetatag === '<POPULAR>' ? '#5CA0A7' : '#999999'
                    }
                  />
                  <p
                    className={classNames(
                      styles.text,
                      selectedMetatag === '<POPULAR>' && styles.selectedText
                    )}
                  >
                    Popular
                  </p>
                </div>

                <div
                  className={classNames(
                    styles.options,
                    selectedMetatag === '<EYCROWD_CHOICE>' &&
                      styles.selectedOptions
                  )}
                  onClick={() => {
                    if (selectedCategory) {
                      setSelectedCategory(null);
                    }
                    if (selectedSocialPlatform) {
                      setSelectedSocialPlatform([]);
                      setSelectedObjectiveType(null);
                    }
                    setSelectedMetatag((prev) =>
                      prev === '<EYCROWD_CHOICE>' ? null : '<EYCROWD_CHOICE>'
                    );
                  }}
                >
                  <FavoriteIcon
                    width={30}
                    color={
                      selectedMetatag === '<EYCROWD_CHOICE>'
                        ? '#5CA0A7'
                        : '#999999'
                    }
                  />
                  <p
                    className={classNames(
                      styles.text,
                      selectedMetatag === '<EYCROWD_CHOICE>' &&
                        styles.selectedText
                    )}
                  >
                    EyChoice™
                  </p>
                </div>
                <div
                  className={classNames(
                    styles.options,
                    selectedMetatag === '<COMING_SOON>' &&
                      styles.selectedOptions
                  )}
                  onClick={() => {
                    if (selectedCategory) {
                      setSelectedCategory(null);
                    }
                    if (selectedSocialPlatform) {
                      setSelectedSocialPlatform([]);
                      setSelectedObjectiveType(null);
                    }
                    setSelectedMetatag((prev) =>
                      prev === '<COMING_SOON>' ? null : '<COMING_SOON>'
                    );
                  }}
                >
                  <SandWatchIcon
                    width={30}
                    color={
                      selectedMetatag === '<COMING_SOON>'
                        ? '#5CA0A7'
                        : '#999999'
                    }
                  />
                  <p
                    className={classNames(
                      styles.text,
                      selectedMetatag === '<COMING_SOON>' && styles.selectedText
                    )}
                  >
                    Coming Soon
                  </p>
                </div>
                <div
                  className={classNames(
                    styles.options,
                    selectedMetatag === null && styles.selectedOptions
                  )}
                  onClick={() => {
                    if (selectedCategory) {
                      setSelectedCategory(null);
                    }
                    if (selectedSocialPlatform) {
                      setSelectedSocialPlatform([]);
                      setSelectedObjectiveType(null);
                    }
                    setSelectedMetatag(null);
                  }}
                >
                  <AllIcon
                    width={30}
                    color={selectedMetatag === null ? '#5CA0A7' : '#999999'}
                  />
                  <p
                    className={classNames(
                      styles.text,
                      selectedMetatag === null && styles.selectedText
                    )}
                  >
                    All
                  </p>
                </div>
              </div>
              <div className={styles.socialMediaFilter}>
                <div
                  className={classNames(
                    styles.filterButton,
                    selectedObjectiveType === null &&
                      styles.selectedFilterButton
                  )}
                >
                  <EycLogoIcon
                    width={28}
                    onClick={() => {
                      setSelectedMetatag(null);
                      setSelectedObjectiveType(null);
                    }}
                  />
                </div>
                <div
                  className={classNames(
                    styles.filterButton,
                    selectedObjectiveType === '2. ONLINE' &&
                      styles.selectedFilterButton
                  )}
                >
                  <EycLogoBlackIcon
                    width={28}
                    onClick={() => {
                      setSelectedMetatag(null);
                      setSelectedObjectiveType((prev) =>
                        prev === '2. ONLINE' ? null : '2. ONLINE'
                      );
                    }}
                  />
                </div>
                <div
                  className={classNames(
                    styles.filterButton,
                    selectedObjectiveType === '1. IN-PERSON' &&
                      styles.selectedFilterButton
                  )}
                >
                  <InPersonIcon
                    width={28}
                    onClick={() => {
                      setSelectedMetatag(null);
                      setSelectedObjectiveType((prev) =>
                        prev === '1. IN-PERSON' ? null : '1. IN-PERSON'
                      );
                    }}
                  />
                </div>
                <div className={styles.horizontalLine} />
                <div
                  className={classNames(
                    styles.filterButton,
                    selectedSocialPlatform.includes('IG') &&
                      styles.selectedFilterButton
                  )}
                >
                  <InstagramIcon
                    width={28}
                    onClick={() => {
                      setSelectedMetatag(null);
                      setSelectedSocialPlatform((prev) =>
                        prev.includes('IG')
                          ? prev.filter((f) => f !== 'IG')
                          : [...prev, 'IG']
                      );
                    }}
                  />
                </div>
                <div
                  className={classNames(
                    styles.filterButton,
                    selectedSocialPlatform.includes('FB') &&
                      styles.selectedFilterButton
                  )}
                >
                  <FacebookIcon
                    width={28}
                    onClick={() => {
                      setSelectedMetatag(null);
                      setSelectedSocialPlatform((prev) =>
                        prev.includes('FB')
                          ? prev.filter((f) => f !== 'FB')
                          : [...prev, 'FB']
                      );
                    }}
                  />
                </div>
                <div
                  className={classNames(
                    styles.filterButton,
                    selectedSocialPlatform.includes('X') &&
                      styles.selectedFilterButton
                  )}
                >
                  <TwitterIcon
                    width={28}
                    onClick={() => {
                      setSelectedMetatag(null);
                      setSelectedSocialPlatform((prev) =>
                        prev.includes('X')
                          ? prev.filter((f) => f !== 'X')
                          : [...prev, 'X']
                      );
                    }}
                  />
                </div>
                <div
                  className={classNames(
                    styles.filterButton,
                    selectedSocialPlatform.includes('TT') &&
                      styles.selectedFilterButton
                  )}
                >
                  <TikTokIcon
                    width={28}
                    onClick={() => {
                      setSelectedMetatag(null);
                      setSelectedSocialPlatform((prev) =>
                        prev.includes('TT')
                          ? prev.filter((f) => f !== 'TT')
                          : [...prev, 'TT']
                      );
                    }}
                  />
                </div>
                <div
                  className={classNames(
                    styles.filterButton,
                    selectedSocialPlatform.includes('HTTP3') &&
                      styles.selectedFilterButton
                  )}
                >
                  <WorldIcon
                    width={28}
                    onClick={() => {
                      setSelectedMetatag(null);
                      setSelectedSocialPlatform((prev) =>
                        prev.includes('HTTP3')
                          ? prev.filter((f) => f !== 'HTTP3')
                          : [...prev, 'HTTP3']
                      );
                    }}
                  />
                </div>
                <div
                  className={classNames(
                    styles.filterButton,
                    selectedSocialPlatform.includes('LI') &&
                      styles.selectedFilterButton
                  )}
                >
                  <LinkedinIcon
                    width={28}
                    onClick={() => {
                      setSelectedMetatag(null);
                      setSelectedSocialPlatform((prev) =>
                        prev.includes('LI')
                          ? prev.filter((f) => f !== 'LI')
                          : [...prev, 'LI']
                      );
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const SidebarStep = ({ text, number, activeStep, onClick }) => {
  return (
    <div
      className={classNames(
        styles.sidebarStep,
        activeStep && styles.activeSidebarStep
      )}
      onClick={onClick}
    >
      <div className={classNames(styles.step, activeStep && styles.activeStep)}>
        {number === 0 ? (
          <Icon className={styles.icon} size="md30" icon={PlayIcon} />
        ) : (
          <p>{number}</p>
        )}
      </div>
      <p>{text}</p>
    </div>
  );
};

export default Sidebar;
