import {
  FETCH_SINGLE_CAMPAIGN_REQUEST,
  FETCH_SINGLE_CAMPAIGN_SUCCESS,
  FETCH_SINGLE_CAMPAIGN_GALLERY_REQUEST,
  FETCH_SINGLE_CAMPAIGN_GALLERY_SUCCESS,
  FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_REQUEST,
  FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_SUCCESS,
  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_ERROR,
  CANCEL_SINGLE_CAMPAIGN_REQUEST,
  CANCEL_SINGLE_CAMPAIGN_SUCCESS,
  SET_IS_EDIT_MODE,
  SEND_CUSTOM_NOTIFICATION,
  FETCH_CUSTOM_NOTIFICATIONS_SUCCESS,
  FETCH_CUSTOM_NOTIFICATIONS_REQUEST,
  FETCH_SINGLE_CAMPAIGN_INVITE_LINK_REQUEST,
  FETCH_SINGLE_CAMPAIGN_INVITE_LINK_SUCCESS,
  REMOVE_SINGLE_CAMPAIGN,
  FETCH_CAMPAIGN_CONTENT_REQUEST,
  FETCH_CAMPAIGN_CONTENT_SUCCESS,
  WS_UPDATE_SINGLE_CAMPAIGN_CAMPAIGN_USERS,
  WS_CAMPAIGN_STATUS_CHANGED,
  PUBLISH_PUBLISHABLE_CAMPAIGN_REQUEST,
  PUBLISH_PUBLISHABLE_CAMPAIGN_SUCCESS,
  PUBLISH_PUBLISHABLE_CAMPAIGN_ERROR,
  SET_PRE_PUBLISH_CAMPAIGN_TO_PUBLISHABLE,
} from './constants';

export function fetchSingleCampaignRequest(id) {
  return {
    type: FETCH_SINGLE_CAMPAIGN_REQUEST,
    id,
  };
}

export function fetchSingleCampaignSuccess(singleCampaign) {
  return {
    type: FETCH_SINGLE_CAMPAIGN_SUCCESS,
    singleCampaign,
  };
}

export function fetchSingleCampaignGalleryRequest(
  id,
  page,
  perPage,
  isSupervisor
) {
  return {
    type: FETCH_SINGLE_CAMPAIGN_GALLERY_REQUEST,
    id,
    page,
    perPage,
    isSupervisor,
  };
}

export function fetchSingleCampaignGallerySuccess(singleCampaignGallery) {
  return {
    type: FETCH_SINGLE_CAMPAIGN_GALLERY_SUCCESS,
    singleCampaignGallery,
  };
}

export function fetchSingleCampaignEyvisorGalleryRequest(id, page, perPage) {
  return {
    type: FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_REQUEST,
    id,
    page,
    perPage,
  };
}

export function fetchSingleCampaignEyvisorGallerySuccess(
  singleCampaignEyvisorGallery
) {
  return {
    type: FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_SUCCESS,
    singleCampaignEyvisorGallery,
  };
}

export function updateCampaignRequest(id, campaignData) {
  return {
    type: UPDATE_CAMPAIGN_REQUEST,
    id,
    campaignData,
  };
}

export function updateCampaignSuccess(updatedCampaign) {
  return {
    type: UPDATE_CAMPAIGN_SUCCESS,
    updatedCampaign,
  };
}

export function updateCampaignError() {
  return {
    type: UPDATE_CAMPAIGN_ERROR,
  };
}

export function removeSingleCampaign() {
  return {
    type: REMOVE_SINGLE_CAMPAIGN,
  };
}

export function cancelSingleCampaignRequest(id, isDraft) {
  return {
    type: CANCEL_SINGLE_CAMPAIGN_REQUEST,
    id,
    isDraft,
  };
}

export function cancelSingleCampaignSuccess() {
  return {
    type: CANCEL_SINGLE_CAMPAIGN_SUCCESS,
  };
}

export function publishPublishableCampaignRequest(campaignId) {
  return {
    type: PUBLISH_PUBLISHABLE_CAMPAIGN_REQUEST,
    campaignId,
  };
}

export function setPrepublishCampaignToPublishable() {
  return {
    type: SET_PRE_PUBLISH_CAMPAIGN_TO_PUBLISHABLE,
  };
}

export function publishPublishableCampaignSuccess() {
  return {
    type: PUBLISH_PUBLISHABLE_CAMPAIGN_SUCCESS,
  };
}

export function publishPublishableCampaignError() {
  return {
    type: PUBLISH_PUBLISHABLE_CAMPAIGN_ERROR,
  };
}

export function setIsEditMode(isEditMode) {
  return {
    type: SET_IS_EDIT_MODE,
    isEditMode,
  };
}

export function sendCustomNotification(id, notificationData, onSuccess) {
  return {
    type: SEND_CUSTOM_NOTIFICATION,
    id,
    notificationData,
    meta: { onSuccess },
  };
}

export function fetchCustomNotificationsRequest(id, page, perPage) {
  return {
    type: FETCH_CUSTOM_NOTIFICATIONS_REQUEST,
    id,
    page,
    perPage,
  };
}

export function fetchCustomNotificationsSuccess(customNotifications) {
  return {
    type: FETCH_CUSTOM_NOTIFICATIONS_SUCCESS,
    customNotifications,
  };
}

export function fetchSingleCampaignInviteLinkRequest(id) {
  return {
    type: FETCH_SINGLE_CAMPAIGN_INVITE_LINK_REQUEST,
    id,
  };
}

export function fetchSingleCampaignInviteLinkSuccess(link) {
  return {
    type: FETCH_SINGLE_CAMPAIGN_INVITE_LINK_SUCCESS,
    link,
  };
}

export function fetchCampaignContentRequest(id) {
  return {
    type: FETCH_CAMPAIGN_CONTENT_REQUEST,
    id,
  };
}

export function fetchCampaignContentSuccess(contentData) {
  return {
    type: FETCH_CAMPAIGN_CONTENT_SUCCESS,
    contentData,
  };
}

export function wsUpdateSingleCampaignCampaignUsers(
  campaignUser,
  statusChangedVariable
) {
  return {
    type: WS_UPDATE_SINGLE_CAMPAIGN_CAMPAIGN_USERS,
    campaignUser,
    statusChangedVariable,
  };
}

export function wsCampaignStatusChanged(status, campaignId) {
  return {
    type: WS_CAMPAIGN_STATUS_CHANGED,
    status,
    campaignId,
  };
}
