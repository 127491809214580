import classNames from 'classnames';
import React, { useRef } from 'react';
import { ReactComponent as CheckIcon } from '../../../assets/images/checkMarkWhite.svg';
import CardValueChip from '../CardValueChip';
import styles from './ObjectiveCategoryCard.module.scss';
import { ReactComponent as InstagramIcon } from 'assets/images/social-platforms/instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/social-platforms/facebook2.svg';
import { ReactComponent as TikTokIcon } from 'assets/images/social-platforms/tiktokBox.svg';
import { ReactComponent as AmazonIcon } from 'assets/images/social-platforms/amazon.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/social-platforms/twitter_x.svg';
import { ReactComponent as YelpIcon } from 'assets/images/social-platforms/yelpBox.svg';
import { ReactComponent as LinkedinIcon } from 'assets/images/social-platforms/linkedin.svg';
import { ReactComponent as EycLogoIcon } from 'assets/images/logoY.svg';
import { ReactComponent as WorldIcon } from 'assets/images/social-platforms/world.svg';
import { ReactComponent as InPersonIcon } from 'assets/images/in_person_logo.svg';
import Tooltip from 'components/Tooltip';

const ObjectiveCategoryCard = ({
  type,
  title,
  description,
  onClick,
  selected,
  objective,
  chipValue,
  objectiveInfo,
  platformActions,
  disabled,
}) => {
  const titleRef = useRef();
  const descriptionRef = useRef();

  const renderPlatformIcon = () => {
    if (objective.location === '1. IN-PERSON') {
      return <InPersonIcon width={24} height={24} />;
    } else {
      switch (objective.primaryPlatform) {
        case 'IG':
          return <InstagramIcon width={24} height={24} />;
        case 'FB':
          return <FacebookIcon width={24} height={24} />;
        case 'TT':
          return <TikTokIcon width={24} height={24} />;
        case 'LI':
          return <LinkedinIcon width={24} height={24} />;
        case 'AMAZON':
          return <AmazonIcon width={24} height={24} />;
        case 'EYCROWD':
          return <EycLogoIcon width={24} height={24} />;
        case 'HTTP3':
          return <WorldIcon width={24} height={24} />;
        case 'YELP':
          return <YelpIcon width={24} height={24} />;
        case 'X':
          return <TwitterIcon width={24} height={24} />;
        default:
          return null;
      }
    }
  };

  const isTitleOverflow =
    titleRef.current?.scrollHeight > titleRef.current?.offsetHeight;

  const isDescriptionOverflow =
    descriptionRef.current?.scrollHeight >
    descriptionRef.current?.offsetHeight + 5;

  return (
    <div
      className={classNames(
        styles.container,
        styles[`${type}Shadow`],
        selected && styles[`${type}SelectedContainer`],
        disabled && styles.disabled
      )}
      onClick={onClick}
    >
      <div className={styles.header}>
        <CardValueChip text={chipValue} />
        {platformActions && objective && <div>{renderPlatformIcon()}</div>}
        <Tooltip
          label={objectiveInfo}
          customContent={<p>{objectiveInfo}</p>}
          iconClassName={styles.tooltipIcon}
          className={styles.tooltipContainer}
        />
        {/* <div className={styles.tooltipWrapper}>
          <InfoIcon />
          {objectiveInfo && (
            <span className={styles.tooltipInfo}>
              <p>{objectiveInfo}</p>
            </span>
          )}
        </div> */}
      </div>
      <p
        className={classNames(styles.title, styles[`${type}Color`])}
        ref={titleRef}
      >
        {title}
        {/* {isTitleOverflow && (
          <span
            className={classNames(
              styles.tooltipTextTitle,
              styles[`${type}BackgorundColor`]
            )}
          >
            {title}
          </span>
        )} */}
      </p>
      <div
        className={classNames(
          styles.description,
          styles[`${type}BackgorundColorOpacity`]
        )}
      >
        <p className={styles[`${type}Color`]} ref={descriptionRef}>
          {description}
          {/* {isDescriptionOverflow && (
            <span
              className={classNames(
                styles.tooltipText,
                styles[`${type}BackgorundColor`]
              )}
            >
              {description}
            </span>
          )} */}
        </p>
      </div>
      <div className={styles.footer}>
        <div
          className={classNames(
            styles.buttonContainer,
            styles[`${type}BackgorundColor`]
          )}
        >
          <p>
            {objective.disabled
              ? 'coming soon'
              : !selected
              ? 'select'
              : 'selected'}
          </p>
          {selected && <CheckIcon color="#ffffff" />}
        </div>
      </div>
    </div>
  );
};

export default ObjectiveCategoryCard;
