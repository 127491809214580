import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { fetchFreeSlotsDataRequest } from './actions';
import styles from './Banner.module.scss';
import reducer from './reducer';
import saga from './saga';
import { makeSelectFreeSlotsData } from './selectors';
import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';
import Icon from 'components/Icon';
import Button from 'components/Buttons/Button';
import { makeSelectPaymentMethods } from 'containers/App/selectors';
import { usePaymentMethodModal } from 'components/PaymentMethodModal';
import LoadingSpinner from 'components/Layout/LoadingSpinner';
import { makeSelectSingleCampaign } from 'containers/SingleCampaignPage/selectors';
import { setPrepublishCampaignToPublishable } from 'containers/SingleCampaignPage/actions';
import { CAMPAIGN_STATUS } from '../../../constants';

const key = 'freeSlots';

const Banner = ({ paymentMethodBannerText }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  let options = {
    hour: '2-digit',
    minute: '2-digit',
  };
  const dispatch = useDispatch();
  const freeSlots = useSelector(makeSelectFreeSlotsData());
  const paymentMethods = useSelector(makeSelectPaymentMethods());
  const [openPaymentMethodModal] = usePaymentMethodModal();
  const singleCampaign = useSelector(makeSelectSingleCampaign());

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    if (!paymentMethodBannerText) {
      dispatch(fetchFreeSlotsDataRequest());
    }
  }, [dispatch, paymentMethodBannerText]);

  useEffect(() => {
    if (!paymentMethodBannerText) {
      setTime(new Date(freeSlots?.customersFreeSlotsUpdatedAt));
    }
  }, [freeSlots, paymentMethodBannerText]);

  const renderBanner = () => {
    if (paymentMethodBannerText) {
      if (paymentMethods && paymentMethods.length) {
        return <></>;
      } else {
        return (
          <div className={styles.bannerContainer}>
            <Icon icon={WarningIcon} className={styles.icon} size="md" />
            <div className={styles.textContainer}>
              <p className={styles.title}>{paymentMethodBannerText}</p>
            </div>
            <Button
              title="Add payment"
              className={styles.button}
              handleClick={() =>
                openPaymentMethodModal(() => {
                  if (
                    singleCampaign &&
                    singleCampaign.status === CAMPAIGN_STATUS.PRE_PUBLISH
                  ) {
                    dispatch(setPrepublishCampaignToPublishable());
                  }
                })
              }
            />
          </div>
        );
      }
    } else {
      return <></>;
      // return freeSlots?.customersFreeSlots ? (
      //   <div className={styles.bannerContainer}>
      //     <Icon icon={WarningIcon} className={styles.icon} size="md" />
      //     <div className={styles.textContainer}>
      //       <p className={styles.title}>
      //         Only {freeSlots?.customersFreeSlots} new customer slots remain (as
      //         of {time.toLocaleDateString() + ' '}
      //         at
      //         {' ' + time.toLocaleTimeString('en-us', options)})
      //       </p>
      //       <p className={styles.text}>
      //         Due to the popularity of EyCrowd. We are only able to accept a
      //         limited number of customers during our early release period.
      //       </p>
      //       {freeSlots?.customersFreeSlots === 0 && (
      //         <p>
      //           No more slots available? Click here to get more information!
      //         </p>
      //       )}
      //     </div>
      //   </div>
      // ) : (
      //   <div className={styles.bannerContainer}>
      //     <LoadingSpinner />
      //   </div>
      // );
    }
  };

  return renderBanner();
};

export default Banner;
