import React from 'react';
import styles from './CodaBuilderLibrary.module.scss';

function CodaBuilderLibrary() {
  return (
    <iframe
      className={styles.embed}
      src="https://coda.io/embed/AseX0yZ66l/_suMoP?viewMode=embedplay"
      allow="fullscreen"
    ></iframe>
  );
}

export default CodaBuilderLibrary;
