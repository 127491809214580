import { CAMPAIGN_STATUS } from '../../constants';
import produce from 'immer';
import {
  FETCH_SINGLE_CAMPAIGN_REQUEST,
  FETCH_SINGLE_CAMPAIGN_SUCCESS,
  WS_UPDATE_SINGLE_CAMPAIGN_CAMPAIGN_USERS,
  FETCH_SINGLE_CAMPAIGN_GALLERY_REQUEST,
  FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_REQUEST,
  FETCH_SINGLE_CAMPAIGN_GALLERY_SUCCESS,
  FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_SUCCESS,
  UPDATE_CAMPAIGN_SUCCESS,
  CANCEL_SINGLE_CAMPAIGN_SUCCESS,
  SET_IS_EDIT_MODE,
  FETCH_CUSTOM_NOTIFICATIONS_SUCCESS,
  FETCH_SINGLE_CAMPAIGN_INVITE_LINK_SUCCESS,
  REMOVE_SINGLE_CAMPAIGN,
  FETCH_CAMPAIGN_CONTENT_SUCCESS,
  FETCH_CAMPAIGN_CONTENT_REQUEST,
  CampaignUserJoinedLeftVariables,
  CampaignUserStatusChangedVariables,
  WS_CAMPAIGN_STATUS_CHANGED,
  PUBLISH_PUBLISHABLE_CAMPAIGN_REQUEST,
  PUBLISH_PUBLISHABLE_CAMPAIGN_SUCCESS,
  PUBLISH_PUBLISHABLE_CAMPAIGN_ERROR,
  SET_PRE_PUBLISH_CAMPAIGN_TO_PUBLISHABLE,
} from './constants';

export const initialState = {
  singleCampaign: null,
  isSingleCampaignLoading: false,
  singleCampaignGallery: {
    data: null,
    page: null,
    perPage: null,
    total: null,
  },
  singleCampaignEyvisorGallery: {
    data: null,
    page: null,
    perPage: null,
    total: null,
  },
  isLoadingGallery: false,
  isLoadingEyvisorGallery: false,
  isEditMode: false,
  customNotifications: {
    data: null,
    page: null,
    perPage: null,
    total: null,
  },
  contentData: null,
  isLoadingContent: false,
  inviteLink: '',
};

/* eslint-disable default-case */
const singleCampaignReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_PRE_PUBLISH_CAMPAIGN_TO_PUBLISHABLE:
        draft.singleCampaign.status = CAMPAIGN_STATUS.PUBLISHABLE;
        break;
      case PUBLISH_PUBLISHABLE_CAMPAIGN_REQUEST:
        draft.isSingleCampaignLoading = true;
        break;
      case PUBLISH_PUBLISHABLE_CAMPAIGN_SUCCESS:
        draft.isSingleCampaignLoading = false;
        break;
      case PUBLISH_PUBLISHABLE_CAMPAIGN_ERROR:
        draft.isSingleCampaignLoading = false;
        break;
      case FETCH_SINGLE_CAMPAIGN_REQUEST:
        draft.isSingleCampaignLoading = true;
        break;
      case FETCH_SINGLE_CAMPAIGN_SUCCESS:
        draft.isSingleCampaignLoading = false;
        draft.singleCampaign = action.singleCampaign;
        break;
      case FETCH_SINGLE_CAMPAIGN_GALLERY_REQUEST:
        draft.isLoadingGallery = true;
        break;
      case FETCH_SINGLE_CAMPAIGN_GALLERY_SUCCESS:
        draft.singleCampaignGallery = {
          ...action.singleCampaignGallery,
          data:
            action.singleCampaignGallery.page === 1
              ? action.singleCampaignGallery.data
              : [
                  ...draft.singleCampaignGallery.data,
                  ...action.singleCampaignGallery.data,
                ],
        };
        draft.isLoadingGallery = false;
        break;
      case FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_REQUEST:
        draft.isLoadingEyvisorGallery = true;
        break;
      case FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_SUCCESS:
        draft.singleCampaignEyvisorGallery = {
          ...action.singleCampaignEyvisorGallery,
          data:
            action.singleCampaignEyvisorGallery.page === 1
              ? action.singleCampaignEyvisorGallery.data
              : [
                  ...draft.singleCampaignEyvisorGallery.data,
                  ...action.singleCampaignEyvisorGallery.data,
                ],
        };
        draft.isLoadingEyvisorGallery = false;
        break;
      case UPDATE_CAMPAIGN_SUCCESS:
        draft.singleCampaign = {
          ...draft.singleCampaign,
          ...action.updatedCampaign,
        };
        break;
      case REMOVE_SINGLE_CAMPAIGN:
        draft.singleCampaign = null;
        break;
      case CANCEL_SINGLE_CAMPAIGN_SUCCESS:
        draft.singleCampaign = null;
        break;
      case SET_IS_EDIT_MODE:
        draft.isEditMode = action.isEditMode;
        break;
      case FETCH_CUSTOM_NOTIFICATIONS_SUCCESS:
        draft.customNotifications = {
          ...action.customNotifications,
          data:
            action.customNotifications.page === 1
              ? action.customNotifications.data
              : [
                  ...draft.customNotifications.data,
                  ...action.customNotifications.data,
                ],
        };
        break;
      case FETCH_CAMPAIGN_CONTENT_REQUEST:
        draft.isLoadingContent = true;
        break;
      case FETCH_CAMPAIGN_CONTENT_SUCCESS:
        draft.isLoadingContent = false;
        draft.contentData = action.contentData;
        break;
      case FETCH_SINGLE_CAMPAIGN_INVITE_LINK_SUCCESS:
        draft.inviteLink = action.link;
        break;
      case WS_CAMPAIGN_STATUS_CHANGED:
        if (draft.singleCampaign.id === action.campaignId)
          draft.singleCampaign = {
            ...draft.singleCampaign,
            status: action.status,
          };
        break;
      case WS_UPDATE_SINGLE_CAMPAIGN_CAMPAIGN_USERS:
        switch (action.statusChangedVariable) {
          case CampaignUserJoinedLeftVariables.JoinedCampaign:
            draft.singleCampaign = {
              ...draft.singleCampaign,
              campaignUsers: [
                ...draft.singleCampaign.campaignUsers,
                action.campaignUser,
              ],
            };
            break;
          case CampaignUserJoinedLeftVariables.LeftCampaign:
            draft.singleCampaign = {
              ...draft.singleCampaign,
              campaignUsers: draft.singleCampaign.campaignUsers.filter(
                (campaignUser) => {
                  return +campaignUser.userId !== +action.campaignUser.userId;
                }
              ),
            };
            break;
          case CampaignUserStatusChangedVariables.FinishedCampaign:
            draft.singleCampaign = {
              ...draft.singleCampaign,
              campaignUsers: draft.singleCampaign.campaignUsers.map(
                (campaignUser) => {
                  if (campaignUser.id === action.campaignUser.id) {
                    return action.campaignUser;
                  } else {
                    return campaignUser;
                  }
                }
              ),
            };
            break;
          case CampaignUserStatusChangedVariables.TaskChecked:
            draft.singleCampaign = {
              ...draft.singleCampaign,
              campaignUsers: draft.singleCampaign.campaignUsers.map(
                (campaignUser) => {
                  if (campaignUser.id === action.campaignUser.id) {
                    return action.campaignUser;
                  } else {
                    return campaignUser;
                  }
                }
              ),
            };
            break;
          case CampaignUserStatusChangedVariables.TaskUnchecked:
            draft.singleCampaign = {
              ...draft.singleCampaign,
              campaignUsers: draft.singleCampaign.campaignUsers.map(
                (campaignUser) => {
                  if (campaignUser.id === action.campaignUser.id) {
                    return action.campaignUser;
                  } else {
                    return campaignUser;
                  }
                }
              ),
            };
            break;
          case CampaignUserStatusChangedVariables.ArrivalTaskChecked:
            draft.singleCampaign = {
              ...draft.singleCampaign,
              campaignUsers: draft.singleCampaign.campaignUsers.map(
                (campaignUser) => {
                  if (campaignUser.id === action.campaignUser.id) {
                    return action.campaignUser;
                  } else {
                    return campaignUser;
                  }
                }
              ),
            };
            break;
          case CampaignUserStatusChangedVariables.ArrivalTaskUnchecked:
            draft.singleCampaign = {
              ...draft.singleCampaign,
              campaignUsers: draft.singleCampaign.campaignUsers.map(
                (campaignUser) => {
                  if (campaignUser.id === action.campaignUser.id) {
                    return action.campaignUser;
                  } else {
                    return campaignUser;
                  }
                }
              ),
            };
            break;
          case CampaignUserStatusChangedVariables.PromotedToSupervisor:
            draft.singleCampaign = {
              ...draft.singleCampaign,
              campaignUsers: draft.singleCampaign.campaignUsers.map(
                (campaignUser) => {
                  if (campaignUser.id === action.campaignUser.id) {
                    return action.campaignUser;
                  } else {
                    return campaignUser;
                  }
                }
              ),
            };
            break;
          case CampaignUserStatusChangedVariables.DemotedFromSupervisor:
            draft.singleCampaign = {
              ...draft.singleCampaign,
              campaignUsers: draft.singleCampaign.campaignUsers.map(
                (campaignUser) => {
                  if (campaignUser.id === action.campaignUser.id) {
                    return action.campaignUser;
                  } else {
                    return campaignUser;
                  }
                }
              ),
            };
            break;
          default:
            break;
        }
    }
  });

export default singleCampaignReducer;
