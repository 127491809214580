import React, { useRef } from 'react';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import ReactTooltip from 'react-tooltip';
import Icon from 'components/Icon';
import styles from './Tooltip.module.scss';
import classNames from 'classnames';

const Tooltip = ({
  label,
  className,
  tooltipOverrideClassName,
  customContent,
  offset,
  iconClassName,
  overridePosition,
  buttonText,
  textClassName,
}) => {
  const reactTooltipRef = useRef(null);

  return (
    <div
      className={classNames(styles.container, className)}
      data-tip
      data-for={label}
    >
      <Icon
        icon={InfoIcon}
        size="sm"
        className={classNames(styles.icon, iconClassName)}
      />
      {buttonText && <p className={classNames(textClassName)}>{buttonText}</p>}

      <ReactTooltip
        offset={offset || { top: -5, right: 140 }}
        arrowColor="transparent"
        className={classNames(styles.tooltipOverride, tooltipOverrideClassName)}
        id={label}
        place="top"
        effect="solid"
        overridePosition={overridePosition}
        ref={reactTooltipRef}
        afterShow={(evt) => {
          const { tooltipRef } = reactTooltipRef.current;

          if (!tooltipRef) return;

          const rect = tooltipRef.getBoundingClientRect();

          const overflownLeft = rect.left < 0;
          const overflownRight = rect.right > window.innerWidth;

          const overflownTop = rect.top < 0;

          if (overflownTop) {
            tooltipRef.style.setProperty('top', '100px');
            tooltipRef.style.setProperty('bottom', 'auto');
          }

          if (overflownLeft) {
            tooltipRef.style.setProperty('left', '10px');
            tooltipRef.style.setProperty('right', 'auto');
          } else if (overflownRight) {
            tooltipRef.style.setProperty('left', 'auto');
            tooltipRef.style.setProperty('right', '10px');
          }
        }}
      >
        {customContent ? (
          customContent
        ) : (
          <p className={styles.label}>{label}</p>
        )}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
