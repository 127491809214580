import { defineMessages } from 'react-intl';

export const scope = 'dashboard_layout';

export default defineMessages({
  logOut: {
    id: `${scope}.text.log_out`,
    defaultMessage: 'Log Out',
  },
  verificationMessage: {
    id: `${scope}.text.verification_message`,
    defaultMessage:
      'Please click the link in your email to verify your account. Once the verification is complete, you can start running your campaigns.',
  },
  resendLink: {
    id: `${scope}.text.resend_link`,
    defaultMessage: 'Resend link',
  },
  timeline: {
    id: `${scope}.text.timeline`,
    defaultMessage: 'Timeline',
  },
  statistics: {
    id: `${scope}.text.statistics`,
    defaultMessage: 'Statistics',
  },
  campaigns: {
    id: `${scope}.text.campaigns`,
    defaultMessage: 'Campaigns',
  },
  dashboardTitle: {
    id: `${scope}.text.dashboard_title`,
    defaultMessage: 'Dashboard',
  },
  totalCampaigns: {
    id: `${scope}.text.total_campaigns`,
    defaultMessage: 'Total Campaigns',
  },
  totalEyVocates: {
    id: `${scope}.text.total_eyvocates`,
    defaultMessage: 'Total EyVocates',
  },
  totalHours: {
    id: `${scope}.text.total_hours`,
    defaultMessage: 'Total Hours',
  },
  totalMoneySpent: {
    id: `${scope}.text.total_money_spent`,
    defaultMessage: 'Total Money Spent',
  },
  eyvocates: {
    id: `${scope}.text.eyvocates`,
    defaultMessage: 'EyVocates',
  },
  moneySpent: {
    id: `${scope}.text.money_spent`,
    defaultMessage: 'Money Spent',
  },
  areYouSureLeavePage: {
    id: `${scope}.are_you_sure_leave_page`,
    defaultMessage:
      'Changes that you made may not be saved. Are you sure you want to leave page?',
  },
});
