import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { create } from 'lodash';

const newExperienceCampaignBuilder = (state) =>
  state.newExperienceCampaignBuilder || initialState;

const makeObjectiveCategoriesSelector = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.objectiveCategories
  );

const makeObjectivesSelector = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.objectives
  );

const makeTasksSelector = () =>
  createSelector(newExperienceCampaignBuilder, (substate) => substate.tasks);

const makePlatformActionsSelector = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.platformActions
  );

const makeSelectSuggestions = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.suggestions
  );

const makeSelectSocialAccountSuggestions = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.socialAccountSuggestions
  );

const makeSelectGetSocialAccountSuggestionsPending = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.getSocialAccountSuggestionsPending
  );

const makeSelectGetSuggestionsPending = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.getSuggestionsPending
  );

const makeSelectGetSocialAccountSuggestionsMessage = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.getSocialAccountSuggestionsMessage
  );

const makeSelectGetSocialAccountSuggestionsError = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.getSocialAccountSuggestionsError
  );

const makeSelectObjectiveSuggestions = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.objectiveSuggestions
  );

const makeSelectGetObjectiveSuggestionsPending = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.getObjectiveSuggestionsPending
  );

const makeSelectGetObjectiveSuggestionsMessage = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.getObjectiveSuggestionsMessage
  );

const makeSelectGetObjectiveSuggestionsError = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.getObjectiveSuggestionsError
  );

const makeSelectGetBrandStoryPending = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.getBrandStoryPending
  );

const makeSelectGetTaskDescriptionsPending = () =>
  createSelector(
    newExperienceCampaignBuilder,
    (substate) => substate.getTaskDescriptionsPending
  );

export {
  makeObjectiveCategoriesSelector,
  makeObjectivesSelector,
  makeTasksSelector,
  makePlatformActionsSelector,
  makeSelectSuggestions,
  makeSelectSocialAccountSuggestions,
  makeSelectGetSocialAccountSuggestionsPending,
  makeSelectGetSuggestionsPending,
  makeSelectGetSocialAccountSuggestionsMessage,
  makeSelectGetSocialAccountSuggestionsError,
  makeSelectObjectiveSuggestions,
  makeSelectGetObjectiveSuggestionsPending,
  makeSelectGetObjectiveSuggestionsMessage,
  makeSelectGetObjectiveSuggestionsError,
  makeSelectGetBrandStoryPending,
  makeSelectGetTaskDescriptionsPending,
};
