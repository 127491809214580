import React from 'react';
import styles from './CodaWhyEyCrowd.module.scss';

function CodaWhyEyCrowd() {
  return (
    <iframe
      className={styles.embed}
      src="https://coda.io/embed/ElYIPpb4TA/_suMoP?viewMode=embedplay"
      allow="fullscreen"
    ></iframe>
  );
}

export default CodaWhyEyCrowd;
