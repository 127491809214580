import produce from 'immer';
import {
  FETCH_OPENAI_SUGGESTIONS_ERROR,
  FETCH_OPENAI_SUGGESTIONS_REQUEST,
  FETCH_OPENAI_SUGGESTIONS_SUCCESS,
  FETCH_OPENAI_SUGGESTIONS_RESET,
  FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_ERROR,
  FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_REQUEST,
  FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_SUCCESS,
  FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_RESET,
  REORGANIZE_JSON_DATA,
  ADD_DUPLICATE_TASK,
  DELETE_TASK,
  MOVE_TASK,
  FETCH_OBJECTIVE_SUGGESTIONS_REQUEST,
  FETCH_OBJECTIVE_SUGGESTIONS_SUCCESS,
  FETCH_OBJECTIVE_SUGGESTIONS_ERROR,
  GET_BRAND_STORY_REQUEST,
  GET_BRAND_STORY_SUCCESS,
  GET_BRAND_STORY_ERROR,
  GET_TASK_DESCRIPTIONS_REQUEST,
  GET_TASK_DESCRIPTIONS_SUCCESS,
  GET_TASK_DESCRIPTIONS_ERROR,
} from './constants';

export const initialState = {
  objectiveCategories: [],
  objectives: [],
  tasks: [],
  platformActions: [],
  suggestions: {},
  socialAccountSuggestions: null,
  getSocialAccountSuggestionsPending: false,
  getSocialAccountSuggestionsMessage: null,
  getSocialAccountSuggestionsError: false,
  getSuggestionsPending: false,
  numberOfTimesUserUsedAiAssistWithError: 0,
  getObjectiveSuggestionsPending: false,
  objectiveSuggestions: [],
  getObjectiveSuggestionsMessage: null,
  getObjectiveSuggestionsError: null,
  getBrandStoryPending: false,
  getTaskDescriptionsPending: false,
};

/* eslint-disable default-case */
const newExperienceCampaignBuilderReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case REORGANIZE_JSON_DATA:
        const { jsonData } = action.payload;
        const { objective_category } = jsonData;

        const retObjectiveCategories = [];
        const retObjectives = [];
        const retTasks = [];
        const retPlatformActions = [];

        objective_category.forEach((oc) => {
          retObjectiveCategories.push({
            id: oc.objective_category_id,
            category: oc.objective_category,
            description: oc.objective_category_desc,
            headline: oc.objective_category_headline,
            tagline: oc.objective_category_tagline,
            subcategory: oc.objective_subcategory.map((osc) => ({
              id: osc.objective_subcategory_id,
              subcategory: osc.objective_subcategory,
              description: osc.objective_subcategory_desc,
              shortDescription: osc.objective_subcategory_short_desc,
            })),
          });

          oc.objective_subcategory.forEach((sc) => {
            sc.objective_type.forEach((type) => {
              if (type.objective) {
                type.objective.forEach((o) => {
                  if (o.visible === '5. GA' || o.visible === '4. COMING_SOON') {
                    retObjectives.push({
                      id: o.objective_id,
                      headline: o.headline,
                      loadOrder: o.load_order,
                      tagline: o.tagline,
                      metatags: o.metatags,
                      objective: o.objective,
                      description: o.objective_description,
                      subcategoryId: sc.objective_subcategory_id,
                      categoryId: oc.objective_category_id,
                      subcategoryShortDesc: sc.objective_subcategory_short_desc,
                      disabled: o.visible === '4. COMING_SOON',
                      location: type.objective_location,
                      primaryPlatform: type.primary_platform,
                    });

                    if (o.tasks) {
                      o.tasks.forEach((t) => {
                        retTasks.push({
                          id: t.task_id,
                          type: t.task_type,
                          time: t.task_time,
                          category: t.task_category,
                          subcategory: t.task_subcategory,
                          priority: t.task_priority,
                          name: t.task,
                          ...(t.task_descriptions && {
                            descriptions: t.task_descriptions.map((td) => ({
                              label: td.task_description,
                            })),
                          }),
                          objectiveId: o.objective_id,
                          objectiveHeadline: o.headline,
                        });

                        t.platform_actions.forEach((pa) => {
                          retPlatformActions.push({
                            ...pa,
                            categoryId: oc.objective_category_id,
                            subcategoryId: sc.objective_subcategory_id,
                            objectiveId: o.objective_id,
                            taskId: t.task_id,
                          });
                        });
                      });
                    }
                  }
                });
              }
            });
          });
        });

        draft.objectiveCategories = retObjectiveCategories;
        draft.objectives = retObjectives;
        draft.tasks = retTasks;
        draft.platformActions = retPlatformActions;

        break;
      case FETCH_OPENAI_SUGGESTIONS_REQUEST:
        draft.getSuggestionsPending = true;
        break;
      case FETCH_OPENAI_SUGGESTIONS_SUCCESS:
        draft.getSuggestionsPending = false;
        draft.suggestions = action.payload;
        break;
      case FETCH_OPENAI_SUGGESTIONS_ERROR:
        draft.getSuggestionsPending = false;
        break;
      case FETCH_OPENAI_SUGGESTIONS_RESET:
        draft.suggestions = {};
        break;
      case FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_REQUEST:
        draft.getSocialAccountSuggestionsPending = true;
        break;
      case FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_SUCCESS:
        draft.getSocialAccountSuggestionsPending = false;
        draft.socialAccountSuggestions = action.payload.suggestions;
        draft.getSocialAccountSuggestionsMessage = action.payload.message;
        draft.getSocialAccountSuggestionsError = false;
        break;
      case FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_ERROR:
        draft.getSocialAccountSuggestionsPending = false;
        draft.getSocialAccountSuggestionsError = true;
        draft.numberOfTimesUserUsedAiAssistWithError =
          draft.numberOfTimesUserUsedAiAssistWithError + 1;
        draft.getSocialAccountSuggestionsMessage =
          draft.numberOfTimesUserUsedAiAssistWithError < 3
            ? action.payload
            : 'smart ai text failed - contact support (manual entry)';

        break;
      case FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_RESET:
        draft.numberOfTimesUserUsedAiAssistWithError = 0;
        draft.getObjectiveSuggestionsMessage = null;
        draft.socialAccountSuggestions = null;
        draft.getSocialAccountSuggestionsError = false;
        break;
      case FETCH_OBJECTIVE_SUGGESTIONS_REQUEST:
        draft.getObjectiveSuggestionsPending = true;
        draft.getObjectiveSuggestionsError = false;
        draft.getObjectiveSuggestionsMessage = null;
        break;
      case FETCH_OBJECTIVE_SUGGESTIONS_SUCCESS:
        draft.getObjectiveSuggestionsPending = false;
        draft.objectiveSuggestions = [...action.payload.objectives.campaigns];
        draft.getObjectiveSuggestionsMessage = action.payload.message;
        draft.getObjectiveSuggestionsError = false;
        break;
      case FETCH_OBJECTIVE_SUGGESTIONS_ERROR:
        draft.getObjectiveSuggestionsPending = false;
        draft.getObjectiveSuggestionsError = true;
        draft.objectiveSuggestions = [];
        draft.getObjectiveSuggestionsMessage = action.payload;
        break;
      case ADD_DUPLICATE_TASK:
        const { task, newId } = action.payload;

        const taskIndex = draft.tasks.findIndex(
          (t) => t.id === task.id && t.objectiveId === task.objectiveId
        );
        if (taskIndex !== -1) {
          const duplicatedTask = {
            ...task,
            originalId: task.originalId ? task.originalId : task.id,
            id: newId,
          };
          draft.tasks.splice(taskIndex + 1, 0, duplicatedTask);
        } else {
          draft.tasks.push({
            ...task,
            originalId: task.originalId ? task.originalId : task.id,
            id: newId,
          });
        }
        break;
      case DELETE_TASK:
        const { id, objectiveId } = action.payload;
        draft.tasks = draft.tasks.filter(
          (task) => !(task.id === id && task.objectiveId === objectiveId)
        );
        break;
      case MOVE_TASK:
        const { currentTask, direction } = action.payload;
        const index = draft.tasks.findIndex(
          (t) =>
            t.id === currentTask.id && t.objectiveId === currentTask.objectiveId
        );

        if (index < 0) {
          break;
        }

        const newIndex = index + direction;

        if (newIndex < 0 || newIndex >= draft.tasks.length) {
          break;
        }

        const [foundTask] = draft.tasks.splice(index, 1);
        draft.tasks.splice(newIndex, 0, foundTask);
        break;
      case GET_BRAND_STORY_REQUEST:
        draft.getBrandStoryPending = true;
        break;
      case GET_BRAND_STORY_SUCCESS:
        draft.getBrandStoryPending = false;
        break;
      case GET_BRAND_STORY_ERROR:
        draft.getBrandStoryPending = false;
        break;
      case GET_TASK_DESCRIPTIONS_REQUEST:
        draft.getTaskDescriptionsPending = true;
        break;
      case GET_TASK_DESCRIPTIONS_SUCCESS:
        draft.getTaskDescriptionsPending = false;
        break;
      case GET_TASK_DESCRIPTIONS_ERROR:
        draft.getTaskDescriptionsPending = false;
        break;
    }
  });

export default newExperienceCampaignBuilderReducer;
