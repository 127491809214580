import classNames from 'classnames';
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import styles from './Objectives.module.scss';
import { useSelector } from 'react-redux';
import {
  makeObjectivesSelector,
  makePlatformActionsSelector,
  makeTasksSelector,
} from 'containers/NewExperienceCampaignBuilderPage/selectors';
import ObjectiveCategoryCard from '../ObjectiveCategoryCard';
import Input from '../Input';
import { ReactComponent as SearchIcon } from 'assets/images/search_icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close_icon.svg';

const Objectives = ({
  selectedCategory,
  setSelectedCategory,
  selectedSubcategories,
  setSelectedSubcategories,
  selectedObjectives,
  setSelectedObjectives,
  selectedMetatag,
  setSelectedMetatag,
  selectedSocialPlatform,
  selectedObjectiveType,
  areObjectivesFromDream,
  importedCampaign,
}) => {
  const objectives = useSelector(makeObjectivesSelector());
  const tasks = useSelector(makeTasksSelector());
  const platformAction = useSelector(makePlatformActionsSelector());
  const [searchValue, setSearchValue] = useState('');
  const [objectivesFound, setObjectiveFound] = useState(0);

  const contentRef = useRef(null);

  const getTaskProjectedValue = useCallback(
    (taskId) => {
      return platformAction.find((p) => p.taskId === taskId);
    },
    [platformAction]
  );

  const newObjectives = useMemo(() => {
    if (selectedSubcategories.length) {
      return objectives.filter((o) =>
        selectedSubcategories.find((sc) => sc.id === o.subcategoryId)
      );
    }

    const sortedObjectives = [...objectives].sort(
      (a, b) => a.loadOrder - b.loadOrder
    );

    if (searchValue !== '') {
      const lowerCaseSearchValue = searchValue.toLowerCase();

      const searchObjectives = sortedObjectives.filter((o) => {
        const objectiveTasks = tasks.filter((t) => o.id === t.objectiveId);

        let platformMatch = false;
        for (const task of objectiveTasks) {
          let pv;
          if (task.originalId) {
            pv = getTaskProjectedValue(task.originalId);
          } else {
            pv = getTaskProjectedValue(task.id);
          }

          if (
            pv &&
            pv.platform &&
            pv.platform.toLowerCase().includes(lowerCaseSearchValue)
          ) {
            platformMatch = true;
            break;
          }
        }

        return (
          platformMatch ||
          [
            'headline',
            'tagline',
            'objective',
            'description',
            'subcategoryShortDesc',
          ].some(
            (field) =>
              typeof o[field] === 'string' &&
              o[field].toLowerCase().includes(lowerCaseSearchValue)
          )
        );
      });

      setObjectiveFound(searchObjectives.length);
      return searchObjectives;
    }

    if (
      selectedCategory ||
      selectedMetatag ||
      selectedSocialPlatform.length ||
      selectedObjectiveType
    ) {
      let newObjectives = [...sortedObjectives];

      if (selectedMetatag) {
        newObjectives = newObjectives.filter(
          (o) => o.metatags && o.metatags.includes(selectedMetatag)
        );
      }

      if (selectedCategory) {
        newObjectives = newObjectives.filter(
          (o) => selectedCategory.id === o.categoryId
        );
      }

      if (selectedSocialPlatform.length) {
        newObjectives = newObjectives.filter((o) => {
          const objectiveTasks = tasks.filter((t) => o.id === t.objectiveId);
          for (const task of objectiveTasks) {
            const pv = getTaskProjectedValue(task.id);

            return selectedSocialPlatform.includes(pv.platform);
          }
          return false;
        });
      }

      if (selectedObjectiveType) {
        newObjectives = newObjectives.filter(
          (o) => selectedObjectiveType === o.location
        );
      }

      return newObjectives;
    }

    return sortedObjectives;
  }, [
    selectedSocialPlatform,
    selectedCategory,
    selectedSubcategories,
    objectives,
    selectedMetatag,
    searchValue,
    selectedObjectiveType,
  ]);

  useEffect(() => {
    if (importedCampaign) {
      const retObjectives = [];
      for (const [index, task] of importedCampaign.tasks.entries()) {
        const objectiveInRetObjectives = retObjectives.find(
          (obj) => obj.id === task.objectiveId
        );
        let foundObjective = newObjectives.find(
          (obj) => obj.id === task.objectiveId
        );
        if (foundObjective) {
          if (!objectiveInRetObjectives) {
            retObjectives.push(foundObjective);
          } else {
            retObjectives.push({
              ...foundObjective,
              id: `${foundObjective.id}/${index}`,
            });
          }
        }
      }

      setSelectedObjectives(retObjectives);
    }
  }, [importedCampaign, newObjectives, setSelectedObjectives]);

  const onObjectiveClick = (objective) => {
    const index = selectedObjectives.indexOf(objective);

    let newObjectives = [...selectedObjectives];
    if (index > -1) {
      const filteredTasks = newObjectives.filter(
        (t) => t.headline !== objective.headline
      );
      setSelectedObjectives(filteredTasks);
    } else {
      setSelectedObjectives([...newObjectives, objective]);
    }
  };

  const onSearchObjectives = (searchTerm) => {
    setSearchValue(searchTerm);
    if (selectedMetatag) {
      setSelectedMetatag(null);
    }
  };

  useEffect(() => {
    if (areObjectivesFromDream && contentRef.current) {
      contentRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [areObjectivesFromDream]);

  return (
    <div className={styles.contentContainer} ref={contentRef}>
      <div className={styles.containerHeader}>
        <div
          className={styles.options}
          onClick={() => {
            if (selectedCategory) {
              setSelectedCategory(null);
            }
            setSelectedMetatag((prev) =>
              prev === '<FEATURED>' ? null : '<FEATURED>'
            );
          }}
        >
          <div className={styles.checkbox}>
            {'<FEATURED>' === selectedMetatag && (
              <div className={styles.selectedOption} />
            )}
          </div>
          <p className={styles.text}>
            ⭐️ Featured <span></span>
          </p>
        </div>
        <div
          className={styles.options}
          onClick={() => {
            if (selectedCategory) {
              setSelectedCategory(null);
            }
            setSelectedMetatag((prev) =>
              prev === '<POPULAR>' ? null : '<POPULAR>'
            );
          }}
        >
          <div className={styles.checkbox}>
            {'<POPULAR>' === selectedMetatag && (
              <div className={styles.selectedOption} />
            )}
          </div>
          <p className={styles.text}>📈 Popular</p>
        </div>
        <div
          className={styles.options}
          onClick={() => {
            if (selectedCategory) {
              setSelectedCategory(null);
            }
            setSelectedMetatag((prev) =>
              prev === '<EYCROWD_CHOICE>' ? null : '<EYCROWD_CHOICE>'
            );
          }}
        >
          <div className={styles.checkbox}>
            {'<EYCROWD_CHOICE>' === selectedMetatag && (
              <div className={styles.selectedOption} />
            )}
          </div>
          <p className={styles.text}>💎 EyCrowd Choice</p>
        </div>
        <div
          className={styles.options}
          onClick={() => {
            if (selectedCategory) {
              setSelectedCategory(null);
            }
            setSelectedMetatag((prev) =>
              prev === '<COMING_SOON>' ? null : '<COMING_SOON>'
            );
          }}
        >
          <div className={styles.checkbox}>
            {'<COMING_SOON>' === selectedMetatag && (
              <div className={styles.selectedOption} />
            )}
          </div>
          <p className={styles.text}>⏳️ Coming Soon</p>
        </div>
        <div
          className={styles.options}
          onClick={() => {
            if (selectedCategory) {
              setSelectedCategory(null);
            }
            setSelectedMetatag(null);
          }}
        >
          <div className={styles.checkbox}>
            {null === selectedMetatag && (
              <div className={styles.selectedOption} />
            )}
          </div>
          <p className={styles.text}>💯 All</p>
        </div>
      </div>
      <div className={styles.searchContainer}>
        <SearchIcon
          width={20}
          height={20}
          style={{ position: 'absolute', top: 6, left: 13, zIndex: 100 }}
        />
        <Input
          className={styles.input}
          placeholder={'search objectives'}
          value={searchValue}
          maxLength={500}
          hideErrorMessage
          onChange={(event) => onSearchObjectives(event.target.value)}
        />
        {searchValue !== '' && (
          <div
            onClick={() => {
              setSearchValue('');
              setObjectiveFound(0);
            }}
            style={{ position: 'absolute', top: 6, right: 13, zIndex: 100 }}
          >
            <CloseIcon width={20} height={20} />
          </div>
        )}
      </div>
      {/* // )} */}
      {objectivesFound !== 0 && objectives.length !== objectivesFound && (
        <div>
          {objectivesFound <= 1 ? (
            <p
              className={styles.searchResultInfo}
            >{`${objectivesFound} objective found`}</p>
          ) : (
            <p
              className={styles.searchResultInfo}
            >{`${objectivesFound} objectives found`}</p>
          )}
        </div>
      )}
      <div className={styles.container}>
        <div
          className={classNames(
            styles.objectivesContainer,
            styles.customBackgorundColor
          )}
        >
          {!!selectedObjectives.length && (
            <div style={{ width: '100%' }}>
              <div className={styles.selectedObjectives}>
                {selectedObjectives.map((objective) => {
                  return (
                    <ObjectiveCategoryCard
                      key={objective.id}
                      chipValue={objective.subcategoryShortDesc}
                      objective={objective}
                      platformActions={platformAction}
                      type="custom"
                      title={objective.headline}
                      description={objective.tagline}
                      selected={selectedObjectives.includes(objective)}
                      onClick={() =>
                        !objective.disabled && onObjectiveClick(objective)
                      }
                      objectiveInfo={objective.description}
                      disabled={objective.disabled}
                    />
                  );
                })}
              </div>
              <p className={classNames(styles.sectionTitle, styles.firstTitle)}>
                SELECTIONS
              </p>
              <div className={styles.horizontalLine} />
              <p
                style={{ marginBottom: '34px' }}
                className={styles.sectionTitle}
              >
                CATALOG
              </p>
            </div>
          )}
          <div style={{ width: '100%' }}>
            <div className={styles.selectedObjectives}>
              {newObjectives.map((objective) => {
                return (
                  <ObjectiveCategoryCard
                    key={objective.id}
                    chipValue={objective.subcategoryShortDesc}
                    objective={objective}
                    platformActions={platformAction}
                    type="custom"
                    title={objective.headline}
                    description={objective.tagline}
                    selected={selectedObjectives.includes(objective)}
                    onClick={() =>
                      !objective.disabled && onObjectiveClick(objective)
                    }
                    objectiveInfo={objective.description}
                    disabled={objective.disabled}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Objectives;
