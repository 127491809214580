import React from 'react';
import styles from './RadioButton.module.scss';

const RadioButton = ({ onClick, checked, title }) => {
  return (
    <div onClick={onClick} className={styles.radioButtonContainer}>
      <div className={styles.radioButton}>
        {checked && <div className={styles.checked} />}
      </div>
      <p className={styles.radioButtonText}>{title}</p>
    </div>
  );
};

export default RadioButton;
