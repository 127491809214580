import React, { useState, useEffect } from 'react';
import styles from '../TaskDetails/TaskDetails.module.scss';
import Input from '../Input';
import { ReactComponent as SyncIcon } from 'assets/images/sync.svg';
import LoadingSpinner from 'components/Layout/LoadingSpinner';

function TaskInput({
  pv,
  task,
  tasksInfo,
  onTextChange,
  onRegenerateClick,
  width,
  loading,
}) {
  const [inputValue, setInputValue] = useState('');
  const [userModified, setUserModified] = useState(false);

  useEffect(() => {
    if (userModified) return;

    if (!tasksInfo[`${task.objectiveId}-${task.id}`]?.description) {
      setInputValue(task.descriptions[0].label);
    } else {
      setInputValue(tasksInfo[`${task.objectiveId}-${task.id}`]?.description);
    }
  }, [task, tasksInfo, userModified]);

  useEffect(() => {
    onTextChange(
      `${task.objectiveId}-${task.id}`,
      tasksInfo[`${task.objectiveId}-${task.id}`]?.description
        ? tasksInfo[`${task.objectiveId}-${task.id}`]?.description
        : task.descriptions[0].label,
      'description',
      pv.platform
    );
  }, []);

  const handleRegenerate = () => {
    setUserModified(false);
    onRegenerateClick(`${task.objectiveId}-${task.id}`, task.descriptions);
  };

  return (
    <div
      className={styles.surveyContainer}
      style={{
        paddingRight:
          task.descriptions.length <= 1
            ? width <= 950
              ? '20px'
              : width <= 630
              ? '10px'
              : '130px'
            : '0px',
      }}
    >
      <p className={styles.surveyText}>Input: </p>
      <Input
        className={styles.surveyInput}
        type={'textarea'}
        placeholder={
          tasksInfo[`${task.objectiveId}-${task.id}`]?.description
            ? tasksInfo[`${task.objectiveId}-${task.id}`].description
            : tasksInfo[`${task.objectiveId}-${task.originalId}`]?.description
            ? tasksInfo[`${task.objectiveId}-${task.originalId}`].description
            : task.descriptions[0].label
        }
        value={inputValue}
        maxLength={100}
        hideErrorMessage
        onChange={(event) => {
          setUserModified(true);
          setInputValue(event.target.value);
          onTextChange(
            `${task.objectiveId}-${task.id}`,
            event.target.value,
            'description',
            pv.platform
          );
        }}
      />
      {task.descriptions.length > 1 &&
        (loading ? (
          <LoadingSpinner className={styles.taskSpinner} />
        ) : (
          <div
            className={styles.regenerateContainer}
            onClick={handleRegenerate}
          >
            <SyncIcon color="white" width={18} height={18} />
            <p className={styles.pbutton}>regenerate</p>
          </div>
        ))}
    </div>
  );
}

export default TaskInput;
