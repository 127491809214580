import { stateAbbreviations } from 'constants/states';

export default function findState(name) {
  let stateFullObject = null;

  stateFullObject = stateAbbreviations[name];

  if (stateFullObject) {
    return stateFullObject;
  } else {
    return null;
  }
}
