import classNames from 'classnames';
import React, { forwardRef } from 'react';
import styles from './InvitationInput.module.scss';

const InvitationInput = forwardRef(
  (
    {
      name,
      defaultValue,
      value,
      placeholder,
      onChange,
      autoFocus,
      disabled,
      label,
      className,
      error,
      type,
      hideErrorMessage,
      required,
      onBlur,
      labelStyle,
      ...inputProps
    },
    ref
  ) => {
    return (
      <label
        className={classNames(styles.label, disabled && styles.disabledLabel)}
        style={labelStyle}
      >
        <p>{label}</p>
        <input
          ref={ref}
          type={type}
          name={name}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus={autoFocus}
          disabled={disabled}
          className={classNames(styles.styledInput, className && className)}
          {...inputProps}
        />
      </label>
    );
  }
);

export default InvitationInput;
