import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import styles from './AddPaymentMethodForm.module.scss';
import { ReactComponent as PaymentIcon } from 'assets/images/payment.svg';
import classNames from 'classnames';

import { ReactComponent as AmexIcon } from 'assets/images/bank-card-brand/amex.svg';
import { ReactComponent as VisaIcon } from 'assets/images/bank-card-brand/visa.svg';
import { ReactComponent as UnionIcon } from 'assets/images/bank-card-brand/union.svg';
import { ReactComponent as MasterIcon } from 'assets/images/bank-card-brand/master.svg';
import { ReactComponent as CartesIcon } from 'assets/images/bank-card-brand/cartes_bancaires.svg';
import { ReactComponent as JCBIcon } from 'assets/images/bank-card-brand/jcb.svg';
import { ReactComponent as DinersIcon } from 'assets/images/bank-card-brand/diners.svg';
import { useEffect } from 'react';

const STRIPE_ELEMENTS_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '#1e1e1e',
      fontFamily: 'Avenir Next',
      letterSpacing: '-0.5px',
      height: '1.5em',
      '::placeholder': {
        color: '#919191',
      },
    },
    invalid: {},
  },
};

const AddPaymentMethodForm = ({ totalAmount = 200, setInputInvalid }) => {
  const stripe = useStripe();
  const stripeElements = useElements();

  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
  const [isCardCvcValid, setIsCardCvcValid] = useState(false);
  const [brand, setBrand] = useState('unknown');

  useEffect(() => {
    setInputInvalid(
      !stripe ||
        !stripeElements ||
        !isCardNumberValid ||
        !isCardExpiryValid ||
        !isCardCvcValid
    );
  }, [
    stripe,
    isCardCvcValid,
    isCardNumberValid,
    isCardExpiryValid,
    stripeElements,
    setInputInvalid,
  ]);

  const renderCardBradIcon = () => {
    switch (brand) {
      case 'amex':
        return <AmexIcon />;
      case 'cartes_bancaires':
        return <CartesIcon />;
      case 'diners':
        return <DinersIcon />;
      case 'jcb':
        return <JCBIcon />;
      case 'mastercard':
        return <MasterIcon />;
      case 'visa':
        return <VisaIcon />;
      case 'unionpay':
        return <UnionIcon />;

      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p className={styles.title}>Payment amount</p>
        <div className={styles.amountWrapper}>
          <p>${totalAmount.toFixed(2)}</p>
        </div>
      </div>
      <div className={styles.paymentMethodContainer}>
        <div className={styles.inputWrapper}>
          <p className={styles.inputTitle}>Card Number</p>
          <PaymentIcon className={styles.cardIcon} />
          <CardNumberElement
            onChange={(e) => {
              setBrand(e.brand);
              setIsCardNumberValid(!e.error && e.complete);
            }}
            options={STRIPE_ELEMENTS_OPTIONS}
            className={styles.inputField}
          />
          <div className={styles.brandIconWrapper}>{renderCardBradIcon()}</div>
        </div>
        <div
          className={classNames(styles.inputWrapper, styles.smallInputWrapper)}
        >
          <p className={styles.inputTitle}>Expiry</p>
          <CardExpiryElement
            onChange={(e) => setIsCardExpiryValid(!e.error && e.complete)}
            options={STRIPE_ELEMENTS_OPTIONS}
            className={styles.smallInput}
          />
        </div>
        <div
          className={classNames(styles.inputWrapper, styles.smallInputWrapper)}
        >
          <p className={styles.inputTitle}>CVV / CVC</p>
          <CardCvcElement
            onChange={(e) => setIsCardCvcValid(!e.error && e.complete)}
            options={STRIPE_ELEMENTS_OPTIONS}
            className={styles.smallInput}
          />
        </div>
      </div>
    </div>
  );
};

export default AddPaymentMethodForm;
