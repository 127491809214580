import { defineMessages } from 'react-intl';

export const scope = 'global';

export default defineMessages({
  email: {
    id: `${scope}.validations.email`,
    defaultMessage: '{label} must be a valid email',
  },
  zipCode: {
    id: `${scope}.validations.zipCode`,
    defaultMessage: '{label} must be exactly 5 digits',
  },
  phone: {
    id: `${scope}.validations.phone`,
    defaultMessage: 'Phone number is not valid',
  },
  required: {
    id: `${scope}.validations.required`,
    defaultMessage: '{label} is required',
  },
  oneOf: {
    id: `${scope}.validations.one_of`,
    defaultMessage: '{label} must match with {value}',
  },
  integer: {
    id: `${scope}.validations.integer`,
    defaultMessage: '{label} must be a number',
  },
  areYouSure: {
    id: `${scope}.text.are_you_sure`,
    defaultMessage: 'Are you sure you want to delete this task?',
  },
  positive: {
    id: `${scope}.validations.positive`,
    defaultMessage: '{label} must be a positive number',
  },
  moreThan: {
    id: `${scope}.validations.more_than`,
    defaultMessage: '{label} value must be higher then previous field value',
  },
  min: {
    id: `${scope}.validations.min`,
    defaultMessage: '{label} must have at least {minValue} characters',
  },
  max: {
    id: `${scope}.validations.max`,
    defaultMessage: '{label} must have less than {maxValue} characters',
  },
  matches: {
    id: `${scope}.validations.matches`,
    defaultMessage: '{label} must contain uppercase, lowercase and digits',
  },
  zipCodeMatches: {
    id: `${scope}.validations.zipCodeMatches`,
    defaultMessage: '{label} must be only digits',
  },
  unique: {
    id: `${scope}.validations.unique`,
    defaultMessage: '{label} already taken',
  },
  sessionExpired: {
    id: `${scope}.session_expired`,
    defaultMessage: 'Your session has expired',
  },
  minNumber: {
    id: `${scope}.validations.min_number`,
    defaultMessage: '{label} must be at least {minValue}',
  },
  minDate: {
    id: `${scope}.validations.min_date`,
    defaultMessage:
      '{label} must be, at least, {value} {unit} {when} {dateField}',
  },
  maxDate: {
    id: `${scope}.validations.max_date`,
    defaultMessage:
      '{label} must be, at least, {value} {unit} {when} {dateField}',
  },
  arrayMax: {
    id: `${scope}.validations.array_max`,
    defaultMessage:
      'The maximum number of {label} labels can not be higher than {maxValue}',
  },
  somethingWentWrong: {
    id: `${scope}.errors.something_went_wrong`,
    defaultMessage: 'Something went wrong',
  },
  maxFileSize: {
    id: `${scope}.errors.maxFileSize`,
    defaultMessage: 'Max file size is ',
  },
  unsuportedFileFormat: {
    id: `${scope}.errors.unsuportedFileFormat`,
    defaultMessage: 'Unsuported File Format',
  },
  unauthorized: {
    id: `${scope}.errors.unauthorized`,
    defaultMessage: 'Unauthorized',
  },
  campaignSuccessfullyCreated: {
    id: `${scope}.text.campaign_successfully_created`,
    defaultMessage: 'Campaign is successfully created!',
  },
  messageSuccessfullySent: {
    id: `${scope}.text.message_successfully_sent`,
    defaultMessage: 'Message successfully sent!',
  },
  campaignSuccessfullyUpdated: {
    id: `${scope}.text.campaign_successfully_updated`,
    defaultMessage: 'Campaign is successfully updted!',
  },
  yourCampaignIsInReview: {
    id: `${scope}.text.your_campaign_is_in_review`,
    defaultMessage: 'Your Campaign is in review',
  },
  companyName: {
    id: `${scope}.text.company_name`,
    defaultMessage: 'Company name',
  },
  fullName: {
    id: `${scope}.text.full_name`,
    defaultMessage: 'Full name',
  },
  companyNameTooltip: {
    id: `${scope}.text.company_name_tooltip`,
    defaultMessage: "Enter your company's full business name",
  },
  fullNameTooltip: {
    id: `${scope}.text.full_name_tooltip`,
    defaultMessage: 'Enter your full name',
  },
  businessType: {
    id: `${scope}.text.business_type`,
    defaultMessage: 'Business legal type',
  },
  businessTypeTooltip: {
    id: `${scope}.text.business_type_tooltip`,
    defaultMessage: '"Select the correct legal type of your business"',
  },
  einNumber: {
    id: `${scope}.text.ein_number`,
    defaultMessage: 'EIN Number',
  },
  einNumberTooltip: {
    id: `${scope}.text.ein_number_tooltip`,
    defaultMessage: 'Enter your employer identification number.',
  },
  companyAddress: {
    id: `${scope}.text.company_address`,
    defaultMessage: 'Company address',
  },
  companyAddressTooltip: {
    id: `${scope}.text.company_address_tooltip`,
    defaultMessage: 'Enter the mailing address of your company.',
  },
  companyPhone: {
    id: `${scope}.text.company_phone`,
    defaultMessage: 'Phone Number',
  },
  companyPhoneTooltip: {
    id: `${scope}.text.company_phone_tooltip`,
    defaultMessage: '"Enter the main phone number for your company."',
  },
  contactPersonName: {
    id: `global.text.contact_person_name`,
    defaultMessage: 'Full name',
  },
  contactPersonNameTooltip: {
    id: `global.text.contact_person_name_tooltip`,
    defaultMessage:
      "Enter the name of the person who should be used as EyCrowd's contact within your business.",
  },
  businessContactInfoSubtitle: {
    id: `${scope}.text.business_contact_info_subtitle`,
    defaultMessage: 'Business point of contact',
  },
  businessTypes: {
    llc: {
      id: `${scope}.business_types.llc`,
      defaultMessage: 'LLC',
    },
    'c-corp': {
      id: `${scope}.business_types.c-corp`,
      defaultMessage: 'C-Corp',
    },
    's-corp': {
      id: `${scope}.business_types.s-corp`,
      defaultMessage: 'S-Corp',
    },
    partnership: {
      id: `${scope}.business_types.partnership`,
      defaultMessage: 'Partnership',
    },
    'sole-proprietorship': {
      id: `${scope}.business_types.sole-proprietorship`,
      defaultMessage: 'Sole Proprietorship',
    },
  },
  companyNamePlaceholder: {
    id: `${scope}.placeholder.companyName`,
    defaultMessage: 'Enter company name',
  },
  businessTypePlaceholder: {
    id: `${scope}.placeholder.business_type`,
    defaultMessage: 'Select business type',
  },
  einNumberPlaceholder: {
    id: `${scope}.placeholder.ein_number`,
    defaultMessage: 'Enter number',
  },
  companyAddressStreetInputLabel: {
    id: `${scope}.input_label.company_address_street`,
    defaultMessage: 'Street',
  },
  companyAddressStreetPlaceholder: {
    id: `${scope}.placeholder.company_address_street`,
    defaultMessage: 'Street',
  },
  companyAddressCityInputLabel: {
    id: `${scope}.input_label.company_address_city`,
    defaultMessage: 'City',
  },
  companyAddressCityPlaceholder: {
    id: `${scope}.placeholder.company_address_city`,
    defaultMessage: 'City',
  },
  companyAddressCountryInputLabel: {
    id: `${scope}.input_label.company_address_country`,
    defaultMessage: 'Country',
  },
  companyAddressCountryPlaceholder: {
    id: `${scope}.placeholder.company_address_country`,
    defaultMessage: 'Country',
  },
  companyAddressStateInputLabel: {
    id: `${scope}.input_label.company_address_state`,
    defaultMessage: 'State',
  },
  companyAddressStatePlaceholder: {
    id: `${scope}.placeholder.company_address_state`,
    defaultMessage: 'State',
  },
  taskDeleted: {
    id: `${scope}.text.task_deleted`,
    defaultMessage: 'Task successfully deleted',
  },
  areYouSureDeleteSocialAccount: {
    id: `${scope}.areYouSureDeleteSocialAccount`,
    defaultMessage: 'Are you sure you want to delete social account?',
  },
  socialAccountDeleted: {
    id: `${scope}.text.social_account_deleted`,
    defaultMessage: 'Social account successfully deleted',
  },
  socialAccountAdded: {
    id: `${scope}.text.social_account_deleted`,
    defaultMessage: 'Social account successfully added',
  },
  socialAccountUpdated: {
    id: `${scope}.text.social_account_updated`,
    defaultMessage: 'Social account successfully updated',
  },
  companyAddressZipInputLabel: {
    id: `${scope}.input_label.company_address_zip`,
    defaultMessage: 'ZIP',
  },
  companyAddressZipPlaceholder: {
    id: `${scope}.placeholder.company_address_zip`,
    defaultMessage: 'ZIP',
  },
  companyPhonePlaceholder: {
    id: `${scope}.placeholder.company_phone`,
    defaultMessage: 'Enter phone number',
  },
  contactPersonNamePlaceholder: {
    id: `${scope}.placeholder.contact_person_name`,
    defaultMessage: 'Enter name',
  },
  contactPersonPhoneInputLabel: {
    id: `${scope}.input_label.contact_person_phone`,
    defaultMessage: 'Mobile phone number',
  },
  contactPersonPhoneTooltip: {
    id: `${scope}.input_label.contact_person_phone_tooltip`,
    defaultMessage:
      "Enter the phone number of your business's main point of contact.",
  },
  contactPersonPhonePlaceholder: {
    id: `${scope}.placeholder.contact_person_phone`,
    defaultMessage: 'Enter number',
  },
  return: {
    id: `${scope}.link.return`,
    defaultMessage: 'Return',
  },
  'in-person': {
    id: `${scope}.text.in-person`,
    defaultMessage: 'In-Person',
  },
  digital: {
    id: `${scope}.text.digital`,
    defaultMessage: 'Digital',
  },
  hybrid: {
    id: `${scope}.text.hybrid`,
    defaultMessage: 'Hybrid',
  },
  all_ages: {
    id: `${scope}.text.all_ages`,
    defaultMessage: 'All Ages',
  },
  'age_range_18-24': {
    id: `${scope}.text.age_range_18-24`,
    defaultMessage: '18-24',
  },
  'age_range_25-34': {
    id: `${scope}.age_range_25-34`,
    defaultMessage: '25-34',
  },
  'age_range_35-44': {
    id: `${scope}.age_range_35-44`,
    defaultMessage: '35-44',
  },
  'age_range_45-49': {
    id: `${scope}.age_range_45-49`,
    defaultMessage: '45-49',
  },
  'age_range_50-54': {
    id: `${scope}.age_range_50-54`,
    defaultMessage: '50-54',
  },
  'age_range_55-64': {
    id: `${scope}.age_range_55-64`,
    defaultMessage: '55-64',
  },
  'age_range_65-above': {
    id: `${scope}.text.age_range_65-above`,
    defaultMessage: '65+',
  },
  'all-genders': {
    id: `${scope}.text.all_genders`,
    defaultMessage: 'All Genders',
  },
  'mostly-custom': {
    id: `${scope}.text.mostly_custom`,
    defaultMessage: 'Mostly Custom',
  },
  'mostly-female': {
    id: `${scope}.text.mostly_female`,
    defaultMessage: 'Mostly Female',
  },
  'mostly-male': {
    id: `${scope}.text.mostly_male`,
    defaultMessage: 'Mostly Male',
  },
  custom: {
    id: `${scope}.text.only_custom`,
    defaultMessage: 'Only Custom',
  },
  female: {
    id: `${scope}.text.only_female`,
    defaultMessage: 'Only Female',
  },
  male: {
    id: `${scope}.text.only_male`,
    defaultMessage: 'Only Male',
  },
  interests: {
    'all-interests': {
      id: `${scope}.text.all-interests`,
      defaultMessage: 'All',
    },
    gaming: {
      id: `${scope}.text.gaming`,
      defaultMessage: 'Gaming',
    },
    fashion: {
      id: `${scope}.text.fashion`,
      defaultMessage: 'Fashion',
    },
    music: {
      id: `${scope}.text.music`,
      defaultMessage: 'Music',
    },
    beauty: {
      id: `${scope}.text.beauty`,
      defaultMessage: 'Beauty',
    },
    movies: {
      id: `${scope}.text.movies`,
      defaultMessage: 'Movies',
    },
    tech: {
      id: `${scope}.text.tech`,
      defaultMessage: 'Tech',
    },
    photography: {
      id: `${scope}.text.photography`,
      defaultMessage: 'Photography',
    },
    'social-media': {
      id: `${scope}.text.social-media`,
      defaultMessage: 'Social media',
    },
    automotive: {
      id: `${scope}.text.automotive`,
      defaultMessage: 'Automotive',
    },
    lifestyle: {
      id: `${scope}.text.lifestyle`,
      defaultMessage: 'Lifestyle',
    },
    'home-and-garden': {
      id: `${scope}.text.home-and-garden`,
      defaultMessage: 'Home and garden',
    },
    'food-and-drink': {
      id: `${scope}.text.food-and-drink`,
      defaultMessage: 'Food and drink',
    },
    'pets-and-animals': {
      id: `${scope}.text.pets-and-animals`,
      defaultMessage: 'Pets and animals',
    },
    outdoors: {
      id: `${scope}.text.outdoors`,
      defaultMessage: 'Outdoors',
    },
    'arts-and-crafts': {
      id: `${scope}.text.arts-and-crafts`,
      defaultMessage: 'Arts and crafts',
    },
    sports: {
      id: `${scope}.text.sports`,
      defaultMessage: 'Sports',
    },
    cooking: {
      id: `${scope}.text.cooking`,
      defaultMessage: 'Cooking',
    },
  },
  disabilities: {
    none: {
      id: `${scope}.text.none-disability`,
      defaultMessage: 'None',
    },
    'learning-disability': {
      id: `${scope}.text.learning-disability`,
      defaultMessage: 'Learning disability',
    },
    'mobility-disability': {
      id: `${scope}.text.mobility-disability`,
      defaultMessage: 'Mobility disability',
    },
    'medical-disability': {
      id: `${scope}.text.medical-disability`,
      defaultMessage: 'Medical disability',
    },
    'psychiatric-disability': {
      id: `${scope}.text.psychiatric-disability`,
      defaultMessage: 'Psychiatric disability',
    },
    'visual-impairments': {
      id: `${scope}.text.visual-impairments`,
      defaultMessage: 'Visual impairments',
    },
    'hearing-disability': {
      id: `${scope}.text.hearing-disability`,
      defaultMessage: 'Deaf and hard of hearing',
    },
    'autism-spectrum-disorder': {
      id: `${scope}.text.autism-spectrum-disorder`,
      defaultMessage: 'Autism spectrum disorders',
    },
  },
  groups: {
    id: `${scope}.text.groups`,
    defaultMessage: 'Groups',
  },
  individual: {
    id: `${scope}.text.individual`,
    defaultMessage: 'Individual',
  },
  random: {
    id: `${scope}.text.random`,
    defaultMessage: 'Random',
  },
  supervisorTasks: {
    id: `${scope}.text.supervisor_tasks`,
    defaultMessage: 'Supervisor Tasks',
  },
  step: {
    id: `${scope}.text.step`,
    defaultMessage: 'Step',
  },
  paymentProviderUnavailable: {
    id: `${scope}.text.payment_provider_unavailable`,
    defaultMessage: 'Payment provider currently unavailable. Try again later.',
  },
  campaignType: {
    id: `${scope}.text.campaign_type`,
    defaultMessage: 'Type',
  },
  campaignName: {
    id: `${scope}.text.camapign_name`,
    defaultMessage: 'Campaign Name',
  },
  startDate: {
    id: `${scope}.text.start_date`,
    defaultMessage: 'Start Date',
  },
  startTime: {
    id: `${scope}.text.start_time`,
    defaultMessage: 'Start Time',
  },
  endDate: {
    id: `${scope}.text.end_date`,
    defaultMessage: 'End Date fads',
  },
  endTime: {
    id: `${scope}.text.end_time`,
    defaultMessage: 'End Time',
  },
  duration: {
    id: `${scope}.text.duration`,
    defaultMessage: 'Duration',
  },
  eyvocates: {
    id: `${scope}.text.eyvocates`,
    defaultMessage: 'EyVocates',
  },
  totalPrice: {
    id: `${scope}.text.total_price`,
    defaultMessage: 'Total Price',
  },
  status: {
    id: `${scope}.text.status`,
    defaultMessage: 'Status',
  },
  actions: {
    id: `${scope}.text.actions`,
    defaultMessage: 'Actions',
  },
  noData: {
    id: `${scope}.text.no_data`,
    defaultMessage: 'No campaigns',
  },
  noEyvisorsOnThisCampaign: {
    id: `${scope}.text.no_eyvisors_On_This_Campaign`,
    defaultMessage: 'No EyVisors on this campaign!',
  },
  uploadLogo: {
    id: `${scope}.text.upload_logo`,
    defaultMessage: 'Upload company logo',
  },
  payment: {
    visa: {
      id: `${scope}.text.visa`,
      defaultMessage: 'Visa',
    },
    mastercard: {
      id: `${scope}.text.mastercard`,
      defaultMessage: 'MasterCard',
    },
  },
  verifyAccountSuccessfully: {
    id: `${scope}.text.verificationCompleted`,
    defaultMessage: 'Verification completed',
  },
  verifyAccountFailed: {
    id: `${scope}.text.verificationError`,
    defaultMessage: 'Verification failed',
  },
  tasks: {
    preCampaignTasks: {
      id: `${scope}.pre_campaign_task_tooltip`,
      defaultMessage:
        'These tasks must be completed prior to the campaign starting.',
    },
    liveCampaignTasks: {
      id: `${scope}.live_campaign_task_tooltip`,
      defaultMessage:
        'These tasks must be completed while the campaign is live.',
    },
    followUpTasks: {
      id: `${scope}.follow_up_task_tooltip`,
      defaultMessage:
        'These tasks must be completed after the campaign is over.',
    },
  },
  daysUnit: {
    id: `${scope}.text.days_unit`,
    defaultMessage: 'days',
  },
  hoursUnit: {
    id: `${scope}.text.hours_unit`,
    defaultMessage: 'hours',
  },
  minutesUnit: {
    id: `${scope}.text.minutes_unit`,
    defaultMessage: 'minutes',
  },
  today: {
    id: `${scope}.text.today`,
    defaultMessage: 'today',
  },
  now: {
    id: `${scope}.text.now`,
    defaultMessage: 'now',
  },
  before: {
    id: `${scope}.text.before`,
    defaultMessage: 'before',
  },
  after: {
    id: `${scope}.text.after`,
    defaultMessage: 'after',
  },
  from: {
    id: `${scope}.text.from`,
    defaultMessage: 'from',
  },
  confirmationModal: {
    confirm: {
      id: `${scope}.button.confirm`,
      defaultMessage: 'Confirm',
    },
    cancel: {
      id: `${scope}.button.cancel`,
      defaultMessage: 'Cancel',
    },
  },
  optional: {
    id: `${scope}.text.optional`,
    defaultMessage: 'Optional',
  },
  numberOfEyVocates: {
    id: `${scope}.input_label.number_of_eyvocates`,
    defaultMessage: 'Number of EyVocates',
  },
  numberOfEyVocatesTooltip: {
    id: `${scope}.input_label.number_of_eyvocates_tooltip`,
    defaultMessage:
      "Choose the range of EyVocates you'd like to participate in your campaign.",
  },
  pricePerHour: {
    id: `${scope}.input_label.price_per_hour`,
    defaultMessage: 'Price per EyVocate per hour',
  },
  pricePerHourTooltip: {
    id: `${scope}.input_label.price_per_hour_tooltip`,
    defaultMessage:
      "Choose the monetary compensation you'd like your EyVocates to receive at the end of the campaign.",
  },
  campaignDuration: {
    id: `${scope}.input_label.campaing_duration`,
    defaultMessage: 'Campaign Duration',
  },
  campaignEndsTooltip: {
    id: `${scope}.input_label.campaing_ends_tooltip`,
    defaultMessage: 'Choose the duration for your new campaign.',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear',
  },
  verificationLinkSent: {
    id: `${scope}.verification_link_sent`,
    defaultMessage: 'Verification link sent. Check your email.',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  paymentAddedSuccessMessage: {
    id: `${scope}.payment.added.success.message`,
    defaultMessage: 'Successfully added payment method',
  },
  paymentDeletedSuccessMessage: {
    id: `${scope}.payment.delete.success.message`,
    defaultMessage: 'You have successfully removed your payment method',
  },
  paymentSetDefaultSuccessMessage: {
    id: `${scope}.payment.set.default.success.message`,
    defaultMessage: 'You have successfully set new default payment method',
  },
  signUpText: {
    id: `${scope}.sign.up.text`,
    defaultMessage: `Sign Up`,
  },
  signUpDescriptionMessage: {
    id: `${scope}.sign.up.description.message`,
    defaultMessage: `Don't have an account? {br}Sign up for EyCrowd Campaign Manager`,
  },
  signInText: {
    id: `${scope}.sign.in.text`,
    defaultMessage: `Sign In`,
  },
  signInDescriptionMessage: {
    id: `${scope}.sign.in.description.message`,
    defaultMessage: `Already have an account? {br}Sign in EyCrowd Campaign Manager`,
  },
  partnerProgramApplicationSuccess: {
    id: `${scope}.partner.program.application.success`,
    defaultMessage: 'Your partner application has been successfully approved',
  },
});
