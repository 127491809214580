export const APP = '[App]';
export const CAMPAIGN_FORM = '[CampaignForm]';

export const FETCH_AUTHENTICATED_ADVERTISER_REQUEST = `${APP} FETCH_AUTHENTICATED_ADVERTISER_REQUEST`;
export const FETCH_AUTHENTICATED_ADVERTISER_SUCCESS = `${APP} FETCH_AUTHENTICATED_ADVERTISER_SUCCESS`;
export const LOGOUT_REQUEST = `${APP} LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${APP} LOGOUT_SUCCESS`;
export const SET_TOKEN = `${APP} SET_TOKEN`;
export const SESSION_EXPIRED = `${APP} SESSION_EXPIRED`;

export const FETCH_CAMPAIGN_COMMON_DATA_REQUEST = `${CAMPAIGN_FORM} FETCH_CAMPAIGN_COMMON_DATA_REQUEST`;
export const FETCH_CAMPAIGN_COMMON_DATA_SUCCESS = `${CAMPAIGN_FORM} FETCH_CAMPAIGN_COMMON_DATA_SUCCESS`;

export const FETCH_COMMON_DATA_REQUEST = `${CAMPAIGN_FORM} FETCH_COMMON_DATA_REQUEST`;
export const FETCH_COMMON_DATA_SUCCESS = `${CAMPAIGN_FORM} FETCH_COMMON_DATA_SUCCESS`;

export const FETCH_EYVOCATE_LOCATIONS_ATTEMPT = `${CAMPAIGN_FORM} FETCH_EYVOCATE_LOCATIONS_ATTEMPT`;
export const FETCH_EYVOCATE_LOCATIONS_SUCCESS = `${CAMPAIGN_FORM} FETCH_EYVOCATE_LOCATIONS_SUCCESS`;
export const FETCH_EYVOCATE_LOCATIONS_ERROR = `${CAMPAIGN_FORM} FETCH_EYVOCATE_LOCATIONS_ERROR`;

export const CREATE_CAMPAIGN_REQUEST = `${CAMPAIGN_FORM} CREATE_CAMPAIGN_REQUEST`;
export const CREATE_CAMPAIGN_SUCCESS = `${CAMPAIGN_FORM} CREATE_CAMPAIGN_SUCCESS`;
export const CREATE_CAMPAIGN_ERROR = `${CAMPAIGN_FORM} CREATE_CAMPAIGN_ERROR`;
export const SET_DISABLE_PUBLISH = `${CAMPAIGN_FORM} SET_DISABLE_PUBLISH_TRUE`;

export const FETCH_PAYMENT_METHODS_REQUEST = `${APP} FETCH_PAYMENT_METHODS_REQUEST`;
export const FETCH_PAYMENT_METHODS_SUCCESS = `${APP} FETCH_PAYMENT_METHODS_SUCCESS`;

export const ADD_PAYMENT_METHOD_REQUEST = `${APP} ADD_PAYMENT_METHOD_REQUEST`;
export const ADD_PAYMENT_METHOD_SUCCESS = `${APP} ADD_PAYMENT_METHOD_SUCCESS`;
export const ADD_PAYMENT_METHOD_ERROR = `${APP} ADD_PAYMENT_METHOD_ERROR`;

export const SET_DEFAULT_PAYMENT_METHOD_REQUEST = `${APP} SET_DEFAULT_PAYMENT_METHOD_REQUEST`;
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS = `${APP} SET_DEFAULT_PAYMENT_METHOD_SUCCESS`;
export const SET_DEFAULT_PAYMENT_METHOD_ERROR = `${APP} SET_DEFAULT_PAYMENT_METHOD_ERROR`;

export const DELETE_PAYMENT_METHOD_REQUEST = `${APP} DELETE_PAYMENT_METHOD_REQUEST`;
export const DELETE_PAYMENT_METHOD_SUCCESS = `${APP} DELETE_PAYMENT_METHOD_SUCCESS`;
export const DELETE_PAYMENT_METHOD_ERROR = `${APP} DELETE_PAYMENT_METHOD_ERROR`;
export const VERIFY_ACCOUNT_REQUEST = `${APP} VERIFY_ACCOUNT_REQUEST`;
export const VERIFY_ACCOUNT_SUCCESS = `${APP} VERIFY_ACCOUNT_SUCCESS`;
export const VERIFY_ACCOUNT_ERROR = `${APP} VERIFY_ACCOUNT_ERROR`;

export const SET_VERIFICATION_TOKEN = `${APP} SET_VERIFICATION_TOKEN`;

export const MOBILE_TRUE = `${APP} SET_MOBILE_TRUE_WINDOW`;
export const MOBILE_FALSE = `${APP} SET_MOBILE_FALSE_WINDOW`;

export const RESEND_VERIFICATION_LINK = `${APP} RESEND_VERIFICATION_LINK`;

export const UPDATE_COMPANY_INFO_REQUEST = `${CAMPAIGN_FORM} UPDATE_COMPANY_INFO_REQUEST`;
export const UPDATE_COMPANY_INFO_SUCCESS = `${CAMPAIGN_FORM} UPDATE_COMPANY_INFO_SUCCESS`;
export const UPDATE_COMPANY_INFO_ERROR = `${CAMPAIGN_FORM} UPDATE_COMPANY_INFO_ERROR`;
export const UPDATE_COMPANY_INFO_RESET = `${CAMPAIGN_FORM} UPDATE_COMPANY_INFO_RESET`;

export const ADD_SOCIAL_ACCOUNT_REQUEST = `${CAMPAIGN_FORM} ADD_SOCIAL_ACCOUNT_REQUEST`;
export const ADD_SOCIAL_ACCOUNT_SUCCESS = `${CAMPAIGN_FORM} ADD_SOCIAL_ACCOUNT_SUCCESS`;
export const ADD_SOCIAL_ACCOUNT_ERROR = `${CAMPAIGN_FORM} ADD_SOCIAL_ACCOUNT_ERROR`;
export const ADD_SOCIAL_ACCOUNT_RESET = `${CAMPAIGN_FORM} ADD_SOCIAL_ACCOUNT_RESET`;
export const UPDATE_SOCIAL_ACCOUNT_REQUEST = `${CAMPAIGN_FORM} UPDATE_SOCIAL_ACCOUNT_REQUEST`;
export const UPDATE_SOCIAL_ACCOUNT_SUCCESS = `${CAMPAIGN_FORM} UPDATE_SOCIAL_ACCOUNT_SUCCESS`;
export const UPDATE_SOCIAL_ACCOUNT_RESET = `${CAMPAIGN_FORM} UPDATE_SOCIAL_ACCOUNT_RESET`;

export const VERIFY_SOCIAL_ACCOUNT_REQUEST = `${CAMPAIGN_FORM} VERIFY_SOCIAL_ACCOUNT_REQUEST`;
export const VERIFY_SOCIAL_ACCOUNT_SUCCESS = `${CAMPAIGN_FORM} VERIFY_SOCIAL_ACCOUNT_SUCCESS`;
export const VERIFY_SOCIAL_ACCOUNT_RESET = `${CAMPAIGN_FORM} VERIFY_SOCIAL_ACCOUNT_RESET`;

export const DELETE_TASK_TEMPLATE_REQUEST = `${CAMPAIGN_FORM} DELETE_TASK_TEMPLATE_REQUEST`;
export const DELETE_TASK_TEMPLATE_SUCCESS = `${CAMPAIGN_FORM} DELETE_TASK_TEMPLATE_SUCCESS`;

export const FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ATTEMPT = `${CAMPAIGN_FORM} FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ATTEMPT`;
export const FETCH_ADVERTISER_SOCIAL_ACCOUNTS_SUCCESS = `${CAMPAIGN_FORM} FETCH_ADVERTISER_SOCIAL_ACCOUNTS_SUCCESS`;
export const FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ERROR = `${CAMPAIGN_FORM} FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ERROR`;

export const DELETE_SOCIAL_ACCOUNT_REQUEST = `${CAMPAIGN_FORM} DELETE_SOCIAL_ACCOUNT_REQUEST`;
export const DELETE_SOCIAL_ACCOUNT_SUCCESS = `${CAMPAIGN_FORM} DELETE_SOCIAL_ACCOUNT_SUCCESS`;
export const DELETE_SOCIAL_ACCOUNT_RESET = `${CAMPAIGN_FORM} DELETE_SOCIAL_ACCOUNT_RESET`;

export const FETCH_CAMPAIGN_TEMPLATES_REQUEST = `${CAMPAIGN_FORM} FETCH_CAMPAIGN_TEMPLATES_REQUEST`;
export const FETCH_CAMPAIGN_TEMPLATES_SUCCESS = `${CAMPAIGN_FORM} FETCH_CAMPAIGN_TEMPLATES_SUCCESS`;

export const APPLY_TO_PARTNER_PROGRAM_REQUEST = `${APP} APPLY_TO_PARTNER_PROGRAM_REQUEST`;
export const APPLY_TO_PARTNER_PROGRAM_SUCCESS = `${APP} APPLY_TO_PARTNER_PROGRAM_SUCCESS`;
export const APPLY_TO_PARTNER_PROGRAM_ERROR = `${APP} APPLY_TO_PARTNER_PROGRAM_ERROR`;
export const APPLY_TO_PARTNER_PROGRAM_RESET = `${APP} APPLY_TO_PARTNER_PROGRAM_RESET`;
