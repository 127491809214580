import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import styles from './ObjectiveCategories.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as DeleteBlackIcon } from 'assets/images/black_close_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandStoryRequest } from 'containers/NewExperienceCampaignBuilderPage/actions';
import { makeSelectGetBrandStoryPending } from 'containers/NewExperienceCampaignBuilderPage/selectors';
import Input from '../Input';
import { makeSelectEyVocateLocations } from 'containers/App/selectors';
import { fetchEyVocateLocations } from 'containers/App/actions';
import _ from 'lodash';
import SearchInput from '../SearchInput';
import classNames from 'classnames';
import ErrorContainer from '../ErrorContainer';
import CheckBox from '../CheckBox';
import Tooltip from 'components/Tooltip';
import RadioButton from '../RadioButton';
import CampaignLocation from 'components/ECWCreateCampaign/ECWCampaignLocation';
import { BASIC_EXPERIENCE_STEP } from '../WelcomeStep';

const campaignBasicInfoSchema = yup
  .object({
    businessName: yup.string().trim().required('Business name is required'),
    businessWebsite: yup
      .string()
      .trim()
      .required('Business website is required'),
    locations: yup.array().when('inPerson', {
      is: false,
      then: yup.array().min(1, 'At least one location must be selected'),
    }),
    hasMapLocation: yup.bool().when('inPerson', {
      is: true,
      then: yup
        .bool()
        .required('Location must be selected')
        .oneOf([true], 'Location must be selected'),
    }),
  })
  .required();

const ObjectiveCategories = forwardRef(
  (
    {
      setStep,
      setLoadingBtn,
      setBrandStory,
      setBrandWebsite,
      storyBrandFormData,
      setStoryBrandFormData,
      onFieldsFilledChange,
      setUserLocations,
      polygon,
      setPolygon,
      location,
      setLocation,
      circle,
      setCircle,
      setInPerson,
      basicExperience,
      importedCampaign,
      setSelectedMode,
      setSelectedMetatag,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const getBrandStoryPending = useSelector(makeSelectGetBrandStoryPending());
    const [locationsSearch, setLocationsSearch] = useState('');
    const selectedEyVocateLocations = useSelector(
      makeSelectEyVocateLocations()
    );

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
      watch,
      control,
    } = useForm({
      defaultValues: {
        businessName: '',
        businessWebsite: '',
        locations: [],
        inPerson: true,
        hasMapLocation: false,
      },
      delayError: 300,
      resolver: yupResolver(campaignBasicInfoSchema),
    });

    useEffect(() => {
      if (basicExperience) {
        if (BASIC_EXPERIENCE_STEP.ONLINE_INTERACTIONS === basicExperience) {
          setValue('inPerson', false);
        }
      }
    }, [basicExperience, setValue]);

    useEffect(() => {
      if (importedCampaign) {
        setValue('businessName', importedCampaign.brandName);
        setValue('businessWebsite', importedCampaign.website);
        setValue('locations', [{ id: 'planet-earth', label: 'Global' }]);
        setValue('inPerson', false);
      }
    }, [importedCampaign, setValue]);

    const businessNameWatch = watch('businessName');
    const businessWebsiteWatch = watch('businessWebsite');
    const selectedLocations = watch('locations');
    const inPersonWatch = watch('inPerson');

    useEffect(() => {
      const allFieldsFilled =
        businessNameWatch &&
        businessNameWatch !== '' &&
        businessWebsiteWatch &&
        businessWebsiteWatch !== '' &&
        selectedLocations &&
        selectedLocations.length === 0;

      onFieldsFilledChange(allFieldsFilled);
    }, [
      selectedLocations,
      businessNameWatch,
      businessWebsiteWatch,
      onFieldsFilledChange,
    ]);

    useEffect(() => {
      setLoadingBtn(getBrandStoryPending);
    }, [getBrandStoryPending, setLoadingBtn]);

    useImperativeHandle(ref, () => ({
      handleFormSubmit() {
        handleSubmit(onSubmit)();
      },
    }));

    const locationOptions = [
      { id: 'planet-earth', label: 'Global' },
      { id: 'ChIJCzYy5IS16lQRQrfeQ5K5Oxw', label: 'United States' },
      { id: 'ChIJPV4oX_65j4ARVW8IJ6IJUYs', label: 'California' },
      { id: 'ChIJE9on3F3HwoAR9AhGJW_fL-I', label: 'Los Angeles, CA' },
      { id: 'ChIJIQBpAG2ahYAR_6128GcTUEo', label: 'San Francisco, CA' },
    ];

    const changeHandler = useCallback(
      (text) => {
        if (text !== '') {
          dispatch(fetchEyVocateLocations(text));
        }
      },
      [dispatch]
    );

    const debouncedChangeHandler = useMemo(
      () => _.debounce(changeHandler, 300),
      [changeHandler]
    );

    const locations = useMemo(() => {
      if (selectedEyVocateLocations?.length && locationsSearch !== '') {
        return selectedEyVocateLocations?.map((eyVocateLocation) => {
          return {
            id: eyVocateLocation.externalPlaceId,
            label: eyVocateLocation.description,
          };
        });
      } else {
        return locationOptions;
      }
    }, [selectedEyVocateLocations, locationOptions, locationsSearch]);

    const cancelLocationSearch = () => {
      setLocationsSearch('');
    };

    useEffect(() => {
      if (storyBrandFormData) {
        setValue('businessName', storyBrandFormData.businessName);
        setValue('businessWebsite', storyBrandFormData.businessWebsite);
        setValue('locations', storyBrandFormData.locations);
      }
    }, [storyBrandFormData, setValue]);

    const onSubmit = (data) => {
      const { businessName, businessWebsite, locations, inPerson } = data;

      if (importedCampaign) {
        setBrandWebsite(businessWebsite);
        setStep((step) => step + 1);
        setInPerson(inPerson);
        setUserLocations((userLocations) => [
          ...userLocations,
          ...locations.map((location) => location),
        ]);
        setBrandStory(importedCampaign.brandStory);
        setSelectedMode('CATALOG');
        setSelectedMetatag(null);
      } else {
        const bodyObj = {
          brandName: businessName,
          url: businessWebsite,
          location: locations.map((v) => v.label).join(', '),
        };
        dispatch(
          getBrandStoryRequest(
            bodyObj,
            (brandStory) => {
              setStoryBrandFormData({
                businessName,
                businessWebsite,
                locations,
              });
              setBrandStory(brandStory);
              setBrandWebsite(businessWebsite);
              setStep((step) => step + 1);
              setInPerson(inPerson);
              setUserLocations((userLocations) => [
                ...userLocations,
                ...locations.map((location) => location),
              ]);
            },
            () => {}
          )
        );
      }
    };

    const handleClearInputForField = (fieldName) => {
      setValue(fieldName, '');
    };

    return (
      <div className={styles.contentContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formRow}>
            <p className={styles.text}>Tell us about your brand...</p>
          </div>
          <div className={styles.formRow}>
            <div className={styles.leftContainer}>
              <p className={styles.textLeft}>Brand/business name:*</p>
              <Tooltip
                className={styles.tooltip}
                label={`businessName`}
                customContent={
                  <p>
                    Enter the brand name your customers recognize for your
                    business.
                  </p>
                }
              />
            </div>
            <Input
              placeholder="enter name of business"
              {...register('businessName')}
              error={errors.businessName?.message}
              rightIcon={<DeleteBlackIcon />}
              handleClearInput={() => handleClearInputForField('businessName')}
              value={businessNameWatch}
            />
          </div>

          <div className={styles.formRow}>
            <div className={styles.leftContainer}>
              <p className={styles.textLeft}>
                Website and Social URLs (web, e-commerce, social media, etc):*
              </p>
              <Tooltip
                className={styles.tooltip}
                label={`businessWebsite`}
                customContent={
                  <p>
                    Enter the URLs where your customers find your brand, such as
                    your website, social media pages, or online stores.
                  </p>
                }
              />
            </div>
            <Input
              type={'textarea'}
              placeholder="enter one or more brand URLs https://"
              {...register('businessWebsite')}
              error={errors.businessWebsite?.message}
              rightIcon={<DeleteBlackIcon />}
              handleClearInput={() =>
                handleClearInputForField('businessWebsite')
              }
              value={businessWebsiteWatch}
            />
          </div>
          <div
            className={classNames(
              styles.formRow,
              basicExperience && styles.disabledFormRow
            )}
          >
            <div className={styles.leftContainer}>
              <p className={styles.textLeft}>Brand Experience Type:</p>
              <Tooltip
                className={styles.tooltip}
                label={`experienceType`}
                customContent={<p>lorem ipsum</p>}
              />
            </div>
            <div style={{ width: '100%' }}>
              <Controller
                name="inPerson"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.radioButtonContainer}>
                      <RadioButton
                        title="In-Person: Geofenced Experience"
                        checked={field.value}
                        onClick={() => !basicExperience && field.onChange(true)}
                      />
                      <RadioButton
                        title="Online: Online Experience"
                        checked={!field.value}
                        onClick={() =>
                          !basicExperience && field.onChange(false)
                        }
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
          {inPersonWatch ? (
            <div className={styles.formRow} style={{ flexDirection: 'column' }}>
              <CampaignLocation
                polygon={polygon ? polygon : []}
                location={location}
                setLocation={setLocation}
                circle={circle}
                setCircle={setCircle}
                handlePolygonDrawn={(value) => setPolygon(value)}
                handleValidation={() => {}}
                setValue={setValue}
              />
              <ErrorContainer errorText={errors.hasMapLocation?.message} />
            </div>
          ) : (
            <div className={styles.formRow}>
              <div className={styles.leftContainer}>
                <p className={styles.textLeft}>
                  Brand Experience Area (where your customers and attendees
                  live):*
                </p>
                <Tooltip
                  className={styles.tooltip}
                  label={`locations`}
                  customContent={
                    <p>
                      Include the areas where your devoted customers and brand
                      enthusiasts, who will participate in your EyCrowd app
                      experience, are located. It’s recommended to specify a
                      broader region rather than a narrow one.
                    </p>
                  }
                />
              </div>
              <div style={{ width: '100%' }}>
                <SearchInput
                  placeholder="search locations"
                  onChange={(event) => {
                    setLocationsSearch(event.target.value);
                    debouncedChangeHandler(event.target.value);
                  }}
                  onCancelClick={cancelLocationSearch}
                  value={locationsSearch}
                />
                <div
                  className={classNames(
                    styles.searchContainer,
                    styles.locationsSearch
                  )}
                >
                  {locations.map((location) => {
                    return (
                      <Controller
                        name="locations"
                        control={control}
                        key={location.id}
                        render={({ field }) => (
                          <CheckBox
                            onChange={() => {
                              const ind = field.value.findIndex(
                                (value) => value.id === location.id
                              );

                              const newValues = [...field.value];
                              if (ind !== -1) {
                                newValues.splice(ind, 1);
                                field.onChange(newValues);
                              } else {
                                field.onChange([...field.value, location]);
                              }
                            }}
                            checked={
                              !!field.value.find(
                                (value) => value.id === location.id
                              )
                            }
                          >
                            {location.label}
                          </CheckBox>
                        )}
                      />
                    );
                  })}
                </div>
                <div className={styles.customHorizontalLine}></div>
                <div>
                  Selected Locations:{' '}
                  {selectedLocations.map((v) => v.label).join(', ')}
                </div>
                <div className={styles.line} />
                <ErrorContainer errorText={errors.locations?.message} />
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
);

export default ObjectiveCategories;
