import { defineMessages } from 'react-intl';

export const scope = 'campaign_location';

export default defineMessages({
  timeTooltip: {
    id: `${scope}.text.time_tooltip`,
    defaultMessage: 'This is the total time commitment per EyVocate.',
  },
  reset: {
    id: `${scope}.button.reset`,
    defaultMessage: 'Reset',
  },
  draw: {
    id: `${scope}.button.draw`,
    defaultMessage: 'Draw',
  },
  location: {
    id: `${scope}.input_label.location`,
    defaultMessage: 'Geofence Location',
  },
  locationPlaceholder: {
    id: `${scope}.placeholder.location_placeholder`,
    defaultMessage: 'Enter City name',
  },
  titleLocation: {
    id: `${scope}.text.title_location`,
    defaultMessage: 'Campaign Geofence Location',
  },
  locationTooltip: {
    id: `${scope}.text.tooltip_location`,
    defaultMessage:
      'Here you can use the ‘Draw’ button to create a virtual perimeter around the location of your campaign. Try it out!',
  },
});
