import * as Yup from 'yup';
import messages from '../messages';

Yup.setLocale({
  mixed: {
    required: messages.required,
    oneOf: messages.oneOf,
  },
  number: {
    integer: messages.integer,
    positive: messages.positive,
    moreThan: messages.moreThan,
    min: messages.minNumber,
  },
  date: {
    min: messages.minDate,
    max: messages.maxDate,
  },
  string: {
    email: messages.email,
    min: messages.min,
    max: messages.max,
    matches: messages.matches,
  },
  array: {
    max: messages.arrayMax,
  },
});

export default Yup;

export function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}
