export const NEW_EXPERIENCE_CAMPAIGN_BUILDER = '[NewExperienceCampaignBuilder]';

export const REORGANIZE_JSON_DATA = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} REORGANIZE_JSON_DATA`;
export const REGISTER_ADVERTISER_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} REGISTER_ADVERTISER_REQUEST`;
export const PUBLISH_EXCLUSIVE_CAMPAING_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} PUBLISH_EXCLUSIVE_CAMPAING_REQUEST`;
export const LOGIN_ADVERTISER_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} LOGIN_ADVERTISER_REQUEST`;
export const FETCH_OPENAI_SUGGESTIONS_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_OPENAI_SUGGESTIONS_REQUEST`;
export const FETCH_OPENAI_SUGGESTIONS_SUCCESS = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_OPENAI_SUGGESTIONS_SUCCESS`;
export const FETCH_OPENAI_SUGGESTIONS_ERROR = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_OPENAI_SUGGESTIONS_ERROR`;
export const FETCH_OPENAI_SUGGESTIONS_RESET = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_OPENAI_SUGGESTIONS_RESET`;

export const FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_REQUEST`;
export const FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_SUCCESS = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_SUCCESS`;
export const FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_ERROR = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_ERROR`;
export const FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_RESET = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_SOCIAL_ACCOUNT_SUGGESTIONS_RESET`;

export const ADD_DUPLICATE_TASK = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} ADD_DUPLICATE_TASK`;
export const DELETE_TASK = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} DELETE_TASK`;
export const MOVE_TASK = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} MOVE_TASK`;

export const FETCH_OBJECTIVE_SUGGESTIONS_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_OBJECTIVE_SUGGESTIONS_REQUEST`;
export const FETCH_OBJECTIVE_SUGGESTIONS_SUCCESS = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_OBJECTIVE_SUGGESTIONS_SUCCESS`;
export const FETCH_OBJECTIVE_SUGGESTIONS_ERROR = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} FETCH_OBJECTIVE_SUGGESTIONS_ERROR`;

export const GET_BRAND_STORY_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} GET_BRAND_STORY_REQUEST`;
export const GET_BRAND_STORY_SUCCESS = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} GET_BRAND_STORY_SUCCESS`;
export const GET_BRAND_STORY_ERROR = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} GET_BRAND_STORY_ERROR`;

export const GET_TASK_DESCRIPTIONS_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} GET_TASK_DESCRIPTIONS_REQUEST`;
export const GET_TASK_DESCRIPTIONS_SUCCESS = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} GET_TASK_DESCRIPTIONS_SUCCESS`;
export const GET_TASK_DESCRIPTIONS_ERROR = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} GET_TASK_DESCRIPTIONS_ERROR`;

export const GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_REQUEST`;
export const GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_SUCCESS = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_SUCCESS`;
export const GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_ERROR = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} GET_AI_TASK_DESCRIPTION_SOCIAL_MEDIA_URLS_ERROR`;

export const SET_LAST_TIME_ACCESSED_BUILDER_TIME_REQUEST = `${NEW_EXPERIENCE_CAMPAIGN_BUILDER} SET_LAST_TIME_ACCESSED_BUILDER_TIME_REQUEST`;
