import React from 'react';
import styles from './CodaAppLibrary.module.scss';

function CodaAppLibrary() {
  return (
    <iframe
      className={styles.embed}
      src="https://coda.io/embed/RMy-1me3mV/_suMoP?viewMode=embedplay"
      allow="fullscreen"
    ></iframe>
  );
}

export default CodaAppLibrary;
