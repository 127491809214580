import React, { useEffect, useState } from 'react';
import styles from './WelcomeStep.module.scss';
import { ReactComponent as BoxIcon } from '../../../assets/images/box.svg';
import { ReactComponent as FootsIcon } from '../../../assets/images/foots.svg';
import { ReactComponent as WorldIcon } from '../../../assets/images/world.svg';
import classNames from 'classnames';
import {
  makeSelectAdvertiser,
  makeSelectBusinessRulesVariables,
  makeSelectIsAuthenticated,
} from 'containers/App/selectors';
import { useSelector } from 'react-redux';
import { CAMPAIGN_STATUS } from '../../../constants';

export const BASIC_EXPERIENCE_STEP = {
  FOOT_TRAFFIC: 'FOOT_TRAFFIC',
  ONLINE_INTERACTIONS: 'ONLINE_INTERACTIONS',
  PRODUCT_DISCOVERY: 'PRODUCT_DISCOVERY',
};

const WelcomeStep = ({ setStep, setBasicExperience }) => {
  const [disabledCustom, setDisabledCustom] = useState(true);
  const businessRulesVariables = useSelector(
    makeSelectBusinessRulesVariables()
  );
  const isAuth = useSelector(makeSelectIsAuthenticated());
  const advertiser = useSelector(makeSelectAdvertiser());

  useEffect(() => {
    if (isAuth) {
      if (advertiser.enableCustomExperience) {
        setDisabledCustom(false);
      } else {
        if (advertiser.company?.campaigns?.length > 0) {
          const campaigns = advertiser.company.campaigns.filter(
            (c) => c.status === CAMPAIGN_STATUS.FINISHED
          );
          if (
            campaigns.length >
            businessRulesVariables.minCampaignsForCustomExperience
          ) {
            setDisabledCustom(false);
          }
        }
      }
    }
  }, [
    isAuth,
    advertiser,
    businessRulesVariables.minCampaignsForCustomExperience,
  ]);

  const onCustomClick = () => {
    // if (!disabledCustom) {
    setBasicExperience(null);
    setStep(0);
    // }
  };

  const onStepClick = (step) => {
    setBasicExperience(step);
    setStep(0);
  };

  return (
    <div className={styles.container}>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <div className={styles.titleContainer}>
            <BoxIcon />
            <h2 className={styles.title}>Real Product Interactions</h2>
          </div>
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              Bring <span>real people</span> to interact with your products and
              services, both in-person and digitally.
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Instantly add authentic product discovery and credibility with
                real users and measurable results at scale.
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Gather thousands of ugc, surveys, & in-app interactions
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Generate real shopper insights and social proof
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Fun experiences that drive brand awareness
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                No additional staff or resources required
              </div>
            </div>
          </div>
          <div
            className={styles.button}
            onClick={() => onStepClick(BASIC_EXPERIENCE_STEP.PRODUCT_DISCOVERY)}
          >
            select
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.titleContainer}>
            <FootsIcon />
            <h2 className={styles.title}>Real Foot Traffic</h2>
          </div>
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              Bring <span>real people</span> to your brick-and-mortar locations,
              instantly boosting critical retail metrics and in-store dynamics.
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Instantly add quality traffic quality, consumer engagement,
                brand awareness, and organic word- of-mouth.
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Gather thousands of ugc, surveys, & interactions
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Strengthen loyalty with brand immersion
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Fun experiences that boost brand awareness
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                No additional staff or resources required
              </div>
            </div>
          </div>
          <div
            className={styles.button}
            onClick={() => onStepClick(BASIC_EXPERIENCE_STEP.FOOT_TRAFFIC)}
          >
            select
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.titleContainer}>
            <WorldIcon />
            <h2 className={styles.title}>Real Online Interactions</h2>
          </div>
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              Bring <span>real people</span> to your digital touchpoints,
              elevating key performance indicators through consumer
              interactions.
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Seamlessly add quality traffic, user engagement, brand
                awareness, and conversion potential.
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Gather thousands of ugc, surveys, & in-app interactions
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Strengthen loyalty with brand immersion
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                Fun experiences that boost brand awareness
              </div>
            </div>
            <div className={styles.contentTextContainer}>
              <div className={styles.dotContainer}>•</div>
              <div className={styles.contentText}>
                No additional staff or resources required
              </div>
            </div>
          </div>
          <div
            className={styles.button}
            onClick={() =>
              onStepClick(BASIC_EXPERIENCE_STEP.ONLINE_INTERACTIONS)
            }
          >
            select
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles.customContainer
          // disabledCustom && styles.disabledCustom
        )}
      >
        <p className={styles.title}>Custom Brand Experience</p>
        <div className={styles.button} onClick={onCustomClick}>
          select
        </div>
      </div>
    </div>
  );
};

export default WelcomeStep;
