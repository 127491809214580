import React from 'react';
import ReactSelect from 'react-select';

const Select = ({ selectedOption, options, handleChange, isSearchable }) => {
  return (
    <ReactSelect
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          background: '#00000010',
          border: 'none',
          height: '32px',
          minHeight: '32px',
          borderRadius: 0,
          outline: 'none',
          fontSize: '14px',
          boxShadow: 'none',
          padding: '0',
          cursor: 'pointer',
          fontWeight: '400',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          background: '#E6E6E6',
          borderRadius: '2px',
          border: 'none',
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: '#121212',
          fontSize: '14px',
          fontWeight: '400',
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'transparent',
          color: '#121212',
          fontSize: '14px',
          fontWeight: '400',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#d3d3d3',
          },
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: '#919191',
        }),
        indicatorSeparator: () => ({ display: 'none' }),
      }}
      isSearchable={isSearchable}
      value={selectedOption}
      onChange={handleChange}
      options={options}
    />
  );
};

export default Select;
