import classNames from 'classnames';
import React from 'react';
import styles from './Slider.module.scss';

const Slider = ({
  value,
  onChange,
  min,
  max,
  valuePrefix,
  valueSufix,
  step,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.valueContainer}>
        {valuePrefix && (
          <p className={classNames(styles.sufix)}>{valuePrefix}</p>
        )}
        <input
          type="number"
          value={value}
          className={styles.input}
          min={min}
          max={max}
          onChange={(event) => {
            const inputValue = event.target.value;
            if (inputValue !== '') {
              if (+inputValue > max) {
                onChange(max);
                return;
              }
              if (+inputValue < 0) {
                onChange(min);
                return;
              }
            }
            onChange(event);
          }}
          onBlur={() => {
            if (value < min) {
              onChange(min);
            }
          }}
        />
        {valueSufix && <p className={classNames(styles.sufix)}>{valueSufix}</p>}
      </div>
      <input
        type="range"
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        value={value}
        className={styles.slider}
        style={{
          background: `linear-gradient(to right, #338891 ${
            ((value - min) * 100) / (max - min) - (value > max * 0.9 ? 2 : 0)
          }%, #33889120 0px`,
        }}
      ></input>
    </div>
  );
};

export default Slider;
