import {
  makePlatformActionsSelector,
  makeSelectGetSocialAccountSuggestionsPending,
  makeSelectSocialAccountSuggestions,
  makeTasksSelector,
  makeSelectGetSocialAccountSuggestionsMessage,
  makeSelectGetSocialAccountSuggestionsError,
} from 'containers/NewExperienceCampaignBuilderPage/selectors';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TaskDetails.module.scss';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import Input from '../Input';
import { CAMPAIGN_TASKS_CATEGORIES } from '../../../constants';
import { ReactComponent as CloseIcon } from 'assets/images/close1.svg';
import { ReactComponent as SyncIcon } from 'assets/images/sync.svg';
import {
  aiRegenerateTaskDescriptionPlusSocialMediaUrlsRequest,
  fetchOpenAiSocialAccountSuggestionsRequest,
  moveTask,
} from 'containers/NewExperienceCampaignBuilderPage/actions';
import LoadingSpinner from 'components/Layout/LoadingSpinner';
import { useEffect } from 'react';
import { ReactComponent as DuplicateIcon } from 'assets/images/duplicate.svg';
import { ReactComponent as DeleteTaskIcon } from 'assets/images/remove_circle_outline.svg';
import { ReactComponent as ArrouwDownIcon } from 'assets/images/arrow_down.svg';
import {
  addDuplicateTask,
  deleteTask,
} from 'containers/NewExperienceCampaignBuilderPage/actions';
import { ReactComponent as VisitIcon } from 'assets/images/visit.svg';
// import { ReactComponent as AssistIcon } from 'assets/images/ai_assist.svg';
import { ReactComponent as SearchIcon } from 'assets/images/searchIcon.svg';
import { ReactComponent as DeleteBlackIcon } from 'assets/images/black_close_icon.svg';
import TaskInput from '../TaskInput';

export const timeConvert = (n) => {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + ' hour(s) and ' + rminutes + ' minute(s)';
};

export const PLATFOMR_MAPPER = {
  EYCROWD: 'EyCrowd',
  FB: 'Facebook',
  IG: 'Instagram',
  X: 'Twitter',
  TW: 'Twitter',
  TT: 'Tik-Tok',
  LI: 'LinkedIn',
  YELP: 'Yelp',
  AMAZON: 'Amazon',
  HTTP3: 'Website',
};

const SOCIAL_MEDIA_MAPPER = {
  FB: 'facebook',
  IG: 'instagram',
  X: 'twitter',
  LI: 'linkedIn',
  TT: 'tiktok',
};

const TaskDetails = ({
  selectedObjectives,
  setSelectedObjectives,
  tasksInfo,
  setTasksInfo,
  taskDescriptions,
  brandWebsite,
  brandStory,
  storyBrandFormData,
  inPerson,
}) => {
  const tasks = useSelector(makeTasksSelector());
  const platformAction = useSelector(makePlatformActionsSelector());
  // const [companyUrl, setCompanyUrl] = useState('');
  // const [previousValue, setPreviousValue] = useState('');
  const [userInput, setUserInput] = useState({});
  const [loadingStates, setLoadingStates] = useState({});
  const socialAccountSuggestions = useSelector(
    makeSelectSocialAccountSuggestions()
  );
  const getSocialAccountSuggestionsPending = useSelector(
    makeSelectGetSocialAccountSuggestionsPending()
  );
  // const getSocialAccountSuggestionsMessage = useSelector(
  //   makeSelectGetSocialAccountSuggestionsMessage()
  // );
  // const getSocialAccountSuggestionsError = useSelector(
  //   makeSelectGetSocialAccountSuggestionsError()
  // );
  const dispatch = useDispatch();

  const selectedTasks = useMemo(() => {
    return selectedObjectives.flatMap((objective) =>
      tasks.filter((task) => task.objectiveId === objective.id)
    );
  }, [selectedObjectives, tasks]);

  // const onEditLink = (taskId) => {
  //   const index = editingTask.indexOf(taskId);
  //   let newEditingTask = [...editingTask];
  //   if (index > -1) {
  //     newEditingTask.splice(index, 1);
  //     setEditingTask(newEditingTask);
  //   } else {
  //     setEditingTask([...newEditingTask, taskId]);
  //   }
  // };

  useEffect(() => {
    if (!!taskDescriptions.length) {
      for (const taskDescription of taskDescriptions) {
        setTasksInfo((prev) => ({
          ...prev,
          [taskDescription.id]: {
            ...prev[taskDescription.id],
            description: taskDescription.description,
          },
        }));
      }
    }
  }, [taskDescriptions, setTasksInfo]);

  useEffect(() => {
    if (brandWebsite && !socialAccountSuggestions) {
      dispatch(
        fetchOpenAiSocialAccountSuggestionsRequest({ url: brandWebsite })
      );
    }
  }, [brandWebsite, dispatch, socialAccountSuggestions]);

  const onSelectChange = (taskId, selectedOption) => {
    setTasksInfo((prev) => ({
      ...prev,
      [taskId]: { ...prev[taskId], stage: selectedOption },
    }));
  };

  const onInputBlur = (text) => {
    dispatch(fetchOpenAiSocialAccountSuggestionsRequest({ url: text }));
  };

  const onRegenerateClick = (combinedTaskId, descriptions) => {
    const [objectiveId, taskId] = combinedTaskId.split('-');
    const task = selectedTasks.find(
      (task) => task.id == taskId && task.objectiveId == objectiveId
    );

    if (task) {
      const { id, objectiveId, objectiveHeadline, name } = task;

      let pv;
      if (task.originalId) {
        pv = getTaskProjectedValue(task.originalId);
      } else {
        pv = getTaskProjectedValue(task.id);
      }

      const objectiveTask = {
        taskId: id,
        objectiveId,
        objectiveHeadline,
        taskName: name,
        taskDescriptions: descriptions.map((description) => description.label),
      };
      setLoadingStates((prevState) => ({
        ...prevState,
        [combinedTaskId]: true,
      }));
      dispatch(
        aiRegenerateTaskDescriptionPlusSocialMediaUrlsRequest(
          {
            objectiveTask,
            brandStory,
            brandName: storyBrandFormData.businessName,
          },
          (result) => {
            setTasksInfo((prev) => ({
              ...prev,
              [combinedTaskId]: {
                ...prev[combinedTaskId],
                description: result.tasks[0].task_description,
              },
            }));
            if (!!pv.url_required) {
              setTasksInfo((prev) => ({
                ...prev,
                [combinedTaskId]: {
                  ...prev[combinedTaskId],
                  url: result.socialMediaUrls[SOCIAL_MEDIA_MAPPER[pv.platform]]
                    .link,
                  platform: pv.platform,
                  isLinkValid:
                    result.socialMediaUrls[SOCIAL_MEDIA_MAPPER[pv.platform]]
                      .isValid,
                },
              }));
            }
            if (!!+pv.hashtags_required) {
              onTextChange(
                combinedTaskId,
                result.hashtags[SOCIAL_MEDIA_MAPPER[pv.platform]],
                'hashtags',
                pv.platform
              );
            }
            if (!!+pv.mentions_required) {
              onTextChange(
                combinedTaskId,
                result.mentions[SOCIAL_MEDIA_MAPPER[pv.platform]],
                'mentions',
                pv.platform
              );
            }
            setLoadingStates((prevState) => ({
              ...prevState,
              [combinedTaskId]: false,
            }));
          },
          () => {
            setLoadingStates((prevState) => ({
              ...prevState,
              [combinedTaskId]: false,
            }));
            if (tasksInfo[combinedTaskId]?.description) {
              const ind = descriptions.findIndex(
                (d) => d.label === tasksInfo[combinedTaskId].description
              );

              if (ind === descriptions.length - 1) {
                setTasksInfo((prev) => ({
                  ...prev,
                  [combinedTaskId]: {
                    ...prev[combinedTaskId],
                    description: descriptions[0].label,
                  },
                }));
              } else {
                setTasksInfo((prev) => ({
                  ...prev,
                  [combinedTaskId]: {
                    ...prev[combinedTaskId],
                    description: descriptions[ind + 1].label,
                  },
                }));
              }
            } else {
              if (descriptions.length > 1) {
                setTasksInfo((prev) => ({
                  ...prev,
                  [combinedTaskId]: {
                    ...prev[combinedTaskId],
                    description: descriptions[1].label,
                  },
                }));
              }
            }
          }
        )
      );
    }
  };

  const onDuplicateClick = (task) => {
    const newId = new Date().getTime();
    const oldTaskInfoId = `${task.objectiveId}-${task.id}`;
    const newTaskInfoId = `${task.objectiveId}-${newId}`;
    const taskInfoProp = tasksInfo[oldTaskInfoId];
    setTasksInfo((taskInfo) => ({
      ...taskInfo,
      [newTaskInfoId]: {
        ...taskInfoProp,
        description:
          taskInfo[oldTaskInfoId] && taskInfo[oldTaskInfoId].description
            ? taskInfo[oldTaskInfoId].description
            : task.descriptions[0].label,
      },
    }));
    dispatch(addDuplicateTask(task, newId));
  };

  const onDeleteClick = (task) => {
    dispatch(deleteTask(task.id, task.objectiveId));
  };

  const getTaskProjectedValue = useCallback(
    (taskId) => {
      return platformAction.find((p) => p.taskId === taskId);
    },
    [platformAction]
  );

  useEffect(() => {
    if (socialAccountSuggestions) {
      for (const task of selectedTasks) {
        let pv;
        if (task.originalId) {
          pv = getTaskProjectedValue(task.originalId);
        } else {
          pv = getTaskProjectedValue(task.id);
        }
        const taskKey = `${task.objectiveId}-${task.id}`;
        const currentUserInput = userInput[taskKey] || {};

        if (!!pv.url_required) {
          const socialUrls = socialAccountSuggestions.socialMediaUrls;
          const userURL = currentUserInput.url || '';

          if (pv.platform === 'FB' && (userURL || socialUrls.facebook.link)) {
            // onTextChange(
            //   taskKey,
            //   userURL || socialUrls.facebook.link,
            //   'url',
            //   pv.platform
            // );
            setTasksInfo((prev) => ({
              ...prev,
              [taskKey]: {
                ...prev[taskKey],
                isLinkValid: socialUrls.facebook.isValid,
                platform: pv.platform,
                url: userURL || socialUrls.facebook.link,
              },
            }));
          }

          if (pv.platform === 'IG' && (userURL || socialUrls.instagram.link)) {
            setTasksInfo((prev) => ({
              ...prev,
              [taskKey]: {
                ...prev[taskKey],
                isLinkValid: socialUrls.instagram.isValid,
                platform: pv.platform,
                url: userURL || socialUrls.instagram.link,
              },
            }));
          }

          if (pv.platform === 'X' && (userURL || socialUrls.twitter.link)) {
            setTasksInfo((prev) => ({
              ...prev,
              [taskKey]: {
                ...prev[taskKey],
                isLinkValid: socialUrls.twitter.isValid,
                platform: pv.platform,
                url: userURL || socialUrls.twitter.link,
              },
            }));
          }

          if (pv.platform === 'TT' && (userURL || socialUrls.tiktok.link)) {
            setTasksInfo((prev) => ({
              ...prev,
              [taskKey]: {
                ...prev[taskKey],
                isLinkValid: socialUrls.tiktok.isValid,
                platform: pv.platform,
                url: userURL || socialUrls.tiktok.link,
              },
            }));
          }

          if (pv.platform === 'LI' && (userURL || socialUrls.linkedIn.link)) {
            setTasksInfo((prev) => ({
              ...prev,
              [taskKey]: {
                ...prev[taskKey],
                isLinkValid: socialUrls.linkedIn.isValid,
                platform: pv.platform,
                url: userURL || socialUrls.linkedIn.link,
              },
            }));
          }
        }

        if (!!+pv.hashtags_required) {
          const userHashtags = currentUserInput.hashtags || '';
          const newHashtags = socialAccountSuggestions.hashtags.map((h) => {
            if (!h.startsWith('#')) {
              return `#${h}`;
            } else {
              return h;
            }
          });
          const combinedHashtags = [userHashtags, newHashtags]
            .filter(Boolean)
            .join(', ');

          onTextChange(taskKey, combinedHashtags, 'hashtags', pv.platform);
        }

        if (!!+pv.mentions_required) {
          const userMentions = currentUserInput.mentions || '';
          const newMentions = socialAccountSuggestions.mentions.join(', ');
          const combinedMentions = [userMentions, newMentions]
            .filter(Boolean)
            .join(', ');

          onTextChange(taskKey, combinedMentions, 'mentions', pv.platform);
        }
      }
    }
  }, [socialAccountSuggestions, selectedTasks, getTaskProjectedValue]);

  const onTextChange = (taskId, value, prop, platform) => {
    setTasksInfo((prev) => ({
      ...prev,
      [taskId]: { ...prev[taskId], [prop]: value, platform },
    }));
  };

  const onUserTextChange = (taskId, value, prop, platform) => {
    setTasksInfo((prev) => ({
      ...prev,
      [taskId]: { ...prev[taskId], [prop]: value, platform },
    }));
    setUserInput((prev) => ({
      ...prev,
      [taskId]: { ...prev[taskId], [prop]: value },
    }));
  };

  const removeOption = (taskId, optionIndex) => {
    const newOptions = tasksInfo[taskId].options;
    if (newOptions && newOptions.length) {
      newOptions.splice(optionIndex, 1);
      setTasksInfo((prev) => ({
        ...prev,
        [taskId]: {
          ...prev[taskId],
          options: newOptions,
        },
      }));
    }
  };

  const addOption = (taskId, isOther) => {
    if (tasksInfo[taskId] && tasksInfo[taskId].options) {
      setTasksInfo((prev) => ({
        ...prev,
        [taskId]: {
          ...prev[taskId],
          options: [
            ...prev[taskId].options,
            { text: isOther ? 'Other' : '', isOther },
          ],
        },
      }));
    } else {
      setTasksInfo((prev) => ({
        ...prev,
        [taskId]: {
          ...prev[taskId],
          options: [
            { text: '', isOther: false },
            { text: isOther ? 'Other' : '', isOther },
          ],
        },
      }));
    }
  };

  const onOptionTextChange = (taskId, indexOption, value) => {
    if (tasksInfo[taskId] && tasksInfo[taskId].options) {
      let newOptions = tasksInfo[taskId].options;
      newOptions[indexOption] = {
        isOther:
          tasksInfo[taskId].options.length === 1
            ? false
            : newOptions[indexOption].isOther,
        text: value,
      };

      setTasksInfo((prev) => ({
        ...prev,
        [taskId]: {
          ...prev[taskId],
          options: newOptions,
        },
      }));
    } else {
      setTasksInfo((prev) => ({
        ...prev,
        [taskId]: {
          ...prev[taskId],
          options: [{ text: value, isOther: false }],
        },
      }));
    }
  };

  const moveObjective = (currentIndex, direction) => {
    const newIndex = currentIndex + direction;
    const newObjectives = [...selectedObjectives];
    [newObjectives[currentIndex], newObjectives[newIndex]] = [
      newObjectives[newIndex],
      newObjectives[currentIndex],
    ];

    setSelectedObjectives(newObjectives);
  };

  return (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.container}>
          {/* <div className={styles.tableRow}>
            <p className={styles.tableHeaderText}>Campaign Tasks</p>
          </div> */}
          {selectedTasks.map((task, index) => {
            const objectiveIndex = selectedObjectives.findIndex(
              (obj) => obj.id === task.objectiveId
            );
            const isFirstObjective = objectiveIndex === 0;
            const isLastObjective =
              objectiveIndex === selectedObjectives.length - 1;
            const tasksInCurrentObjective = tasks.filter(
              (t) => t.objectiveId === task.objectiveId
            ).length;

            const tasksInCurrentObjectiveArray = tasks.filter(
              (t) => t.objectiveId === task.objectiveId
            );

            const originalTasksInCurrentObjective = tasks.filter(
              (t) => t.objectiveId === task.objectiveId && !t.originalId
            ).length;

            const isFirstTaskInCurrentObjective =
              tasksInCurrentObjectiveArray[0].id === task.id;
            const isLastTaskInCurrentObjective =
              tasksInCurrentObjectiveArray[tasksInCurrentObjective - 1].id ===
              task.id;

            const canDelete =
              tasksInCurrentObjective > originalTasksInCurrentObjective &&
              ((Boolean(task.originalId) &&
                tasks.filter(
                  (otherTask) =>
                    otherTask.objectiveId === task.objectiveId &&
                    (otherTask.id === task.originalId ||
                      otherTask.originalId === task.originalId)
                ).length > 1) ||
                (!Boolean(task.originalId) &&
                  tasks.filter(
                    (otherTask) =>
                      otherTask.objectiveId === task.objectiveId &&
                      otherTask.originalId === task.id
                  ).length > 0));

            let pv;
            if (task.originalId) {
              pv = getTaskProjectedValue(task.originalId);
            } else {
              pv = getTaskProjectedValue(task.id);
            }

            return (
              <div
                key={`${task.objectiveId}-${task.id}`}
                className={styles.tableBodyRow}
              >
                {/* <p className={styles.text2}>{task.time} min</p> */}

                {/* <div className={styles.selectContainer}>
                  <Select
                    options={[
                      { label: 'PRE, LIVE', value: 'PRE, LIVE' },
                      { label: 'LIVE', value: 'LIVE' },
                      { label: 'LIVE, POST', value: 'LIVE, POST' },
                    ]}
                    selectedOption={
                      tasksInfo[`${task.objectiveId}-${task.id}`]?.stage
                        ? tasksInfo[`${task.objectiveId}-${task.id}`]?.stage
                        : { label: 'LIVE', value: 'LIVE' }
                    }
                    handleChange={(value) =>
                      onSelectChange(`${task.objectiveId}-${task.id}`, value)
                    }
                  />
                </div> */}
                <div
                  className={styles.sortContainer}
                  style={{
                    marginTop: !(
                      selectedTasks.filter(
                        (t) => t.objectiveId === task.objectiveId
                      ).length > 1 &&
                      selectedTasks.findIndex(
                        (t) => t.objectiveId === task.objectiveId
                      ) < index
                    )
                      ? '60px'
                      : '0',
                  }}
                >
                  <div
                    style={{
                      transform: 'rotate(180deg)',
                      opacity: isFirstTaskInCurrentObjective ? 0.3 : 1,
                    }}
                    onClick={() => {
                      if (!isFirstTaskInCurrentObjective) {
                        dispatch(moveTask(task, -1));
                      }
                    }}
                  >
                    <ArrouwDownIcon color="gray" width={14} height={14} />
                  </div>
                  <div
                    style={{ opacity: isLastTaskInCurrentObjective ? 0.3 : 1 }}
                    onClick={() => {
                      if (!isLastTaskInCurrentObjective) {
                        dispatch(moveTask(task, 1));
                      }
                    }}
                  >
                    <ArrouwDownIcon color="gray" width={14} height={14} />
                  </div>
                </div>
                <div className={styles.descriptionContainer}>
                  {!(
                    selectedTasks.filter(
                      (t) => t.objectiveId === task.objectiveId
                    ).length > 1 &&
                    selectedTasks.findIndex(
                      (t) => t.objectiveId === task.objectiveId
                    ) < index
                  ) && (
                    <div className={styles.objectiveContainer}>
                      <div
                        className={styles.sortContainer}
                        style={{
                          marginLeft: '-40px',
                        }}
                      >
                        <div
                          style={{
                            transform: 'rotate(180deg)',
                            opacity: isFirstObjective ? 0.3 : 1,
                          }}
                          onClick={() => {
                            if (!isFirstObjective) {
                              moveObjective(objectiveIndex, -1);
                            }
                          }}
                        >
                          <ArrouwDownIcon
                            color="#338891"
                            width={14}
                            height={14}
                          />
                        </div>
                        <div
                          style={{
                            opacity: isLastObjective ? 0.3 : 1,
                          }}
                          onClick={() => {
                            if (!isLastObjective) {
                              moveObjective(objectiveIndex, 1);
                            }
                          }}
                        >
                          <ArrouwDownIcon
                            color="#338891"
                            width={14}
                            height={14}
                          />
                        </div>
                      </div>
                      <p className={styles.objectiveHeadline}>OBJECTIVE:</p>
                      <p className={styles.objectiveHeadline2}>
                        {task.objectiveHeadline}
                      </p>
                    </div>
                  )}
                  {pv.platform_action.toLowerCase() ===
                    CAMPAIGN_TASKS_CATEGORIES.SURVEY ||
                  [
                    'SURVEY-LONG',
                    'SURVEY-SHORT',
                    'SURVEY-MULTIPLE',
                    'SURVEY-SINGLE',
                  ].includes(pv.basic_verification_method) ? (
                    <>
                      <div className={styles.tasksDescriptionContainer}>
                        <p className={styles.taskText}>ACTIVITY {index + 1}:</p>
                        <div className={styles.actionsContainer}>
                          <Tooltip
                            className={styles.tooltip}
                            label={`task-${task.id}`}
                            customContent={<p>{task.name}</p>}
                          />

                          {/* <p
                    className={classNames(styles.pbutton, styles.saveButton)}
                    onClick={() => onEditLink(task.id)}
                  >
                    {editingTask.includes(task.id) ? 'save' : 'edit'}
                  </p> */}
                        </div>
                      </div>
                      <TaskInput
                        pv={pv}
                        task={task}
                        tasksInfo={tasksInfo}
                        onTextChange={onTextChange}
                        onRegenerateClick={onRegenerateClick}
                        width={window.innerWidth}
                        loading={
                          loadingStates[`${task.objectiveId}-${task.id}`]
                        }
                      />
                    </>
                  ) : (
                    <div className={styles.tasksContainer}>
                      <p className={styles.taskText}>USER TASK {index + 1}:</p>
                      <div className={styles.actionsContainer}>
                        <Tooltip
                          className={styles.tooltip}
                          label={`task-${task.id}`}
                          customContent={<p>{task.name}</p>}
                        />

                        {/* <p
                    className={classNames(styles.pbutton, styles.saveButton)}
                    onClick={() => onEditLink(task.id)}
                  >
                    {editingTask.includes(task.id) ? 'save' : 'edit'}
                  </p> */}
                      </div>
                      {task.originalId ? (
                        <div className={styles.duplicateInputContainer}>
                          <Input
                            className={styles.duplicatedInput}
                            rightIconClassName={styles.eraseDescriptionIcon}
                            type={'textarea'}
                            placeholder={
                              tasksInfo[`${task.objectiveId}-${task.id}`]
                                ?.description
                                ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                    .description
                                : tasksInfo[
                                    `${task.objectiveId}-${task.originalId}`
                                  ]?.description
                                ? tasksInfo[
                                    `${task.objectiveId}-${task.originalId}`
                                  ].description
                                : task.descriptions[0].label
                            }
                            value={
                              tasksInfo[`${task.objectiveId}-${task.id}`]
                                ?.description
                                ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                    .description
                                : ''
                            }
                            rightIcon={
                              <DeleteBlackIcon width={20} height={20} />
                            }
                            handleClearInput={() => {
                              onTextChange(
                                `${task.objectiveId}-${task.id}`,
                                '',
                                'description',
                                pv.platform
                              );
                            }}
                            maxLength={500}
                            hideErrorMessage
                            onChange={(event) =>
                              onTextChange(
                                `${task.objectiveId}-${task.id}`,
                                event.target.value,
                                'description',
                                pv.platform
                              )
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className={styles.relativeDescriptionInputContainer}
                        >
                          <Input
                            className={styles.duplicatedInput}
                            type={'textarea'}
                            placeholder={
                              tasksInfo[`${task.objectiveId}-${task.id}`]
                                ?.description
                                ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                    .description
                                : task.descriptions[0].label
                            }
                            value={
                              tasksInfo[`${task.objectiveId}-${task.id}`]
                                ?.description
                                ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                    .description
                                : task.descriptions[0].label
                            }
                            maxLength={500}
                            hideErrorMessage
                            rightIcon={
                              <DeleteBlackIcon width={20} height={20} />
                            }
                            handleClearInput={() => {
                              onTextChange(
                                `${task.objectiveId}-${task.id}`,
                                '',
                                'description',
                                pv.platform
                              );
                            }}
                            onChange={(event) =>
                              onTextChange(
                                `${task.objectiveId}-${task.id}`,
                                event.target.value,
                                'description',
                                pv.platform
                              )
                            }
                          />
                        </div>
                        // <p className={styles.text}>{`${
                        //   tasksInfo[`${task.objectiveId}-${task.id}`]
                        //     ?.description
                        //     ? tasksInfo[`${task.objectiveId}-${task.id}`]
                        //         .description
                        //     : Array.isArray(taskDescriptions) &&
                        //       taskDescriptions.length
                        //     ? taskDescriptions.find((t) => +t.id === +task.id)
                        //         ?.description
                        //     : task.descriptions[0].label
                        // }`}</p>
                      )}

                      {pv.platform_action.toLowerCase() !==
                        CAMPAIGN_TASKS_CATEGORIES.SURVEY &&
                        ![
                          'SURVEY-LONG',
                          'SURVEY-SHORT',
                          'SURVEY-MULTIPLE',
                          'SURVEY-SINGLE',
                        ].includes(pv.basic_verification_method) &&
                        (loadingStates[`${task.objectiveId}-${task.id}`] ? (
                          <LoadingSpinner className={styles.taskSpinner} />
                        ) : (
                          <div
                            className={styles.regenerateButton}
                            onClick={() =>
                              onRegenerateClick(
                                `${task.objectiveId}-${task.id}`,
                                task.descriptions
                              )
                            }
                          >
                            <SyncIcon color="white" width={18} height={18} />
                            <p className={styles.pbutton}>regenerate</p>
                          </div>
                        ))}
                    </div>
                  )}
                  <div className={styles.userActionContainer}>
                    <div className={styles.timeContainer}>
                      <p className={styles.time}>Time:</p>
                      <p className={styles.timeText}>{task.time} minutes</p>
                      {/* <div className={styles.selectContainer}> */}
                      {/* stage:
                      <div className={styles.selectA}>
                        <Select
                          options={[
                            { label: 'PRE, LIVE', value: 'PRE, LIVE' },
                            { label: 'LIVE', value: 'LIVE' },
                            { label: 'LIVE, POST', value: 'LIVE, POST' },
                          ]}
                          selectedOption={
                            tasksInfo[`${task.objectiveId}-${task.id}`]?.stage
                              ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                  ?.stage
                              : { label: 'LIVE', value: 'LIVE' }
                          }
                          handleChange={(value) =>
                            onSelectChange(
                              `${task.objectiveId}-${task.id}`,
                              value
                            )
                          }
                        />
                      </div> */}
                      {/* </div> */}
                    </div>
                    {!!pv.url_required && (
                      <>
                        {/* <div className={styles.urlInputContainer}>
                          <p className={styles.companyLabel}>
                            {!!pv.url_required && 'Smart AI Assist 💡'}
                          </p>
                          {!!pv.url_required && (
                            <div className={styles.relativeInputContainer}>
                              <Input
                                className={styles.companyInput}
                                placeholder={`enter any url (your website, social url, etc.)`}
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  let newValue;

                                  if (value) {
                                    if (!value.startsWith('https://')) {
                                      newValue = `https://${value}`;
                                    } else {
                                      newValue = value;
                                    }
                                    setCompanyUrl(newValue);
                                    if (
                                      !getSocialAccountSuggestionsPending &&
                                      window.innerWidth <= 630
                                    ) {
                                      onInputBlur(newValue);
                                    }
                                  }
                                }}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  let newValue;

                                  if (value.length > previousValue.length) {
                                    if (
                                      value &&
                                      !value.startsWith('https://')
                                    ) {
                                      newValue = `https://${value}`;
                                    } else {
                                      newValue = value;
                                    }
                                  } else {
                                    newValue = value;
                                  }
                                  setPreviousValue(newValue);
                                  setCompanyUrl(newValue);
                                }}
                                hideErrorMessage
                                value={companyUrl}
                              />
                              {companyUrl !== '' && (
                                <div
                                  onClick={() => {
                                    if (companyUrl !== '') {
                                      setCompanyUrl('');
                                    }
                                  }}
                                  className={styles.eraseIcon}
                                >
                                  <DeleteBlackIcon width={20} height={20} />
                                </div>
                              )}
                            </div>
                          )}
                          <div
                            className={styles.assistContainer}
                            onClick={() => {
                              if (
                                companyUrl !== '' &&
                                !getSocialAccountSuggestionsPending
                              ) {
                                onInputBlur(companyUrl);
                              }
                            }}
                          >
                            <AssistIcon color="white" width={18} height={18} />
                            <p className={styles.pbutton}>guess</p>
                          </div>
                          <div className={styles.spinnerDiv} />
                        </div>
                        {getSocialAccountSuggestionsMessage && (
                          <div
                            className={
                              styles.getSocialAccountSuggestionsMessageContainer
                            }
                          >
                            <p
                              style={{
                                color: getSocialAccountSuggestionsError
                                  ? '#DB4F4FCC'
                                  : '#5CA0A7',
                              }}
                            >
                              {getSocialAccountSuggestionsMessage}
                            </p>
                          </div>
                        )} */}
                        <div className={styles.urlInputContainer}>
                          <p className={styles.inputLabel}>
                            {!!pv.url_required &&
                              (PLATFOMR_MAPPER[pv.platform] === 'EyCrowd' ||
                              PLATFOMR_MAPPER[pv.platform] === 'HTTP3'
                                ? 'URL:'
                                : `${PLATFOMR_MAPPER[pv.platform]} URL: *`)}
                          </p>
                          {!!pv.url_required && (
                            <div className={styles.relativeInputContainer}>
                              <Input
                                placeholder={`https://`}
                                onChange={(event) => {
                                  onUserTextChange(
                                    `${task.objectiveId}-${task.id}`,
                                    event.target.value,
                                    'url',
                                    pv.platform
                                  );
                                }}
                                hideErrorMessage
                                rightIcon={
                                  <DeleteBlackIcon width={20} height={20} />
                                }
                                handleClearInput={() => {
                                  onTextChange(
                                    `${task.objectiveId}-${task.id}`,
                                    '',
                                    'url',
                                    pv.platform
                                  );
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  let newValue;

                                  if (value) {
                                    if (!value.startsWith('https://')) {
                                      newValue = `https://${value}`;
                                    } else {
                                      newValue = value;
                                    }
                                    // if (!socialAccountSuggestions) {
                                    //   onInputBlur(newValue);
                                    // }
                                    onUserTextChange(
                                      `${task.objectiveId}-${task.id}`,
                                      newValue.trim(),
                                      'url',
                                      pv.platform
                                    );
                                  }
                                }}
                                value={
                                  tasksInfo[`${task.objectiveId}-${task.id}`]
                                    ?.url
                                    ? tasksInfo[
                                        `${task.objectiveId}-${task.id}`
                                      ].url
                                    : ''
                                }
                              />
                            </div>
                          )}
                          {tasksInfo[`${task.objectiveId}-${task.id}`]?.url &&
                            !getSocialAccountSuggestionsPending && (
                              <div
                                className={styles.visitContainer}
                                style={{
                                  background: tasksInfo[
                                    `${task.objectiveId}-${task.id}`
                                  ].isLinkValid
                                    ? 'rgba(92, 160, 167, 0.8)'
                                    : 'rgba(102, 102, 102, 0.6)',
                                }}
                                onClick={() => {
                                  if (
                                    tasksInfo[`${task.objectiveId}-${task.id}`]
                                      .isLinkValid
                                  ) {
                                    const url = tasksInfo[
                                      `${task.objectiveId}-${task.id}`
                                    ]?.url
                                      ? tasksInfo[
                                          `${task.objectiveId}-${task.id}`
                                        ].url
                                      : '';
                                    if (url !== '') {
                                      window.open(url, '_blank');
                                    }
                                  }
                                }}
                              >
                                <VisitIcon
                                  color="white"
                                  width={18}
                                  height={18}
                                />
                                <p>visit</p>
                              </div>
                            )}
                          {getSocialAccountSuggestionsPending ? (
                            <LoadingSpinner className={styles.spinner} />
                          ) : (
                            <div className={styles.spinnerDiv} />
                          )}
                        </div>
                      </>
                    )}

                    {!!+pv.hashtags_required && (
                      <div className={styles.hashtagContainer}>
                        <p className={styles.hashtagLabel}>Hashtags:</p>

                        {!!+pv.hashtags_required && (
                          <div className={styles.relativeInputContainer}>
                            <Input
                              placeholder="(optinal) #hashtag1 #hashtag2 ..."
                              hideErrorMessage
                              onChange={(event) =>
                                onUserTextChange(
                                  `${task.objectiveId}-${task.id}`,
                                  event.target.value,
                                  'hashtags',
                                  pv.platform
                                )
                              }
                              rightIcon={
                                <DeleteBlackIcon width={20} height={20} />
                              }
                              handleClearInput={() => {
                                onTextChange(
                                  `${task.objectiveId}-${task.id}`,
                                  '',
                                  'hashtags',
                                  pv.platform
                                );
                              }}
                              onBlur={(e) => {
                                const value = e.target.value;
                                if (value.trim()) {
                                  const values = value.split(' ');
                                  const newValues = [];

                                  for (const v of values) {
                                    if (!v.startsWith('#') && v.trim()) {
                                      newValues.push(`#${v}`);
                                    } else {
                                      newValues.push(v);
                                    }
                                  }

                                  onUserTextChange(
                                    `${task.objectiveId}-${task.id}`,
                                    newValues.join(' '),
                                    'hashtags',
                                    pv.platform
                                  );
                                }
                              }}
                              value={
                                tasksInfo[`${task.objectiveId}-${task.id}`]
                                  ?.hashtags
                                  ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                      .hashtags
                                  : ''
                              }
                            />
                          </div>
                        )}
                        {tasksInfo[`${task.objectiveId}-${task.id}`]
                          ?.hashtags &&
                          !getSocialAccountSuggestionsPending && (
                            <div
                              className={styles.hashtagsContainer}
                              onClick={() => {
                                let url = '';
                                const hashtags = tasksInfo[
                                  `${task.objectiveId}-${task.id}`
                                ]?.hashtags
                                  ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                      .hashtags
                                  : '';
                                const hashtagArray = hashtags
                                  .split(',')
                                  .map((tag) => tag.trim());
                                const firstHashtag = hashtagArray[0];

                                const firstHashtagWithoutHash =
                                  firstHashtag.slice(1);
                                if (pv.platform === 'FB') {
                                  url = `https://www.facebook.com/hashtag/${firstHashtagWithoutHash}`;
                                }

                                if (pv.platform === 'IG') {
                                  url = `https://www.instagram.com/explore/tags/${firstHashtagWithoutHash}`;
                                }
                                if (pv.platform === 'X') {
                                  url = `https://twitter.com/search?q=%23${firstHashtagWithoutHash}`;
                                }

                                if (pv.platform === 'TT') {
                                  url = `https://www.tiktok.com/search?q=${firstHashtagWithoutHash}`;
                                }

                                if (pv.platform === 'LI') {
                                  url = `https://www.linkedin.com/feed/hashtag/${firstHashtagWithoutHash}`;
                                }

                                if (url !== '') {
                                  window.open(url, '_blank');
                                }
                              }}
                            >
                              <SearchIcon
                                color="white"
                                width={18}
                                height={18}
                              />
                              <p>search</p>
                            </div>
                          )}
                        {getSocialAccountSuggestionsPending ? (
                          <LoadingSpinner className={styles.spinner} />
                        ) : (
                          <div className={styles.spinnerDiv} />
                        )}
                      </div>
                    )}

                    {!!+pv.mentions_required && (
                      <div className={styles.hashtagContainer}>
                        <p className={styles.hashtagLabel}>Mentions:</p>
                        {!!+pv.mentions_required && (
                          <div className={styles.relativeInputContainer}>
                            <Input
                              placeholder="(optinal) @mention1 @mention2 ..."
                              hideErrorMessage
                              onChange={(event) =>
                                onUserTextChange(
                                  `${task.objectiveId}-${task.id}`,
                                  event.target.value,
                                  'mentions',
                                  pv.platform
                                )
                              }
                              rightIcon={
                                <DeleteBlackIcon width={20} height={20} />
                              }
                              handleClearInput={() => {
                                onTextChange(
                                  `${task.objectiveId}-${task.id}`,
                                  '',
                                  'mentions',
                                  pv.platform
                                );
                              }}
                              onBlur={(e) => {
                                const value = e.target.value;
                                if (value.trim()) {
                                  const values = value.split(' ');
                                  const newValues = [];

                                  for (const v of values) {
                                    if (!v.startsWith('@') && v.trim()) {
                                      newValues.push(`@${v}`);
                                    } else {
                                      newValues.push(v);
                                    }
                                  }

                                  onUserTextChange(
                                    `${task.objectiveId}-${task.id}`,
                                    newValues.join(' '),
                                    'mentions',
                                    pv.platform
                                  );
                                }
                              }}
                              value={
                                tasksInfo[`${task.objectiveId}-${task.id}`]
                                  ?.mentions
                                  ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                      .mentions
                                  : ''
                              }
                            />
                          </div>
                        )}
                        {tasksInfo[`${task.objectiveId}-${task.id}`]
                          ?.mentions &&
                          !getSocialAccountSuggestionsPending && (
                            <div
                              className={styles.hashtagsContainer}
                              onClick={() => {
                                let url = '';
                                const mentions = tasksInfo[
                                  `${task.objectiveId}-${task.id}`
                                ]?.mentions
                                  ? tasksInfo[`${task.objectiveId}-${task.id}`]
                                      .mentions
                                  : '';
                                const mentionArray = mentions
                                  .split(',')
                                  .map((mention) => mention.trim());
                                const firstMention = mentionArray[0];

                                const firstMentionWithoutAt =
                                  firstMention.slice(1);
                                if (pv.platform === 'FB') {
                                  url = `https://www.facebook.com/${firstMentionWithoutAt}`;
                                }

                                if (pv.platform === 'IG') {
                                  url = `https://www.instagram.com/${firstMentionWithoutAt}`;
                                }
                                if (pv.platform === 'X') {
                                  url = `https://twitter.com/${firstMentionWithoutAt}`;
                                }

                                if (pv.platform === 'TT') {
                                  url = `https://www.tiktok.com/@${firstMentionWithoutAt}`;
                                }

                                if (pv.platform === 'LI') {
                                  url = `https://www.linkedin.com/${firstMentionWithoutAt}`;
                                }

                                if (url !== '') {
                                  window.open(url, '_blank');
                                }
                              }}
                            >
                              <SearchIcon
                                color="white"
                                width={18}
                                height={18}
                              />
                              <p>search</p>
                            </div>
                          )}
                        {getSocialAccountSuggestionsPending ? (
                          <LoadingSpinner className={styles.spinner} />
                        ) : (
                          <div className={styles.spinnerDiv} />
                        )}
                      </div>
                    )}

                    {((pv.platform_action.toLowerCase() ===
                      CAMPAIGN_TASKS_CATEGORIES.SURVEY &&
                      ['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(
                        pv.platform_item
                      )) ||
                      ['SURVEY-MULTIPLE', 'SURVEY-SINGLE'].includes(
                        pv.basic_verification_method
                      )) && (
                      <div className={styles.textareasRow}>
                        {/* <div> */}
                        <div className={styles.selectionTextContainer}>
                          <p className={styles.changeableText}>
                            {pv.platform_item === 'MULTIPLE_CHOICE'
                              ? 'Multiple'
                              : 'Single'}
                          </p>
                          <p className={styles.selectionText}>Selection:</p>
                        </div>

                        {/* </div>
                        <div> */}
                        {/* {editingTask.includes(task.id) ? ( */}
                        <div className={styles.inputs}>
                          {tasksInfo[`${task.objectiveId}-${task.id}`]?.options
                            ?.length > 1 ? (
                            tasksInfo[
                              `${task.objectiveId}-${task.id}`
                            ]?.options.map((op, index) => {
                              return (
                                <div
                                  className={styles.inputContainer}
                                  key={index}
                                >
                                  <Input
                                    placeholder={`option${index + 1}`}
                                    value={op.text ? op.text : ''}
                                    hideErrorMessage
                                    disabled={op.isOther}
                                    required
                                    maxLength={30}
                                    onChange={(event) =>
                                      onOptionTextChange(
                                        `${task.objectiveId}-${task.id}`,
                                        index,
                                        event.target.value
                                      )
                                    }
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      removeOption(
                                        `${task.objectiveId}-${task.id}`,
                                        index
                                      )
                                    }
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div className={styles.inputContainer}>
                              <Input
                                placeholder={`option1`}
                                hideErrorMessage
                                required
                                maxLength={30}
                                onChange={(event) =>
                                  onOptionTextChange(
                                    `${task.objectiveId}-${task.id}`,
                                    0,
                                    event.target.value
                                  )
                                }
                                value={
                                  tasksInfo[`${task.objectiveId}-${task.id}`]
                                    ?.options?.length
                                    ? tasksInfo[
                                        `${task.objectiveId}-${task.id}`
                                      ]?.options[0].text
                                    : ''
                                }
                              />
                            </div>
                          )}
                          <div className={styles.addOptions}>
                            <p
                              className={classNames(
                                styles.addOption,
                                tasksInfo[`${task.objectiveId}-${task.id}`]
                                  ?.options?.length > 5 &&
                                  styles.addOptionDisabled
                              )}
                              onClick={() =>
                                addOption(
                                  `${task.objectiveId}-${task.id}`,
                                  false
                                )
                              }
                            >
                              + add option
                            </p>
                            <p>or</p>
                            <p
                              className={classNames(
                                styles.addOther,
                                (tasksInfo[`${task.objectiveId}-${task.id}`]
                                  ?.options?.length > 5 ||
                                  tasksInfo[
                                    `${task.objectiveId}-${task.id}`
                                  ]?.options?.some((o) => o.isOther)) &&
                                  styles.addOptionDisabled
                              )}
                              onClick={() =>
                                addOption(
                                  `${task.objectiveId}-${task.id}`,
                                  true
                                )
                              }
                            >
                              + add "other"
                            </p>
                          </div>
                        </div>
                        {/* ) : (
                            <>
                              {!!tasksInfo[task.id]?.options?.length ? (
                                tasksInfo[task.id]?.options.map((o, index) => (
                                  <p
                                    className={styles.changeableText}
                                    key={index}
                                  >
                                    {o.text}
                                  </p>
                                ))
                              ) : (
                                <p className={styles.changeableText}>
                                  choice1
                                  <br />
                                  choice2 ... <br />
                                  (edit to expand)
                                </p>
                              )}
                            </>
                          )} */}
                        {/* </div> */}
                      </div>
                    )}
                    <div className={styles.manageContainer}>
                      <p className={styles.manageText}>Manage: </p>
                      <div className={styles.manageButtonsContainer}>
                        <div
                          className={styles.duplicateButton}
                          onClick={() => onDuplicateClick(task)}
                        >
                          <DuplicateIcon color="black" width={18} height={18} />
                          <p className={styles.duplicateText}>duplicate task</p>
                        </div>
                        {canDelete && (
                          <div
                            className={styles.deleteButton}
                            onClick={() => onDeleteClick(task)}
                          >
                            <DeleteTaskIcon
                              color="black"
                              width={18}
                              height={18}
                            />
                            <p className={styles.duplicateText}>
                              delete activity
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TaskDetails;
