import { ConfirmationModalProvider } from 'components/ConfirmationModal';
import { PaymentMethodModalProvider } from 'components/PaymentMethodModal';
import Notifier from 'containers/Notifier';
import { SnackbarProvider } from 'notistack';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useInjectSaga } from 'utils/injectSaga';
import {
  fetchAuthenticatedAdvertiser,
  fetchPaymentMethods,
  setMobileFalse,
  setMobileTrue,
} from './actions';
import Routes from './Routes';
import saga from './saga';
import { makeSelectAdvertiser, makeSelectToken } from './selectors';
import TagManager from 'react-gtm-module';
import config from 'config';
import LoadingSpinner from 'components/Layout/LoadingSpinner';
import { scrollToTop } from '../../utils/scrollToTop';
import {
  ADVERTISER_SETTINGS,
  CREATE_CAMPAIGN,
  DASHBOARD,
  DASHBOARD_CREATE_CAMPAIGN,
  ECW_CREATE_CAMPAIGN,
  ECW_CREATE_CAMPAIGN_OBJECTIVES,
  FORGOT_PASSWORD,
  LOGIN,
  REGISTER,
  REPORTS,
  RESET_PASSWORD,
  SINGLE_CAMPAIGN,
  PARTNER_PROGRAM,
} from '../../routes';
import { useInjectReducer } from 'utils/injectReducer';
import ecwSaga from '../ECWCreateCampaignPage/saga';
import ecwReducer from '../ECWCreateCampaignPage/reducer';
import WebsocketProvider from 'components/WebsocketProvider';
import NewRelic from 'new-relic-agent-react';
import smartlookClient from 'smartlook-client';

const key = 'app';

function App() {
  useInjectSaga({ key, saga });
  useInjectSaga({ key: 'ecwCreateCampaign', saga: ecwSaga });
  useInjectReducer({ key: 'ecwCreateCampaign', reducer: ecwReducer });

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let history = useHistory();

  if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
    const tagManagerArgs = {
      gtmId: config.googleTagManager.id,
    };

    TagManager.initialize(tagManagerArgs);
  }

  if (config.smartlook.enabled === 'true') {
    smartlookClient.init(config.smartlook.key);
  }

  const dashboardRoutes = [
    DASHBOARD,
    CREATE_CAMPAIGN,
    DASHBOARD_CREATE_CAMPAIGN,
    ECW_CREATE_CAMPAIGN,
    ECW_CREATE_CAMPAIGN_OBJECTIVES,
    SINGLE_CAMPAIGN,
    ADVERTISER_SETTINGS,
    REPORTS,
    PARTNER_PROGRAM,
  ];

  const noFooterPages = [
    LOGIN,
    FORGOT_PASSWORD,
    REGISTER,
    RESET_PASSWORD,
    ...dashboardRoutes,
  ];

  const token = useSelector(makeSelectToken());
  const advertiser = useSelector(makeSelectAdvertiser());

  const impersonateToken = queryString.parse(history.location.search)?.token;

  useEffect(() => {
    if (pathname !== '/advertisers') scrollToTop();
  }, [pathname]);

  useEffect(() => {
    if (token || impersonateToken) {
      dispatch(fetchAuthenticatedAdvertiser(impersonateToken));
    }
    //eslint-disable-next-line
  }, [token, impersonateToken]);

  useEffect(() => {
    if (advertiser) {
      if (advertiser.verifiedAt) {
        dispatch(fetchPaymentMethods());
      }
    }
    //eslint-disable-next-line
  }, [advertiser]);

  useEffect(() => {
    updateSize();
    scrollToTop();

    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
    //eslint-disable-next-line
  }, []);

  const updateSize = () => {
    if (window.innerWidth <= 768) {
      dispatch(setMobileTrue());
    } else {
      dispatch(setMobileFalse());
    }
  };

  const shouldRenderFooter = () => {
    const hasRoute = noFooterPages.find((noFooterPage) => {
      if (pathname.includes(noFooterPage)) return noFooterPage;

      return undefined;
    });

    if (!hasRoute) {
      // return <Footer />;
    }

    return null;
  };

  return (
    <HelmetProvider>
      <NewRelic
        licenseKey={config.newrelic.licenseKey}
        applicationID={config.newrelic.applicationId}
      />
      <ConfirmationModalProvider>
        <PaymentMethodModalProvider>
          <SnackbarProvider
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            {(token || impersonateToken) && !advertiser ? (
              <LoadingSpinner />
            ) : (
              <WebsocketProvider>
                <Routes />
              </WebsocketProvider>
            )}
            {shouldRenderFooter()}
            <Notifier />
          </SnackbarProvider>
        </PaymentMethodModalProvider>
      </ConfirmationModalProvider>
    </HelmetProvider>
  );
}

export default App;
