import React, { forwardRef, useRef } from 'react';
import styles from './Header.module.scss';
import EycrowdLogo from 'assets/images/logoY.png';
import { ReactComponent as QuestionIcon } from 'assets/images/questionmark.svg';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import toasterVariants from 'constants/toasterVariants';
import { makeSelectAdvertiser } from 'containers/App/selectors';

const Header = forwardRef(
  ({ activeStep, setHelpActive, selectedMode, setImportedCampaign }, ref) => {
    const advertiser = useSelector(makeSelectAdvertiser());

    const dispatch = useDispatch();
    const inputRef = useRef();
    const renderDescription = () => {
      switch (activeStep) {
        case 0:
          return (
            <p className={styles.description}>
              enter{' '}
              <span style={{ textDecoration: 'underline' }}>
                basic information
              </span>{' '}
              about your brand
            </p>
          );

        case 1:
          return (
            <p className={styles.description}>
              select{' '}
              <span style={{ textDecoration: 'underline' }}>multiple</span>{' '}
              {selectedMode === 'DREAM' ? 'goals' : 'objectives'} (step 1 of 4)
            </p>
          );

        case 2:
          return (
            <p className={styles.description}>
              customize the tasks users see (on app) (step 2 of 4)
            </p>
          );

        case 3:
          return (
            <p className={styles.description}>
              select your EyVocates™ & add details (step 3 of 4)
            </p>
          );

        case 4:
          return (
            <p className={styles.description}>
              finalize links and connections (step 4 of 4)
            </p>
          );
        case 5:
          return <p className={styles.description}>review and purchase</p>;
        default:
          break;
      }
    };

    const handleChange = (e) => {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsText(file, 'UTF-8');
      fileReader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          setImportedCampaign(json);
        } catch (error) {
          dispatch(
            enqueueSnackbar({
              message: 'Invalid JSON file',
              variant: toasterVariants.error,
            })
          );
          inputRef.current.value = '';
        }
      };
    };

    return (
      <div className={styles.header} ref={ref}>
        <div className={styles.logoContainer}>
          <img src={EycrowdLogo} alt="eycrowd-logo" className={styles.logo} />
          <p className={styles.logoContainerTrademark}>™</p>
        </div>

        <div className={styles.headerContent}>
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <p className={styles.title}>EyLa™ AI:</p>
              <p className={styles.title}>Campaign Experience Builder</p>
            </div>
            <div className={styles.descriptionContainer}>
              {renderDescription()}
            </div>
          </div>
          {advertiser?.isStaff && (
            <>
              <label for="file-upload" className={styles.fileInputLabel}>
                {inputRef.current?.files[0]?.name || 'Upload Experience'}
              </label>
              <input
                id="file-upload"
                className={styles.fileInput}
                type="file"
                onChange={handleChange}
                accept=".json"
                ref={inputRef}
              />
            </>
          )}
        </div>

        <div
          className={styles.helpContainer}
          onClick={() => setHelpActive(true)}
        >
          <QuestionIcon width={20} height={20} color="rgba(0,0,0,.3)" />
          <p className={styles.helpText}>help</p>
        </div>
      </div>
    );
  }
);

export default Header;
