import classNames from 'classnames';
import React, { forwardRef } from 'react';
import ErrorContainer from '../ErrorContainer';
import styles from './Input.module.scss';

const Input = forwardRef(
  (
    {
      name,
      defaultValue,
      value,
      placeholder,
      onChange,
      autoFocus,
      disabled,
      label,
      className,
      error,
      type,
      hideErrorMessage,
      required,
      onBlur,
      onDelete,
      rightIcon,
      handleClearInput,
      maxLength = 1000,
      redBorder = false,
      ...inputProps
    },
    ref
  ) => {
    return (
      <label
        className={classNames(styles.label, disabled && styles.disabledLabel)}
      >
        <p>{label}</p>
        {required && <p className={styles.requiredValue}>*</p>}
        {type === 'textarea' ? (
          <>
            <textarea
              ref={ref}
              name={name}
              defaultValue={defaultValue}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              autoFocus={autoFocus}
              disabled={disabled}
              className={classNames(
                styles.textarea,
                className && className,
                !!error && redBorder && styles.errorBorder
              )}
            />
            {rightIcon && value && (
              <div onClick={handleClearInput} className={styles.rightIcon}>
                {rightIcon}
              </div>
            )}
          </>
        ) : (
          <>
            <input
              ref={ref}
              type={type}
              name={name}
              defaultValue={defaultValue}
              maxLength={maxLength}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              autoFocus={autoFocus}
              disabled={disabled}
              className={classNames(
                styles.styledInput,
                className && className,
                !!error && redBorder && styles.errorBorder
              )}
              {...inputProps}
            />
            {rightIcon && value && (
              <div onClick={handleClearInput} className={styles.rightIcon}>
                {rightIcon}
              </div>
            )}
          </>
        )}

        {!hideErrorMessage && <ErrorContainer errorText={error} />}
      </label>
    );
  }
);

export default Input;
