import React from 'react';
import styles from './HelpContainer.module.scss';
import Button from '../Button';

const HelpContainer = ({ onBack }) => {
  return (
    <div className={styles.contentContainer}>
      <p className={styles.question}>What is EyCrowd?</p>
      <p className={styles.answer}>
        EyCrowd is the world's first brand experience app and smart platform.
        EyCrowd fosters authentic engagement between brands and their devoted
        customers, engaging in online and in-person brand experiences that
        entertain and reward, deepening loyalty among your top consumers and
        fans. Real people. Real connection. Brand safe.
      </p>
      <p className={styles.answer}>Welcome to the future of marketing.</p>
      <p className={styles.question}>
        What are the key features offered to marketers by EyCrowd?
      </p>
      <p className={styles.answer}>
        Create Lasting Customer Memories 🤩with the EyCrowd App
      </p>
      <p className={styles.answer}>
        • Providing unforgettable experiences that captivate and entertain your
        best customers, connecting them to spend hours interacting with and
        endorsing your brand through engaging activities on the EyCrowd App.
      </p>
      <p className={styles.answer}>
        Empower Fans, Amplify Reach, and Ignite Growth 🚀 with EyCrowd
      </p>
      <p className={styles.answer}>
        • Engage and reward loyal customers through entertaining in-person and
        online brand experiences managed by our platform. Foster human
        connections, championing your brand on social media and in-person
        experiences igniting word-of-mouth ripple effects.
      </p>
      <p className={styles.answer}>
        Brand safety is priority #1 with EyCrowd 👏.
      </p>
      <p className={styles.answer}>
        • Each experience interaction by your EyVocate users within the EyCrowd
        app undergoes a thorough assessment for authenticity and adherence to
        brand integrity, powered by our cutting-edge AI technology platform.
        Rest easy, genuine brand experiences that are tangible and verifiable
        have arrived.
      </p>
      <p className={styles.answer}>
        Effortlessly Design Experiences with EyLa AI 🧚 from EyCrowd
      </p>
      <p className={styles.answer}>
        • Craft engaging endless brand experiences with the EyCrowd Campaign
        Experience Builder, powered by Eyla, your EyCrowd AI expert. Interact
        with Eyla in a few sentences, and she'll design custom brand experiences
        for you. Never get stuck again. Experience the magic today!
      </p>
      <p className={styles.answer}>
        Demonstrable and Authentic: Watch Your Brand's Value Soar 📈
      </p>
      <p className={styles.answer}>
        • With EyCrowd, seamlessly create consistent, engaging brand experiences
        that captivate. Just incorporate activities and EyVocates to amplify
        your brand experiences's impact, guaranteeing resonant and effective
        interactions. Our AI scrutinizes each user action for genuineness and
        brand safety, delivering secure brand outcomes. Experience the advantage
        of entirely authentic engagement and undeniable value you can see and
        prove.
      </p>
      <p className={styles.question}>
        What value does EyCrowd provide for marketers?
      </p>
      <p className={styles.answer}>
        • Brand Impact You Can Prove: Drive your brand's influence with
        authentic online and in-person engagement, testimonials, in-depth
        surveys, personal narratives, direct consumer-brand interactions,
        organic discovery of products and services, and much more. Garner
        sustained brand attention, genuine viewership, and verifiable
        word-of-mouth advocacy. 100% real people and real results.
      </p>
      <p className={styles.answer}>
        • User-Generated Content: Elevate your brand with credible visuals and
        narratives through real photographs, videos, surveys, critiques,
        peer-to-peer discussions, and more.
      </p>
      <p className={styles.answer}>
        • Online Engagement: Strengthen your brand's digital footprint with
        trustworthy social posts, shares, follows, comments, likes, and
        mentions, alongside authentic web traffic and engagement. Real people,
        real connection, zero artificial ingredients.
      </p>
      <p className={styles.answer}>
        • In-Person Engagement: Boost your brand's presence with actual foot
        traffic, authentic on-site enthusiasm, genuine face-to-face
        interactions, spontaneous conversations, true on-location visits, and
        more.
      </p>
      <p className={styles.answer}>
        • Expansion Opportunities: Discover additional benefits with our
        intuitive brand experience experience builder, powered by AyLa AI, to
        easily create brand experiences, access pricing, and forecast the impact
        of your brand's experiences.
      </p>
      <p className={styles.answer}>
        • Partners of EyCrowd Receive More Benefits: Be a trailblazer in
        authenticity with brand experiences from EyCrowd. Benefit from special
        incentives, leads, expert training, direct contact with our executive
        team, and more while you shape the next era of marketing alongside us.
        Welcome to the future of experience marketing.
      </p>
      <p className={styles.question}>What is an EyCrowd brand experience? </p>
      <p className={styles.answer}>
        Brand experiences represent a cutting-edge, adaptable approach to
        marketing "micro-events" available in-person, online, or as hybrid
        engagements through the EyCrowd app. Here, EyVocates delve into
        captivating, autonomous activities, each verified for brand safety by
        our advanced AI-driven platform. With minimal effort, customize and
        craft your brand's experiences, predicting their impact to match your
        goals and budget, all within a few simple clicks!
      </p>
      <p className={styles.question}>What is an EyVocate™?</p>
      <p className={styles.answer}>
        EyVocates are users of the EyCrowd app, comprising your customers, brand
        advocates, and individuals aligned with your brand's ethos, who
        genuinely support and endorse brands they trust and believe in. They
        share a connection with your brand with others who share similar values
        as they participate in your entertaining and rewarding brand
        experiences.
      </p>
      <p className={styles.question}>
        What's the financial transaction process with EyVocates?
      </p>
      <p className={styles.answer}>
        EyVocates receive their earnings through Stripe once they've
        successfully finished and earned a high rating for every brand
        experience. This method guarantees safety for your brand and maintains
        both privacy and financial protection for the EyVocates.
      </p>
      <p className={styles.question}>
        How many brand experiences can an EyVocate join at once?{' '}
      </p>
      <p className={styles.answer}>
        While digital experiences have no limits, in-person experiences are
        capped at one per day for each EyVocate.
      </p>

      <p className={styles.question}>
        Is it mandatory for EyVocates to have public social media profiles?
      </p>
      <p className={styles.answer}>
        Yes, public profiles are required in order to join and participate in
        brand experiences on the EyCrowd App.
      </p>
      <p className={styles.question}>
        What happened when an EyVocate's account is suspended?
      </p>
      <p className={styles.answer}>
        EyVocates should reach out to us through our support page to understand
        and rectify account issues.
      </p>
      <p className={styles.question}>
        What's the current active user count on the EyCrowd app?
      </p>
      <p className={styles.answer}>
        The app boasts tens of thousands of active EyVocates in the U.S., with
        the community growing weekly.
      </p>
      <p className={styles.question}>
        How frequently do EyVocates engage with the app?
      </p>
      <p className={styles.answer}>
        Thousands of EyVocates engage with the app weekly, indicating a robust
        and expanding user base.
      </p>
      <p className={styles.question}>
        Do EyVocates consistently complete brand experiences?
      </p>
      <p className={styles.answer}>
        Yes they do, brand experiences are quickly "sold out", many times in a
        matter of minutes, showing high commitment from our user base.
      </p>
      <p className={styles.question}>
        How does an EyCrowd brand experience work?
      </p>
      <p className={styles.answer}>
        • Seamlessly design unlimited brand experiences using our
        straightforward creation tool, featuring EyLa AI. Think of EyLa AI as an
        innovative addition to your branding team, guiding your creative process
        with just a few sentences of input.
      </p>
      <p className={styles.answer}>
        • Feel free to converse with EyLa AI as you would with a teammate; she
        is designed to understand you and grasp your objectives. Rest assured,
        we're here to support you every step of the way. You've got this.
      </p>
      <p className={styles.answer}>
        • Effortlessly create and personalize your brand's experiences with the
        help of EyLa AI, or by crafting them yourself using our campaign
        experience builder. Launch with confidence, adjusting the scope of your
        brand experience to match your budget and objectives based on our
        projected value forecast.
      </p>
      <p className={styles.answer}>
        • After launching your brand experience, send a special invitation to
        your customers and EyVocates through a distinctive link in the EyCrowd
        app. You have the flexibility to welcome public users on the EyCrowd app
        by setting your experience to public, or you can tailor private and
        public versions of your experience. Managing this is straightforward and
        entirely under your control.
      </p>
      <p className={styles.answer}>
        • See and feel the growth of brand loyalty as your brand safe experience
        progresses, catalyzing genuine advocacy and amplifying your brand's
        influence through dynamic word-of-mouth momentum.
      </p>
      <p className={styles.question}>
        Can you tailor the goals and activities for brand experiences?
      </p>
      <p className={styles.answer}>
        Yes, EyCrowd offers customizable objectives and activities for your
        brand experiences, powered by EyLa AI. This allows you to infuse your
        brand's unique style and tone into a variety of engaging activities,
        from live events to social media interactions and content creation.
        Leverage the power of EyLa AI within our campaign experience builder to
        craft an unlimited array of tailored brand experiences.
      </p>
      <p className={styles.question}>
        What exactly is the campaign experience window?
      </p>
      <p className={styles.answer}>
        The campaign experience window is the allocated time frame in which
        EyVocate users can perform all the planned activities for your brand
        experience. It defines the start and end of the active period for your
        campaign experience.
      </p>
      <p className={styles.question}>
        What are the meanings of “Pre,” “Live,” and “Post” activities in brand
        experiences?
      </p>
      <p className={styles.answer}>
        These terms are used to organize the schedule of activities around your
        brand experience. "Pre" activities are those that can be completed from
        the moment they join your brand experience. "Live" activities are
        performed during the campaign experience window. Lastly, "Post"
        activities may be carried out both during and after the brand
        experience's live period. Completion of these activities is required for
        EyVocates to receive their compensation.
      </p>
      <p className={styles.question}>
        How do you extend invites for newly launched brand experiences?
      </p>
      <p className={styles.answer}>
        The process is in your hands. When you publish a brand experience, a
        unique URL link is generated for you to share with your customers and
        brand enthusiasts. For public brand experiences, we proactively notify
        our community of EyVocate users about the latest experience
        opportunities via the EyCrowd app notifications, directing users with
        interests aligned to your brand straight to your experience.
      </p>
      <p className={styles.question}>
        Is it possible to invite my customer base to brand experiences?
      </p>
      <p className={styles.answer}>
        Yes, incorporating 60%-80% of your own customers is recommended.
        EyVocates with matching profiles receive notifications to view and join
        your brand experience in the EyCrowd app.
      </p>
      <p className={styles.question}>
        What is the EyCrowd App’s gallery function?
      </p>
      <p className={styles.answer}>
        The EyCrowd gallery serves as a secure digital space within the app to
        store and access user-generated content captured during campaign
        experiences for your brand.
      </p>
      <p className={styles.question}>
        Which online platforms can I use with brand experiences?
      </p>
      <p className={styles.answer}>
        Activities can be linked with various platforms including Instagram,
        Twitter, Facebook, and TikTok in addition to any web URL.
      </p>
      <p className={styles.question}>
        What if fewer participants complete the experience than were paid for?
      </p>
      <p className={styles.answer}>
        Clients are issued prorated refunds for any shortfall in participation.
        EyVocate user ratings are linked to their timely participation.
      </p>
      <p className={styles.question}>
        How can clients access content, particularly from private accounts?
      </p>
      <p className={styles.answer}>
        Content can be downloaded through the client portal, ensuring clients
        have access to the campaign results.
      </p>
      <p className={styles.question}>
        Can experiences be exclusive to EyVocates with public social accounts?
      </p>
      <p className={styles.answer}>
        To ensure maximum engagement, we require EyVocates to have public social
        media profiles to join and participate in brand experiences.
      </p>
      <p className={styles.question}>
        What does "time window" mean for a brand experience?
      </p>
      <p className={styles.answer}>
        The duration of a brand experience can be adjusted to last anywhere from
        1 to 7 days to align with your specific campaign experience goals.
        Within this period, known as the campaign experience window, EyVocate
        users will carry out all the activities associated with your brand
        experience.
      </p>
      <p className={styles.question}>
        What amount of advance planning is required for initiating a brand
        experience?
      </p>
      <p className={styles.answer}>
        You can publish a brand experience instantly on our platform. However,
        it's advisable to schedule the start of the brand experience a few days
        post-publication, allowing ample time for your dedicated customers and
        enthusiasts to discover and engage with your brand experience.
      </p>
      <p className={styles.question}>
        Is it possible for clients to specify a minimum number of followers for
        participants?
      </p>
      <p className={styles.answer}>
        No, such a requirement is unnecessary with EyCrowd, as our emphasis on
        authenticity means we don't limit participation based on follower
        counts. We value real individuals with a sincere interest in your
        brand's success over mere numbers. It’s a whole new world. Welcome to
        the new era of genuine, impactful marketing through brand experiences
        with EyCrowd.
      </p>
      <p className={styles.question}>
        Can EyVocate users evaluate my products and services?
      </p>
      <p className={styles.answer}>
        Absolutely. You'll be responsible for distributing your products and
        services to customers before the brand experience begins. Once that's
        handled, you can leverage EyCrowd and our pioneering EyLa AI to craft
        the ideal unboxing and trial experience tailored to your brand's needs.
        Gather essential feedback and engagement via the EyCrowd app. Bear in
        mind that EyVocate users are fervent supporters of your brand and will
        value the genuine connection they make during your brand experience.
      </p>
      <p className={styles.question}>
        What happens if an EyVocate doesn't fulfill the requirements of an
        experience?
      </p>
      <p className={styles.answer}>
        At EyCrowd, authenticity, brand safety, and tangible value priority one.
        Our sophisticated AI system scrutinizes each activity and immediately
        provides a real-time rating to the EyVocate as they go. If the score
        falls short of perfection, EyVocates are encouraged to retry the
        activity to improve their rating, adding an element of fun and incentive
        to the experience. If the your results don't meet the projected value,
        EyCrowd will gladly offers a prorated credit appropriate.
      </p>
      <p className={styles.question}>
        Does EyCrowd offer the services of professional content creators for
        events?
      </p>
      <p className={styles.answer}>
        No. We place a higher value on genuine user-generated photos and videos
        over professional-grade content. Our platform is dedicated to fostering
        authenticity and enjoyment while ensuring brand safety via real,
        authentic content. We invite you to experience the future of real
        user-generated content with EyCrowd.
      </p>
      <p className={styles.question}>
        How can I become more proficient with the EyCrowd platform?
      </p>
      <p className={styles.answer}>
        By joining the EyCrowd partner program, you'll gain access to
        comprehensive training, direct engagement with our team, exclusive
        incentives, potential leads, and a trove of best practices. You'll also
        have the opportunity to contribute to the evolution of brand experience
        marketing. Once you're part of our partnership program, arrange a web
        meeting via our business page to delve into strategies for leveraging
        the full power of the platform.
      </p>
      <p className={styles.question}>
        Are EyVocate users eligible for partial credit or payment if they
        complete a portion of a brand experience successfully?
      </p>
      <p className={styles.answer}>
        To ensure the highest quality of engagement, EyVocates are rewarded when
        they fully complete and excel in each aspect of the brand experience,
        achieving a rating of 3 or higher. 100% completion is essential for
        compensation, fostering a culture of excellence. It’s our commitment to
        an all-or-nothing standard of success.
      </p>
    </div>
  );
};

export default HelpContainer;
