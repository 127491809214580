import CampaignExperienceBasics from 'components/NewExperienceCampaignBuilder/CampaignExperienceBasics';
import CampaignExperienceConnections from 'components/NewExperienceCampaignBuilder/CampaignExperienceConnections';
import CampaignExperienceSummary from 'components/NewExperienceCampaignBuilder/CampaignExperienceSummary';
import Header from 'components/NewExperienceCampaignBuilder/Header';
import Sidebar from 'components/NewExperienceCampaignBuilder/Sidebar';
import { timeConvert } from 'components/NewExperienceCampaignBuilder/TaskDetails';
import Objectives from 'components/NewExperienceCampaignBuilder/Objectives';
import { fetchCampaignCommonData } from 'containers/App/actions';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import styles from './NewExperienceCampaignBuilderPage.module.scss';
import appReducer from 'containers/App/reducer';
import reducer from './reducer';
import saga from './saga';
import appSaga from 'containers/App/saga';
import { GOOGLE_MAPS } from '../../constants';
import config from 'config';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  getTaskDescriptionsRequest,
  reorganizeJsonData,
  setLastTimeAccessedBuilderTimeRequest,
} from './actions';
import ObjectiveCategories from 'components/NewExperienceCampaignBuilder/ObjectiveCategories';
import ProjectedValues from 'components/NewExperienceCampaignBuilder/ProjectedValues';
import {
  makePlatformActionsSelector,
  makeSelectGetBrandStoryPending,
  makeSelectGetObjectiveSuggestionsPending,
  makeSelectGetSocialAccountSuggestionsPending,
  makeSelectGetSuggestionsPending,
  makeSelectGetTaskDescriptionsPending,
  makeSelectObjectiveSuggestions,
  makeTasksSelector,
} from './selectors';
import ActionButtonsContainer from 'components/NewExperienceCampaignBuilder/ActionButtonsContainer';
import classNames from 'classnames';
import { makeSelectIsAuthenticated } from 'containers/App/selectors';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from 'routes';
import HelpContainer from 'components/NewExperienceCampaignBuilder/HelpContainer';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import EycrowdLogo from 'assets/images/eycrowd-logo.png';
import { ReactComponent as EycLogo } from 'assets/images/eyc-logo-new.svg';
import LogoY from 'assets/images/logoY.png';
import InvitationInput from 'components/NewExperienceCampaignBuilder/InvitationInput';
import Tooltip from 'components/Tooltip';
import axios from 'axios';
import LoadingSpinner from 'components/Layout/LoadingSpinner';
import AICampaignSuggestion from 'components/NewExperienceCampaignBuilder/AICampaignSuggestion';
import TaskManager from 'components/NewExperienceCampaignBuilder/TaskDetails/TaskManager';
import WelcomeStep from 'components/NewExperienceCampaignBuilder/WelcomeStep';

const stripe = loadStripe(config.stripe.publicKey);

const key = 'app';

const NewExperienceCampaignBuilderPage = () => {
  useInjectSaga({ key, saga: appSaga });
  useInjectSaga({ key: 'newExperienceCampaignBuilder', saga });

  useInjectReducer({ key, reducer: appReducer });
  useInjectReducer({ key: 'newExperienceCampaignBuilder', reducer: reducer });

  const platformAction = useSelector(makePlatformActionsSelector());
  const isAuth = useSelector(makeSelectIsAuthenticated());
  const history = useHistory();
  const tasks = useSelector(makeTasksSelector());
  const getTaskDescriptionsPending = useSelector(
    makeSelectGetTaskDescriptionsPending()
  );

  const [step, setStep] = useState(-1);
  const [basicExperience, setBasicExperience] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const [selectedCampaignIndex, setSelectedCampaignIndex] = useState(null);
  const [inPerson, setInPerson] = useState(true);
  const [tasksInfo, setTasksInfo] = useState({});
  const [formData, setFormData] = useState({});
  const [totalEyvocate, setTotalEyvocate] = useState(30);
  const [helpActive, setHelpActive] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [sumaryBtnDisabled, setSumaryBtnDisabled] = useState(false);
  const [pricePerHourWatch, setPricePerHourWatch] = useState(25);
  const [selectedMetatag, setSelectedMetatag] = useState('<FEATURED>');
  const [invitationScreen, setInvitationScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState('DREAM');
  const campaignSuggestions = useSelector(makeSelectObjectiveSuggestions());
  const [selectedSocialPlatform, setSelectedSocialPlatform] = useState([]);
  const [selectedObjectiveType, setSelectedObjectiveType] = useState(null);
  const [brandStory, setBrandStory] = useState(null);
  const [taskDescriptions, setTaskDescriptions] = useState([]);
  const [brandWebsite, setBrandWebsite] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [storyBrandFormData, setStoryBrandFormData] = useState(null);
  const campaignSuggestionsPending = useSelector(
    makeSelectGetObjectiveSuggestionsPending()
  );
  const getBrandStoryPending = useSelector(makeSelectGetBrandStoryPending());
  const [areAllFieldsFilled, setAreAllFieldsFilled] = useState(false);
  const getSocialAccountSuggestionsPending = useSelector(
    makeSelectGetSocialAccountSuggestionsPending()
  );
  const getSuggestionsPending = useSelector(makeSelectGetSuggestionsPending());
  const [areObjectivesFromDream, setAreObjectivesFromDream] = useState(false);
  const [userLocations, setUserLocations] = useState([]);
  const [importedCampaign, setImportedCampaign] = useState(null);

  const handleFieldsFilledChange = (filled) => {
    setAreAllFieldsFilled(filled);
  };

  useEffect(() => {
    setLoadingBtn(getTaskDescriptionsPending);
  }, [getTaskDescriptionsPending]);

  const selectedTasks = useMemo(() => {
    const retTasks = [];

    for (const objective of selectedObjectives) {
      if ((objective.id + '').includes('/')) {
        let objectiveId = +objective.id.split('/')[0];
        const objectiveTasks = tasks.filter(
          (task) => task.objectiveId === objectiveId
        );
        retTasks.push(
          ...objectiveTasks.map((task) => ({
            ...task,
            objectiveId: objective.id,
          }))
        );
      } else {
        const objectiveTasks = tasks.filter(
          (task) => task.objectiveId === objective.id
        );
        retTasks.push(...objectiveTasks);
      }
    }

    return retTasks;
  }, [selectedObjectives, tasks]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.googleMaps.apiKey,
    libraries: GOOGLE_MAPS.LIBRARIES,
  });

  const [polygon, setPolygon] = useState([]);
  const [circle, setCircle] = useState(null);
  const [location, setLocation] = useState(
    GOOGLE_MAPS.DEFAULT_MAP_CENTER_LATLNG
  );
  const handlePolygonDrawn = (value) => {
    setPolygon(value);
  };

  const [numOfEyvocates, setNumOfEyvocates] = useState(30);

  const selectedPlatformActions = useMemo(() => {
    if (selectedObjectives.length) {
      return platformAction.filter((p) =>
        selectedObjectives.find((so) => so.id === p.objectiveId)
      );
    }

    if (selectedSubcategories.length) {
      return platformAction.filter((p) =>
        selectedSubcategories.find((sc) => sc.id === p.subcategoryId)
      );
    }

    if (selectedCategory) {
      return platformAction.filter((p) => p.categoryId === selectedCategory.id);
    }

    return [];
  }, [
    platformAction,
    selectedCategory,
    selectedSubcategories,
    selectedObjectives,
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    let configuratorLink =
      'https://objective-configurator.s3.us-east-2.amazonaws.com/qa/configurator.json';

    switch (config.api.baseUrl) {
      case 'https://eycrowd-server.qa.eycrowd.com/api':
        configuratorLink =
          'https://objective-configurator.s3.us-east-2.amazonaws.com/qa/configurator.json';
        break;
      case 'https://eycrowd-server.hercules.eycrowd.com/api/':
        configuratorLink =
          'https://objective-configurator.s3.us-east-2.amazonaws.com/hercules/configurator.json';
        break;
      case 'https://eycrowd-server.production.eycrowd.com/api':
        configuratorLink =
          'https://objective-configurator.s3.us-east-2.amazonaws.com/production/configurator.json';
        break;
      default:
        break;
    }

    axios
      .get(configuratorLink)
      .then(function (response) {
        const data = response.data;

        dispatch(reorganizeJsonData(data));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCampaignCommonData());
  }, [dispatch]);

  useEffect(() => {
    const handleNavigation = (location, action) => {
      if (action === 'POP') {
        switch (step) {
          case 0:
            return true;
          case 1:
            if (selectedMode === 'CATALOG') {
              setSelectedMode('DREAM');
            } else {
              setStep((prevStep) => prevStep - 1);
            }
            return false;
          case 2:
            if (areObjectivesFromDream) {
              setSelectedMode('DREAM');
            } else {
              setSelectedMode('CATALOG');
            }
            setStep((prevStep) => prevStep - 1);
            return false;
          case 3:
            campaignBasicsRef.current.onFormBack();
            return false;
          case 4:
          case 5:
            setStep((prevStep) => prevStep - 1);
            return false;
          default:
            return true;
        }
      }
    };

    const unblock = history.block(handleNavigation);

    return () => {
      unblock();
    };
  }, [step, history, selectedMode, areObjectivesFromDream, isAuth]);

  const onBack = () => {
    if (helpActive) {
      setHelpActive(false);
      return;
    }
    if (step !== -1) {
      if (step === 1) {
        if (selectedMode === 'CATALOG') {
          setSelectedMode('DREAM');
        } else {
          setStep((step) => step - 1);
        }
        return;
      }
      if (step === 2) {
        campaignConnectionsRef.current.onBack();
      }
      if (step === 3) {
        taskDetailsRef.current.onBack();
        if (areObjectivesFromDream) {
          setSelectedMode('DREAM');
        } else {
          setSelectedMode('CATALOG');
        }
      }
      if (step === 4) {
        campaignBasicsRef.current.onFormBack();
        return;
      }
      setStep((step) => step - 1);
    } else {
      if (isAuth) {
        history.push(DASHBOARD);
      }
    }
  };

  const campaignBasicsRef = useRef();
  const campaignConnectionsRef = useRef();
  const campaignSummaryRef = useRef();
  const brandStoriesRef = useRef();
  const taskDetailsRef = useRef();

  const onContinue = () => {
    if (step === -1) {
      setStep(0);
      return;
    }
    if (step === 0) {
      brandStoriesRef.current.handleFormSubmit();
      return;
    }
    if (step === 1) {
      if (selectedMode === 'CATALOG') {
        if (!importedCampaign) {
          if (!taskDescriptions.length) {
            dispatch(
              getTaskDescriptionsRequest(
                {
                  brandStory,
                  tasks: selectedTasks.map((task) => ({
                    id: `${task.objectiveId}-${task.id}`,
                    description: task.descriptions[0].label,
                  })),
                },
                (taskDescriptions) => {
                  setTaskDescriptions(taskDescriptions);
                  setStep(2);
                },
                () => {
                  setStep(2);
                  setLoadingBtn(false);
                }
              )
            );
          } else {
            setStep(2);
          }
        } else {
          setStep(2);
        }
      } else {
        if (basicExperience) {
          dispatch(
            getTaskDescriptionsRequest(
              {
                brandStory,
                tasks: selectedTasks.map((task) => ({
                  id: `${task.objectiveId}-${task.id}`,
                  description: task.descriptions[0].label,
                })),
              },
              (taskDescriptions) => {
                setTaskDescriptions(taskDescriptions);
                setStep(2);
              },
              () => {
                setStep(2);
                setLoadingBtn(false);
              }
            )
          );
        } else {
          setSelectedMode('CATALOG');
        }
      }

      return;
    }
    if (step === 2) {
      campaignConnectionsRef.current.handleFormSubmit();
      return;
    }
    if (step === 3) {
      taskDetailsRef.current.handleFormSubmit();
      return;
    }
    if (step === 4) {
      campaignBasicsRef.current.handleFormSubmit();
      return;
    }
    if (step === 5) {
      campaignSummaryRef.current.handleFormSubmit();
      return;
    }
    if (step !== 5) {
      if (selectedMode === 'DREAM') {
        setStep(1);
      } else {
        setStep((step) => step + 1);
      }
    }
  };

  const continueBtnDisabled = useMemo(() => {
    switch (step) {
      case 1:
        return selectedObjectives.length < 2;

      case 5:
        if (coupon?.type !== 'vip') {
          return sumaryBtnDisabled;
        } else {
          return false;
        }

      default:
        break;
    }
  }, [step, selectedObjectives, sumaryBtnDisabled, coupon]);

  const renderContent = () => {
    switch (step) {
      case -1:
        return (
          <WelcomeStep
            setBasicExperience={setBasicExperience}
            setStep={setStep}
          />
        );
      case 0:
        return (
          <ObjectiveCategories
            ref={brandStoriesRef}
            setStep={setStep}
            basicExperience={basicExperience}
            setLoadingBtn={setLoadingBtn}
            brandStory={brandStory}
            setBrandStory={setBrandStory}
            setBrandWebsite={setBrandWebsite}
            setStoryBrandFormData={setStoryBrandFormData}
            storyBrandFormData={storyBrandFormData}
            onFieldsFilledChange={handleFieldsFilledChange}
            setUserLocations={setUserLocations}
            polygon={polygon}
            setPolygon={setPolygon}
            location={location}
            setLocation={setLocation}
            circle={circle}
            setCircle={setCircle}
            setInPerson={setInPerson}
            importedCampaign={importedCampaign}
            setSelectedMode={setSelectedMode}
            setSelectedMetatag={setSelectedMetatag}
          />
        );
      case 1:
        return selectedMode === 'CATALOG' ? (
          <Objectives
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedSubcategories={selectedSubcategories}
            setSelectedSubcategories={setSelectedSubcategories}
            selectedObjectives={selectedObjectives}
            setSelectedObjectives={setSelectedObjectives}
            selectedMetatag={selectedMetatag}
            setSelectedMetatag={setSelectedMetatag}
            selectedSocialPlatform={selectedSocialPlatform}
            selectedObjectiveType={selectedObjectiveType}
            areObjectivesFromDream={areObjectivesFromDream}
            importedCampaign={importedCampaign}
          />
        ) : (
          <AICampaignSuggestion
            setSelectedObjectives={setSelectedObjectives}
            selectedObjectives={selectedObjectives}
            setFormData={setFormData}
            selectedCampaignIndex={selectedCampaignIndex}
            setSelectedCampaignIndex={setSelectedCampaignIndex}
            onFieldsFilledChange={handleFieldsFilledChange}
            setAreObjectivesFromDream={setAreObjectivesFromDream}
            setSelectedMode={setSelectedMode}
            basicExperience={basicExperience}
          />
        );
      case 2:
        return (
          isLoaded && (
            <CampaignExperienceConnections
              setStep={setStep}
              handlePolygonDrawn={handlePolygonDrawn}
              setLocation={setLocation}
              location={location}
              polygon={polygon}
              setFormData={setFormData}
              tasksInfo={tasksInfo}
              formData={formData}
              selectedObjectives={selectedObjectives}
              setTasksInfo={setTasksInfo}
              setLoadingBtn={setLoadingBtn}
              ref={campaignConnectionsRef}
              brandStory={brandStory}
              importedCampaign={importedCampaign}
            />
          )
        );
      case 3:
        return (
          <TaskManager
            ref={taskDetailsRef}
            inPerson={inPerson}
            selectedObjectives={selectedObjectives}
            setSelectedObjectives={setSelectedObjectives}
            setStep={setStep}
            tasksInfo={tasksInfo}
            setTasksInfo={setTasksInfo}
            taskDescriptions={taskDescriptions}
            brandWebsite={brandWebsite}
            brandStory={brandStory}
            storyBrandFormData={storyBrandFormData}
            importedCampaign={importedCampaign}
          />
        );
      case 4:
        return (
          <CampaignExperienceBasics
            setStep={setStep}
            setFormData={setFormData}
            selectedObjectives={selectedObjectives}
            formData={formData}
            setTotalEyvocate={setTotalEyvocate}
            setNumOfEyvocates={setNumOfEyvocates}
            setPricePerHourWatch={setPricePerHourWatch}
            userLocations={userLocations}
            ref={campaignBasicsRef}
            importedCampaign={importedCampaign}
          />
        );

      case 5:
        return (
          <Elements stripe={stripe}>
            <CampaignExperienceSummary
              formData={formData}
              setStep={setStep}
              selectedObjectives={selectedObjectives}
              tasksInfo={tasksInfo}
              polygon={polygon}
              location={location}
              circle={circle}
              inPerson={inPerson}
              setLoadingBtn={setLoadingBtn}
              setInputInvalid={setSumaryBtnDisabled}
              ref={campaignSummaryRef}
              basicExperience={basicExperience}
              coupon={coupon}
              setCoupon={setCoupon}
              storyBrandFormData={storyBrandFormData}
              brandStory={brandStory}
            />
          </Elements>
        );
      default:
        break;
    }
  };

  const renderTooltipText = () => {
    switch (step) {
      case 0:
        return "Create an engaging, safe, and fun brand experience that offers hours of immersion for customers and fans aligned with your brand's values. Brand experience coverage area' helps EyLa AI to develop experience ideas for customers and fans who live in specific locations.  In addition, if your experience is public, EyLa AI uses this information to decide who can join via the EyCrowd App. For private experiences or public experiences where people live outside the covered area, only those with a 'private link' can join.";
      case 1:
        return "Create an engaging, safe, and fun brand experience that offers hours of immersion for customers and fans aligned with your brand's values.";
      case 2:
        return "Click 'regenerate' to customize tasks with social URLs, hashtags, and mentions for your EyVocates' engaging campaign experience on EyCrowd, creating buzz among customers and fans.";
      case 3:
        return "Select EyVocates' interests, demographics, and pay per hour for rewarding experiences that honor their loyalty and shared values with your brand.";
      case 4:
        return "Choose your brand experience title and description, use 'regenerate' for ideas or customize, and set start and end times to set the experience window for EyVocate activities. Review, publish, and see your project's value unfold.";
      case 5:
        return "Choose your brand experience title and description, use 'regenerate' for ideas or customize, and set start and end times to set the experience window for EyVocate activities. Review, publish, and see your project's value unfold.";
      default:
        break;
    }
  };

  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [step, helpActive]);

  useEffect(() => {
    if (isAuth) {
      dispatch(setLastTimeAccessedBuilderTimeRequest());
    }
  }, [isAuth, dispatch]);

  const taskDuration = selectedTasks.length
    ? selectedTasks
        .map((item) => item.time)
        .reduce((prev, next) => +prev + +next)
    : 0;

  const [height, setHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const ref = useRef(null);
  const footerRef = useRef(null);

  const onResize = useCallback(() => {
    if (ref.current) setHeight(ref.current.clientHeight);
  }, []);

  const onResizeFooter = useCallback(() => {
    if (footerRef.current) setFooterHeight(footerRef.current.clientHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('resize', onResizeFooter);

    onResize();
    onResizeFooter();
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('resize', onResizeFooter);
    };
  }, [
    onResize,
    step,
    onResizeFooter,
    selectedObjectives.length,
    helpActive,
    invitationScreen,
  ]);

  const totalPrice = useMemo(
    () => (totalEyvocate * pricePerHourWatch * taskDuration) / 60,
    [totalEyvocate, pricePerHourWatch, taskDuration]
  );

  const renderFooterContent = () => {
    switch (step) {
      case 0:
        return (
          <div className={styles.footer}>
            {getBrandStoryPending ? (
              <span>
                EyLa AI > Exploring your brand and assembling your unique unique
                essence and expression
                <span className={styles.ellipsis}></span>
              </span>
            ) : areAllFieldsFilled ? (
              <p>{'Thank you! select continue to create your campaign 👍'}</p>
            ) : (
              <p>{'Please enter the basics about your brand ✏️'}</p>
            )}
          </div>
        );
      case 1:
        return (
          <div className={styles.footer}>
            {selectedObjectives.length === 0 ||
            selectedObjectives.length === 1 ? (
              selectedMode === 'DREAM' ? (
                campaignSuggestionsPending ? (
                  <span>
                    EyLa AI > Dreaming up campaign experiences for your brand
                    <span className={styles.ellipsis}></span>
                  </span>
                ) : areAllFieldsFilled ? (
                  !!campaignSuggestions.length ? (
                    <p>{'Select a campaign experience to continue 🤩'}</p>
                  ) : (
                    <p>{'Please click the dream button to continue ⏩'}</p>
                  )
                ) : (
                  <p>
                    {
                      'Please enter your outcomes + requirements and select dream button ✏️'
                    }
                  </p>
                )
              ) : (
                <p>{'Select two or more objectives to continue 🤩'}</p>
              )
            ) : getTaskDescriptionsPending ? (
              <span>
                EyLa AI > Dreaming up the perfect narratives for your activities
                now
                <span className={styles.ellipsis}></span>
              </span>
            ) : (
              <p>
                {'Select continue when ready ✅'}
                {/* {selectedObjectives.length} objectives selected ✅,{' '}
                {timeConvert(taskDuration)} */}
              </p>
            )}
          </div>
        );
      case 2:
        return (
          <div className={styles.footer}>
            {getSocialAccountSuggestionsPending ? (
              <span>
                {`EyLa AI > Dreaming up task descriptions for your experience`}
                <span className={styles.ellipsis}></span>
              </span>
            ) : (
              <p>{`${selectedObjectives.length} objectives, ${
                selectedTasks.length
              } activities, ${timeConvert(
                selectedTasks
                  .map((item) => item.time)
                  .reduce((prev, next) => +prev + +next)
              )}`}</p>
            )}
          </div>
        );
      case 3:
        return (
          <div className={styles.footer}>
            <p>{`${selectedObjectives.length} objectives, ${
              selectedTasks.length
            } activities, ${timeConvert(
              selectedTasks
                .map((item) => item.time)
                .reduce((prev, next) => +prev + +next)
            )}`}</p>
            <p>
              {`${totalEyvocate} EyVocates, $${pricePerHourWatch} per hour, $${totalPrice?.toFixed(
                2
              )}  total`}
            </p>
          </div>
        );
      case 4:
        return (
          <div className={styles.footer}>
            {getSuggestionsPending ? (
              <span>
                EyLa AI > Dreaming up the title and description for your
                experience
                <span className={styles.ellipsis}></span>
              </span>
            ) : (
              <>
                <p>{`${selectedObjectives.length} objectives, ${
                  selectedTasks.length
                } activities, ${timeConvert(
                  selectedTasks
                    .map((item) => item.time)
                    .reduce((prev, next) => +prev + +next)
                )}`}</p>
                {/* <p>
                  {`${formData.totalEyvocate} EyVocates, $${
                    formData.pricePerHour
                  } per hour, $${formData.totalPrice?.toFixed(2)}  total`}
                </p> */}
              </>
            )}
          </div>
        );
      case 5:
        return (
          <div className={styles.footer}>
            <p>{`${selectedObjectives.length} objectives, ${
              selectedTasks.length
            } activities, ${timeConvert(
              selectedTasks
                .map((item) => item.time)
                .reduce((prev, next) => +prev + +next)
            )}`}</p>
            <div className={styles.textdiv}>
              <p
                className={classNames(
                  coupon?.type === 'vip' && styles.lineThrough
                )}
              >
                {`${formData.totalEyvocate} EyVocates, $${
                  formData.pricePerHour
                } per hour, $${formData.totalPrice?.toFixed(2)}  total`}
              </p>
              {coupon?.type === 'vip' && (
                <p>
                  --&gt; {formData.totalEyvocate} EyVocates, $0 per hour, $0
                  total
                </p>
              )}
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className={styles.container}>
      {!isAuth && invitationScreen ? (
        <div className={styles.ivitationContainer}>
          <div className={styles.header}>
            <img src={EycrowdLogo} alt="logo" className={styles.logo} />
            <div className={styles.headerButtons}>
              <button
                className={styles.btn}
                style={{ backgroundColor: '#000', borderColor: '#000' }}
              >
                Download App
              </button>
              <button
                className={styles.btn}
                style={{ backgroundColor: '#fff', color: '#e0685e' }}
              >
                Business Login
              </button>
              <button className={styles.btn}>Create a Campaign</button>
            </div>
          </div>
          <div className={styles.ivitationContent}>
            <div className={styles.firstSection}>
              <div className={styles.firstSectionContent}>
                <p className={styles.title}>Create a Campaign Experience</p>
                <p className={styles.subtitle}>Exclusive early access</p>
                <p className={styles.firstSectionText}>
                  Enter your invitation code and email to continue.
                </p>
                <div className={styles.firstSectionInputs}>
                  <InvitationInput
                    label="Invitation Code:"
                    labelStyle={{ maxWidth: '408px' }}
                  />
                  <div>
                    <InvitationInput label="Invitation Email:" />
                    <button
                      className={styles.btn}
                      onClick={() => setInvitationScreen(false)}
                    >
                      ENTER
                    </button>
                  </div>
                </div>
              </div>
              <img src={LogoY} className={styles.logoY} alt="logo-y" />
            </div>
            <p className={styles.firstSectionText}>
              Are you a member of an innovative ad agency, marketing
              professional, or business owner operating locations in metro Los
              Angeles? EyCrowd™ is now accepting a limited number of clients for
              early access. Submit your details for more information.
            </p>
            <div className={styles.thirdSection}>
              <InvitationInput label="Name:" />
              <InvitationInput label="Email:" />
              <button className={styles.btn}>SUBMIT</button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Sidebar
            activeStep={step}
            setActiveStep={setStep}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            basicExperience={basicExperience}
            top={height}
            selectedMetatag={selectedMetatag}
            setSelectedMetatag={setSelectedMetatag}
            setSelectedMode={setSelectedMode}
            selectedMode={selectedMode}
            setSelectedSocialPlatform={setSelectedSocialPlatform}
            selectedSocialPlatform={selectedSocialPlatform}
            setSelectedObjectiveType={setSelectedObjectiveType}
            selectedObjectiveType={selectedObjectiveType}
          />
          <Header
            ref={ref}
            activeStep={step}
            selectedObjectives={selectedObjectives}
            numOfEyvocates={numOfEyvocates}
            setHelpActive={setHelpActive}
            renderTooltipText={renderTooltipText}
            selectedMode={selectedMode}
            setImportedCampaign={setImportedCampaign}
          />

          <div className={styles.infoContainer}>
            <Tooltip
              customContent={<p>{renderTooltipText()}</p>}
              iconClassName={styles.tooltipIcon}
              className={styles.tooltipContainer}
              offset={{ right: -30, top: 8 }}
              buttonText={''}
              textClassName={styles.infoText}
            />
            {/* <p className={}>info</p> */}
          </div>

          <div
            className={classNames(
              styles.contentContainer,
              helpActive && styles.helpContent
            )}
            style={{
              top: `${height + 30}px`,
              bottom: `${footerHeight + 30}px`,
            }}
            ref={contentRef}
          >
            {helpActive ? (
              <HelpContainer onBack={() => setHelpActive(false)} />
            ) : loading ? (
              <LoadingSpinner />
            ) : (
              renderContent()
            )}
            {!helpActive && (
              <div
                className={styles.projectedValuesContainer}
                style={{
                  top: `${height + 30}px`,
                }}
              >
                {!!selectedPlatformActions.length ? (
                  <ProjectedValues
                    onHelpClick={() => setHelpActive(true)}
                    numOfEyvocates={totalEyvocate}
                    values={selectedPlatformActions}
                    inPerson={inPerson}
                  />
                ) : (
                  <div className={styles.welcomeToEycContainer}>
                    <p className={styles.welcomeToEycContainerText}>
                      👋 Welcome to
                    </p>
                    <div className={styles.welcomeToEycLogoContainer}>
                      <EycLogo />
                      <p className={styles.welcomeToEycContainerTrademark}>™</p>
                    </div>

                    {/* <div
                      className={styles.helpCenter}
                      onClick={() => setHelpActive(true)}
                    >
                      <QuestionIcon color="gray" />
                      <p>HELP CENTER</p>
                    </div> */}
                  </div>
                )}
              </div>
            )}
          </div>
          <ActionButtonsContainer
            ref={footerRef}
            onBack={onBack}
            continueHide={helpActive}
            onContinue={onContinue}
            continueLoading={loadingBtn}
            continueBtnDisabled={continueBtnDisabled}
            continueBtnTitle={step === 5 ? 'PURCHASE' : 'CONTINUE'}
          >
            {!helpActive && renderFooterContent()}
          </ActionButtonsContainer>
        </>
      )}
    </div>
  );
};

export default NewExperienceCampaignBuilderPage;
