import React from 'react';
import styles from './CodaPartnerUpdate.module.scss';

function CodaPartnerUpdate() {
  return (
    <iframe
      className={styles.embed}
      src="https://coda.io/embed/Llr4VuQond/_suMoP?viewMode=embedplay"
      width="1189"
      height="607"
      style={{ maxWidth: '100%' }}
      allow="fullscreen"
    ></iframe>
  );
}

export default CodaPartnerUpdate;
