import React from 'react';
import styles from './CampaignBuilderHeader.module.scss';

const CampaignBuilderHeader = ({ title, description }) => {
  return (
    <div>
      <div className={styles.rowTextContainer}>
        <p className={styles.title}>{title}</p>
        <p className={styles.rowText}>{description}</p>
      </div>
      <div className={styles.line} />
    </div>
  );
};

export default CampaignBuilderHeader;
