import {
  ADD_PAYMENT_METHOD_ERROR,
  ADD_PAYMENT_METHOD_REQUEST,
  ADD_PAYMENT_METHOD_SUCCESS,
  CREATE_CAMPAIGN_ERROR,
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  DELETE_PAYMENT_METHOD_ERROR,
  DELETE_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_SUCCESS,
  FETCH_AUTHENTICATED_ADVERTISER_REQUEST,
  FETCH_AUTHENTICATED_ADVERTISER_SUCCESS,
  FETCH_CAMPAIGN_COMMON_DATA_REQUEST,
  FETCH_CAMPAIGN_COMMON_DATA_SUCCESS,
  FETCH_COMMON_DATA_REQUEST,
  FETCH_COMMON_DATA_SUCCESS,
  FETCH_EYVOCATE_LOCATIONS_ATTEMPT,
  FETCH_EYVOCATE_LOCATIONS_SUCCESS,
  FETCH_EYVOCATE_LOCATIONS_ERROR,
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  MOBILE_FALSE,
  MOBILE_TRUE,
  SESSION_EXPIRED,
  SET_DEFAULT_PAYMENT_METHOD_ERROR,
  SET_DEFAULT_PAYMENT_METHOD_REQUEST,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  SET_TOKEN,
  SET_VERIFICATION_TOKEN,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  RESEND_VERIFICATION_LINK,
  SET_DISABLE_PUBLISH,
  UPDATE_COMPANY_INFO_REQUEST,
  UPDATE_COMPANY_INFO_SUCCESS,
  UPDATE_COMPANY_INFO_ERROR,
  UPDATE_COMPANY_INFO_RESET,
  DELETE_TASK_TEMPLATE_SUCCESS,
  DELETE_TASK_TEMPLATE_REQUEST,
  ADD_SOCIAL_ACCOUNT_REQUEST,
  ADD_SOCIAL_ACCOUNT_SUCCESS,
  UPDATE_SOCIAL_ACCOUNT_REQUEST,
  UPDATE_SOCIAL_ACCOUNT_SUCCESS,
  ADD_SOCIAL_ACCOUNT_RESET,
  VERIFY_SOCIAL_ACCOUNT_REQUEST,
  VERIFY_SOCIAL_ACCOUNT_SUCCESS,
  VERIFY_SOCIAL_ACCOUNT_RESET,
  FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ATTEMPT,
  FETCH_ADVERTISER_SOCIAL_ACCOUNTS_SUCCESS,
  FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ERROR,
  DELETE_SOCIAL_ACCOUNT_SUCCESS,
  DELETE_SOCIAL_ACCOUNT_REQUEST,
  DELETE_SOCIAL_ACCOUNT_RESET,
  FETCH_CAMPAIGN_TEMPLATES_REQUEST,
  FETCH_CAMPAIGN_TEMPLATES_SUCCESS,
  VERIFY_ACCOUNT_ERROR,
  UPDATE_SOCIAL_ACCOUNT_RESET,
  APPLY_TO_PARTNER_PROGRAM_REQUEST,
  APPLY_TO_PARTNER_PROGRAM_SUCCESS,
  APPLY_TO_PARTNER_PROGRAM_ERROR,
  APPLY_TO_PARTNER_PROGRAM_RESET,
} from './constants';

export function fetchAuthenticatedAdvertiser(
  impersonateToken = null,
  verificationToken = null
) {
  return {
    type: FETCH_AUTHENTICATED_ADVERTISER_REQUEST,
    impersonateToken,
    verificationToken,
  };
}

export function fetchAuthenticatedAdvertiserSuccess(advertiser) {
  return {
    type: FETCH_AUTHENTICATED_ADVERTISER_SUCCESS,
    advertiser,
  };
}

export function logout(mobile) {
  return {
    type: LOGOUT_REQUEST,
    mobile,
  };
}

export function logoutSuccess(mobile) {
  return {
    type: LOGOUT_SUCCESS,
    mobile,
  };
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    token,
  };
}

export function sessionExpired() {
  return {
    type: SESSION_EXPIRED,
  };
}

export function fetchCommonData() {
  return {
    type: FETCH_COMMON_DATA_REQUEST,
  };
}

export function fetchCommonDataSuccess(commonData) {
  return {
    type: FETCH_COMMON_DATA_SUCCESS,
    commonData,
  };
}

export function fetchCampaignCommonData() {
  return {
    type: FETCH_CAMPAIGN_COMMON_DATA_REQUEST,
  };
}

export function fetchCampaignCommonDataSuccess(campaignCommonData) {
  return {
    type: FETCH_CAMPAIGN_COMMON_DATA_SUCCESS,
    campaignCommonData,
  };
}

export function fetchEyVocateLocations(inputFieldValue) {
  return {
    type: FETCH_EYVOCATE_LOCATIONS_ATTEMPT,
    inputFieldValue,
  };
}

export function fetchEyVocateLocationsSuccess(eyVocateLocations) {
  return {
    type: FETCH_EYVOCATE_LOCATIONS_SUCCESS,
    eyVocateLocations,
  };
}

export function fetchEyVocateLocationsError(error) {
  return {
    type: FETCH_EYVOCATE_LOCATIONS_ERROR,
    payload: error,
  };
}

export function createCampaign(campaignData) {
  return {
    type: CREATE_CAMPAIGN_REQUEST,
    campaignData,
  };
}

export function createCampaignSuccess(campaignData) {
  return {
    type: CREATE_CAMPAIGN_SUCCESS,
    campaignData,
  };
}

export function createCampaignError() {
  return {
    type: CREATE_CAMPAIGN_ERROR,
  };
}

export function fetchPaymentMethods() {
  return {
    type: FETCH_PAYMENT_METHODS_REQUEST,
  };
}

export function fetchPaymentMethodsSuccess(paymentMethods) {
  return {
    type: FETCH_PAYMENT_METHODS_SUCCESS,
    paymentMethods,
  };
}

export function deleteTaskTemplateSuccess(id) {
  return {
    type: DELETE_TASK_TEMPLATE_SUCCESS,
    id,
  };
}

export function deleteTaskTemplateRequest(id) {
  return {
    type: DELETE_TASK_TEMPLATE_REQUEST,
    id,
  };
}

export function deleteSocialAccountSuccess(id) {
  return {
    type: DELETE_SOCIAL_ACCOUNT_SUCCESS,
    id,
  };
}

export function deleteSocialAccountRequest(id) {
  return {
    type: DELETE_SOCIAL_ACCOUNT_REQUEST,
    id,
  };
}

export function deleteSocialAccountReset() {
  return {
    type: DELETE_SOCIAL_ACCOUNT_RESET,
  };
}
export function addPaymentMethod(
  paymentProviderPaymentMethodId,
  onSuccess = () => {},
  onError = () => {}
) {
  return {
    type: ADD_PAYMENT_METHOD_REQUEST,
    paymentProviderPaymentMethodId,
    meta: {
      onSuccess,
      onError,
    },
  };
}

export function addPaymentMethodSuccess(paymentMethod) {
  return {
    type: ADD_PAYMENT_METHOD_SUCCESS,
    paymentMethod,
  };
}

export function addPaymentMethodError() {
  return {
    type: ADD_PAYMENT_METHOD_ERROR,
  };
}

export function setDefaultPaymentMethod(paymentMethod) {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD_REQUEST,
    paymentMethod,
  };
}

export function setDefaultPaymentMethodSuccess(paymentMethod) {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
    paymentMethod,
  };
}

export function setDefaultPaymentMethodError() {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD_ERROR,
  };
}

export function updatePartialCompanyInfo(companyInfo) {
  return {
    type: UPDATE_COMPANY_INFO_REQUEST,
    companyInfo,
  };
}

export function updatePartialCompanyInfoSuccess(advertiser) {
  return {
    type: UPDATE_COMPANY_INFO_SUCCESS,
    advertiser,
  };
}

export function updatePartialCompanyInfoError() {
  return {
    type: UPDATE_COMPANY_INFO_ERROR,
  };
}

export function updatePartialCompanyInfoReset() {
  return {
    type: UPDATE_COMPANY_INFO_RESET,
  };
}

export function addSocialAccountRequest(socialAccountInfo) {
  return {
    type: ADD_SOCIAL_ACCOUNT_REQUEST,
    socialAccountInfo,
  };
}

export function addSocialAccountSuccess(socialAccount) {
  return {
    type: ADD_SOCIAL_ACCOUNT_SUCCESS,
    socialAccount,
  };
}

export function updateSocialAccountRequest(id, socialAccountInfo) {
  return {
    type: UPDATE_SOCIAL_ACCOUNT_REQUEST,
    id,
    socialAccountInfo,
  };
}

export function updateSocialAccountSuccess(id, socialAccountInfo) {
  return {
    type: UPDATE_SOCIAL_ACCOUNT_SUCCESS,
    id,
    socialAccountInfo,
  };
}

export function updateSocialAccountReset() {
  return {
    type: UPDATE_SOCIAL_ACCOUNT_RESET,
  };
}

export function addSocialAccountReset() {
  return {
    type: ADD_SOCIAL_ACCOUNT_RESET,
  };
}

export function verifySocialAccountRequest(socialAccountInfo) {
  return {
    type: VERIFY_SOCIAL_ACCOUNT_REQUEST,
    socialAccountInfo,
  };
}

export function verifySocialAccountSuccess(socialAccount) {
  return {
    type: VERIFY_SOCIAL_ACCOUNT_SUCCESS,
    socialAccount,
  };
}

export function verifySocialAccountReset() {
  return {
    type: VERIFY_SOCIAL_ACCOUNT_RESET,
  };
}

export function deletePaymentMethod(paymentMethod) {
  return {
    type: DELETE_PAYMENT_METHOD_REQUEST,
    paymentMethod,
  };
}

export function deletePaymentMethodSuccess(paymentMethod) {
  return {
    type: DELETE_PAYMENT_METHOD_SUCCESS,
    paymentMethod,
  };
}

export function deletePaymentMethodError() {
  return {
    type: DELETE_PAYMENT_METHOD_ERROR,
  };
}

export function verifyAccount(token, onSuccess, onError) {
  return {
    type: VERIFY_ACCOUNT_REQUEST,
    token,
    meta: { onSuccess, onError },
  };
}

export function verifyAccountSuccess() {
  return {
    type: VERIFY_ACCOUNT_SUCCESS,
  };
}

export function verifyAccountError() {
  return {
    type: VERIFY_ACCOUNT_ERROR,
  };
}

export function setVerificationToken(token) {
  return {
    type: SET_VERIFICATION_TOKEN,
    token,
  };
}

export function setMobileTrue() {
  return {
    type: MOBILE_TRUE,
    action: true,
  };
}

export function setMobileFalse() {
  return {
    type: MOBILE_FALSE,
    action: false,
  };
}

export function resendVerificationLink() {
  return {
    type: RESEND_VERIFICATION_LINK,
  };
}

export function setDisablePublish() {
  return {
    type: SET_DISABLE_PUBLISH,
  };
}

export function fetchAdvertisersSocialAccounts() {
  return {
    type: FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ATTEMPT,
  };
}

export function fetchAdvertisersSocialAccountsSuccess(socialAccounts) {
  return {
    type: FETCH_ADVERTISER_SOCIAL_ACCOUNTS_SUCCESS,
    socialAccounts,
  };
}

export function fetchAdvertisersSocialAccountsError(error) {
  return {
    type: FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ERROR,
    payload: error,
  };
}

export function fetchCampaignTemplates(page, perPage, search = '') {
  return {
    type: FETCH_CAMPAIGN_TEMPLATES_REQUEST,
    page,
    perPage,
    search,
  };
}

export function fetchCampaignTemplatesSuccess(campaignTemplates) {
  return {
    type: FETCH_CAMPAIGN_TEMPLATES_SUCCESS,
    campaignTemplates,
  };
}

export function applyToPartnerProgramRequest(data, onSuccess, onError) {
  return {
    type: APPLY_TO_PARTNER_PROGRAM_REQUEST,
    data,
    meta: { onSuccess, onError },
  };
}

export function applyToPartnerProgramSuccess(advertiser) {
  return {
    type: APPLY_TO_PARTNER_PROGRAM_SUCCESS,
    advertiser,
  };
}

export function applyToPartnerProgramError() {
  return {
    type: APPLY_TO_PARTNER_PROGRAM_ERROR,
  };
}

export function applyToPartnerProgramReset() {
  return {
    type: APPLY_TO_PARTNER_PROGRAM_RESET,
  };
}
