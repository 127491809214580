import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import {
  GoogleMap,
  StandaloneSearchBox,
  DrawingManager,
  Polygon,
  useJsApiLoader,
  Marker,
  Circle,
} from '@react-google-maps/api';
import config from 'config';
import { GOOGLE_MAPS } from '../../../constants';
import LoadingSpinner from 'components/Layout/LoadingSpinner';
import styles from './CampaignLocation.module.scss';
import Button from 'components/Buttons/Button';
import { mapStyles } from 'utils/mapStyles.js';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { ReactComponent as MyLocation } from 'assets/images/my-location.svg';
import Spinner from 'assets/images/spinner.gif';
import Error from 'components/Forms/Error';

const CampaignLocation = ({
  polygon,
  handlePolygonDrawn,
  location,
  setLocation,
  handleValidation,
  disabled,
  circle,
  setCircle,
  setValue,
}) => {
  const { formatMessage } = useIntl();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.googleMaps.apiKey,
    libraries: GOOGLE_MAPS.LIBRARIES,
  });

  const [type, setType] = useState(GOOGLE_MAPS.LOCATION_TYPE_COUNTRY);
  const [ref, setRef] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [error, setError] = useState('');

  const [currentLocationLoading, setCurrentLocationLoading] = useState(false);

  const [isMarkerShown, setIsMarkerShown] = useState(false);

  const [zoom, setZoom] = useState(4);
  const [isDrawReady, setIsDrawReady] = useState(false);
  const [draggableMarker, setDraggableMarker] = useState(true);
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: '10px',
    marginBottom: '16px',
    position: 'relative',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  };

  let center = location;

  const handleReset = () => {
    if (ref.getPlaces()) {
      if (
        !locationContainsExactAddress(ref.getPlaces()[0].address_components)
      ) {
        setIsMarkerShown(false);
      }
    } else {
      setIsMarkerShown(false);
    }

    setValue('hasMapLocation', false, { shouldValidate: true });
    handlePolygonDrawn([]);
    setCircle(null);
  };

  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    polygon.map((place) => {
      bounds.extend(place);
      return place;
    });
    map?.fitBounds && map.fitBounds(bounds);
  };

  const handleMapLoad = (ref) => {
    polygon.length > 0 && fitBounds(ref);
  };

  useEffect(() => {
    handleMapLoad(mapRef);
    //eslint-disable-next-line
  }, [polygon, mapRef]);

  const onLoad = (ref) => {
    setRef(ref);
  };

  const locationContainsExactAddress = (location) => {
    if (location) {
      for (const obj of location) {
        if (obj.types.includes('street_number')) {
          return true;
        }
      }
    }

    return false;
  };

  const onPlacesChanged = () => {
    handlePolygonDrawn([]);
    setDraggableMarker(true);
    setIsMarkerShown(false);

    if (ref.getPlaces()[0].address_components) {
      if (locationContainsExactAddress(ref.getPlaces()[0].address_components)) {
        setIsMarkerShown(true);
        setDraggableMarker(false);
      }

      setLocation({
        lat: ref.getPlaces()[0].geometry.location.lat(),
        lng: ref.getPlaces()[0].geometry.location.lng(),
      });
      setType(ref.getPlaces()[0].types[0]);
    }
  };

  useEffect(() => {
    if (type === GOOGLE_MAPS.LOCATION_TYPE_COUNTRY) {
      setZoom(4);
    } else if (type === GOOGLE_MAPS.LOCATION_TYPE_LOCALITY) {
      setZoom(9);
    } else {
      setZoom(18);
    }
  }, [type]);

  useEffect(() => {
    if (!polygon.length) {
      setIsDrawReady(false);
    }
  }, [polygon]);

  const handleCircleComplete = (circle) => {
    const radius = circle.getRadius();
    const latitude = circle.getCenter().lat();
    const longitude = circle.getCenter().lng();

    handlePolygonDrawn([]);
    setLocation({ lat: latitude, lng: longitude });
    setCircle({ radius, lat: latitude, lng: longitude });
    setValue('hasMapLocation', true, { shouldValidate: true });

    setIsMarkerShown(true);
    circle.setMap(null);
  };

  useEffect(() => {
    if (!!polygon.length || !!circle) {
      setValue('hasMapLocation', true, { shouldValidate: true });
    }
  }, [polygon, circle, setValue]);

  const handlePolygonComplete = (path) => {
    setCircle(null);

    const createdPolygon = path
      .getPath()
      .getArray()
      .map((latLng) => {
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    handlePolygonDrawn(createdPolygon);

    setValue('hasMapLocation', true, { shouldValidate: true });
    if (ref) {
      if (ref.getPlaces()) {
        if (
          !locationContainsExactAddress(ref.getPlaces()[0].address_components)
        ) {
          setIsMarkerShown(true);
          handleValidation(true);
          setError('');
          let bounds = new window.google.maps.LatLngBounds();
          for (let i = 0; i < createdPolygon.length; i++) {
            bounds.extend(
              new window.google.maps.LatLng(
                createdPolygon[i].lat,
                createdPolygon[i].lng
              )
            );
          }
          let center = bounds.getCenter();
          let latitude = center.lat();
          let longitude = center.lng();

          if (latitude !== location.lat || longitude !== location.lng) {
            setLocation({ lat: latitude, lng: longitude });
          }
        }
      } else {
        setIsMarkerShown(true);
        handleValidation(true);
        setError('');
        let bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < createdPolygon.length; i++) {
          bounds.extend(
            new window.google.maps.LatLng(
              createdPolygon[i].lat,
              createdPolygon[i].lng
            )
          );
        }
        let center = bounds.getCenter();
        let latitude = center.lat();
        let longitude = center.lng();

        if (latitude !== location.lat || longitude !== location.lng) {
          setLocation({ lat: latitude, lng: longitude });
        }
      }
    }

    path.setMap(null);
  };

  const infoWindow = useMemo(
    () => (window.google ? new window.google.maps.InfoWindow() : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoaded]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const handlePolygonEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPolygon = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      handlePolygonDrawn(nextPolygon);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPolygonLoad = useCallback(
    (path) => {
      polygonRef.current = path;
      const pol = path.getPath();
      listenersRef.current.push(
        pol.addListener('set_at', handlePolygonEdit),
        pol.addListener('insert_at', handlePolygonEdit),
        pol.addListener('remove_at', handlePolygonEdit)
      );
    },
    [handlePolygonEdit]
  );

  const onZoomChanged = () => {
    if (mapRef) {
      setZoom(mapRef.zoom);
    }
  };

  useEffect(() => {
    if ((!!polygon.length || !!circle) && isLoaded) {
      setIsMarkerShown(true);

      let selectedPoint = new window.google.maps.LatLng(
        location.lat,
        location.lng
      );

      if (circle) {
        let retCircle = new window.google.maps.Circle({
          center: { lat: circle.lat, lng: circle.lng },
          radius: circle.radius,
        });

        if (
          window.google.maps.geometry.spherical.computeDistanceBetween(
            selectedPoint,
            retCircle.getCenter()
          ) > retCircle.getRadius()
        ) {
          handleValidation(false);
          setError('A geofenced area must contain location.');
        } else {
          handleValidation(true);
          setError('');
        }
      } else {
        let retPolygon = new window.google.maps.Polygon({
          paths: polygon,
        });
        if (
          !window.google.maps.geometry.poly.containsLocation(
            selectedPoint,
            retPolygon
          )
        ) {
          handleValidation(false);
          setError('A geofenced area must contain location.');
        } else {
          handleValidation(true);
          setError('');
        }
      }
    }
  }, [polygon, location, circle, handleValidation, isLoaded]);

  const showCurrentLocation = () => {
    setCurrentLocationLoading(true);
    if (infoWindow) {
      infoWindow.close();
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          mapRef.setCenter(
            new window.google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            )
          );
          setZoom(15);
          setCurrentLocationLoading(false);
        },
        () => {
          setCurrentLocationLoading(false);
          if (infoWindow) {
            handleLocationError(true, infoWindow);
          }
        }
      );
    } else {
      setCurrentLocationLoading(false);
      if (infoWindow) {
        handleLocationError(false, infoWindow);
      }
    }
  };

  const handleLocationError = (browserHasGeolocation, infoWindow) => {
    if (mapRef) {
      infoWindow.setPosition(mapRef.getCenter());
      infoWindow.setContent(
        browserHasGeolocation
          ? 'Enable location service on your browser to get your current position.'
          : "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(mapRef);
    }
  };

  const onDragEnd = (position) => {
    const { latLng } = position;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setLocation({ lat, lng });
  };

  const renderMap = () => {
    const drawingOptions = {
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: isDrawReady
          ? [
              window.google.maps.drawing.OverlayType.POLYGON,
              window.google.maps.drawing.OverlayType.CIRCLE,
            ]
          : [],
      },
    };

    return (
      <GoogleMap
        onLoad={(map) => {
          handleMapLoad(map);
          setMapRef(map);
        }}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onZoomChanged={onZoomChanged}
        options={{
          styles: mapStyles,
          streetViewControl: false,
          mapTypeControl: false,
          scaleControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        {isMarkerShown && (
          <Marker
            onDragEnd={onDragEnd}
            draggable={draggableMarker}
            position={location}
          />
        )}
        <DrawingManager
          options={drawingOptions}
          onPolygonComplete={handlePolygonComplete}
          onCircleComplete={handleCircleComplete}
        />
        {polygon && (
          <>
            <Polygon
              options={{
                fillColor: 'red',
                fillOpacity: 0.5,
                strokeWeight: 5,
                clickable: false,
                zIndex: 1,
              }}
              editable
              draggable
              path={polygon}
              onLoad={onPolygonLoad}
              onMouseUp={handlePolygonEdit}
              onDragEnd={handlePolygonEdit}
              onUnmount={onUnmount}
            />
          </>
        )}
        {circle && (
          <Circle
            center={{ lat: circle.lat, lng: circle.lng }}
            radius={circle.radius}
          />
        )}
        <div className={styles.buttonsWrapper}>
          <Button
            type="button"
            onClick={handleReset}
            altReverse
            className={styles.buttonReset}
            disabled={disabled}
          >
            {formatMessage(messages.reset)}
          </Button>
          <Button
            type="button"
            disabled={isDrawReady || disabled}
            onClick={() => {
              setIsDrawReady(true);
            }}
            alt
            className={styles.buttonDraw}
          >
            {formatMessage(messages.draw)}
          </Button>
        </div>
        <Button
          type="button"
          onClick={showCurrentLocation}
          alt
          className={styles.positonButton}
        >
          {currentLocationLoading ? (
            <img src={Spinner} alt="Spinner" className={styles.spinner} />
          ) : (
            <Icon icon={MyLocation} className={styles.myLocationIcon} />
          )}
        </Button>
      </GoogleMap>
    );
  };

  return (
    <div className={styles.content}>
      <div style={{ position: 'relative' }}>
        <div className={styles.label}>
          <p className={styles.labelText}>{formatMessage(messages.location)}</p>
          <Tooltip label={formatMessage(messages.locationTooltip)} />
        </div>
        {isLoaded && (
          <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <input
              name="location"
              type="search"
              autoComplete="off"
              placeholder={formatMessage(messages.locationPlaceholder)}
              label={formatMessage(messages.location)}
              className={`${styles.input} ${disabled ? styles.disabled : ''}`}
            ></input>
          </StandaloneSearchBox>
        )}
        <Icon className={styles.icon} size={'sm14'} icon={SearchIcon} />
      </div>

      {isLoaded ? renderMap() : <LoadingSpinner />}
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default CampaignLocation;
