export const WELCOME = '/';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_CREATE_CAMPAIGN = '/campaigns/create-campaign';
export const DASHBOARD_CREATE_CAMPAIGN_OBJECTIVES =
  '/campaigns/create-campaign/objectives';
export const ECW_CREATE_CAMPAIGN =
  '/campaigns/ecw-campaigns/ecw-create-campaign';
export const ECW_CREATE_CAMPAIGN_OBJECTIVES =
  '/campaigns/ecw-campaigns/ecw-create-campaign/objectives';
export const SINGLE_CAMPAIGN = '/campaigns/:id';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const REPORTS = '/reports';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password/:token';
export const ADVERTISER_SETTINGS = '/settings';
export const NEED_HELP = '/need-help';
export const CREATE_CAMPAIGN = '/create-campaign';
export const ADVERTISERS = '/advertisers';
export const ABOUT_US = '/about-us';
export const JOBS = '/jobs';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const NOT_FOUND = '/404';
export const CONTACT = '/contact';
export const WE_LOVE_HOUSTON = '/city/houston';
export const CAMPAIGN_SUMMARY = '/campaign-summary/:id';
export const CREATE_SIMPLE_CAMPAIGN = '/create-simple';
export const VERIFY_EMAIL = '/verifyemail';
export const NEW_CREATE_CAMPAIGN = '/create-experience';
export const DELETE_ACCOUNT = '/delete-account';
export const PARTNER_PROGRAM = '/partner-program';
export const PARTNER = '/partner';
export const PARTNER_PROGRAM_INFO = '/partner-program-info';
export const APP_VIDEO_LIBRARY = '/app-video-library';
export const BUILDER_VIDEO_LIBRARY = '/builder-video-library';
export const QA_VIDEO_LIBRARY = '/qa-video-library';
export const PARTNER_PROGRAM_UPDATE = '/partner-program-update';
export const EYCROWD_UPDATES = '/eycrowd-updates';
export const WHY_EYCROWD = '/why-eycrowd';
