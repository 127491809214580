import React from 'react';
import ErrorContainer from '../ErrorContainer';
import styles from './CheckBox.module.scss';
import classNames from 'classnames';

const CheckBox = ({
  checked,
  disabled,
  onChange,
  error,
  hideErrorMessage,
  children,
  circle,
}) => {
  return (
    <label
      className={classNames(styles.label, disabled && styles.disabledLabel)}
      disabled={disabled}
    >
      <div className={styles.checkBoxWrapper}>
        {checked ? (
          circle ? (
            <div className={styles.checkedCircle} />
          ) : (
            <div className={styles.checked} />
          )
        ) : circle ? (
          <div className={styles.uncheckedCircle} />
        ) : (
          <div className={styles.unchecked} />
        )}
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={styles.hiddenInput}
        />
        <div className={styles.content}>{children}</div>
      </div>
      {!hideErrorMessage && <ErrorContainer errorText={error} />}
    </label>
  );
};

export default CheckBox;
