import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styles from './CampaignExperienceBasics.module.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import ErrorContainer from '../ErrorContainer';
import Slider from '../Slider';
import classNames from 'classnames';
import CheckBox from '../CheckBox';
import SearchInput from '../SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeSelectAgeRanges,
  makeSelectEyVocateLocations,
  makeSelectInterests,
} from 'containers/App/selectors';
import { useMemo } from 'react';
import globalMessages from 'messages';
import { useIntl } from 'react-intl';
import { DEMOGRAPHIC_OPTIONS } from '../../../constants';
import { makeTasksSelector } from 'containers/NewExperienceCampaignBuilderPage/selectors';
import _ from 'lodash';
import { fetchEyVocateLocations } from 'containers/App/actions';
import PoweredByGoogleLogo from 'assets/images/powered_by_google_on_white_hdpi.png';
import Tooltip from 'components/Tooltip';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import toasterVariants from 'constants/toasterVariants';
import { timeConvert } from '../TaskDetails';
import CampaignBuilderHeader from '../CampaignBuilderHeader';
import RadioButton from '../RadioButton';
import config from '../../../config';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import findState from 'utils/getStateObject';

const CampaignExperienceBasics = forwardRef(
  (
    {
      setStep,
      setFormData,
      selectedObjectives,
      formData,
      setTotalEyvocate,
      setNumOfEyvocates,
      setPricePerHourWatch,
      userLocations,
      importedCampaign,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();

    const interests = useSelector(makeSelectInterests());
    const ageRanges = useSelector(makeSelectAgeRanges());
    const selectedEyVocateLocations = useSelector(
      makeSelectEyVocateLocations()
    );
    const tasks = useSelector(makeTasksSelector());

    const [searchInterest, setSearchInterest] = useState('');
    const [locationsSearch, setLocationsSearch] = useState('');

    const dispatch = useDispatch();

    const mobileLinkPrefix =
      config.api.baseUrl === 'https://eycrowd-server.production.eycrowd.com/api'
        ? 'https://eycrowd.app.link/'
        : 'https://eycrowd.test-app.link/';

    const searchedInterest = useMemo(() => {
      if (searchInterest.trim()) {
        return interests.filter((i) =>
          formatMessage(globalMessages.interests[i.slug])
            .toLocaleLowerCase()
            .includes(searchInterest.toLocaleLowerCase())
        );
      } else {
        return [{ id: 999, slug: 'all-interests' }, ...interests];
      }
    }, [searchInterest, interests, formatMessage]);

    const selectedTasks = useMemo(() => {
      const retTasks = [];

      for (const objective of selectedObjectives) {
        if ((objective.id + '').includes('/')) {
          let objectiveId = +objective.id.split('/')[0];
          const objectiveTasks = tasks.filter(
            (task) => task.objectiveId === objectiveId
          );
          retTasks.push(
            ...objectiveTasks.map((task) => ({
              ...task,
              objectiveId: objective.id,
            }))
          );
        } else {
          const objectiveTasks = tasks.filter(
            (task) => task.objectiveId === objective.id
          );
          retTasks.push(...objectiveTasks);
        }
      }

      return retTasks;
    }, [selectedObjectives, tasks]);

    const campaignBasicInfoSchema = yup
      .object({
        interests: yup.array().min(1, 'At least one interest must be selected'),
        ageRanges: yup
          .array()
          .min(1, 'At least one age range must be selected'),
        genders: yup.array().min(1, 'At least one gender must be selected'),
        locations: yup.array().min(1, 'At least one location must be selected'),
      })
      .required();

    const {
      handleSubmit,
      setValue,
      formState: { errors },
      control,
      getValues,
      watch,
    } = useForm({
      defaultValues: {
        numOfEyvocates: 30,
        file: null,
        pricePerHour: 25,
        overAllocationPercentage: 30,
        interests: [{ id: 999, slug: 'all-interests' }],
        isPrivate: false,
        ageRanges: [{ id: 0, label: 'All' }],
        genders: [
          {
            id: DEMOGRAPHIC_OPTIONS.ALL_GENDERS,
            label: 'All',
          },
        ],
        locations: [{ id: 'planet-earth', label: 'Planet Earth' }],
        mobileLink: null,
      },
      delayError: 300,
      resolver: yupResolver(campaignBasicInfoSchema),
    });

    const numOfEyvocatesWatch = watch('numOfEyvocates');
    const overAllocationPercentagWatch = watch('overAllocationPercentage');
    const pricePerHourWatch = watch('pricePerHour');
    const selectedLocations = watch('locations');

    const isPrivateWatch = watch('isPrivate');
    const mobileLinkWatch = watch('mobileLink');

    useEffect(() => {
      if (!mobileLinkWatch) {
        setValue('mobileLink', Math.random().toString(36).substring(3, 15));
      }
    }, [mobileLinkWatch, setValue]);

    useEffect(() => {
      setPricePerHourWatch(pricePerHourWatch);
    }, [pricePerHourWatch, setPricePerHourWatch]);

    useEffect(() => {
      if (Object.keys(formData).length > 2) {
        if (formData.ageRanges) {
          setValue('ageRanges', formData.ageRanges);
          setValue('numOfEyvocates', formData.numOfEyvocates);
          setValue(
            'overAllocationPercentage',
            formData.overAllocationPercentage
          );
          setValue('interests', formData.interests);
          setValue('genders', formData.genders);
          setValue('locations', formData.locations);
          setValue('pricePerHour', formData.pricePerHour);
          setValue('isPrivate', formData.isPrivate);
          setValue('mobileLink', formData.mobileLink);
        } else {
          if (importedCampaign) {
            if (importedCampaign.numOfEyvocates) {
              setValue('numOfEyvocates', importedCampaign.numOfEyvocates);
            }
            if (importedCampaign.overAllocationPercentage) {
              setValue(
                'overAllocationPercentage',
                importedCampaign.overAllocationPercentage
              );
            }
            if (importedCampaign.pricePerHour) {
              setValue('pricePerHour', importedCampaign.pricePerHour);
            }
            if (importedCampaign.interests) {
              setValue('interests', importedCampaign.interests);
            }
            if (importedCampaign.ageRanges) {
              setValue('ageRanges', importedCampaign.ageRanges);
            }
            if (importedCampaign.genders) {
              setValue('genders', importedCampaign.genders);
            }
            if (importedCampaign.locations) {
              setValue('locations', importedCampaign.locations);
            }
          }
        }
      }
    }, [setValue, formData, importedCampaign]);

    const totalEyvocate = useMemo(
      () =>
        +numOfEyvocatesWatch +
        Math.round(numOfEyvocatesWatch * (overAllocationPercentagWatch / 100)),
      [overAllocationPercentagWatch, numOfEyvocatesWatch]
    );

    const calculateEyVocatesEarningsRange = (hourlyRate, durationInMinutes) => {
      const adjustedDurationInMinutes = Math.ceil(durationInMinutes / 5) * 5;
      const finalDurationInMinutes =
        adjustedDurationInMinutes % 60 === 0
          ? adjustedDurationInMinutes + 5
          : adjustedDurationInMinutes;
      const durationHours = finalDurationInMinutes / 60;

      const lowPercentage = 0.3967;
      const highPercentage = 0.3217;

      const customRound = (value) => {
        const fractionalPart = value % 1;
        return fractionalPart < 0.5 ? Math.floor(value) : Math.ceil(value);
      };

      const lowPointEarningsBeforeRounding =
        hourlyRate * durationHours * (1 - lowPercentage);
      const highPointEarningsBeforeRounding =
        hourlyRate * durationHours * (1 - highPercentage);

      const lowPointEarnings = customRound(lowPointEarningsBeforeRounding);
      const highPointEarnings = customRound(highPointEarningsBeforeRounding);

      const formattedLowPointEarnings = lowPointEarnings.toFixed(2);
      const formattedHighPointEarnings = highPointEarnings.toFixed(2);

      return `$${formattedLowPointEarnings} to $${formattedHighPointEarnings}`;
    };

    useEffect(() => {
      setNumOfEyvocates(totalEyvocate);
    }, [totalEyvocate, setNumOfEyvocates]);

    const taskDuration = selectedTasks
      .map((item) => item.time)
      .reduce((prev, next) => +prev + +next);

    const totalPrice = useMemo(
      () => (totalEyvocate * pricePerHourWatch * taskDuration) / 60,
      [totalEyvocate, pricePerHourWatch, taskDuration]
    );

    useEffect(() => {
      setTotalEyvocate(totalEyvocate);
    }, [totalEyvocate, setTotalEyvocate]);

    const restructureAgeRanges = useMemo(() => {
      if (!!ageRanges?.length) {
        const sortedValues = ageRanges.slice().sort((a, b) => {
          return a.from - b.from;
        });

        const newValues = [
          ...sortedValues.slice(0, 2),
          { id: 998, from: 35, to: 54 },
          { id: 999, from: 55 },
        ];

        const mappedSortedValues = newValues.map((ageRange) => {
          if (ageRange.to) {
            return {
              id: ageRange.id,
              label: ageRange.from + '-' + ageRange.to,
            };
          } else {
            return { id: ageRange.id, label: '55+' };
          }
        });
        mappedSortedValues.unshift({ id: 0, label: 'All' });

        return mappedSortedValues;
      } else {
        return [];
      }
    }, [ageRanges]);

    const genderOptions = [
      {
        id: DEMOGRAPHIC_OPTIONS.ALL_GENDERS,
        label: 'All',
      },
      {
        id: DEMOGRAPHIC_OPTIONS.MOSTLY_MALE,
        label: formatMessage(globalMessages[DEMOGRAPHIC_OPTIONS.MOSTLY_MALE]),
      },
      {
        id: DEMOGRAPHIC_OPTIONS.MOSTLY_FEMALE,
        label: formatMessage(globalMessages[DEMOGRAPHIC_OPTIONS.MOSTLY_FEMALE]),
      },
      // {
      //   id: DEMOGRAPHIC_OPTIONS.ONLY_MALE,
      //   label: formatMessage(globalMessages[DEMOGRAPHIC_OPTIONS.ONLY_MALE]),
      // },
      // {
      //   id: DEMOGRAPHIC_OPTIONS.ONLY_FEMALE,
      //   label: formatMessage(globalMessages[DEMOGRAPHIC_OPTIONS.ONLY_FEMALE]),
      // },
      // {
      //   id: DEMOGRAPHIC_OPTIONS.ONLY_CUSTOM,
      //   label: formatMessage(globalMessages[DEMOGRAPHIC_OPTIONS.ONLY_CUSTOM]),
      // },
      // {
      //   id: DEMOGRAPHIC_OPTIONS.MOSTLY_CUSTOM,
      //   label: formatMessage(globalMessages[DEMOGRAPHIC_OPTIONS.MOSTLY_CUSTOM]),
      // },
    ];

    const locationOptions = [
      { id: 'planet-earth', label: 'Planet Earth' },
      { id: 'ChIJCzYy5IS16lQRQrfeQ5K5Oxw', label: 'United States' },
      { id: 'ChIJPV4oX_65j4ARVW8IJ6IJUYs', label: 'California, USA' },
    ];

    useEffect(() => {
      if (!!userLocations.length) {
        let locations = [];
        let addedStates = new Set();

        for (const userLocation of userLocations) {
          const parts = userLocation.label.split(', ');
          const stateAbbrev = parts.length >= 2 ? parts[1] : null;

          if (stateAbbrev) {
            const location = findState(stateAbbrev);
            if (location && !addedStates.has(location.label)) {
              locations.push(location);
              addedStates.add(location.label);
            }
          } else {
            locations.push(userLocation);
          }
        }
        setValue('locations', locations);
      }
    }, [userLocations, setValue]);

    const onSubmit = (data) => {
      setFormData((prev) => ({ ...prev, ...data, totalPrice, totalEyvocate }));
      setStep((step) => step + 1);
    };

    const changeHandler = useCallback(
      (text) => {
        if (text !== '') {
          dispatch(fetchEyVocateLocations(text));
        }
      },
      [dispatch]
    );

    const debouncedChangeHandler = useMemo(
      () => _.debounce(changeHandler, 300),
      [changeHandler]
    );

    const locations = useMemo(() => {
      if (selectedEyVocateLocations?.length && locationsSearch !== '') {
        return selectedEyVocateLocations?.map((eyVocateLocation) => {
          return {
            id: eyVocateLocation.externalPlaceId,
            label: eyVocateLocation.description,
          };
        });
      } else {
        return locationOptions;
      }
    }, [selectedEyVocateLocations, locationOptions, locationsSearch]);

    const cancelLocationSearch = () => {
      setLocationsSearch('');
    };

    useImperativeHandle(ref, () => ({
      handleFormSubmit() {
        handleSubmit(onSubmit)();
      },
      onFormBack() {
        const dataForm = getValues();
        setFormData((prev) => ({
          ...prev,
          ...dataForm,
          totalPrice,
          totalEyvocate,
        }));
        setStep((step) => step - 1);
      },
    }));

    const customerInvitaitonMimeTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
      'text/plain',
      'application/rtf',
    ];

    const validateCustomerInvitationFile = (file) => {
      if (file.size < 10685760) {
        if (customerInvitaitonMimeTypes.includes(file.type)) {
          return file;
        } else {
          dispatch(
            enqueueSnackbar({
              message: `unsuported file type`,
              variant: toasterVariants.error,
            })
          );
        }
      } else {
        dispatch(
          enqueueSnackbar({
            message: `file size error`,
            variant: toasterVariants.error,
          })
        );
      }
    };

    const handleCopyClick = () => {
      navigator.clipboard.writeText(`${mobileLinkPrefix}${mobileLinkWatch}`);
      dispatch(
        enqueueSnackbar({
          message: 'Link successfully copied to your clipboard',
          variant: toasterVariants.success,
        })
      );
    };

    return (
      <>
        <div className={styles.contentContainer}>
          <div className={styles.container}>
            <div className={styles.summaryContainer}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <>
                  <CampaignBuilderHeader title="EYVOCATE™ SELECTION" />
                  <div className={styles.separator} />

                  <RowContainer
                    title="Number of EyVocates™:"
                    description="30 or more recommended"
                    withoutError
                    infoButton="Choose the number of EyVocates for this campaign experience. 30 or more are recommended."
                  >
                    <Controller
                      name="numOfEyvocates"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Slider
                            value={field.value}
                            onChange={(event) => {
                              if (event.target) {
                                field.onChange(event.target.value);
                              } else {
                                field.onChange(event);
                              }
                            }}
                            min={5}
                            max={300}
                            step={1}
                          />
                        );
                      }}
                    />
                  </RowContainer>
                  <RowContainer
                    title="Over-Book Percentage:"
                    description="30% is recommended"
                    withoutError
                    infoButton="Select the over-booking percentage of EyVocates to account for last minute cancelations, etc. 30% is recommended"
                  >
                    <Controller
                      name="overAllocationPercentage"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Slider
                            valueSufix="%"
                            value={field.value}
                            onChange={(event) => {
                              if (event.target) {
                                field.onChange(event.target.value);
                              } else {
                                field.onChange(event);
                              }
                            }}
                            min={20}
                            max={60}
                            step={5}
                          />
                        );
                      }}
                    />
                  </RowContainer>
                  <RowContainer
                    title="Total EyVocates™:"
                    withoutError
                    infoButton="The total number of EyVocates for this campaign experience including over-booking."
                  >
                    <p className={styles.windowEnd}>{totalEyvocate}</p>
                  </RowContainer>

                  <RowContainer
                    title="EyVocate™ Mix:"
                    withoutError
                    infoButton={
                      'Public experiences on EyCrowd are open to all EyVocate users who fit your EyVocate Selection criteria, while private experiences are exclusive to invitees with whom you share the Experience Link URL.'
                    }
                  >
                    <div className={styles.eyvocateMix}>
                      <Controller
                        name="isPrivate"
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className={styles.radioButtonContainer}>
                              <RadioButton
                                title="Public:"
                                checked={!field.value}
                                onClick={() => field.onChange(false)}
                              />
                              <p>My Customers and Public App Users</p>
                            </div>
                          );
                        }}
                      />

                      <Controller
                        name="isPrivate"
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className={styles.radioButtonContainer}>
                              <RadioButton
                                title="Private:"
                                checked={field.value}
                                onClick={() => field.onChange(true)}
                              />
                              <p>My Customers Only</p>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </RowContainer>

                  {/* <RowContainer
                    title="Private Customer Invitation List (60% to 100% recommended) :"
                    withoutError
                    infoButton="The total number of EyVocates for this campaign experience including over-booking."
                  >
                    <Controller
                      name="file"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <div className={styles.fileInputCard}>
                              <input
                                type="file"
                                onChange={(e) => {
                                  const { files: newFiles } = e.target;
                                  if (newFiles.length) {
                                    const file = validateCustomerInvitationFile(
                                      newFiles[0]
                                    );
                                    field.onChange(file);
                                  }
                                }}
                                accept=".pdf, .doc, .docx, .xls, .xlsx, .csv, .txt, .rtf"
                              />
                              {field.value ? (
                                <p>Filename: {field.value.name}</p>
                              ) : (
                                <p>
                                  contacts with id, name, email, and phone drop
                                  files or click to upload
                                </p>
                              )}
                            </div>

                            <ErrorContainer errorText={errors.file?.message} />
                          </>
                        );
                      }}
                    />
                  </RowContainer> */}

                  <RowContainer
                    title="Experience Link (for use on mobile phone):"
                    withoutError
                    infoButton="Share the private experience link with customers and contacts for campaign access via email, text, or any method; it downloads the EyCrowd app and loads your experience."
                  >
                    <div className={styles.mobileLinkContainer}>
                      {mobileLinkWatch ? (
                        <p>
                          {mobileLinkPrefix}
                          {mobileLinkWatch}
                        </p>
                      ) : (
                        <p>Generating link...</p>
                      )}

                      <div
                        className={styles.copyContainer}
                        onClick={handleCopyClick}
                      >
                        <CopyIcon color="white" width={18} height={18} />
                        <p>copy</p>
                      </div>
                    </div>
                  </RowContainer>

                  <RowContainer
                    title="EyVocate Selection:"
                    description="select 3 or more"
                    infoButton={
                      isPrivateWatch
                        ? 'Invitees with the Experience Link URL have exclusive access to interact with this private brand experience via the EyCrowd app on their mobile phones.'
                        : 'Choose the interests of the EyVocates you want to invite to your campaign experience. EyVocates select their preferences in their profile. Select 3 or more.'
                    }
                  >
                    {isPrivateWatch ? (
                      <p className={styles.privateExperienceText}>
                        Private Experience selected <br /> (EyVocate selection
                        only applies to public brand experiences).
                      </p>
                    ) : (
                      <>
                        <SearchInput
                          placeholder="search interests"
                          onChange={(event) =>
                            setSearchInterest(event.target.value)
                          }
                          onCancelClick={() => {
                            setSearchInterest('');
                          }}
                          value={searchInterest}
                        />
                        <div className={styles.searchContainer}>
                          {searchedInterest.map((interest) => {
                            return (
                              <Controller
                                name="interests"
                                control={control}
                                key={interest.id}
                                render={({ field }) => {
                                  return (
                                    <div
                                      className={
                                        styles.searchableCheckboxWrapper
                                      }
                                    >
                                      <CheckBox
                                        disabled={isPrivateWatch}
                                        hideErrorMessage
                                        onChange={() => {
                                          if (interest.id === 999) {
                                            field.onChange([interest]);
                                            return;
                                          } else {
                                            const allRangesIndex =
                                              field.value.findIndex(
                                                (value) => value.id === 999
                                              );
                                            if (allRangesIndex !== -1) {
                                              field.onChange([interest]);
                                              return;
                                            }
                                          }

                                          const ind = field.value.findIndex(
                                            (value) => value.id === interest.id
                                          );

                                          const newValues = [...field.value];
                                          if (ind !== -1) {
                                            newValues.splice(ind, 1);
                                            field.onChange(newValues);
                                          } else {
                                            field.onChange([
                                              ...field.value,
                                              interest,
                                            ]);
                                          }
                                        }}
                                        checked={
                                          !!field.value.find(
                                            (value) => value.id === interest.id
                                          )
                                        }
                                      >
                                        {formatMessage(
                                          globalMessages.interests[
                                            interest.slug
                                          ]
                                        )}
                                      </CheckBox>
                                    </div>
                                  );
                                }}
                              />
                            );
                          })}
                        </div>
                        <div className={styles.line} />
                        <ErrorContainer errorText={errors.interests?.message} />
                      </>
                    )}
                  </RowContainer>
                  <RowContainer
                    title="EyVocate Ages:"
                    infoButton={
                      isPrivateWatch
                        ? 'Invitees with the Experience Link URL have exclusive access to interact with this private brand experience via the EyCrowd app on their mobile phones.'
                        : 'Choose the age range of the EyVocates you wish to invite to your campaign experience.'
                    }
                  >
                    {isPrivateWatch ? (
                      <p className={styles.privateExperienceText}>
                        Private Experience selected <br /> (EyVocate selection
                        only applies to public brand experiences).
                      </p>
                    ) : (
                      <>
                        <div className={styles.checkboxesContainer}>
                          {restructureAgeRanges.map((ageRange) => {
                            return (
                              <Controller
                                name="ageRanges"
                                control={control}
                                key={ageRange.id}
                                render={({ field }) => {
                                  return (
                                    <div
                                      className={
                                        styles.checkboxWrapperAgeRanges
                                      }
                                    >
                                      <CheckBox
                                        disabled={isPrivateWatch}
                                        hideErrorMessage
                                        onChange={() => {
                                          if (ageRange.id === 0) {
                                            field.onChange([ageRange]);
                                            return;
                                          } else {
                                            const allRangesIndex =
                                              field.value.findIndex(
                                                (value) => value.id === 0
                                              );
                                            if (allRangesIndex !== -1) {
                                              field.onChange([ageRange]);
                                              return;
                                            }
                                          }

                                          const ind = field.value.findIndex(
                                            (value) => value.id === ageRange.id
                                          );
                                          const newValues = [...field.value];
                                          if (ind !== -1) {
                                            newValues.splice(ind, 1);
                                            field.onChange(newValues);
                                          } else {
                                            field.onChange([
                                              ...field.value,
                                              ageRange,
                                            ]);
                                          }
                                        }}
                                        checked={
                                          !!field.value.find(
                                            (value) => value.id === ageRange.id
                                          )
                                        }
                                      >
                                        {ageRange.label}
                                      </CheckBox>
                                    </div>
                                  );
                                }}
                              />
                            );
                          })}
                        </div>
                        <ErrorContainer errorText={errors.ageRanges?.message} />
                      </>
                    )}
                  </RowContainer>
                  <RowContainer
                    title="EyVocate Gender:"
                    infoButton={
                      isPrivateWatch
                        ? 'Invitees with the Experience Link URL have exclusive access to interact with this private brand experience via the EyCrowd app on their mobile phones.'
                        : 'Choose the genders of the EyVocates you wish to invite to your campaign experience.'
                    }
                  >
                    {isPrivateWatch ? (
                      <p className={styles.privateExperienceText}>
                        Private Experience selected <br /> (EyVocate selection
                        only applies to public brand experiences).
                      </p>
                    ) : (
                      <>
                        <div className={styles.checkboxesContainer}>
                          {genderOptions.map((gender) => {
                            return (
                              <Controller
                                name="genders"
                                control={control}
                                key={gender.id}
                                render={({ field }) => (
                                  <div className={styles.checkboxWrapper}>
                                    <CheckBox
                                      disabled={isPrivateWatch}
                                      hideErrorMessage
                                      onChange={() => {
                                        field.onChange([gender]);
                                      }}
                                      checked={
                                        !!field.value.find(
                                          (value) => value.id === gender.id
                                        )
                                      }
                                    >
                                      {gender.label}
                                    </CheckBox>
                                  </div>
                                )}
                              />
                            );
                          })}
                        </div>
                        <ErrorContainer errorText={errors.genders?.message} />
                      </>
                    )}
                  </RowContainer>
                  <RowContainer
                    title="EyVocate Home Locations:"
                    description="states / large areas recommended"
                    infoButton={
                      isPrivateWatch
                        ? 'Invitees with the Experience Link URL have exclusive access to interact with this private brand experience via the EyCrowd app on their mobile phones.'
                        : 'Choose the location where your EyVocates live for this campaign experience. Select "Planet Earth" for any location. Selecting states and countries is recommended.'
                    }
                  >
                    {isPrivateWatch ? (
                      <p className={styles.privateExperienceText}>
                        Private Experience selected <br /> (EyVocate selection
                        only applies to public brand experiences).
                      </p>
                    ) : (
                      <>
                        <SearchInput
                          placeholder="search locations"
                          onChange={(event) => {
                            setLocationsSearch(event.target.value);
                            debouncedChangeHandler(event.target.value);
                          }}
                          onCancelClick={cancelLocationSearch}
                          value={locationsSearch}
                        />
                        <div
                          className={classNames(
                            styles.searchContainer,
                            styles.locationsSearch
                          )}
                        >
                          {locations.map((location) => {
                            return (
                              <Controller
                                name="locations"
                                control={control}
                                key={location.id}
                                render={({ field }) => (
                                  <CheckBox
                                    disabled={isPrivateWatch}
                                    onChange={() => {
                                      const ind = field.value.findIndex(
                                        (value) => value.id === location.id
                                      );

                                      const newValues = [...field.value];
                                      if (ind !== -1) {
                                        newValues.splice(ind, 1);
                                        field.onChange(newValues);
                                      } else {
                                        field.onChange([
                                          ...field.value,
                                          location,
                                        ]);
                                      }
                                    }}
                                    checked={
                                      !!field.value.find(
                                        (value) => value.id === location.id
                                      )
                                    }
                                  >
                                    {location.label}
                                  </CheckBox>
                                )}
                              />
                            );
                          })}
                        </div>
                        <div className={styles.poweredByGoogleLogoWrapper}>
                          <img
                            className={styles.poweredByGoogleLogo}
                            src={PoweredByGoogleLogo}
                            alt="Powered By Google"
                          />
                          {selectedLocations.map((v) => v.label).join(', ')}
                        </div>
                        <div className={styles.line} />
                        <ErrorContainer errorText={errors.locations?.message} />
                      </>
                    )}
                  </RowContainer>

                  <RowContainer
                    title="Fee Per Hour:"
                    // description="$20 or more recommended"
                    withoutError
                    infoButton="Select the amount per hour to pay your loyal customer EyVocates for this campaign experience. EyVocates receive 60% to 70% of this fee based on their rating and experience. Current minimum is $18. per hour."
                  >
                    <Controller
                      name="pricePerHour"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Slider
                            valuePrefix="$"
                            value={field.value}
                            onChange={(event) => {
                              if (event.target) {
                                field.onChange(event.target.value);
                              } else {
                                field.onChange(event);
                              }
                            }}
                            min={18}
                            max={200}
                            step={0.5}
                          />
                        );
                      }}
                    />
                  </RowContainer>
                  <RowContainer
                    title="Activity Duration:"
                    withoutError
                    infoButton={
                      'Activity duration reflects the time spent by your loyal customers and fans engaging in and refining experience activities as they enjoy your brand experience. Attendees have unlimited opportunities to redo activities as needed as they meet your aims and remain true to their values.'
                    }
                  >
                    <p className={styles.windowEnd}>
                      {timeConvert(
                        selectedTasks
                          .map((item) => item.time)
                          .reduce((prev, next) => +prev + +next)
                      )}
                    </p>
                  </RowContainer>
                  <RowContainer
                    title="EyVocates Earn:"
                    description={'(range based on user rating)'}
                    withoutError
                  >
                    <p className={styles.windowEnd}>
                      {calculateEyVocatesEarningsRange(
                        pricePerHourWatch,
                        selectedTasks.reduce((acc, task) => acc + task.time, 0)
                      )}
                    </p>
                  </RowContainer>
                  <RowContainer
                    title="Total:"
                    withoutError
                    infoButton="The number of EyVocates multiplied by the EyVocate price per hour."
                  >
                    <p className={styles.windowEnd}>${totalPrice.toFixed(2)}</p>
                  </RowContainer>
                </>
              </form>
            </div>
            <div />
          </div>
        </div>
      </>
    );
  }
);

export const RowContainer = ({
  title,
  description,
  infoButton,
  children,
  withoutError,
}) => {
  return (
    <div
      className={classNames(
        styles.rowContainer,
        withoutError && styles.rowContainerWithoutError
      )}
    >
      <p className={styles.rowLabel}>{title}</p>
      <div className={styles.rowActions}>
        {infoButton && (
          <Tooltip
            className={styles.tooltip}
            label={`${title} ${description}`}
            customContent={<p>{infoButton}</p>}
          />
        )}
      </div>
      <div className={styles.rowContent}>{children}</div>
    </div>
  );
};

export default CampaignExperienceBasics;
