import React from 'react';
import styles from './CodaPartner.module.scss';

function CodaPartner() {
  return (
    <iframe
      className={styles.embed}
      src="https://coda.io/embed/psmLuTWKhe/_suMoP?viewMode=embedplay"
      width="1189"
      height="607"
      style={{ maxWidth: '100%' }}
      allow="fullscreen"
    ></iframe>
  );
}

export default CodaPartner;
